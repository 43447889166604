.colorInput {
  /* ... */
}

.input {
  pointer-events: none;
}

.colorPreview {
  width: 10px;
  height: 10px;

  border-radius: 1px;

  box-sizing: border-box;
  border: 1px solid transparent;
}
