.expandible {
  display: flex;
  flex-direction: column;
}
.expandible > * {
  width: fit-content;
}

.button {
  margin-top: 9px;
  opacity: 0.5;
  cursor: pointer;
}
.button:hover {
  opacity: 0.7;
}
.button::before {
  content: '(';
}
.button::after {
  content: ')';
}
