.main .content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.content p {
  margin: 0 0 20px 0;
  padding: 0;
  color: #656565;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}
