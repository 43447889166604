.collapse {
  user-select: none;
  box-sizing: border-box;
}

.collapse.borderTop {
  border-top: 1px solid #eae8e4;
}

.collapse.borderBottom {
  border-bottom: 1px solid #eae8e4;
}

/* Removes border in adjacent closed collapses */
.collapse:not(.open) + .collapse {
  border-top: none;
}

.collapseLabel {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  background-color: #f6f5f4;
  border-bottom: 1px solid #eae8e4;
  width: 100%;
  height: 36px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
}

.labelDescription {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
  padding: 15px 15px 0 15px;
}

.labelActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 15px 4px 15px;
  box-sizing: border-box;
}

.labelActions.borderTop {
  border-top: 1px solid #eae8e4;
}

.labelActions.borderBottom {
  border-bottom: 1px solid #eae8e4;
}

.collapseLabelTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
}

.collapseLabelTitle svg {
  position: relative;
  margin: 0 7px 0 0;
  transition: transform 0.1s ease-in-out;
}

.collapseLabelTitle .rotate svg {
  transform: rotate(-90deg);
}

.collapseLabelAddButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  line-height: 20px;
  cursor: pointer;
}

.collapseLabelAddButtonLabel {
  color: #5d5d5c;
  font-size: 13px;
  font-weight: 500;
  margin-right: 8px;
}

.collapseChildren {
  will-change: height, opacity;
  transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  overflow: hidden;
}

.collapseChildren.hide {
  opacity: 0;
}

.collapseChildren.show {
  opacity: 1;
}

.collapseChildrenContent {
  box-sizing: border-box;
  overflow: visible;
}

.collapseCount {
  color: #807e7c;
  font-size: 11px;
  background-color: #dddbd7;
  border-radius: 2px;
  padding: 0 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
