.card {
  position: relative;
  box-sizing: border-box;

  background-color: #ffffff;

  border: 1px solid #dddbd7;
  border-radius: 4px;

  width: 100%;
}

/* Overlay for graying out and loader */
.cardOverlay {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 16;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.7);
}
.card,
.cardOverlay {
  border-radius: 4px;
}
