/* ------------------------------ COMMON STYLES ----------------------------- */

.submissionFieldHeader {
  overflow: hidden;

  background: none;
  border: none;
  margin: 0;
  padding: 0;

  user-select: none;

  display: flex;
  align-items: center;
}

.submissionFieldHeader > * {
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
}
.submissionFieldHeader > .icon {
  display: flex;
  align-items: flex-start;
  width: 24px;
  overflow: hidden;
}

.dropdownIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-left: 12px;
}

.label {
  overflow: hidden;
  flex-shrink: 1;
  min-width: 0;
  text-overflow: ellipsis;
}

/* --------------------------- WHEN USED IN TABLE --------------------------- */

/* Minimum width and size to fit text */
.theme-for-table {
  width: 100%;
  min-width: 120px;
}
/* Dropdown indicator in right corner */
.theme-for-table .dropdownIcon {
  flex-grow: 1;
}

.theme-for-table .label {
  color: #454442;

  font-weight: 500;
  line-height: 16px;
}

/* --------------------------------- THEMES --------------------------------- */

.submissionFieldHeader.theme-for-table {
  /* Content wrapper will cover entire cell (assuming it's padding is 8px 16px) */
  box-sizing: content-box;
  width: 100%;
  margin: -8px -16px;
  padding: 8px 16px;
}

/* Ellipsis for too long headers */
.submissionFieldHeader.theme-for-rows {
  min-width: 0;
  max-width: 100%;
}

.submissionFieldHeader.theme-for-rows .label {
  color: #807e7c;

  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}
