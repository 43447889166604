/* ------------------------------ SHARED STYLES ----------------------------- */

.checkboxGroup {
  user-select: none;
  display: flex;
  flex-direction: column;
}

.header {
  color: #5d5d5c;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  margin-bottom: 5px;
}

.placeholder {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  padding: 8px 12px;

  color: #454442;
  background-color: #f6f5f4;

  border: 1px solid #dddbd7;
  border-radius: 4px;
}

/* ----------------------------- DEFAULT LAYOUT ----------------------------- */

.checkboxGroupDefault {
  display: grid;

  grid-template-columns: auto 1fr;
}

.checkboxGroupDefaultInput {
  cursor: pointer;
  padding-right: 10px;
}

.checkboxGroupDefaultLabel {
  cursor: pointer;
  color: #807e7c;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 6px 0;
}

/* ------------------------------ BLOCKS LAYOUT ----------------------------- */

.checkboxGroupBlocks {
  display: grid;

  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

/*
  Block
*/
.checkboxGroupBlocksBlock {
  cursor: pointer;

  display: flex;
  align-items: center;

  padding: 5px 12px;

  background-color: #f6f5f4;

  border: 1px solid #f6f5f4;
  border-radius: 4px;
}
.checkboxGroupBlocksBlock.active {
  border: 1px solid #dddbd7;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.05);
}

/*
  Elements within block
*/
.checkboxGroupBlocksBlock > * {
  flex-shrink: 0;
  flex-grow: 0;
}
.checkboxGroupBlocksBlock > *:not(:last-child) {
  margin-right: 15px;
}

.checkboxGroupBlocksLabel {
  flex-shrink: 1;
  flex-grow: 1;

  color: #454442;
  font-size: 13px;
  line-height: 16px;

  font-weight: 500;
}

/* ------------------------- BLOCKS COMPACT LAYOUT -------------------------- */

.checkboxGroupBlocksCompact {
  display: flex;
  flex-direction: row;
}
.checkboxGroupBlocksCompact > *:not(:last-child) {
  margin-right: 8px;
}

/*
  Block
*/
.checkboxGroupBlocksCompactBlock {
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 9px;
  height: 36px;

  color: #454442;
  background-color: #ffffff;

  border: 1px solid #dddbd7;
  border-radius: 4px;
}
.checkboxGroupBlocksCompactBlock.active {
  background-color: #f6f5f4;
  border: 1px solid #dddbd7;
}
