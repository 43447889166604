.uploadsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.uploadPreviewSuccessful,
.uploadPreviewFailed {
  white-space: pre;
  line-height: 1.5;

  display: flex;
  align-items: center;
}

.uploadPreviewSuccessful {
  cursor: pointer;
  color: #2675d1;
  text-decoration: underline;
}
.downloadIcon {
  width: 14px;
  margin-right: 5px;
}

.uploadPreviewFailed {
  cursor: not-allowed;
  color: #b4b2af;
  user-select: none;
}

/* --------------------------------- THEMES --------------------------------- */

.filesUploadPreview.theme-for-table {
  /* with this list cells are more visually aligned with regular text cells */
  margin: -4px;
}
