.table {
  border-collapse: collapse;
  border: none;
}

.table td {
  vertical-align: middle;
}

.table tr:not(:last-child) td {
  padding-bottom: 0.5ch;
}

.table .bullet {
  padding-right: 0.5ch;
}

.table .label {
  white-space: nowrap;
  padding-right: 1ch;
}
.label::after {
  content: ' :';
}
