/* -------------------------------------------------------------------------- */
/*                                 ANIMATIONS                                 */
/* -------------------------------------------------------------------------- */

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translateX(3000px) scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translateX(-12px) scaleX(1);
  }

  75% {
    transform: translateX(5px) scaleX(0.98);
  }

  90% {
    transform: translateX(-2px) scaleX(0.995);
  }

  to {
    transform: translateX(0);
  }
}

/* -------------------------------------------------------------------------- */
/*                           COUNTDOWN PROGRESS BAR                           */
/* -------------------------------------------------------------------------- */

.countdownProgressBar {
  box-sizing: border-box;
  overflow: hidden;

  height: 4px;
  width: 100%;

  color: #807e7c;
  background-color: white;

  /*
  border-top: 1px solid #eae8e4;
  */
}
.countdownProgressBarLane {
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  color: inherit;
  background-color: currentColor;
}

/* -------------------------------------------------------------------------- */
/*                                 CONTAINERS                                 */
/* -------------------------------------------------------------------------- */

/* Container managed by react-alert with injected style */
.snackbarOuterContainer {
  user-select: none;
}

/* Container with additional enter animation */
.snackbarContainer {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  width: 400px;

  animation-name: bounceInRight;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;

  border-radius: 4px;

  box-shadow: 0 0px 3px rgba(48, 47, 46, 0.3), 0 4px 4px rgba(48, 47, 46, 0.15), 0 8px 8px rgba(48, 47, 46, 0.15);
}

/* -------------------------------------------------------------------------- */
/*                                   CONTENT                                  */
/* -------------------------------------------------------------------------- */

/* Content container */
.contentContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  min-height: 60px;

  width: 100%;
  min-width: 0;
  padding: 18px 12px;
  padding-right: 24px;
  padding-bottom: 14px;

  color: #282f2e;
  background-color: #ffffff;
}

/* Close button */
.closeButton {
  position: absolute;
  top: 0px;
  right: 5px;

  padding: 3px;
  margin: 0;

  cursor: pointer;

  font-size: 20px;
  line-height: 1em;
  font-weight: 500;
  transform: scaleY(0.7);

  color: #b4b2af;
  background: none;

  outline: none;
  border: none;
}
.closeButton:hover {
  color: #454442;
}

/* Content */
.content {
  display: flex;
  align-items: center;

  min-width: 0;
  width: 100%;
}

.content > .icon {
  flex-grow: 0;
  flex-shrink: 0;

  margin-right: 18px;

  transform: scale(1.3) translateX(2px);
}
.message {
  display: flex;
  flex-direction: column;

  flex-shrink: 1;
  flex-grow: 1;

  min-width: 0;
}
.message > *:not(:last-child) {
  margin-bottom: 5px;
}

.message p {
  font-size: 13px;
  font-weight: 600;

  line-height: 1.3;
  white-space: pre-wrap;
}

.message small {
  color: #b4b2af;
  font-weight: 600;
}

/* -------------------------------------------------------------------------- */
/*                                   THEMES                                   */
/* -------------------------------------------------------------------------- */

/* Success */
.snackbarContainer.type-success .countdownProgressBar,
.snackbarContainer.type-success .icon {
  color: #0f9f6e;
}

/* Info */
.snackbarContainer.type-info .countdownProgressBar,
.snackbarContainer.type-info .icon {
  color: #2675d1;
}

/* Error */
.snackbarContainer.type-error .countdownProgressBar,
.snackbarContainer.type-error .icon {
  color: #f05252;
}

/* Warning */
.snackbarContainer.type-warning .countdownProgressBar,
.snackbarContainer.type-warning .icon {
  color: #fbc75d;
}
