/* ----------------------------- DEFAULT STYLES ----------------------------- */

/* Tabs container */
.container {
  user-select: none;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;

  position: relative;

  box-sizing: border-box;

  min-height: 28px;
}

/* Tabs item */
.item {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  color: #807e7c;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  cursor: pointer;

  padding: 0;
  margin: 0;
  border: none;
  background: none;
}
.theme-underline .item:disabled {
  cursor: default;
}
.item:last-child {
  margin-right: 0;
}
.item.active {
  cursor: default;
}

.itemInner {
  display: flex;
  justify-content: center;
}
.itemInner > *[data-component='icon'] {
  margin-right: 5px;
}

.separator,
.indicator {
  display: none;
}

/* ----------------------------- CONTAINED THEME ---------------------------- */

.theme-contained .item {
  justify-content: center;

  z-index: 2;
  padding: 6px 12px;
  border-radius: 7px;
  background-color: transparent;

  transition: background-color 0.05s ease-in-out;
}
.theme-contained > *:not(:last-child) {
  margin-right: 6px;
}
.theme-contained .item:hover,
.theme-contained .item:focus {
  background-color: #f7f5f0;
}
.theme-contained .item.active {
  background-color: #eae8e4;
  color: #454442;
}

/*
.theme-contained-moving .item {
  z-index: 2;
  padding: 6px 12px;
  border-radius: 7px;
  background-color: transparent;
}
.theme-contained-moving .indicator {
  z-index: 1;

  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 7px;

  width: 0px;
  background-color: #eae8e4;
  transform-origin: top left;
  transform: translateX(0px) scaleX(1);
}
*/

/* ----------------------------- UNDERLINE THEME ---------------------------- */

.theme-underline.container {
  align-items: stretch;
}

.theme-underline > *:not(:last-child) {
  margin-right: 30px;
}
.theme-underline .item {
  padding-bottom: 10px;
}
.theme-underline .item:hover,
.theme-underline .item:focus {
  color: #b4b2af;
}
.theme-underline .item.active {
  color: #454442;
}
.theme-underline .item:disabled {
  color: #807e7c;
  opacity: 0.5;
}

/* Lines under tabs */
.theme-underline .separator,
.theme-underline .indicator {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  height: 2px;
}

/* Line under tabs */
.theme-underline .separator {
  background-color: #eae8e4;
  width: 100%;
}

/* Selection indicator under tabs */
.theme-underline .indicator {
  width: 0px;
  background-color: #454442;
  transform-origin: top left;
  transform: translateX(0px) scaleX(1); /* Dynamic value set from JS */
}

/* Disabled mode */
.disabled .indicator {
  display: none;
}
