.macBar {
  height: 40px;
  font-size: 13px;
}

.left,
.right,
.middle {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.left {
  padding-left: 20px;
}
.right {
  padding-right: 20px;
}

.middle {
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
}

.middle > *:not(:last-child),
.left > *:not(:last-child) {
  margin-right: 13px;
}
.right > *:not(:last-child) {
  margin-right: 20px;
}

/* Three color dots */
.dots {
  display: flex;
}
.dots > * {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-right: 13px;
}
.dots > *:not(:last-child) {
  margin-right: 8px;
}
