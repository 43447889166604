.urlPreview {
  display: block;

  text-overflow: ellipsis;
  overflow: hidden;
}
.urlPreview > a {
  color: #2675d1;
  text-decoration: underline;
}
.urlPreview > a:visited {
  color: #9061f9;
  text-decoration: underline;
}

/* --------------------------------- THEMES --------------------------------- */

.urlPreview.theme-for-table {
  white-space: nowrap;
  max-width: 400px;
}
.urlPreview.theme-for-rows {
  max-width: 100%;
  word-break: break-all;
}
