.hint {
  /* ... */
}

.content {
  max-width: 220px;
  font-size: 13px;
  line-height: 16px;

  text-align: center;
}
