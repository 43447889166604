.upsellModal {
  max-width: 400px;
  padding: 20px 0;

  display: flex;
  flex-direction: column;

  text-align: center;

  font-size: 13px;
  font-weight: 400;
  line-height: 1.5em;
  color: #5d5d5c;
}
.upsellModal > *:not(:last-child) {
  margin-bottom: 20px;
}

.featureName {
  font-size: 15px;
  font-weight: 500;

  padding: 15px 25px;

  color: white;
}

.message {
  padding: 0px 25px;
}
.message > *:not(:last-child) {
  margin-bottom: 7px;
}

.planName {
  font-weight: 600;
  color: #282f2e;
}

/* Cancel, confirm buttons */
.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 0px 20px;
}
.buttons > *:not(:last-child) {
  margin-right: 20px;
}
