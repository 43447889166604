/* ---------------------------------- LIST ---------------------------------- */

.list {
  position: relative;
}

/* ------------------------------- DRAG HANDLE ------------------------------ */

.list.enabled .dragHandle {
  cursor: grab;
}

/* -------------------------- DEFAULT ITEM STYLING -------------------------- */

.list:not(.dragging) .dropLine {
  display: none !important;
}

.sortedItem {
  /* border-box ir required for react-sortful to work right */
  box-sizing: border-box !important;
}

/* ----------------------------- SORTING STATES ----------------------------- */

.sortedItem[data-sortful-role='placeholder'] {
  pointer-events: none;
}

@keyframes ghostize {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.8;
  }
}
.sortedItem[data-sortful-role='ghost'] {
  pointer-events: none;

  animation-name: ghostize;
  animation-duration: 200ms;
  animation-fill-mode: forwards;

  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.05);
}

/* --------------------------- DEFAULT DROP LINES --------------------------- */

.dropLine {
  height: 2px;
  background-color: #807e7c;
}

.dropLine.horizontal {
  width: 2px;
  height: auto;
}

.dropLine::before,
.dropLine::after {
  --size: 6px;
  position: absolute;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: inherit;
  content: '';
}

.dropLine::before {
  top: calc(var(--size) / 2 * -1 + 1px);
  left: calc(var(--size) / 2 * -1);
}

.dropLine::after {
  top: calc(var(--size) / 2 * -1 + 1px);
  right: calc(var(--size) / 2 * -1);
}

.dropLine.horizontal::before {
  top: calc(var(--size) / 2 * -1);
  left: calc(var(--size) / 2 * -1 + 1px);
}

.dropLine.horizontal::after {
  top: auto;
  bottom: calc(var(--size) / 2 * -1);
  left: calc(var(--size) / 2 * -1 + 1px);
}
