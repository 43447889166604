.label {
  padding: 0 15px;
  user-select: none;
  box-sizing: border-box;
}

.label.borderTop {
  border-top: 1px solid #eae8e4;
}

.label.borderBottom {
  border-bottom: 1px solid #eae8e4;
}

.label .labelContent {
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.label .labelTextTop {
  width: 100%;
}

.label .labelTextTopContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  min-height: 36px;
  width: 100%;
}

.label .labelTextTopContent.withBack {
  cursor: pointer;
}

.label .labelDescription {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
  padding: 0;
  margin: 0 0 15px 0;
}

.label .labelBack {
  cursor: pointer;
  width: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  transform: rotate(90deg);
}

.label .labelText {
  width: calc(100% - 20px);
}

.label .labelClose {
  cursor: pointer;
  width: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
}

.label .labelActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 4px 0;
}

.label .labelAddButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;

  cursor: pointer;
}

.label .labelAddButtonLabel {
  color: #5d5d5c;
  font-size: 13px;
  font-weight: 500;
  margin-right: 8px;
}
