.content {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 84px);
  max-height: calc(90vh - 84px);
  overflow-y: auto;
}

.strong {
  color: #454442;
  font-weight: 600;
}

.usage {
  margin: 0 0 30px 0;
}

.label {
  color: #302F2E;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
}

.label span {
  color: #807E7C;
  font-weight: 400;
  padding: 0 0 0 5px;
}

.track {
  background-color: #EAE8E4;
  border-radius: 0 9px 9px 0;
  width: 100%;
  height: 9px;
  margin: 10px 0 0 0;
}

.track .bar {
  background-color: #FF623D;
  border-radius: 0 9px 9px 0;
  height: 9px;
}
