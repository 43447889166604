/* ------------------------------ MAIN MESSAGE ------------------------------ */

.notFound {
  position: absolute;
  box-sizing: border-box;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #5d5d5c;

  font-size: 13px;
  text-align: center;
  line-height: 1.5;
}
.notFound.inline {
  overflow: hidden;

  position: static;
  transform: none;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  padding: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.logo {
  width: 50px;
  height: 50px;
}

.icon {
  position: relative;

  margin-bottom: 15px;

  opacity: 0;
  animation: fadeIn 1.5s forwards;
  animation-delay: 0s;
}

.header {
  color: #454442;
  font-weight: 600;

  line-height: 16px;
  margin-bottom: 3px;

  opacity: 0;
  animation: fadeIn 1.5s forwards;
  animation-delay: 0.5s;
}
.message {
  color: #807e7c;
  font-weight: 500;
  line-height: 16px;

  opacity: 0;
  animation: fadeIn 1.5s forwards;
  animation-delay: 1s;
}

.header,
.message {
  width: 35ch;
  max-width: 100%;
}

/* ---------------------------------- HINT ---------------------------------- */

@keyframes leftToRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
}
@keyframes rightToRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
  }
}
@keyframes topToBottom {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}
@keyframes bottomToTop {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.hint {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  user-select: none;

  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.hintArrow * {
  fill: currentColor;
  stroke: currentColor;
}

.hintArrow {
  opacity: 0;
  animation: fadeIn 3s forwards;
  animation-delay: 2s;
}
.hintMessage {
  opacity: 0;
  animation: fadeIn 3s forwards;
  animation-delay: 2s;
}

/* Hint placement */
.hint[data-placement^='top'] {
  top: 20px;
}
.hint[data-placement^='bottom'] {
  bottom: 20px;
}
.hint[data-placement$='start'] {
  left: 30px;
}
.hint[data-placement$='end'] {
  right: 30px;
}
.hint[data-placement$='center'] {
  left: 50%;
  transform: translateX(-50%);
}

/* Hint direction container props */
.hint[data-direction='top'] {
  flex-direction: column;
  animation-name: topToBottom;
}
.hint[data-direction='bottom'] {
  flex-direction: column;
  animation-name: bottomToTop;
}
.hint[data-direction='left'] {
  flex-direction: row;
  animation-name: leftToRight;
}
.hint[data-direction='right'] {
  flex-direction: row;
  animation-name: rightToLeft;
}

/* Hint direction arrow props */
.hint[data-direction^='top'] .hintArrow {
  margin-bottom: 10px;
  transform: rotate(90deg);
}
.hint[data-direction^='bottom'] .hintArrow {
  margin-top: 10px;
  order: 2;
  transform: rotate(-90deg);
}
.hint[data-direction$='left'] .hintArrow {
  margin-right: 10px;
  transform: rotate(0deg);
}
.hint[data-direction$='right'] .hintArrow {
  margin-left: 10px;
  order: 2;
  transform: rotate(180deg);
}
