/* ------------------------- DEFAULT WRAPPER STYLING ------------------------ */

.listWrapper {
  min-width: 0;
  flex-shrink: 1;

  position: relative;
  box-sizing: border-box;
}

.list {
  min-width: 0;
  flex-shrink: 0;

  box-sizing: border-box;
  display: flex;
}
.vertical .list {
  flex-direction: column;
}
.horizontal .list {
  display: flex;
}

/* -------------------------------------------------------------------------- */
/*                         STYLES FOR SCROLLABLE MODE                         */
/* -------------------------------------------------------------------------- */

/* ---------------------------- SCROLLABLE STYLES --------------------------- */

.scrollContainer {
  box-sizing: border-box;
}
.vertical > .scrollContainer {
  overflow-y: scroll;
}
.horizontal > .scrollContainer {
  overflow-x: scroll;
}

/* Adds right padding that should be there but isn't due to overflow */
.scrollContainer > .list::after {
  flex-shrink: 0;
  content: ' ';
  display: block;

  white-space: pre;
  overflow: hidden;

  width: 10px;
  height: 100%;
}

.hideScrollBar.scrollContainer {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.hideScrollBar.scrollContainer::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

/* ------------------------------- SCROLL EDGE ------------------------------ */

.scrollEdgeTop,
.scrollEdgeBottom {
  content: '';
  display: block;
  z-index: 100;

  position: absolute;
  width: 40px;
  height: 40px;

  /*
    background-color: rgba(0, 0, 0, 0.2);
  */
}

.horizontal > .scrollEdgeTop,
.horizontal > .scrollEdgeBottom {
  top: 0;
  height: 100%;
}
.vertical > .scrollEdgeTop,
.vertical > .scrollEdgeBottom {
  left: 0;
  width: 100%;
}
.horizontal > .scrollEdgeTop {
  left: 0;
}
.horizontal > .scrollEdgeBottom {
  right: 0;
}
.vertical > .scrollEdgeTop {
  top: 0;
}
.vertical > .scrollEdgeBottom {
  bottom: 0;
}

/* -------------------------------------------------------------------------- */
/*                               SHRINKING ITEMS                              */
/* -------------------------------------------------------------------------- */

.shrinkItems {
  overflow: hidden;
}
/* list is direct children of .listWrapper if scrollable === false */
.shrinkItems .list > * {
  flex-shrink: 1;
  min-width: 43px;
}
/* Removes width styling from dropline and ghost element wrappers */
.shrinkItems .list > .dropLine,
.shrinkItems .list > .dropLine + * {
  min-width: initial;
  width: initial;
}

/* -------------------------------------------------------------------------- */
/*                            DEFAULT ITEM STYLING                            */
/* -------------------------------------------------------------------------- */

.dropLine {
  background-color: #807e7c;
}
:not(.dragging) > .dropLine {
  display: none !important;
}

.vertical .dropLine {
  height: 1px !important;
}
.horizontal .dropLine {
  width: 1px !important;
}

.item {
  /* border-box ir required for react-sortful to work right */
  box-sizing: border-box !important;
}

.dragHandle {
  width: 16px;
  height: 16px;
  cursor: grab;
}
.dragHandle svg {
  height: 100%;
  fill: black;
}

/* ----------------------------- SORTING STATES ----------------------------- */

.item[data-sorting-role='placeholder'] {
  pointer-events: none;
}

@keyframes ghostize {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.8;
  }
}
.item[data-sorting-role='ghost'] {
  pointer-events: none;

  animation-name: ghostize;
  animation-duration: 200ms;
  animation-fill-mode: forwards;

  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.05);
}
