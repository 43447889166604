.form {
  position: relative;

  width: 100%;
  background-color: white;
}

.placeholder {
  user-select: none;
  box-sizing: border-box;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 15px;

  line-height: 1.5;
  font-weight: 500;
  text-align: center;

  color: #807e7c;
  background-color: #eae8e4;
}
