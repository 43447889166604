.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0 0 0;
  padding: 0;
  box-sizing: border-box;
}

.main li {
  min-width: 14px;
  min-height: 14px;
  margin: 0 7px 7px 0;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
}

.main li.selected:after {
  background-image: url(~assets/images/small-check.svg);
  cursor: default;
  transform: scale(0.8);
}

.main li:after {
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid transparent;
  display: block;
  content: '';
  box-sizing: border-box;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
}

.main li span {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: block;
}
