.beta {
  display: inline-block;

  font-size: 9px;
  line-height: 15px;

  padding: 0px 4px;

  background-color: #b4b2af;
  color: white;

  border-radius: 4px;
}
