.timestampPreview {
  display: flex;

  white-space: pre;
}

/* --------------------------------- THEMES --------------------------------- */

.timestampPreview.theme-for-table {
  flex-direction: column;
}
.timestampPreview.theme-for-table > .distance {
  margin-top: 6px;
  opacity: 0.5;
}

.timestampPreview.theme-for-rows > * {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
}
.timestampPreview.theme-for-rows > .distance {
  margin-left: 6px;
}
.timestampPreview.theme-for-rows > .distance::before {
  content: '(';
}
.timestampPreview.theme-for-rows > .distance::after {
  content: ')';
}
