/* ----------------------------- MAIN CONTAINER ----------------------------- */

.filteringMatrix {
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;

  min-height: 50px;
}

.placeholder {
  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  color: #807e7c;
  padding: 10px 20px;
}

/* ------------------------------ ROWS ELEMENTS ----------------------------- */

.filteringRow {
  box-sizing: border-box;

  display: flex;
  align-items: center;
  width: 100%;

  padding: 10px 20px;
}
.filteringRow.disabled > * {
  opacity: 0.4;
}
.filteringRow.readOnly {
  cursor: not-allowed;
}

.filteringRow:nth-child(even) {
  background-color: hsla(0, 0%, 0%, 0.02);
}

.modeInput {
  flex-grow: 0;
  flex-shrink: 0;
  width: 40px;

  font-weight: 500;
  color: #b4b2af;

  margin-right: 15px;
  user-select: none;
}
.columnInput {
  flex-grow: 0;
  flex-shrink: 0;
  width: 140px;

  margin-right: 15px;
}
.conditionInput {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 15px;
}
.conditionInput * {
  font-weight: 500;
}
.valueInput {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 20px;
  min-width: 0;
}
.deleteButton {
  flex-grow: 0;
  flex-shrink: 0;
}

/* --------------------------------- INPUTS --------------------------------- */

.rangeInput {
  display: flex;
  align-items: center;
}
.rangeInput > *:not(:last-child) {
  margin-right: 7px;
}
.rangeInput > span {
  color: #b4b2af;
}
