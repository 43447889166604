.tutorialSkipper {
  width: 450px;
}
.content {
  line-height: 1.5;
}
.content > *:not(:last-child) {
  margin-bottom: 20px;
}
.content img {
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  border: 1px solid #dddbd7;

  border-radius: 4px;
}
