.chatBox {
  height: 100%;

  position: relative;
  display: flex;
  flex-direction: column;

  background: #f6f5f4;
}

/* -------------------------------- MESSAGES -------------------------------- */

.messages {
  flex-grow: 1;
  flex-shrink: 1;

  font-size: 12px;

  padding: 14px;

  overflow-y: auto;
}
.placeholder {
  text-align: center;
  font-weight: 500;
  color: #b4b2af;

  padding: 10px;
}

.message {
  display: flex;
  align-items: stretch;
}

.messageLeftColumn {
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 10px;
}
.messageLeftColumn > * {
  flex-shrink: 0;
  flex-grow: 0;
}

/* Left line */
.message:not(:last-child) .messageLeftColumn::after {
  content: '';
  display: block;

  width: 1px;
  height: 100%;

  background: #dddbd7;

  margin: 6px 0;
}

.messageRightColumn {
  flex-shrink: 1;
  flex-grow: 1;

  min-width: 0;
}

.messageHeader {
  display: flex;
  align-items: center;

  line-height: 14px;
  margin-bottom: 4px;
}
.messageAuthor {
  flex-shrink: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  margin-right: 10px;
  font-weight: 500;
  color: #454442;
}
.messageDate {
  flex-shrink: 1;
  white-space: nowrap;
  color: #807e7c;
  margin-right: 10px;
}
.messageShowMore {
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
  padding-right: 10px;
}

.messageText {
  color: #454442;
  line-height: 18px;

  overflow-wrap: break-word;
}
.message:not(:last-child) .messageText {
  padding-bottom: 15px;
}

/* -------------------------- EDITOR AT THE BOTTOM -------------------------- */

.editor,
.editorPlaceholder {
  flex-grow: 0;
  flex-shrink: 0;

  padding: 14px;

  background: #fbfaf9;
  border-top: 1px solid #dddbd7;
}
.editor,
.editorPlaceholder {
  display: flex;
}
.editor > *:first-child {
  margin-top: 2px;
  margin-right: 10px;
  flex-shrink: 0;
}

.editorPlaceholder {
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #807e7c;

  text-align: center;
  line-height: 1.5em;

  font-weight: 600;
}

/* ------------------------------ PLACEHOLDERS ------------------------------ */

.skeleton {
  color: transparent;
  background-color: #eae8e4;
  display: inline-block;
  user-select: none;
}
