@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

html,
#root {
  height: 100%;
}

#root {
  min-height: 100vh;
}

body {
  min-height: 100%;
  background: #ffffff;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  overscroll-behavior: none;
  overflow: hidden;
}

input {
  border: none;
  background: none;

  &:focus {
    outline: 0;
  }
}

select {
  border: none;
  background: none;

  &:focus {
    outline: 0;
  }
}

textarea {
  border: none;
  background: none;

  &:focus {
    outline: 0;
  }
}

button {
  &:focus {
    outline: 0;
  }
}

a {
  text-decoration: none;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

::-webkit-scrollbar {
  width: 14px; /* Total width including `border-width` of scrollbar thumb */
  height: 14px;

  /* Border as black with alpha so that border is visible on darker backgrounds too */
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  box-sizing: border-box;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-left: 3px solid rgba(0, 0, 0, 0);
  border-right: 3px solid rgba(0, 0, 0, 0);
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-bottom: 3px solid rgba(0, 0, 0, 0);

  background-clip: padding-box;
  border-radius: 6px;

  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-corner {
  background: transparent;
}

/* Full story excluded elements highlight */
.developmentt-mode .full-story-no-record {
  outline: 1px solid pink;
  outline-style: dashed;
  outline-offset: -1px;
  background-color: #ffc0cb1e;
}
.developmentt-mode .full-story-no-record-hard {
  outline: 1px solid rgb(192, 194, 255);
  outline-style: dashed;
  outline-offset: -1px;
  background-color: #c0d7ff1e;
}
