.mediaGridWrapper {
  box-sizing: border-box;
  width: 100%;
}

.mediaGrid {
  box-sizing: border-box;

  margin: auto;

  display: grid;
}

.mediaGridItem {
  box-sizing: border-box;
  min-width: 0;
}
