.header {
  display: flex;
  flex-direction: column;
}
.header > *:not(:last-child) {
  margin-bottom: 5px;
}

.title {
  display: flex;
  align-items: center;

  color: #454442;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  height: 20px;
}
.title > *:not(:last-child) {
  margin-right: 10px;
}
.title > *[data-component='icon'] {
  height: 14px;
}
