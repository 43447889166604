.button {
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  height: 40px;
  box-sizing: border-box;
  padding: 0 15px;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.button:focus {
  outline: 0;
}

.button.fullWidth {
  width: 100%;
}

.button svg {
  margin-right: 10px;
}

.button.onlyIcon svg {
  margin-right: 0;
}

.button:disabled,
.button.disabled {
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
}

/* Sizes */
.button.small {
  height: 26px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 10px;
}

.button.default {
  height: 32px;
  font-size: 13px;
  line-height: 17px;
}

.button.medium {
  height: 34px;
  font-size: 14px;
  line-height: 18px;
}

.button.large {
  height: 38px;
  font-size: 16px;
  line-height: 20px;
}

/* Blue */
.button.blue {
  border: 1px solid #732A56;
  background: #732A56;
  color: #FFFFFF;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}

/* White */
.button.white {
  border: 1px solid #E7E7E7;
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  color: #000000;
}


/* Black */
.button.black {
  border: 1px solid #202020;
  background: #202020;
  color: #FFFFFF;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}

/* Red */
.button.red {
  border: 1px solid #D43D51;
  background: #D43D51;
  color: #FFFFFF;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}

/* Orange */
.button.orange {
  border: 1px solid #FF5602;
  background: #FF5602;
  color: #FFFFFF;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
