.main .content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 15px 0;
  color: #656565;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  box-sizing: border-box;
}

.content .qrCode {
  margin: 0px auto 20px auto;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.content .qrCode.visible {
  opacity: 1;
}

.content p {
  margin: 10px 0 0 0;
}

.digitsTitle {
  color: #363636;
  font-size: 15px;
  width: 100%;
  text-align: center;
  margin: 0 0 5px 0;
}

.error {
  text-align: center;
  width: 100%;
  color: #f05252;
  font-size: 13px;
  line-height: 16px;
}
