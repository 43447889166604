/* Root */
.react-datepicker {
  font-family: inherit;
  font-size: 12px;

  border-radius: 0px;
  border: none;
}
.react-datepicker * {
  user-select: none;
}

.react-datepicker * {
  font-size: 12px;
}

/* Header content */
.react-datepicker__header {
  border-radius: 0;
  background-color: #fbfaf9;
  border-color: #eae8e4;
}
.react-datepicker__header * {
  color: #454442;
}
.react-datepicker__current-month {
  font-size: 13px;
  font-weight: 600;
}
.react-datepicker__day-name {
  text-transform: lowercase;
}

.react-datepicker__day {
  color: #454442;
  line-height: 25px;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #fbfaf9;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range {
  cursor: default;
  background-color: #eae8e4;
  color: #454442;
}

.react-datepicker__time-list-item {
  font-weight: 500 !important;
  color: #454442 !important;
}
.react-datepicker__time-list-item:hover:not(.react-datepicker__time-list-item--selected) {
  background-color: #fbfaf9 !important;
}
.react-datepicker__time-list-item--selected {
  font-weight: 600 !important;
  background-color: #eae8e4 !important;
  color: #454442 !important;
  cursor: default !important;
}
