.main {
  min-height: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px 0 30px; 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #EAE8E4;
}

.title {
  margin: 20px 0 5px 0;
}

.description {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  margin: 5px 0 15px 0;
}

.tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.tabs li {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  margin: 10px 0 0 0;
  padding: 0 0 10px 0;
  position: relative;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 2px 0px -1px transparent;
  transition: box-shadow 0.1s ease-in, color 0.1s ease-in;
}

.tabs li:not(:first-child) {
  margin: 10px 0 0 30px;
}

.tabs li:hover {
  color: #454442;
}

.tabs li:active,
.tabs li.active {
  box-shadow: 0 2px 0px -1px #454442;
  color: #454442;
}

.tabs li .number {
  position: absolute;
  top: 1px;
  background-color: #EAE8E4;
  border-radius: 2px;
  padding: 0 3px;
  height: 15px;
  color: #1B1B1A;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
}

.tabs li .number.one {
  right: -18px;
}

.tabs li .number.two {
  right: -25px;
}
