.flex {
  display: flex;
  box-sizing: border-box;
  min-width: 0;
}
.growItems > * {
  flex-grow: 1;
}

.horizontal {
  flex-direction: row;
}
.gap-1.horizontal > *:not(:last-child) {
  margin-right: 10px;
}
.gap-2.horizontal > *:not(:last-child) {
  margin-right: 20px;
}

.vertical {
  flex-direction: column;
}
.gap-1.vertical > *:not(:last-child) {
  margin-bottom: 10px;
}
.gap-2.vertical > *:not(:last-child) {
  margin-bottom: 15px;
}

.flexScrollableWrapper {
  flex-shrink: 1;
  min-height: 0;

  position: relative;

  display: flex;
  flex-direction: column;
}
.flexScrollable {
  flex-shrink: 1;

  overflow: auto;
  min-height: 40px;
  min-width: 40px;

  /* To prevent input focus states from being cut */
  margin: 0 -5px;
  padding: 0 5px;
}
.overflownY {
  padding-right: 15px;
}
.overflownX {
  padding-bottom: 15px;
}

.scrollIndicatorTop,
.scrollIndicatorBottom {
  pointer-events: none;
  position: absolute;

  left: 0;
  right: 0;
  height: 6px;
  z-index: 1;

  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.scrollIndicatorTop {
  top: -1px;
  background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.15));
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.scrollIndicatorBottom {
  bottom: -1px;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.15));
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.scrollIndicatorVisible {
  opacity: 1;
}
