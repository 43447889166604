.checkbox {
  color: #454442;
  font-size: 14px;
  font-weight: 400;
  line-height: 142%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  min-height: 28px;
  width: 16px;
  min-width: 16px;
}

.checkbox.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.checkbox.inline {
  display: inline-flex;
}

.checkbox.block {
  display: flex;
}

.checkbox .square {
  background-color: #ffffff;
  border-radius: 3px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  position: relative;
  box-sizing: border-box;
  margin-right: 8px;
  user-select: none;
  border: 1px solid #dddbd7;
  transition: border 0.3s ease-in-out;
}

.checkbox .square .spinner {
  position: absolute;
  top: 2px;
  left: 2px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.05s;
}

.checkbox.loading .square .spinner {
  opacity: 1;
}

.checkbox .square .check {
  border-radius: 3px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transform: rotate(-30deg);
  transition: transform 0.3s ease-in-out;
}
.checkbox.checked .square .check {
  transform: none;
}

.checkbox .square .check svg {
  left: -1px;
  top: -1px;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.05s;
}

.checkbox.checked:not(.loading) .square .check svg {
  opacity: 1;
}

.checkbox:hover .square {
  border-color: #b4b2af;
}

.checkbox:active .square {
  border-color: #454442;
  box-shadow: 0px 0px 0px 2px #eae8e4;
}

.checkbox.checked .square {
  border-color: #454442;
}

.checkbox.checked .square .check svg path {
  fill: #454442;
}

/* ------------------------------- THEME BLACK ------------------------------ */

.theme-black.checkbox {
  font-size: 13px;
}

.theme-black.checkbox {
  color: #454442;
}
.theme-black.checkbox .square {
  background-color: #ffffff;
  border: 1px solid #b4b2af;
  transition: background-color 0.1s ease-in-out;
}
.theme-black.checkbox:hover .square {
  border-color: #a5a39f;
  background-color: #f0f0f0;
}
.theme-black.checkbox:active .square {
  border-color: #5e5d5a;
  background-color: #dfdfdf;
  box-shadow: none;
}
.theme-black.checkbox.checked .square {
  border-color: #454442;
  background-color: #454442;
}
.theme-black.checkbox .square .check svg path {
  fill: #ffffff;
}
.theme-black.checkbox.checked .square .check svg path {
  fill: #ffffff;
}
