.pagination {
  display: flex;
  flex-shrink: 0;
}
.pagination > *:not(:last-child) {
  margin-right: 7px;
}

.paginationButton {
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;
  height: 16px;
  line-height: 16px;

  font-variant-numeric: tabular-nums;
  font-size: 13px;
  font-weight: 500;
  color: #b4b2af;

  border: none;
  box-shadow: none;
  background-color: transparent;
}
.paginationButton:disabled {
  cursor: not-allowed;
}
.paginationButton,
.paginationButton * {
  fill: #b4b2af;
}

.paginationButton:hover,
.paginationButton.active {
  color: #1b1b1a;
}
.paginationButton:hover * {
  fill: #1b1b1a;
}

/* ---------------------------------- ICONS --------------------------------- */

.dots {
  width: 20px;
}
.dots > * {
  transform: translateY(1px) scale(0.8);
}

.previous *,
.next * {
  stroke: #1b1b1a;
}
.previous {
  padding-right: 3px;
}
.next {
  padding-left: 3px;
}
.previous > * {
  transform: scale(0.6) translateY(2px);
}
.next > * {
  transform: rotateY(180deg) scale(0.6) translateY(2px);
}

/* ------------------------------ GOTO POPOVER ------------------------------ */

.goToPopover {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
}
.goToPopover > *:not(:last-child) {
  margin-right: 8px;
}

/* -------------------------------- PAGE SIZE ------------------------------- */

.pageSize {
  display: flex;
  justify-content: center;
  align-items: center;

  white-space: nowrap;

  color: #b4b2af;
  font-weight: 500;
  font-size: 13px;
}
.pageSize > *:not(:last-child) {
  margin-right: 5px;
}
