.submissionFieldPreview.blurred {
  position: relative;
  user-select: none;
}
.submissionFieldPreview.blurred.theme-for-table {
  background-color: #f6f5f4;
}

.submissionFieldPreview.blurred > * {
  pointer-events: none;
  opacity: 0.5;
  filter: blur(3px) grayscale(100%);
}
