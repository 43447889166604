.dropdownIcon {
  cursor: pointer;

  width: 12px;
  height: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdownIcon > * {
  transition: transform 0s ease-in-out;
}
.dropdownIcon.open > * {
  transform: rotateZ(90deg);
}
