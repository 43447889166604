/* -------------------------------------------------------------------------- */
/*                                    BASE                                    */
/* -------------------------------------------------------------------------- */

.textPreview {
  display: flex;
  justify-content: space-between;

  height: 100%;
}

/* -------------------------------------------------------------------------- */
/*                             ADDITIONAL FEATURES                            */
/* -------------------------------------------------------------------------- */

.intentPredictionGroup {
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  align-items: stretch;

  user-select: none;

  border-left: 1px solid #f6f5f4;

  filter: hue-rotate(-25deg);
}

.intentPredictionGroup > *:not(:last-child) {
  margin-right: 10px;
}

/* -------------------------------- SENTIMENT ------------------------------- */

.sentiment {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: 25px;
}

.sentiment > * {
  transform: scale(1.3);
}

/* -------------------------------- EMOTIONS -------------------------------- */

.emotions {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 7px;
}

/* Prevents unnecessary gaps for cases with less than 3 emotions */
.emotions.length-1 {
  grid-template-columns: auto;
}
.emotions.length-2 {
  grid-template-columns: auto auto;
}

.emotionProgressCircle {
  position: relative;

  width: 25px;
  height: 25px;
}

.emotionProgressCircleEmoji {
  position: absolute;

  top: 50%;
  left: 50%;

  transform: translate(-50%, calc(-50% - 0.5px));
}

/* -------------------------------------------------------------------------- */
/*                                   THEMES                                   */
/* -------------------------------------------------------------------------- */

.textPreview.theme-for-rows .content {
  min-width: 0;
  max-width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.textPreview.theme-for-rows .intentPredictionGroup {
  margin-left: 12px;
  padding-left: 16px;
}
.textPreview.theme-for-rows .intentPredictionGroup > *:not(:last-child) {
  margin-right: 7px;
}

/* Content will cover height of entire cell (assuming it's padding is 12px 16px) */
.textPreview.theme-for-table .content {
  box-sizing: content-box;
  margin: -12px -16px;
  padding: 12px 16px;
  margin-right: 0;
}

/* Left side bar will cover height of entire cell (assuming it's padding is 12px 16px) */
.textPreview.theme-for-table .intentPredictionGroup {
  box-sizing: content-box;
  margin: -12px -16px;
  padding: 12px 16px;
  margin-left: 0;
}
