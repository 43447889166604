/* --------------------------------- CONTENT -------------------------------- */

.reminder {
  box-sizing: border-box;

  display: flex;
  align-items: stretch;

  width: 100%;
  padding: 15px 15px;

  background-color: #ffffff;

  border: 1px solid #dddbd7;
  border-radius: 4px;
}

.icon {
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 16px;
  width: 16px;

  margin-right: 12px;
}
.reminder.horizontal > .icon {
  height: unset;
}

.content {
  flex-grow: 1;
  flex-shrink: 1;

  display: flex;
  flex-direction: column;
}
.reminder.horizontal > .content {
  flex-direction: row;
  align-items: center;
}
.content > *:not(:last-child) {
  margin-bottom: 16px;
}
.reminder.horizontal > .content > *:not(:last-child) {
  margin-bottom: 0px;
  margin-right: 16px;
}

.textContent {
  flex-grow: 1;
  flex-shrink: 1;

  font-size: 13px;
  line-height: 16px;
  text-align: left;

  color: #807e7c;
}
.textContent > *:not(:last-child) {
  margin-bottom: 6px;
}

.header {
  color: #807e7c;
  font-weight: 700;
}

.suffix {
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
}
.suffix > *:not(:last-child) {
  margin-right: 10px;
}

/* --------------------------------- THEMES --------------------------------- */

.reminder.theme-error {
  border-color: #f05252;
}
