.content {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 60px);
  max-height: calc(90vh - 60px);
  overflow-y: auto;
}

.content .profilePicture {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.content .profilePicture .avatar {
  margin: 0 20px 0 0;
}

.content .firstLastName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.aboutAuthType {
  font-size: 13px;
  color: #454442;
  line-height: 16px;
}
.aboutAuthType > * {
  margin: 0;
}
.aboutAuthType > *:not(:last-child) {
  margin-bottom: 10px;
}
.aboutAuthType ul {
  list-style-type: circle;
  padding-left: 30px;
}
