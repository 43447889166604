.childrenWithMargin > *:not(.hr):not(.headerHr) {
  margin-left: 20px;
  margin-right: 20px;
}

.childrenWithMargin > *:first-child {
  margin-top: 15px;
}
.childrenWithMargin > *:last-child {
  margin-bottom: 15px;
}

.header {
  margin-bottom: 0 !important;
}
.header + .hr {
  margin-top: 15px;
}

.goBack {
  max-height: 18px;
}

.footer {
  background: #fbfaf9;
}

.hr {
  flex-shrink: 0;
  background: #dddbd7;
  width: 100%;
  height: 1px;
  box-sizing: border-box;
  border: none;
  margin: 0;
}
