.main {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #B4B2AF;
  border-radius: 4px;
  color: #FBFAF9;
  text-align: center;
  position: relative;
  user-select: none;
  background-size: contain;
}

.main .activeDot {
  background-color: #9F9C0F;
  border: 1px solid #FBFAF9;
  box-sizing: border-box;
  border-radius: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.main.active .activeDot {
  opacity: 1;
}

.main .loader {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.main.loading .loader {
  opacity: 1;
}