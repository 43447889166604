.tutorialPicker {
  box-sizing: border-box;
  width: 100%;
}
.tutorialPicker > *:not(:last-child) {
  margin-bottom: 20px;
}

.tutorials {
  display: flex;
  flex-direction: column;
}
.tutorials > *:not(:last-child) {
  border-bottom: 1px solid #dddbd7;
}

/* ------------------------------- PLACEHOLDER ------------------------------ */

.placeholder {
  font-size: 13px;
  font-weight: 500;
  color: #807e7c;

  background-color: #fbfaf9;
  padding: 24px 12px;
  border-radius: 4px;
}

/* -------------------------------- TUTORIAL -------------------------------- */

.tutorial {
  user-select: none;

  display: flex;
  align-items: center;

  padding: 12px 0px;

  border: none;
  background: none;
  outline: none;
}
.tutorial:not(:disabled) {
  cursor: pointer;
}
.tutorial:disabled {
  cursor: not-allowed;
}
.tutorial > * {
  flex-shrink: 0;
  flex-grow: 0;
}
.tutorial:not(:disabled):hover > * {
  opacity: 0.8;
}
.tutorial:not(:disabled):active > * {
  opacity: 0.6;
}

.tutorialProgress {
  width: 40px;
  margin-right: 12px;
}
.tutorialProgressText {
  font: inherit;
  font-size: 9px;
  font-weight: 700;
  color: #807e7c;
  transform: translateY(1px);
}

.tutorialLabel {
  flex-shrink: 1;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  min-width: 0;

  text-align: left;
  font-size: 13px;
  line-height: 16px;
}
.tutorialLabel > *:not(:last-child) {
  margin-bottom: 4px;
}
.tutorialTitle {
  display: flex;
  align-items: center;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  color: #454442;
  font-weight: 600;
}
.tutorialDescription {
  color: #807e7c;
}
.tutorialBlocker {
  color: #f05252;
  font-weight: 600;
}

.notStartedBadge {
  display: inline-block;

  text-transform: uppercase;

  font-size: 8px;
  line-height: 1em;
  font-weight: 600;

  padding: 3px 3px;

  color: white;
  background-color: #b4b2af;
  border-radius: 2px;

  margin-left: 8px;
}

.tutorialArrow {
  margin-left: 12px;
  margin-right: 6px;
}
