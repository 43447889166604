.main {
  position: relative;
}

.content {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 84px);
  max-height: calc(90vh - 84px);
  overflow-y: auto;
}

/* plan colors */
.trial {
  font-weight: 500;
  text-transform: capitalize;
}

.personal {
  color: #2675D1;
  font-weight: 500;
  text-transform: capitalize;
}

.starter {
  color: #9061F9;
  font-weight: 500;
  text-transform: capitalize;
}

.pro {
  color: #FF623D;
  font-weight: 500;
  text-transform: capitalize;
}

.bold {
  color: #454442;
  font-weight: 600;
}

.empty {
  color: #CBC9C5;
}

/* invoices */
.invoices {
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 0 0;
  padding: 0;
}

.invoices li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #454442;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.invoices li.invoicesHead {
  border-bottom: 1px solid #EAE8E4;
  padding: 10px 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.invoices li.invoicesBody {
  padding: 10px 0;
}

.invoices li:last-child {
  border-bottom: none;
}

.cancelSubscription {
  margin: 30px 0 0 0;
}

/* status badges */
.open {
  background-color: #5D5D5C;
  border-radius: 2px;
  color: #FBFAF9;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  margin: 0 0 0 5px;
  padding: 2px 5px;
  text-transform: capitalize;
}

.paid {
  background-color: #0F9F6E;
  border-radius: 2px;
  color: #FBFAF9;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  margin: 0 0 0 5px;
  padding: 2px 5px;
  text-transform: capitalize;
}

/* billingInformation */
.billingInformation {
  list-style: none;
  padding: 0;
  margin: 0;
}

.billingInformation li {
  color: #1B1B1A;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 15px 0;
}

.billingInformation li label {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  margin: 0 0 2px 0;
}

/*  */
.top {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.top .left {
  min-width: calc(100% - 300px);
  max-width: calc(100% - 300px);
  padding: 0 45px 0 0;
  box-sizing: border-box;
}

.top .left .description {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
}

.top .right {
  width: 300px;
}

/* cards */
.ccLabel {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  margin: 0 0 5px 0;
}

.ccList {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
}

.ccList li {
  min-height: 22px;
  font-weight: 400;
  color: #1B1B1A;
  font-size: 13px;
  line-height: 22px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ccList li .ccBrand {
  text-transform: capitalize;
}

.ccList li .ccLast4 {
  text-transform: capitalize;
  font-weight: 600;
}

.ccList li .more {
  position: absolute;
  right: 15px;
  top: 25px;
}

/* ccForm */
.ccForm {
  position: relative;
}

.ccFormButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.spinner {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
