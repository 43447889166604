.sortingModeSelect {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #807e7c;
}

/* ------------------------------ VALUE PREVIEW ----------------------------- */

.sortingModeSelectPreview {
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  user-select: none;
}

.label {
  margin-right: 5px;
}

.currentOptionLabel {
  text-align: left;

  flex-grow: 1;
  flex-shrink: 1;

  font-size: 13px;
  font-weight: 600;
  color: #302f2e;

  margin-right: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-variant-numeric: tabular-nums;
}

.dropdownIcon {
  transform: translateY(1px);
}

/* ----------------------------- OPTIONS WRAPPER ---------------------------- */

.sortingOptions {
  user-select: none;

  padding: 15px 0;
}
.sortingOptions > *:not(:last-child) {
  margin-bottom: 10px;
}

.optionsGroup {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.optionsGroupHeader {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;

  color: #807e7c;

  padding: 3px 15px;
}

/* --------------------------------- OPTION --------------------------------- */

.option {
  display: flex;
  align-items: center;

  width: 100%;

  font-size: 13px;
  line-height: 16px;
  font-weight: 500;

  color: #eae8e4;
  background-color: transparent;

  padding: 3px 15px;
  margin: 0;
  outline: 0;

  border: none;
}
.optionLabel {
  white-space: nowrap;
  margin-right: 20px;
}
.optionCheck {
  margin-left: auto;
  width: 10px;
}

.option:not(.enabled) {
  cursor: pointer;
}
.option:not(.enabled):hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.option:not(.enabled):active {
  background-color: rgba(255, 255, 255, 0.2);
}
