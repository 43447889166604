/* --------------------------- LEFT,RIGHT, CENTER --------------------------- */

/*  Main + Left */
.align-left,
.align-right,
.align-center {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.align-left > div,
.align-right > div,
.align-center > div {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}
.align-left > div:nth-child(2),
.align-right > div:nth-child(2),
.align-center > div:nth-child(2) {
  flex-grow: 1;
  overflow: hidden;
}
.align-left > div:nth-child(2) > div,
.align-right > div:nth-child(2) > div,
.align-center > div:nth-child(2) > div {
  display: flex;
  align-items: stretch;
  overflow: hidden;
}

/* Right and center */
.align-right > div:nth-child(2) {
  justify-content: flex-end;
}
.align-center > div:nth-child(2) > div {
  margin: auto;
}

/* --------------------------- ABSOLUTE CENTERING --------------------------- */

.align-center-absolute {
  display: flex;
  align-items: stretch;
}

/* Sides will always have roughly equal size - middle will be absolutely centered */
.align-center-absolute > div {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;

  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}
.align-center-absolute > div:nth-child(2) {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: hidden;
}
.align-center-absolute > div:nth-child(3) {
  justify-content: flex-end;
}

/* Centering content of centered container */
.align-center-absolute > div:nth-child(2) > div {
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
}
