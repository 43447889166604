.youLackPermission {
  width: 400px;

  padding: 20px;
}

.header {
  border-bottom: 1px solid #eae8e4;
  padding-bottom: 15px;
}

.content {
  padding: 15px 0;
}
.content > *:not(:last-child) {
  margin-bottom: 15px;
}

.message {
  line-height: 1.5em;
  font-size: 14px;
}

/* ------------------------------- HOLDER CARD ------------------------------ */

.holderCard {
  padding: 10px;

  display: flex;
  flex-direction: column;

  color: #282f2e;
  background-color: #f6f5f4;

  border: 1px solid #dddbd7;

  border-radius: 5px;

  font-size: 13px;
}

.holderCardHeader {
  font-weight: 500;

  margin-bottom: 10px;
  font-size: 12px;
}

.holderCardContent {
  display: flex;
}

.holderCardAvatar {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
}

.holderCardAbout {
  flex-grow: 1;
  flex-shrink: 1;
}
.holderCardAbout *:not(:last-child) {
  margin-bottom: 5px;
}
.holderCardAbout * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.holderCardAbout a {
  font: inherit;
  color: #2675d1;
  text-decoration: underline;
}

/* --------------------------------- BUTTONS -------------------------------- */

.buttons {
  display: flex;
  justify-content: flex-end;
}
.buttons > *:not(:last-child) {
  margin-right: 10px;
}
