.longPress {
  position: relative;
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 13px;
  height: 13px;
  margin-right: 8px;

  background: transparent;
}

.deleteHint {
  z-index: 100;
  color: #eae8e4;
  font-size: 12px;
  line-height: 14px;
  background-color: #1b1b1a;
  border-radius: 4px;
  position: absolute;
  text-align: center;
  box-sizing: border-box;
  width: 100px;
  left: -110px;
  padding: 5px 8px;
}

.deleteHint:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #1b1b1a;
  margin-top: -4px;
}
