.floatingLogger {
  z-index: 10000;

  pointer-events: none;

  position: fixed;
  bottom: 10px;
  left: 10px;

  border-radius: 5px;

  background: black;
  color: white;

  opacity: 0.5;

  padding: 10px;
}

.floatingLogger * {
  font-family: inherit;
  font-size: 11px;
}
.floatingLogger div {
  font-size: 1.1em;
  font-weight: 600;

  text-decoration: underline;
}
