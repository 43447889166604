/* ----------------------------- SORTING STYLING ---------------------------- */

.tabs {
  /* So that active tabs blend into a background beneath */
  margin-bottom: -1px;
}

/* --------------------------- DEFAULT TAB STYLING -------------------------- */

/* Default state */
.tab {
  flex-shrink: 1;
  flex-basis: 0;
  width: 100%;

  position: relative;

  cursor: pointer;

  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  color: #5d5d5c;

  background-color: #f6f5f4;
  padding: 8px 15px;

  border: 1px solid transparent;
  border-bottom: 1px solid #dddbd7;
  border-radius: 4px 4px 0 0;

  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
}
.tab:hover,
.tab:focus {
  background-color: #eae8e4;
}

.tab > * + * {
  margin-left: 8px;
}
.tab > * + .label {
  margin-left: 0;
  padding-left: 8px;
}

/* Active state */
.tab.active {
  cursor: default;

  background-color: #fbfaf9;
  color: #454442;

  border-color: #dddbd7;
  border-bottom: 1px solid #fbfaf9;
}

/* Sorting states */
.tab[data-sorting-role='placeholder'] {
  background-color: transparent;
  color: #b4b2af;

  border-color: #dddbd7;
  border-style: dashed;
  border-bottom: none;
}
.tab[data-sorting-role='placeholder'] svg {
  opacity: 0.4;
}

.label {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ------------------------------- SEPARATORS ------------------------------- */

.tab::after,
.tab::before {
  content: ' ';
  display: block;

  position: absolute;

  top: 25%;
  height: 50%;
  width: 1px;
}

/* Left separator */
.tab::before {
  left: 0;
  background-color: #dddbd7;
}
/*
Hides left separator if:
- tab is hovered/active,
- tab before is hovered/active
- is first tab
*/
.tabWrapper:first-child > .tab::before,
.tabWrapper:hover + .tabWrapper > .tab::before,
.tabWrapper:hover > .tab::before,
.tabWrapper.active + .tabWrapper > .tab::before,
.tabWrapper.active > .tab::before {
  display: none;
}

/* Right separator visible only for last tab (after tab there is a dropline and a ghost component) */
.tabsSortableList > *:nth-last-child(3) > .tab::after {
  right: 0;
  background-color: #dddbd7;
}
/*
Hides right separator if:
- tab is hovered/active,
- tab before is hovered/active
*/
.tab:hover:after,
.tab.active::after {
  display: none;
}
