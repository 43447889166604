.messaging {
  display: flex;
  align-items: center;

  color: #454442;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin: 0;
}
.messaging > *:not(:last-child) {
  margin-right: 10px;
}
