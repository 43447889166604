.errorFallback.positionAbsolute {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin: 12px;
}

/* --------------------------------- THEMES --------------------------------- */

.errorFallback.theme-block {
  box-sizing: border-box;
  white-space: pre-line;

  padding: 8px 12px;

  line-height: 15px;
  font-size: 12px;

  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: #f05252;
  color: #fff;

  border-radius: 4px;
}

/* --------------------------------- THEMES --------------------------------- */

.errorFallback.theme-text {
  box-sizing: border-box;
  white-space: pre-line;

  line-height: 15px;
  font-size: 12px;

  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #f05252;

  font-weight: 600;
}
