.toggle2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.toggle.disabled,
.toggle2.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.label {
  color: #646464;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin: 0 0 0 8px;
  user-select: none;
}
