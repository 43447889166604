/* -------------------------------------------------------------------------- */
/*                             NAVIGATOR CONTAINER                            */
/* -------------------------------------------------------------------------- */

.navigatorEntry.borderTop {
  border-top: 1px solid #eae8e4;
}

.navigatorEntry.borderBottom {
  border-bottom: 1px solid #eae8e4;
}

/* -------------------------------------------------------------------------- */
/*                                   CONTENT                                  */
/* -------------------------------------------------------------------------- */

/* -------------------------------- CONTAINER ------------------------------- */

.content {
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;

  user-select: none;
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-size: 13px;
  line-height: 16px;

  height: 36px;

  color: #302f2e;
  background-color: #fff;
}
.content > * {
  flex-shrink: 0;
  flex-grow: 0;
}
.content > *:not(:last-child) {
  margin-right: 10px;
}

/* ------------------------------ MAIN CONTENT ------------------------------ */

.icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 14px;
}

.label {
  overflow: hidden;
  white-space: nowrap;

  flex-shrink: 1;
  flex-grow: 1;
}

.labelIndex {
  font-weight: 500;
  margin-right: 0.75ch;
}

/* -------------------------------- SUFFIXES -------------------------------- */

.inlineSuffix {
  padding-right: 10px;
}

.floatingSuffix {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  color: hsla(0, 0%, 0%, 0.45);
  background-color: #fff;
}
.floatingSuffix > *:not(:last-child) {
  margin-right: 10px;
}

.floatingSuffix::before {
  content: '';
  display: block;
  position: absolute;

  top: 0;
  height: 100%;

  left: -50px;
  width: 50px;

  /* NOTE: safari seems to not support 'transparent' keyword */
  background: linear-gradient(to right, hsla(0, 0%, 100%, 0), hsl(0, 0%, 100%));
}

/* -------------------------------------------------------------------------- */
/*                            CONTENT INTERACTIONS                            */
/* -------------------------------------------------------------------------- */

/*
  Additional class thats adds hover states and other custom states support to content.
*/
.reactiveContent {
  background-color: #fff;
  border: 1px dashed #fff;

  transition: opacity 0.1s ease-in-out;
}

.dimmedBackground > .reactiveContent {
  background-color: #fbfaf9;
  border-color: #fbfaf9;
}

.navigatorEntry[data-sortful-role='stacked'] > .reactiveContent {
  border-color: #5d5d5c;
  opacity: 0.5;
}

.dimmedBackground > .reactiveContent .floatingSuffix {
  background-color: #fbfaf9;
}
.dimmedBackground > .reactiveContent .floatingSuffix::before {
  /* NOTE: safari seems to not support 'transparent' keyword */
  background: linear-gradient(to right, hsla(30, 20%, 98%, 0), hsl(30, 20%, 98%));
}

.reactiveContent:hover {
  background-color: #f6f5f4;
  border-color: #f6f5f4;
}
.reactiveContent:hover .floatingSuffix {
  background-color: #f6f5f4;
}
.reactiveContent:hover .floatingSuffix::before {
  background: linear-gradient(to right, hsla(30, 10%, 96%, 0), hsl(30, 10%, 96%));
}

.reactiveContent.invalid {
  background-color: #ffdbdb;
  border-color: #ffdbdb;
}
.reactiveContent.invalid .floatingSuffix {
  background-color: #ffdbdb;
  border-color: #ffdbdb;
}
.reactiveContent.invalid .floatingSuffix::before {
  /* NOTE: safari seems to not support 'transparent' keyword */
  background: linear-gradient(to right, hsla(0, 100%, 93%, 0), hsl(0, 100%, 93%));
}

.reactiveContent.active {
  background-color: #eae8e4;
  border-color: #eae8e4;
}
.reactiveContent.active .floatingSuffix {
  background-color: #eae8e4;
}
.reactiveContent.active .floatingSuffix::before {
  /* NOTE: safari seems to not support 'transparent' keyword */
  background: linear-gradient(to right, hsla(40, 13%, 91%, 0), hsl(40, 12%, 91%));
}
