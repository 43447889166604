/* -------------------------------- NAVIGATOR ------------------------------- */

.navigator {
  overflow: hidden;

  border: 1px solid #b4b2af;
  border-radius: 4px;
}

/* Group */
.navigatorGroup {
  display: flex;
  flex-direction: column;
}

/* Group header */
.navigatorGroupHeader {
  cursor: pointer;

  overflow: hidden;

  box-sizing: border-box;

  display: flex;
  align-items: center;

  padding: 0 15px;
  height: 45px;

  border-bottom: 1px solid #b4b2af;
}

.navigatorGroupHeader > .goBack {
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  align-items: center;

  padding-right: 15px;
  padding-left: 4px;
  margin-right: 10px;

  height: 20px;

  border-right: 1px solid #eae8e4;
}
.navigatorGroupHeader > .goBack:hover {
  opacity: 0.6;
}

/* Group Content */
.navigatorGroupContent {
  display: flex;
  flex-direction: column;
}

/* ------------------------------- BREADCRUMBS ------------------------------ */

/* Group Content */
.breadcrumbs {
  user-select: none;

  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;

  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: row;

  height: 28px;
}

/* ---------------------------------- INPUT --------------------------------- */

.input {
  display: flex;
}

.input > * {
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;

  height: 28px;

  cursor: pointer;
  padding-left: 15px;
  padding-right: 10px;
}

.placeholderText {
  color: #807e7c;
}
.errorText {
  color: #807e7c;
}
