.successModal {
  max-width: 400px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 20px 10px;
}
.successModal > *:not(:last-child) {
  margin-bottom: 20px;
}

.successModal .header {
  font-size: 18px;
  font-weight: 600;
  color: #282f2e;
}
.successModal .text {
  line-height: 1.3em;
  color: #5d5d5c;
}
