@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
.Toggle_toggle__1aHJy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  min-height: 28px;
}

.Toggle_toggle__1aHJy.Toggle_inline__2_eTG {
  display: inline-flex;
}

.Toggle_toggle__1aHJy.Toggle_block__oX2Xz {
  display: flex;
}

.Toggle_toggle__1aHJy.Toggle_disabled__1Z6HQ {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.Toggle_label__3-1yQ {
  color: #454442;
  font-size: 14px;
  font-weight: 400;
  line-height: 142%;
  margin: 0 0 0 8px;
  -webkit-user-select: none;
          user-select: none;
}

.react-toggle{touch-action:pan-x;position:relative;cursor:pointer;background-color:transparent;border:0;padding:0;-webkit-touch-callout:none;-webkit-user-select:none;user-select:none;-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent;height:28px;display:flex;align-items:center}.react-toggle-screenreader-only{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.react-toggle--disabled{cursor:not-allowed;opacity:0.5;transition:opacity 0.25s}.react-toggle-track{width:37px;height:20px;padding:0;border-radius:28px;background-color:#E2E5ED;transition:all 0.2s ease}.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track{background-color:#CFD4D8}.react-toggle--checked .react-toggle-track{background-color:#454442}.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track{-webkit-filter:brightness(98%);filter:brightness(98%);background-color:#454442}.react-toggle--checked:active:not(.react-toggle--disabled) .react-toggle-track{-webkit-filter:brightness(97%);filter:brightness(97%);background-color:#454442}.react-toggle-track-check{position:absolute;width:14px;height:10px;top:0px;bottom:0px;margin-top:auto;margin-bottom:auto;line-height:0;left:8px;opacity:0;transition:opacity 0.25s ease}.react-toggle--checked .react-toggle-track-check{opacity:1;transition:opacity 0.25s ease}.react-toggle-track-x{position:absolute;width:10px;height:10px;top:0px;bottom:0px;margin-top:auto;margin-bottom:auto;line-height:0;right:10px;opacity:1;transition:opacity 0.25s ease}.react-toggle--checked .react-toggle-track-x{opacity:0}.react-toggle-thumb{transition:all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;position:absolute;top:6px;left:1px;width:18px;height:18px;border:1px solid #E2E5ED;border-radius:50%;background-color:#FAFAFA;box-sizing:border-box;transition:all 0.25s ease}.react-toggle--checked .react-toggle-thumb{left:18px;border-color:#454442}

/* ------------------------------- INPUT FRAME ------------------------------ */

.Input_inputWrapper__3tDZn {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  box-sizing: border-box;
  overflow: hidden;
  width: 250px;
  /*height: 28px;*/
  min-width: 0;

  background-color: #ffffff;
  border: 1px solid #dddbd7;

  border-radius: 4px;
  box-shadow: 0px 0px 0px 1px transparent, 0 1px 1px 0 rgba(48, 47, 46, 0.07);

  transition: box-shadow 0.2s ease-in-out, border 0.1s ease-in-out, background 0.2s ease-in-out;
}
.Input_inputWrapper__3tDZn.Input_fullWidth__1k7g0 {
  width: 100%;
}
.Input_inputWrapper__3tDZn.Input_multiline__1fnbs {
  height: 100px;
}

/* Affix borders 
.inputWrapper > *:not(:last-child) {
  border-right: 1px solid #dddbd7;
}
*/

/* Conditional states */
.Input_inputWrapper__3tDZn:hover {
  border-color: #b4b2af;
}
.Input_focusOutline__XkO-P,
.Input_inputWrapper__3tDZn.Input_focused__3LOSn,
.Input_inputWrapper__3tDZn:focus-within {
  box-shadow: 0px 0px 0px 3px #eae8e4, 0 1px 1px 0 rgba(48, 47, 46, 0.07);
  border-color: #b4b2af;
}

/*
.inputWrapper.success > input.input {
  color: #0f9f6e;
}
*/
.Input_inputWrapper__3tDZn.Input_error__ULZcm {
  border-color: #d43d51;
}

.Input_errorOutline__3adN2,
.Input_inputWrapper__3tDZn.Input_focused__3LOSn.Input_error__ULZcm,
.Input_inputWrapper__3tDZn:focus-within.Input_error__ULZcm {
  border-color: #d43d51;
  box-shadow: 0px 0px 0px 3px rgba(212, 61, 81, 0.3), 0 1px 1px 0 rgba(48, 47, 46, 0.07);
}
.Input_inputWrapper__3tDZn.Input_disabled__1v28I {
  background-image: linear-gradient(rgba(47, 46, 45, 0.04), rgba(47, 46, 45, 0.04));
}
.Input_inputWrapper__3tDZn.Input_disabled__1v28I,
.Input_inputWrapper__3tDZn.Input_disabled__1v28I .Input_affix__2VYTE,
.Input_inputWrapper__3tDZn.Input_disabled__1v28I .Input_input__t4szQ {
  cursor: not-allowed;
  pointer-events: none;
}

/* --------------------------- INNER INPUT WRAPPER --------------------------- */

.Input_innerWrapper__5fleh {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;

  display: flex;
  align-items: center;

  padding: 4px 10px;

  font-size: 13px;
  font-weight: 400;
  color: #807e7c;
}
.Input_compact__2iHFv .Input_innerWrapper__5fleh {
  padding: 0px 2px;
}

.Input_innerWrapper__5fleh > *:not(:last-child) {
  margin-right: 0.5em;
}

.Input_innerWrapper__5fleh > * {
  flex-grow: 0;
  flex-shrink: 01;
}

/* ------------------------------- INNER INPUT ------------------------------ */
.Input_input__t4szQ {
  height: 100%;
}
.Input_innerWrapper__5fleh > .Input_input__t4szQ {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;

  box-sizing: border-box;

  font: inherit;
  color: #807e7c;

  transition: color 0.3s ease-in-out;
}
textarea.Input_input__t4szQ {
  resize: none;
  overflow: auto;
}
.Input_inputWrapper__3tDZn.Input_disabled__1v28I input .Input_inputWrapper__3tDZn.Input_disabled__1v28I textarea {
  color: rgba(57, 55, 54, 0.4);
}

/* Conditional styling */
.Input_inputWrapper__3tDZn input::-webkit-input-placeholder, .Input_inputWrapper__3tDZn textarea::-webkit-input-placeholder {
  color: #cbc9c5;
}
.Input_inputWrapper__3tDZn input::placeholder,
.Input_inputWrapper__3tDZn textarea::placeholder,
.Input_placeholder__sIcfg {
  color: #cbc9c5;
}
.Input_inputWrapper__3tDZn.Input_focused__3LOSn input,
.Input_inputWrapper__3tDZn.Input_focused__3LOSn textarea {
  color: #454442;
}
.Input_inputWrapper__3tDZn input[type='file'] {
  position: absolute;
  opacity: 0;
}

/* --------------------------------- SIDERS --------------------------------- */

.Input_inputWrapper__3tDZn .Input_affix__2VYTE {
  flex-shrink: 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 13px;
  font-weight: 600;
  padding: 5px 10px;

  color: #454442;
  background-color: rgba(0, 0, 0, 0.05);
}

.Checkbox_checkbox__2sQC8 {
  color: #454442;
  font-size: 14px;
  font-weight: 400;
  line-height: 142%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  min-height: 28px;
  width: 16px;
  min-width: 16px;
}

.Checkbox_checkbox__2sQC8.Checkbox_disabled__2G7aa {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.Checkbox_checkbox__2sQC8.Checkbox_inline__29MWI {
  display: inline-flex;
}

.Checkbox_checkbox__2sQC8.Checkbox_block__2rqxU {
  display: flex;
}

.Checkbox_checkbox__2sQC8 .Checkbox_square__322Z- {
  background-color: #ffffff;
  border-radius: 3px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  position: relative;
  box-sizing: border-box;
  margin-right: 8px;
  -webkit-user-select: none;
          user-select: none;
  border: 1px solid #dddbd7;
  transition: border 0.3s ease-in-out;
}

.Checkbox_checkbox__2sQC8 .Checkbox_square__322Z- .Checkbox_spinner__3yUzx {
  position: absolute;
  top: 2px;
  left: 2px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.05s;
}

.Checkbox_checkbox__2sQC8.Checkbox_loading__u4dFE .Checkbox_square__322Z- .Checkbox_spinner__3yUzx {
  opacity: 1;
}

.Checkbox_checkbox__2sQC8 .Checkbox_square__322Z- .Checkbox_check__2Gs3X {
  border-radius: 3px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  -webkit-transform: rotate(-30deg);

          transform: rotate(-30deg);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.Checkbox_checkbox__2sQC8.Checkbox_checked__2w8Ak .Checkbox_square__322Z- .Checkbox_check__2Gs3X {
  -webkit-transform: none;
          transform: none;
}

.Checkbox_checkbox__2sQC8 .Checkbox_square__322Z- .Checkbox_check__2Gs3X svg {
  left: -1px;
  top: -1px;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.05s;
}

.Checkbox_checkbox__2sQC8.Checkbox_checked__2w8Ak:not(.Checkbox_loading__u4dFE) .Checkbox_square__322Z- .Checkbox_check__2Gs3X svg {
  opacity: 1;
}

.Checkbox_checkbox__2sQC8:hover .Checkbox_square__322Z- {
  border-color: #b4b2af;
}

.Checkbox_checkbox__2sQC8:active .Checkbox_square__322Z- {
  border-color: #454442;
  box-shadow: 0px 0px 0px 2px #eae8e4;
}

.Checkbox_checkbox__2sQC8.Checkbox_checked__2w8Ak .Checkbox_square__322Z- {
  border-color: #454442;
}

.Checkbox_checkbox__2sQC8.Checkbox_checked__2w8Ak .Checkbox_square__322Z- .Checkbox_check__2Gs3X svg path {
  fill: #454442;
}

/* ------------------------------- THEME BLACK ------------------------------ */

.Checkbox_theme-black__13b51.Checkbox_checkbox__2sQC8 {
  font-size: 13px;
}

.Checkbox_theme-black__13b51.Checkbox_checkbox__2sQC8 {
  color: #454442;
}
.Checkbox_theme-black__13b51.Checkbox_checkbox__2sQC8 .Checkbox_square__322Z- {
  background-color: #ffffff;
  border: 1px solid #b4b2af;
  transition: background-color 0.1s ease-in-out;
}
.Checkbox_theme-black__13b51.Checkbox_checkbox__2sQC8:hover .Checkbox_square__322Z- {
  border-color: #a5a39f;
  background-color: #f0f0f0;
}
.Checkbox_theme-black__13b51.Checkbox_checkbox__2sQC8:active .Checkbox_square__322Z- {
  border-color: #5e5d5a;
  background-color: #dfdfdf;
  box-shadow: none;
}
.Checkbox_theme-black__13b51.Checkbox_checkbox__2sQC8.Checkbox_checked__2w8Ak .Checkbox_square__322Z- {
  border-color: #454442;
  background-color: #454442;
}
.Checkbox_theme-black__13b51.Checkbox_checkbox__2sQC8 .Checkbox_square__322Z- .Checkbox_check__2Gs3X svg path {
  fill: #ffffff;
}
.Checkbox_theme-black__13b51.Checkbox_checkbox__2sQC8.Checkbox_checked__2w8Ak .Checkbox_square__322Z- .Checkbox_check__2Gs3X svg path {
  fill: #ffffff;
}

.Spinner_main__2Hc3J {
  border-radius: 50%;
  -webkit-animation: Spinner_spin__3GFA6 1s linear infinite;
          animation: Spinner_spin__3GFA6 1s linear infinite;
}

@-webkit-keyframes Spinner_spin__3GFA6 {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes Spinner_spin__3GFA6 {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.Radio_radio__2W-q0 {
  color: #454442;
  font-size: 14px;
  font-weight: 400;
  line-height: 142%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  min-height: 28px;
}

.Radio_radio__2W-q0.Radio_inline__3336U {
  display: inline-flex;
}

.Radio_radio__2W-q0.Radio_block__WoWvc {
  display: flex;
}

.Radio_radio__2W-q0 .Radio_circle__3AOnl {
  background-color: #ffffff;
  border-radius: 16px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  position: relative;
  box-sizing: border-box;
  margin-right: 8px;
  -webkit-user-select: none;
          user-select: none;
  border: 1px solid #b4b2af;
  /*transition: border 0.15s ease-in-out;*/
}

/* 
 * states 
*/

/* hover */
.Radio_radio__2W-q0:hover .Radio_circle__3AOnl {
  border: 1px solid #807e7c;
}

/* active & checked */
.Radio_radio__2W-q0:active .Radio_circle__3AOnl,
.Radio_radio__2W-q0.Radio_checked__6gFFk .Radio_circle__3AOnl {
  border: 5px solid #454442;
}

/* disabled */
.Radio_radio__2W-q0.Radio_disabled__3Tgcw {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.Radio_radio__2W-q0.Radio_disabled__3Tgcw .Radio_circle__3AOnl {
  background-color: #fafafa;
}

/* noText */
.Radio_radio__2W-q0.Radio_noText__1EeN1 {
  justify-content: center;
}

.Radio_radio__2W-q0.Radio_noText__1EeN1 .Radio_circle__3AOnl {
  margin: 0;
}

.Select_main__2JZ-k {
  position: relative;
}

.Select_select__j3QIX .Select_dropdownIndicator__39axF {
  padding: 0px 8px;
  margin-top: -2px;
}

.Select_spinnerContainer__2wjCX {
  background: #ffffff;
  position: absolute;
  right: 6px;
  top: 8px;
  width: 15px;
  height: 15px;
}

/*
 * addIconWithLoader
 */
.Icon_icon__JUXZA {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Icon_addIconWithLoader__3Zo0K {
  position: relative;
}

.Icon_inheritColor__1VQsR * {
  fill: currentColor;
}

.Icon_addIconWithLoader__3Zo0K.Icon_loading__387he,
.Icon_addIconWithLoader__3Zo0K.Icon_loading__387he * {
  cursor: default !important;
}

.Icon_addIconWithLoader__3Zo0K svg path {
  transition: opacity 0.3s ease-in-out;
}

.Icon_addIconWithLoader__3Zo0K.Icon_loading__387he svg path {
  opacity: 0;
}

.Icon_addIconWithLoader__3Zo0K .Icon_spinner__1F6Kw {
  position: absolute;
  right: 5px;
  top: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.Icon_addIconWithLoader__3Zo0K.Icon_loading__387he .Icon_spinner__1F6Kw {
  opacity: 1;
}

.Icon_addIconWithLoader__3Zo0K svg {
  cursor: pointer;
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  position: relative;
}

.Icon_addIconWithLoader__3Zo0K:hover svg rect {
  stroke: #b4b2af;
}

.Icon_addIconWithLoader__3Zo0K:active svg rect {
  border-color: #454442;
}

.Icon_addIconWithLoader__3Zo0K .Icon_activeShadow__1561i {
  position: absolute;
  top: 6px;
  left: 6px;
  box-shadow: 0px 0px 0px 2px #eae8e4;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.Icon_addIconWithLoader__3Zo0K:active .Icon_activeShadow__1561i {
  opacity: 1;
}

/*
 * upgradeIcon
 */

.Icon_upgradeIcon__2OfHo {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  position: relative;
  padding: 0 0 0 0;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.Icon_upgradeIcon__2OfHo.Icon_purple__2Kq8F {
  color: #9061f9;
}

.Icon_upgradeIcon__2OfHo.Icon_green__fZCrV {
  color: #0f9f6e;
}

.Icon_upgradeIcon__2OfHo.Icon_blue__1lB1X {
  color: #2675d1;
}

.Icon_upgradeIcon__2OfHo svg {
  position: relative;
  top: 2px;
  margin: 0 7px 0 0;
}

.Icon_iconInRectangle__3eqQp {
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 34px;
  height: 34px;

  background-color: #f6f5f4;
  border-radius: 3px;
}

/* ----------------------------- CSS ANIMATIONS ----------------------------- */

@-webkit-keyframes Icon_headShake__31tOT {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-2px) rotateY(-7deg);
            transform: translateX(-2px) rotateY(-7deg);
  }
  18.5% {
    -webkit-transform: translateX(1px) rotateY(5deg);
            transform: translateX(1px) rotateY(5deg);
  }
  31.5% {
    -webkit-transform: translateX(-0.5px) rotateY(-3deg);
            transform: translateX(-0.5px) rotateY(-3deg);
  }
  43.5% {
    -webkit-transform: translateX(0.25px) rotateY(1deg);
            transform: translateX(0.25px) rotateY(1deg);
  }
  50% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes Icon_headShake__31tOT {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-2px) rotateY(-7deg);
            transform: translateX(-2px) rotateY(-7deg);
  }
  18.5% {
    -webkit-transform: translateX(1px) rotateY(5deg);
            transform: translateX(1px) rotateY(5deg);
  }
  31.5% {
    -webkit-transform: translateX(-0.5px) rotateY(-3deg);
            transform: translateX(-0.5px) rotateY(-3deg);
  }
  43.5% {
    -webkit-transform: translateX(0.25px) rotateY(1deg);
            transform: translateX(0.25px) rotateY(1deg);
  }
  50% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.Icon_animation-headShake__308MT {
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-name: Icon_headShake__31tOT;
          animation-name: Icon_headShake__31tOT;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.Tooltip_box__2euqG {
  outline: none;
  z-index: 127;
}
.Tooltip_box__2euqG[data-reference-hidden] {
  display: none;
}

.Tooltip_innerBox__35rre {
  position: relative;
}

.Tooltip_content__1bIzA {
  box-sizing: border-box;
  border-radius: 3px;
}

/* Popover shadow */
.Tooltip_theme-popover-black__1MU9P,
.Tooltip_theme-popover-white__2AmUV {
  -webkit-filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.4)) drop-shadow(0 4px 4px rgba(48, 47, 46, 0.07))
    drop-shadow(0 8px 8px rgba(48, 47, 46, 0.07)) drop-shadow(0 16px 16px rgba(48, 47, 46, 0.07));
          filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.4)) drop-shadow(0 4px 4px rgba(48, 47, 46, 0.07))
    drop-shadow(0 8px 8px rgba(48, 47, 46, 0.07)) drop-shadow(0 16px 16px rgba(48, 47, 46, 0.07));
}

/* Content container  */
.Tooltip_theme-popover-black__1MU9P > .Tooltip_content__1bIzA,
.Tooltip_theme-black__1MphU > .Tooltip_content__1bIzA {
  background-color: #1b1b1a;

  font-size: 13px;
  font-weight: 500;
  color: #eae8e4;
}
.Tooltip_theme-popover-white__2AmUV > .Tooltip_content__1bIzA,
.Tooltip_theme-white__2y3Cy > .Tooltip_content__1bIzA {
  background-color: #fff;

  font-size: 13px;
  font-weight: 500;
  color: #454442;
}
.Tooltip_theme-black__1MphU > .Tooltip_content__1bIzA,
.Tooltip_theme-white__2y3Cy > .Tooltip_content__1bIzA {
  padding: 7px 10px;
}
.Tooltip_theme-popover-black__1MU9P > .Tooltip_content__1bIzA,
.Tooltip_theme-popover-white__2AmUV > .Tooltip_content__1bIzA {
  border-radius: 6px;
  overflow: hidden;
}

/* Arrow style */
.Tooltip_arrow__ygjAA,
.Tooltip_arrow__ygjAA::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}
.Tooltip_arrow__ygjAA {
  visibility: hidden;
}
.Tooltip_arrow__ygjAA::before {
  visibility: visible;
  content: '';
  -webkit-transform: rotate(45deg) translateX(-3px) translateY(3px);
          transform: rotate(45deg) translateX(-3px) translateY(3px);
}
.Tooltip_theme-popover-black__1MU9P > .Tooltip_arrow__ygjAA::before,
.Tooltip_theme-black__1MphU > .Tooltip_arrow__ygjAA::before {
  background: #1b1b1a;
}
.Tooltip_theme-popover-white__2AmUV > .Tooltip_arrow__ygjAA::before,
.Tooltip_theme-white__2y3Cy > .Tooltip_arrow__ygjAA::before {
  background: #fff;
}

/* Arrow conditional positioning */
.Tooltip_box__2euqG[data-placement^='top'] > .Tooltip_innerBox__35rre > .Tooltip_arrow__ygjAA {
  bottom: -2px;
}
.Tooltip_box__2euqG[data-placement^='bottom'] > .Tooltip_innerBox__35rre > .Tooltip_arrow__ygjAA {
  top: -2px;
}
.Tooltip_box__2euqG[data-placement^='left'] > .Tooltip_innerBox__35rre > .Tooltip_arrow__ygjAA {
  right: 0px;
}
.Tooltip_box__2euqG[data-placement^='right'] > .Tooltip_innerBox__35rre > .Tooltip_arrow__ygjAA {
  left: -0px;
}

.Tooltip_textTooltip__3tinV {
  font-size: 13px;
  line-height: 16px;
  max-width: 220px;
  text-align: center;
  -webkit-user-select: none;
          user-select: none;
}

/* Animation */
@-webkit-keyframes Tooltip_scaleUp__AUyct {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes Tooltip_scaleUp__AUyct {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
.Tooltip_innerBox__35rre {
  -webkit-animation-duration: 0.15s;
          animation-duration: 0.15s;
  -webkit-animation-name: Tooltip_scaleUp__AUyct;
          animation-name: Tooltip_scaleUp__AUyct;

  transition: opacity 0.15s ease-in, -webkit-transform 0.15s ease-in;

  transition: opacity 0.15s ease-in, transform 0.15s ease-in;

  transition: opacity 0.15s ease-in, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
}
.Tooltip_hiding__2WeOZ > .Tooltip_box__2euqG > .Tooltip_innerBox__35rre {
  opacity: 0;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.Tooltip_box__2euqG[data-placement^='top'] > .Tooltip_innerBox__35rre {
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}
.Tooltip_box__2euqG[data-placement^='bottom'] > .Tooltip_innerBox__35rre {
  -webkit-transform-origin: top;
          transform-origin: top;
}
.Tooltip_box__2euqG[data-placement^='bottom-end'] > .Tooltip_innerBox__35rre {
  -webkit-transform-origin: top right;
          transform-origin: top right;
}
.Tooltip_box__2euqG[data-placement^='left'] > .Tooltip_innerBox__35rre {
  -webkit-transform-origin: right;
          transform-origin: right;
}
.Tooltip_box__2euqG[data-placement^='right'] > .Tooltip_innerBox__35rre {
  -webkit-transform-origin: left;
          transform-origin: left;
}

.Tooltip_backdrop__2R5Oe {
  pointer-events: none;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 126;

  background: rgba(0, 0, 0, 0.3);
  opacity: 0;

  transition: opacity 0.2s ease-in-out;
}
.Tooltip_backdrop__2R5Oe.Tooltip_active__2KXG1 {
  opacity: 1;
}

/* Pre-defined utility components */
.Tooltip_popoverHr__1WiJk {
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  border: none;
  height: 1px;
}
.Tooltip_theme-black__1MphU .Tooltip_popoverHr__1WiJk,
.Tooltip_theme-popover-black__1MU9P .Tooltip_popoverHr__1WiJk {
  background-color: #454442;
}
.Tooltip_theme-white__2y3Cy .Tooltip_popoverHr__1WiJk,
.Tooltip_theme-popover-white__2AmUV .Tooltip_popoverHr__1WiJk {
  background-color: #f6f5f4;
}

.Tooltip_popoverButton__2sigO {
  padding: 10px 16px;
  padding-right: 35px;
  justify-content: center;
}
.Tooltip_popoverGroup__1InKG.Tooltip_compact__TdMKt .Tooltip_popoverButton__2sigO {
  padding: 6px 12px;
}
.Tooltip_popoverButton__2sigO > *:not(:last-child) {
  /* Has a bit larger space between icon than a regular button */
  margin-right: 10px !important;
}

.Tooltip_theme-popover-black__1MU9P .Tooltip_popoverButton__2sigO:hover {
  background-color: #302f2e;
}
.Tooltip_theme-popover-white__2AmUV .Tooltip_popoverButton__2sigO:hover {
  background-color: #f6f5f4;
}

.Tooltip_popoverGroup__1InKG {
  margin: 6px 0;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
}
.Tooltip_theme-popover-black__1MU9P .Tooltip_popoverGroup__1InKG.Tooltip_separators__3F7F2 > *:not(:last-child) {
  border-bottom: 1px solid #302f2e;
}
.Tooltip_theme-popover-white__2AmUV .Tooltip_popoverGroup__1InKG.Tooltip_separators__3F7F2 > *:not(:last-child) {
  border-bottom: 1px solid #f6f5f4;
}

.Tooltip_theme-popover-black__1MU9P ::-webkit-scrollbar {
  border-color: transparent;
}
.Tooltip_theme-popover-black__1MU9P ::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
}
.Tooltip_theme-popover-black__1MU9P ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.Button_button__XK0wU {
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  height: 40px;
  box-sizing: border-box;
  -webkit-user-select: none;
          user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  white-space: nowrap;

  box-shadow: 0px 0px 0px 2px rgba(69, 68, 66, 0), 0px 0px 5px rgba(0, 0, 0, 0);

  transition: box-shadow 0.3s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
}
.Button_inherit__2Pmm9 {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: unset;
  justify-content: unset;
}
.Button_button__XK0wU:active {
  transition: box-shadow 0s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
}
.Button_button__XK0wU > *:not(:last-child) {
  margin-right: 6px;
}

.Button_button__XK0wU.Button_fullWidth__2L_Ak {
  width: 100%;
}

.Button_button__XK0wU > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* ----------------------------- SPECIAL STATES ----------------------------- */

.Button_button__XK0wU:disabled,
.Button_button__XK0wU.Button_disabled__3wo0r {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

.Button_button__XK0wU.Button_active__1H11z {
  cursor: default;
}

/* ------------------------------ LOADING STATE ----------------------------- */

.Button_loader__UZXi3 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Button_loading__1EQOt > * {
  visibility: hidden;
}
.Button_loading__1EQOt > .Button_loader__UZXi3 {
  visibility: visible;
}

/* ---------------------------------- SIZES --------------------------------- */

.Button_button__XK0wU.Button_size-small__2UGHn {
  height: 24px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 10px;
}

.Button_button__XK0wU.Button_size-default__3XX-U {
  height: 28px;
  font-size: 13px;
  line-height: 16px;
  padding: 0 12px;
}

.Button_button__XK0wU.Button_size-default-thin__1oEBL {
  height: 24px;
  font-size: 13px;
  line-height: 16px;
  padding: 0 12px;
}

.Button_button__XK0wU.Button_size-medium__3s0Xb {
  height: 32px;
  font-size: 14px;
  line-height: 18px;
  padding: 0 14px;
}

.Button_button__XK0wU.Button_size-large__yWnpt {
  height: 36px;
  font-size: 16px;
  line-height: 20px;
  padding: 0 16px;
}

/* ------------------------------ FILLED THEMES ----------------------------- */

/* Black */
.Button_button__XK0wU.Button_black__16Dty {
  border: 1px solid #454442;
  background: #454442;
  color: #ffffff;
}
.Button_button__XK0wU.Button_black__16Dty:hover,
.Button_button__XK0wU.Button_black__16Dty:active {
  background: #1b1b1a;
}

/* Blue (purple) */
.Button_button__XK0wU.Button_blue__2n9tM {
  border: 1px solid #732a56;
  background: #732a56;
  color: #ffffff;
}
.Button_button__XK0wU.Button_blue__2n9tM:hover,
.Button_button__XK0wU.Button_blue__2n9tM:active {
  background: #602347;
}

/* Orange */
.Button_button__XK0wU.Button_orange__2JPhn {
  border: 1px solid #ff5602;
  background: #ff5602;
  color: #ffffff;
}
.Button_button__XK0wU.Button_orange__2JPhn:hover,
.Button_button__XK0wU.Button_orange__2JPhn:active {
  background: #c94909;
}

/* Common - Font weight */
.Button_button__XK0wU.Button_black__16Dty,
.Button_button__XK0wU.Button_orange__2JPhn,
.Button_button__XK0wU.Button_blue__2n9tM {
  font-weight: 600;
}

/* Common - Outline */
.Button_button__XK0wU.Button_black__16Dty,
.Button_button__XK0wU.Button_orange__2JPhn,
.Button_button__XK0wU.Button_blue__2n9tM {
  box-shadow: 0px 0px 0px 3px rgba(69, 68, 66, 0), 0px 0px 5px rgba(0, 0, 0, 0);
}
.Button_button__XK0wU.Button_black__16Dty:active,
.Button_button__XK0wU.Button_black__16Dty:focus,
.Button_button__XK0wU.Button_orange__2JPhn:active,
.Button_button__XK0wU.Button_orange__2JPhn:focus,
.Button_button__XK0wU.Button_blue__2n9tM:active,
.Button_button__XK0wU.Button_blue__2n9tM:focus {
  box-shadow: 0px 0px 0px 3px rgba(69, 68, 66, 0.2), 0px 0px 5px rgba(0, 0, 0, 0.3);
}

/* ---------------------------- THEMES - OUTLINED --------------------------- */

/* White */
.Button_button__XK0wU.Button_white__aum6Y {
  color: #454442;
  background: #fff;

  border: 1px solid #ddd;
}
.Button_button__XK0wU.Button_white__aum6Y:hover,
.Button_button__XK0wU.Button_white__aum6Y:focus {
  color: #3e3d3b;
  background: #eee;
  border: 1px solid #eee;
}

/* Black outlined */
.Button_button__XK0wU.Button_black-outlined__3_FWK {
  color: #454442;
  background: #fff;

  border: 1px solid currentColor;
}
.Button_button__XK0wU.Button_black-outlined__3_FWK:hover,
.Button_button__XK0wU.Button_black-outlined__3_FWK:focus {
  background: #eee;
}

/* Black outlined */
.Button_button__XK0wU.Button_red__6zjL3 {
  color: #f05252;
  background: #fff;

  border: 1px solid currentColor;
}
.Button_button__XK0wU.Button_red__6zjL3:hover,
.Button_button__XK0wU.Button_red__6zjL3:focus {
  background: rgb(252, 243, 243);
}

/* Common - Font weight */
.Button_button__XK0wU.Button_white__aum6Y,
.Button_button__XK0wU.Button_red__6zjL3,
.Button_button__XK0wU.Button_black-outlined__3_FWK {
  font-weight: 400;
}

/* Common - Font weight */
.Button_button__XK0wU.Button_white__aum6Y,
.Button_button__XK0wU.Button_red__6zjL3,
.Button_button__XK0wU.Button_black-outlined__3_FWK {
  font-weight: 400;
}

/* Common - Outline */
.Button_button__XK0wU.Button_white__aum6Y,
.Button_button__XK0wU.Button_red__6zjL3,
.Button_button__XK0wU.Button_black-outlined__3_FWK {
  box-shadow: 0px 0px 0px 3px rgba(69, 68, 66, 0), 0px 0px 5px rgba(0, 0, 0, 0);
}
.Button_button__XK0wU.Button_white__aum6Y:active,
.Button_button__XK0wU.Button_white__aum6Y:focus,
.Button_button__XK0wU.Button_red__6zjL3:active,
.Button_button__XK0wU.Button_red__6zjL3:focus,
.Button_button__XK0wU.Button_black-outlined__3_FWK:active,
.Button_button__XK0wU.Button_black-outlined__3_FWK:focus {
  box-shadow: 0px 0px 0px 3px rgba(69, 68, 66, 0.07), 0px 0px 5px rgba(0, 0, 0, 0.07);
}

/* --------------------------------- THEMES --------------------------------- */

.Button_button__XK0wU.Button_transparent-blue__1oG5g,
.Button_button__XK0wU.Button_transparent-purple__2WGbN,
.Button_button__XK0wU.Button_transparent-orange__1B5Ra {
  font-weight: 500;
}

/* Black underline */
.Button_button__XK0wU.Button_black-underline__1ewvH {
  font-weight: 500;
  border-bottom: 2px solid #dddbd7;
  background: transparent;
  color: #454442;
  padding: 0 2px;
  border-radius: 0;
}
.Button_button__XK0wU.Button_black-underline__1ewvH:hover,
.Button_button__XK0wU.Button_black-underline__1ewvH:focus {
  color: rgba(69, 68, 66, 0.8);
}
.Button_button__XK0wU.Button_black-underline__1ewvH:active {
  color: rgba(69, 68, 66, 0.7);
}

/* Inherited theme */
.Button_button__XK0wU.Button_inherit__2Pmm9 {
  color: inherit;
  font-weight: 500;
}
.Button_button__XK0wU.Button_inherit__2Pmm9 path,
.Button_button__XK0wU.Button_inherit__2Pmm9 g {
  fill: currentColor;
}

/* Transparent */
.Button_button__XK0wU.Button_noPadding__m1lod {
  padding-left: 0;
  padding-right: 0;
}
.Button_button__XK0wU.Button_transparent__oTJRZ {
  color: #807e7c;
  font-weight: 500;
}
.Button_button__XK0wU.Button_transparent__oTJRZ:hover,
.Button_button__XK0wU.Button_transparent__oTJRZ:focus,
.Button_button__XK0wU.Button_active__1H11z {
  color: #454442;
}
.Button_button__XK0wU.Button_active__1H11z {
  background: #eae8e4;
}

.Button_button__XK0wU.Button_transparent-blue__1oG5g:hover,
.Button_button__XK0wU.Button_transparent-blue__1oG5g:focus,
.Button_button__XK0wU.Button_transparent-purple__2WGbN:hover,
.Button_button__XK0wU.Button_transparent-purple__2WGbN:focus,
.Button_button__XK0wU.Button_transparent-orange__1B5Ra:hover,
.Button_button__XK0wU.Button_transparent-orange__1B5Ra:focus {
  opacity: 0.8;
}
.Button_button__XK0wU.Button_transparent-blue__1oG5g:active,
.Button_button__XK0wU.Button_transparent-purple__2WGbN:active,
.Button_button__XK0wU.Button_transparent-orange__1B5Ra:active {
  opacity: 0.6;
}

.Button_button__XK0wU.Button_transparent-blue__1oG5g {
  color: #2675d1;
}
.Button_button__XK0wU.Button_transparent-purple__2WGbN {
  color: #9061f9;
}
.Button_button__XK0wU.Button_transparent-orange__1B5Ra {
  color: #ff5602;
}

/* Icon only button theme */
.Button_button__XK0wU.Button_icon__1abaN {
  border-radius: 6px;
  padding: 0;
}
.Button_button__XK0wU.Button_icon__1abaN.Button_size-small__2UGHn {
  width: 16px;
  height: 16px;
}
.Button_button__XK0wU.Button_icon__1abaN.Button_size-default__3XX-U {
  width: 24px;
  height: 24px;
}
.Button_button__XK0wU.Button_icon__1abaN.Button_size-default-thin__1oEBL {
  width: 24px;
  height: 16px;
}
.Button_button__XK0wU.Button_icon__1abaN.Button_size-medium__3s0Xb,
.Button_button__XK0wU.Button_icon__1abaN.Button_size-large__yWnpt {
  width: 30px;
  height: 30px;
}

.Button_button__XK0wU.Button_icon__1abaN:hover,
.Button_button__XK0wU.Button_icon__1abaN:focus {
  background-color: #eae8e4;
}
.Button_button__XK0wU.Button_icon__1abaN:disabled {
  background-color: transparent;
}

.useLongPress_icon__1anCz {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 13px;
  height: 13px;
}

.SuspenseFallback_absoluteLoader__2VP-_ {
  z-index: 100;

  position: absolute;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: wait;
}
.SuspenseFallback_inlineLoader__2BkIS {
  padding: 20px 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.SuspenseFallback_spinnerContainer__2Lhem {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.SuspenseFallback_spinnerContainer__2Lhem > *:not(:last-child) {
  margin-bottom: 15px;
}

@-webkit-keyframes SuspenseFallback_fadeIn__R205P {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes SuspenseFallback_fadeIn__R205P {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.SuspenseFallback_spinnerText__284fm {
  -webkit-user-select: none;
          user-select: none;

  color: #807e7c;

  min-width: 0;

  font-size: 11px;
  line-height: 1.5;

  padding: 0px 10px;
  text-align: center;

  -webkit-animation: SuspenseFallback_fadeIn__R205P 3s;

          animation: SuspenseFallback_fadeIn__R205P 3s;
}

.Colors_main__1MUJg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0 0 0;
  padding: 0;
  box-sizing: border-box;
}

.Colors_main__1MUJg li {
  min-width: 14px;
  min-height: 14px;
  margin: 0 7px 7px 0;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
}

.Colors_main__1MUJg li.Colors_selected__3Sz8a:after {
  background-image: url(/static/media/small-check.7fe6b18e.svg);
  cursor: default;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.Colors_main__1MUJg li:after {
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid transparent;
  display: block;
  content: '';
  box-sizing: border-box;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
}

.Colors_main__1MUJg li span {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: block;
}

.Hr_main__11eDX {
  flex-shrink: 0;
  background: #dddbd7;
  width: 100%;
  height: 1px;
  box-sizing: border-box;
  border: none;
  margin: 0;
  position: relative;  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Hr_main__11eDX span {
  display: inline-block;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  padding: 0 15px;
}

.Hr_margin__kz7yT {
  margin: 18px 0;
}

.Label_main__nKdCt {
  width: 100%;
  min-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  -webkit-user-select: none;
          user-select: none;
}

.Label_main__nKdCt label {
  min-height: 28px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Label_main__nKdCt label span {
  display: inline;
  color: #807E7C;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.Label_main__nKdCt label.Label_withClick__3Bg4x span {
  cursor: pointer;
}

.Label_main__nKdCt label .Label_tip__3pfqi {
  display: inline;
  height: 16px;
  max-height: 16px;
  line-height: 16px;
}

.Label_main__nKdCt label .Label_tip__3pfqi svg {
  margin: 0 0 0 5px;
}

.Label_main__nKdCt .Label_checkbox__eE9tT {
  min-height: 28px;
}

.Label_button__2g25H {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  min-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.Enter_main__vVmKG {
  width: 100%;
  height: 0px;
  margin: 10px 0;
}

.Modal_header__1Fwvf {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #eae8e4;
}

.Modal_header__1Fwvf .Modal_title__TjVqI {
  color: #454442;
  font-size: 18px;
  line-height: 28px;
  -webkit-user-select: none;
          user-select: none;
}

.Modal_header__1Fwvf .Modal_description__2HmR6 {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
  margin: 2px 0 0 0;
  -webkit-user-select: none;
          user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Modal_header__1Fwvf .Modal_description__2HmR6 a {
  color: #454442;
  text-decoration: none;
  cursor: pointer;
  padding: 0 4px;
}

.Modal_header__1Fwvf .Modal_description__2HmR6 a:hover {
  color: #282f2e;
}

.Modal_header__1Fwvf .Modal_description__2HmR6 a:active {
  color: #1b1b1a;
}

.Modal_content__wbPr6 {
}

.Modal_footer__Jleya {
  padding: 15px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.Modal_loader__3gRX- {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
}

/* Overlay style and animation */
.ReactModal__Overlay {
  z-index: 100;
  background-color: transparent !important;

  transition: background-color 200ms ease-in-out;
}
.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.ReactModal__Overlay--before-close {
  background-color: transparent !important;

  transition: background-color 300ms ease-in-out;
}

/* Content style and animation */
.ReactModal__Content {
  opacity: 0;
  -webkit-transform: scale(0.8) translate(-50%, -50%);
          transform: scale(0.8) translate(-50%, -50%);
  -webkit-transform-origin: top left;
          transform-origin: top left;

  box-shadow: 0 4px 4px 0 rgba(48, 47, 46, 0.07), 0 8px 8px 0 rgba(48, 47, 46, 0.07), 0 16px 16px 0 rgba(48, 47, 46, 0.07);

  transition: opacity 300ms ease-in-out, -webkit-transform 200ms ease-out;

  transition: opacity 300ms ease-in-out, transform 200ms ease-out;

  transition: opacity 300ms ease-in-out, transform 200ms ease-out, -webkit-transform 200ms ease-out;
  transition-delay: 100ms;
}
.ReactModal__Content--after-open {
  opacity: 1;
  -webkit-transform: scale(1) translate(-50%, -50%);
          transform: scale(1) translate(-50%, -50%);
}
.ReactModal__Content--before-close {
  opacity: 0;
  -webkit-transform: scale(0.5) translate(-50%, -50%);
          transform: scale(0.5) translate(-50%, -50%);

  transition: opacity 300ms ease-in-out, -webkit-transform 300ms ease-in;

  transition: opacity 300ms ease-in-out, transform 300ms ease-in;

  transition: opacity 300ms ease-in-out, transform 300ms ease-in, -webkit-transform 300ms ease-in;
  transition-delay: 0ms;
}

.Button_button__1Y5Fm {
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  height: 40px;
  box-sizing: border-box;
  padding: 0 15px;
  -webkit-user-select: none;
          user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Button_button__1Y5Fm:focus {
  outline: 0;
}

.Button_button__1Y5Fm.Button_fullWidth__f_mhN {
  width: 100%;
}

.Button_button__1Y5Fm svg {
  margin-right: 10px;
}

.Button_button__1Y5Fm.Button_onlyIcon__3GrU5 svg {
  margin-right: 0;
}

.Button_button__1Y5Fm:disabled,
.Button_button__1Y5Fm.Button_disabled__b6OYd {
  cursor: default;
  opacity: 0.4;
  pointer-events: none;
}

/* Sizes */
.Button_button__1Y5Fm.Button_small__2xvC7 {
  height: 26px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 10px;
}

.Button_button__1Y5Fm.Button_default__10Ann {
  height: 32px;
  font-size: 13px;
  line-height: 17px;
}

.Button_button__1Y5Fm.Button_medium__12_tj {
  height: 34px;
  font-size: 14px;
  line-height: 18px;
}

.Button_button__1Y5Fm.Button_large__2WA_9 {
  height: 38px;
  font-size: 16px;
  line-height: 20px;
}

/* Blue */
.Button_button__1Y5Fm.Button_blue__2A8cS {
  border: 1px solid #732A56;
  background: #732A56;
  color: #FFFFFF;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}

/* White */
.Button_button__1Y5Fm.Button_white__1MlCP {
  border: 1px solid #E7E7E7;
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
  color: #000000;
}


/* Black */
.Button_button__1Y5Fm.Button_black__2NZJG {
  border: 1px solid #202020;
  background: #202020;
  color: #FFFFFF;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}

/* Red */
.Button_button__1Y5Fm.Button_red__2766S {
  border: 1px solid #D43D51;
  background: #D43D51;
  color: #FFFFFF;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}

/* Orange */
.Button_button__1Y5Fm.Button_orange__2YeOR {
  border: 1px solid #FF5602;
  background: #FF5602;
  color: #FFFFFF;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

/* text input */
.Input_textInput__2dLdc {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Input_textInput__2dLdc input {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  height: 32px;
  padding: 0 15px;
  border-radius: 4px;
  color: #656565;
  font-size: 13px;
  font-weight: 400;
}

.Input_textInput__2dLdc.Input_withPrefix__20_85 input {
  border-radius: 4px 0 0 4px;
}

.Input_textInput__2dLdc.Input_withSuffix__3su-N input {
  border-radius: 0 4px 4px 0;
}

.Input_textInput__2dLdc.Input_withPrefix__20_85.Input_withSuffix__3su-N input {
  border-radius: 0;
}

.Input_textInput__2dLdc .Input_prefix__EuUF2 {
  background-color: #FAFAFA;
  border-top: 1px solid #BFBFBF;
  border-right: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 0 4px 4px 0;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #646464;
  font-weight: 400;
  line-height: 16px;
  padding: 0 10px;
}

.Input_textInput__2dLdc .Input_suffix__afcSr {
  background-color: #FAFAFA;
  border-top: 1px solid #BFBFBF;
  border-left: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 4px 0 0 4px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #646464;
  font-weight: 400;
  line-height: 16px;
  padding: 0 10px;
}

.Input_textInput__2dLdc.Input_disabled__3YlxI .Input_prefix__EuUF2,
.Input_textInput__2dLdc.Input_disabled__3YlxI .Input_suffix__afcSr,
.Input_textInput__2dLdc input:disabled {
  opacity: 0.5;
}

.Input_textInput__2dLdc.Input_error__1FLPQ input {
  border-color: #D43D51;
}

.Input_textInput__2dLdc input::-webkit-input-placeholder {
  color: #BFBFBF;
}

.Input_textInput__2dLdc input::placeholder {
  color: #BFBFBF;
}

.Input_textInput__2dLdc.Input_error__1FLPQ {
  border-color: #D43D51 !important;
}

/* password input */
.Input_passwordBox__3KPdM {
  background-color: #FFFFFF;
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  width: 100%;
  height: 32px;
  position: relative;
  box-sizing: border-box;
}

.Input_passwordBox__3KPdM.Input_error__1FLPQ {
  border-color: #D43D51;
}

.Input_passwordInput__8buHR {
  width: 100%;
  box-sizing: border-box;
  height: 30px;
  padding: 0 15px;
  border-radius: 4px;
  color: #656565;
  font-size: 13px;
  font-weight: 400;
}

.Input_passwordInput__8buHR::-webkit-input-placeholder {
  color: #BFBFBF;
}

.Input_passwordInput__8buHR::placeholder {
  color: #BFBFBF;
}

.Input_showPasswordBtn__yb2Et {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

/* search input */
.Input_searchInputBox__2--Ov {
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  width: 100%;
  height: 32px;
  position: relative;
  box-sizing: border-box;
}

.Input_searchInput__10xc6 {
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.1);
  border-radius: 4px;
  height: 30px;
  width: 100%;
  padding: 0 38px 0 15px;
  color: #687680;
  font-size: 13px;
  font-weight: 400;
}

.Input_searchInput__10xc6::-webkit-input-placeholder {
  color: #BFBFBF;
}

.Input_searchInput__10xc6::placeholder {
  color: #BFBFBF;
}

.Input_searchInputBtn__1lpge {
  position: absolute;
  right: 10px;
  top: 8px;
}

/* textarea input */
.Input_textArea__GWD0d {
  width: 100%;
  border: 1px solid #BFBFBF;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 10px 15px;
  border-radius: 4px;
  color: #656565;
  font-size: 13px;
  font-weight: 400;
  resize: none;
}

/* radio input */
.Input_radio__neach {
  color: #646464;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 6px 0;
  -webkit-user-select: none;
          user-select: none;
}

.Input_radio__neach .Input_label__1m6kj span {
  background-color: #BFBFBF;
  border-radius: 10px;
  padding: 2px 6px;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  text-align: center;
  margin: 0 0 0 5px;
  position: relative;
  top: -1px;
}

.Input_radio__neach.Input_disabled__3YlxI {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.7;
}

.Input_radio__neach.Input_disabled__3YlxI .Input_radioElem__2Uvax {
  background-color: #FAFAFA;
  border: 1px solid #BFBFBF;
}

.Input_radio__neach.Input_checked__3Di6s .Input_radioElem__2Uvax {
  border: 1px solid #732A56;
}

.Input_radio__neach.Input_checked__3Di6s .Input_radioElem__2Uvax:after {
  content: '';
  background-color: #732A56;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.Input_radio__neach .Input_radioElem__2Uvax {
  background-color: #FFFFFF;
  border: 1px solid #BFBFBF;
  border-radius: 18px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  position: relative;
  box-sizing: border-box;
  margin-right: 8px;
  -webkit-user-select: none;
          user-select: none;
}

.Input_radio__neach.Input_noText__1AKYG {
  justify-content: center;
}

.Input_radio__neach.Input_noText__1AKYG .Input_radioElem__2Uvax  {
  margin: 0;
}

.Input_radio__neach:hover .Input_radioElem__2Uvax {
  -webkit-filter: brightness(98%);
          filter: brightness(98%);
}

.Input_radio__neach:active .Input_radioElem__2Uvax {
  -webkit-filter: brightness(97%);
          filter: brightness(97%);
}

.Input_radio__neach svg {
  margin-left: 7px;
}

/* checkbox input */
.Input_checkbox__14FLf {
  color: #646464;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 6px 0;
}

.Input_checkbox__14FLf.Input_disabled__3YlxI {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.7;
}

.Input_checkbox__14FLf.Input_disabled__3YlxI .Input_checkboxElem__29v8x {
  background-color: #FAFAFA;
  border: 1px solid #BFBFBF;
}

.Input_checkbox__14FLf.Input_checked__3Di6s  .Input_checkboxElem__29v8x {
  border: 1px solid #732A56;
}

.Input_checkbox__14FLf.Input_checked__3Di6s .Input_checkboxElem__29v8x:after {
  content: '\2713';
  color: #732A56;
  font-weight: 700;
  border-radius: 100%;
  font-size: 12px;
  line-height: 12px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.Input_checkbox__14FLf .Input_checkboxElem__29v8x {
  background-color: #FFFFFF;
  border: 1px solid #BFBFBF;
  border-radius: 3px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  position: relative;
  box-sizing: border-box;
  margin: 0 8px 0 0;
  -webkit-user-select: none;
          user-select: none;
}

.Input_checkbox__14FLf.Input_noText__1AKYG {
  justify-content: center;
}

.Input_checkbox__14FLf.Input_noText__1AKYG .Input_checkboxElem__29v8x  {
  margin: 0;
}

.Input_checkbox__14FLf:not(.Input_disabled__3YlxI):hover .Input_checkboxElem__29v8x {
  -webkit-filter: brightness(98%);
          filter: brightness(98%);
}

.Input_checkbox__14FLf:not(.Input_disabled__3YlxI):active .Input_checkboxElem__29v8x {
  -webkit-filter: brightness(97%);
          filter: brightness(97%);
}

.Input_checkbox__14FLf svg {
  margin-left: 7px;
}

/* Font select */
.Input_fontSelect__3nrUV {

}

.Input_fontSelect__3nrUV .Input_dropdown-button__xIOCx {
  display: none;
}

/* creditCardInput */
.Input_creditCard__2ZxA1 {
  position: relative;
  padding: 1px;
}

.Input_creditCard__2ZxA1 .Input_creditCardInput__1LOBn {
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  height: 32px;
  padding: 8px 15px;
  border-radius: 4px;
  position: relative;
  transition: box-shadow .1s ease-in-out;
}

.Input_creditCard__2ZxA1 .Input_creditCardInput__1LOBn:hover {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
}

.Input_creditCard__2ZxA1 .Input_creditCardInput__1LOBn.Input_active__Ol-xS {
  box-shadow: 0 1px 3px 0 #732A56;
}

.Input_creditCard__2ZxA1 .Input_creditCardInputBackground__1RfUN {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 34px;
  border-radius: 4px;
  background: #E7E7E7;
  transition: background .1s ease-in-out;
  position: absolute;
}

.Input_creditCardInputBackground__1RfUN.Input_active__Ol-xS {
  background: #C9CEFB;
}

.Input_creditCard__2ZxA1.Input_error__1FLPQ .Input_creditCardInputBackground__1RfUN {
  background: #D43D51 !important;
}

.Input_creditCard__2ZxA1.Input_error__1FLPQ .Input_creditCardInput__1LOBn {
  box-shadow: 0 1px 3px 0 #D43D51;
}
.Toggle_toggle2__2bsCF {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.Toggle_toggle__17sD8.Toggle_disabled__KwxVG,
.Toggle_toggle2__2bsCF.Toggle_disabled__KwxVG {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.Toggle_label__2KSW7 {
  color: #646464;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin: 0 0 0 8px;
  -webkit-user-select: none;
          user-select: none;
}

.react-toggle{touch-action:pan-x;position:relative;cursor:pointer;background-color:transparent;border:0;padding:0;-webkit-touch-callout:none;-webkit-user-select:none;user-select:none;-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent;height:30px;display:flex;align-items:center}.react-toggle-screenreader-only{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}.react-toggle--disabled{cursor:not-allowed;opacity:0.5;transition:opacity 0.25s}.react-toggle-track{width:37px;height:20px;padding:0;border-radius:30px;background-color:#E2E5ED;transition:all 0.2s ease}.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track{background-color:#CFD4D8}.react-toggle--checked .react-toggle-track{background-color:#732A56}.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track{-webkit-filter:brightness(98%);filter:brightness(98%);background-color:#732A56}.react-toggle--checked:active:not(.react-toggle--disabled) .react-toggle-track{-webkit-filter:brightness(97%);filter:brightness(97%);background-color:#732A56}.react-toggle-track-check{position:absolute;width:14px;height:10px;top:0px;bottom:0px;margin-top:auto;margin-bottom:auto;line-height:0;left:8px;opacity:0;transition:opacity 0.25s ease}.react-toggle--checked .react-toggle-track-check{opacity:1;transition:opacity 0.25s ease}.react-toggle-track-x{position:absolute;width:10px;height:10px;top:0px;bottom:0px;margin-top:auto;margin-bottom:auto;line-height:0;right:10px;opacity:1;transition:opacity 0.25s ease}.react-toggle--checked .react-toggle-track-x{opacity:0}.react-toggle-thumb{transition:all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;position:absolute;top:6px;left:1px;width:18px;height:18px;border:1px solid #E2E5ED;border-radius:50%;background-color:#FAFAFA;box-sizing:border-box;transition:all 0.25s ease}.react-toggle--checked .react-toggle-thumb{left:18px;border-color:#732A56}

.Select_colorSelect__25s1A {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Select_colorSelect__25s1A div {
  border-radius: 4px;
  width: 16px;
  height: 22px;
}

.Select_selectCover__3GYiU  {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Select_select__3PL6i {
  position: relative;
  box-sizing: border-box;
}

.Select_selectInput__2sJJB {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  height: 32px;
  line-height: 32px;
  padding: 0 25px 0 15px;
  border-radius: 4px;
  color: #656565;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Select_selectInput__2sJJB.Select_disabled__3i9nd {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.Select_selectInput__2sJJB.Select_placeholder__JCbns {
  color: #BFBFBF;
}

.Select_select__3PL6i.Select_error__3-kqt .Select_selectInput__2sJJB {
  border-color: #D43D51;
}

.Select_selectInput__2sJJB:after {
  content: '';
  background: url(/static/media/dropdown-arrow.1078894b.svg);
  width: 13px;
  height: 7px;
  position: absolute;
  right: 9px;
  top: 13px;
}

.Select_selectInput__2sJJB.Select_active__2KrWb:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.Select_selectDropdown__39E_r {
  position: absolute;
  box-sizing: border-box;
  z-index: 1024;
  height: 308px;
  display: flex;
  flex-direction: column;
}

.Select_selectDropdownContent__AMwsH {
  background-color: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 4px;
  /* position: absolute; */
  width: 100%;
}

.Select_selectDropdown__39E_r.Select_top__2Nebl {
  align-items: flex-end;
  justify-content: flex-end;
}

.Select_selectDropdown__39E_r.Select_top__2Nebl .Select_selectDropdownContent__AMwsH {
  bottom: 0;
}

.Select_selectDropdown__39E_r.Select_bottom__1IIYZ {
  align-items: flex-start;
  justify-content: flex-start;
}

.Select_selectDropdown__39E_r.Select_bottom__1IIYZ .Select_selectDropdownContent__AMwsH {
  top: 0;
}

.Select_selectDropdownItems__FZ3Ue {
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Select_selectDropdown__39E_r.Select_top__2Nebl .Select_selectDropdownItems__FZ3Ue {
  flex-direction: column-reverse;
}

.Select_selectDropdownItem__Xjnfs {
  color: #656565;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  padding: 6px 15px;
  width: 100%;
  -webkit-user-select: none;
          user-select: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Select_selectDropdownItem__Xjnfs.Select_selected__1JCsL {
  background: rgba(0, 100, 246, 0.05);
}

.Select_selectDropdownItem__Xjnfs:not(.Select_selected__1JCsL):hover {
  background-color: #FAFAFA;
  color: #656565;
  cursor: pointer;
}

.Select_scrollArea__3EIS8 {
  max-height: 308px;
  box-sizing: border-box;
}

/* size */

.Select_select__3PL6i.Select_small__11EPw .Select_selectInput__2sJJB {
  height: 24px;
  line-height: 24px;
}

.Select_select__3PL6i.Select_small__11EPw .Select_selectInput__2sJJB:after {
  top: 9px;
}

.Select_select__3PL6i.Select_small__11EPw .Select_selectDropdown__39E_r {
  top: 26px;
}

.Select_select__3PL6i.Select_small__11EPw .Select_selectDropdownItem__Xjnfs {
  line-height: 17px;
  padding: 3px 15px;
}

/* color palette */
.Select_colorPaletteSelect__1ehyX {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Select_colorPaletteSelect__1ehyX .Select_label__2UJcy {
  display: block;
  margin: 0 0 0 10px;
  width: calc(100% - 28px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #646464;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.Select_colorPaletteDropdown__36BCt {
  position: absolute;
  box-sizing: border-box;
  z-index: 1024;
  display: flex;
  flex-direction: row;
  top: 38px;
  background-color: #FFFFFF;
  border: 1px solid #BFBFBF;
}

.Select_colorPaletteDropdown__36BCt .Select_chromePicker__3Szip {
  box-shadow: none !important;
  border-right: 1px solid #BFBFBF;
}

.Select_colorPaletteDropdown__36BCt .Select_scrollArea__3EIS8 {
  width: 82px;
  max-height: 241px;
}

.Select_colorPaletteDropdown__36BCt ul {
  box-sizing: border-box;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 5px;
  -webkit-user-select: none;
          user-select: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.Select_colorPaletteDropdown__36BCt ul li {
  margin: 5px;
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.Select_colorPaletteDropdown__36BCt ul li .Select_colorCircle__321eM {
  width: 30px;
  height: 30px;
  color: rgb(204, 204, 204);
  font-weight: 300;
  font-size: 30px;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAADAAAAAATDPpdAAAAKElEQVQoFWNkwA1UsEkxYRPEJzYcNDACPYg1NIDid7B5fjh4mmQ/AAB45QE7btOdVgAAAABJRU5ErkJggg==');
}

.Select_colorPaletteDropdown__36BCt ul li:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.Select_colorPaletteDropdown__36BCt ul li.Select_active__2KrWb {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.Select_colorPaletteDropdown__36BCt ul li .Select_colorCircle__321eM span {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  display: block;
}

/* select2 */

.Select_select2__3bfBi .Select_dropdownIndicator__waWKw {
  padding: 0px 8px;
  margin-top: -2px;
}
@-webkit-keyframes Loader_rotator__H5jYx {
  0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}

@keyframes Loader_rotator__H5jYx {
  0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
}

@-webkit-keyframes Loader_colors__2vVFR {
	0% { stroke: #4285F4; }
	25% { stroke: #DE3E35; }
	50% { stroke: #F7C223; }
	75% { stroke: #1B9A59; }
  100% { stroke: #4285F4; }
}

@keyframes Loader_colors__2vVFR {
	0% { stroke: #4285F4; }
	25% { stroke: #DE3E35; }
	50% { stroke: #F7C223; }
	75% { stroke: #1B9A59; }
  100% { stroke: #4285F4; }
}

@-webkit-keyframes Loader_dash__32-R8 {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform:rotate(135deg);
            transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform:rotate(450deg);
            transform:rotate(450deg);
  }
}

@keyframes Loader_dash__32-R8 {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform:rotate(135deg);
            transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform:rotate(450deg);
            transform:rotate(450deg);
  }
}

.Loader_background__23F3v {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Loader_spinner__1U8FS {
  -webkit-animation: Loader_rotator__H5jYx 1.4s linear infinite;
          animation: Loader_rotator__H5jYx 1.4s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 16;
}

.Loader_path__3vJcp {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation:
    Loader_dash__32-R8 1.4s ease-in-out infinite, 
    Loader_colors__2vVFR 5.6s ease-in-out infinite;
          animation:
    Loader_dash__32-R8 1.4s ease-in-out infinite, 
    Loader_colors__2vVFR 5.6s ease-in-out infinite;
}

.Modal_header__VcvCN {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #E7E7E7;
}

.Modal_header__VcvCN span {
  color: #363636;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}

.Modal_content__BaPv5 {

}

.Modal_footer__1lpLO {
  padding: 15px 0 0 0;
  border-top: 1px solid #E7E7E7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.Modal_loader__wa0kq {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
}

.Row_formRow__3vL6W {
  margin: 0 0 15px 0;
  width: 100%;
  position: relative;
}

.Row_formRow__3vL6W.Row_hide__3QmBL {
  display: none;
}

.Row_formRow__3vL6W label {
  color: #656565;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  margin: 0 0 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Row_formRow__3vL6W label .Row_toggle___4Ow4 {
  margin: 0 8px 0 0;
}

.Row_formRow__3vL6W label span {
  color: #FA6D78;
  padding: 0 0 0 1px;
}

.Row_formRow__3vL6W .Row_errorMessage__3NBr0 {
  color: #D43D51;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin: 5px 0 0 0;
}

.Row_formRow__3vL6W .Row_description__wocit {
  color: #656565;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  padding: 5px 0 0 0;
}

.Avatar_avatar__2_mst {
  background-color: red;
  border-radius: 17px;
  box-shadow: 0 1px 3px 0 rgba(191, 167, 144, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: default;
  position: relative;
}

.Avatar_avatar__2_mst.Avatar_border__20vHO {
  border: 2px solid #ffffff;
  box-shadow: none;
}

.Avatar_avatar__2_mst span {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  mix-blend-mode: overlay;
  -webkit-user-select: none;
          user-select: none;
}

.Card_card__21P3q {
  background-color: #FFFFFF;
  border: 1px solid #E7E7E7;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.Card_card__21P3q .Card_disabled__2BUa0 {
  position: absolute;
  border-radius: 4px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 16;
}

.Card_card__21P3q .Card_loader__3Nqr5 {
  position: absolute;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 16;
}

.Card_row__2R95g {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #E7E7E7;
  margin: 0 0 30px 0;
  padding: 0 0 30px 0;
  position: relative;
}

.Card_row__2R95g:last-child {
  border-bottom: 0;
  margin: 0;
  padding: 0;
}

.Card_label__1iPAN {
  max-width: 200px;
  width: 100%;
  padding-right: 60px;
  -webkit-user-select: none;
          user-select: none;
}

.Card_label__1iPAN .Card_title__psrn7 {
  color: #363636;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Card_label__1iPAN .Card_title__psrn7.Card_withToggle__1xuxx {
  cursor: pointer;
}

.Card_label__1iPAN .Card_title__psrn7 > div {
  margin: 0 10px 0 0;
}

.Card_label__1iPAN .Card_subTitle__KILB6 {
  color: #949494;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  padding: 5px 0 0 0;
}

.Card_content__2kS07 {
  width: 100%;
  position: relative;
}

.ColorsPalette_main__1h48w {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0 0 0;
  padding: 0;
  box-sizing: border-box;
}

.ColorsPalette_main__1h48w li {
  min-width: 30px;
  min-height: 30px;
  margin: 0 14px 14px 0;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
}

.ColorsPalette_main__1h48w li:hover:after {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  border: 2px solid #FFFFFF;
}

.ColorsPalette_main__1h48w li.ColorsPalette_selected__1IL2T:after {
  background-image: url(/static/media/small-check.7fe6b18e.svg);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  border: 2px solid #FFFFFF;
  cursor: default;
}

.ColorsPalette_main__1h48w li:after {
  background: transparent;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  border: 2px solid transparent;
  display: block;
  content: '';
  box-sizing: border-box;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
}

.ColorsPalette_main__1h48w li span {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: block;
}
.DropdownMenu_cover__ie7nI {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 64;
}

.DropdownMenu_main__2lIkS {
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 8px 17px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  box-sizing: border-box;
  z-index: 128;
  -webkit-user-select: none;
          user-select: none;
}

.DropdownMenu_main__2lIkS ul {
  list-style: none;
  padding: 13px 0;
  margin: 0;
  box-sizing: border-box;
}

.DropdownMenu_label__wGGfF,
.DropdownMenu_back__U42tl {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 7px 20px 7px 20px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.DropdownMenu_back__U42tl {
  cursor: pointer;
}

.DropdownMenu_back__U42tl svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin: 0 10px 0 0;
}

.DropdownMenu_link__EMS3g,
.DropdownMenu_action__3UC4W {
  color: #646464;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  padding: 7px 20px;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
}

.DropdownMenu_action__3UC4W.DropdownMenu_red__1r1_c {
  color: #D43D51;
}

.DropdownMenu_action__3UC4W.DropdownMenu_selected__3DSgA {
  color: #732A56;
  font-weight: 500;
}

.DropdownMenu_action__3UC4W.DropdownMenu_disabled__2a7fB {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}

.DropdownMenu_hr__c0pgO {
  border: none;
  width: 100%;
  height: 1px;
  background: #E7E7E7;
  margin: 7px 0;
  box-sizing: border-box;
}

.DropdownMenu_list__3C7tl {
  color: #646464;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  padding: 7px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.DropdownMenu_list__3C7tl svg {
  -webkit-transform: rotate(265deg);
          transform: rotate(265deg);
}

.DropdownMenu_link__EMS3g:hover,
.DropdownMenu_action__3UC4W:hover,
.DropdownMenu_list__3C7tl:hover,
.DropdownMenu_back__U42tl:hover {
  background: #FAFAFA;
}

.DropdownMenu_link__EMS3g:active,
.DropdownMenu_action__3UC4W:active,
.DropdownMenu_list__3C7tl:active,
.DropdownMenu_back__U42tl:active {
  background: #E7E7E7;
}

.DropdownMenu_scrollArea__2onOF {
  max-height: 250px;
  box-sizing: border-box;
}

.Slate_editable__298ur {
  width: 100%;
  border: 1px solid #BFBFBF;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 6px 15px;
  border-radius: 4px;
  color: #656565;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  resize: none;
}

.Slate_editable__298ur.Slate_disabled__1o3ly {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.7;
}

.Slate_editable__298ur.Slate_withBar__2fnqr {
  border-radius: 0 0 4px 4px;
}

.Slate_editable__298ur p {
  margin: 5px 0;
}

.Slate_editable__298ur p:first-child {
  margin-top: 0;
}

.Slate_editable__298ur p:last-child {
  margin-bottom: 0;
}

.Slate_editable__298ur ul,
.Slate_editable__298ur ol {
  margin: 0;
  padding: 10px 0 10px 20px;
}

.Slate_dropdown__1MItB {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: 8;
  padding: 10px;
  background-color: #FFFFFF;
  border: 0.5px solid #656565;
  border-radius: 4px;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.Slate_dropdown__1MItB .Slate_item__2TsGY {
  padding: 5px 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 10px);
  border-radius: 3px;
  cursor: pointer;
}

.Slate_dropdown__1MItB .Slate_item__2TsGY:hover {
  background: rgba(0, 100, 246, 0.025) !important;
}

.Slate_dropdown__1MItB .Slate_item__2TsGY span {
  width: 18px;
  height: 18px;
  background: #ECEEF0;
  border-radius: 2px;
  display: block;
  margin: 0 10px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Slate_dropdown__1MItB .Slate_item__2TsGY p {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.Slate_mentionElement__1hxRp {
  margin: 0 1px;
  color: #7F8992;
  border: 1px solid #E7E7E7;
  vertical-align: baseline;
  border-radius: 3px;
  background-color: #FAFAFA;
  font-size: 13px;
  box-sizing: border-box;
  line-height: 18px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  -webkit-user-select: none;
          user-select: none;
}

.Slate_mentionElement__1hxRp .Slate_image__3gt18 {
  border-right: 1px solid #E7E7E7;
  padding: 0 5px;
}

.Slate_mentionElement__1hxRp .Slate_label__2vEZS {
  padding: 0 5px;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Slate_button__1_6jI {
  cursor: pointer;
  opacity: 0.5;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.Slate_button__1_6jI.Slate_active__YiR7M {
  opacity: 1;
}

.Slate_button__1_6jI.Slate_formatBold__32ZXj {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNOSAxMC4zNDRxLjQzOCAwIC43Mi0uMjk3dC4yOC0uNzAzLS4yOC0uNzAzVDkgOC4zNDVINi42NTZ2Mkg5em0tMi4zNDQtNnYyaDJxLjQwNiAwIC43MDMtLjI5N3QuMjk2LS43MDMtLjI5Ny0uNzAzLS43MDQtLjI5NmgtMnptMy43NSAyLjg0NHExLjQzOC42NTYgMS40MzggMi4yOCAwIDEuMDY0LS43MDMgMS43OThUOS4zNzYgMTJoLTQuNzJWMi42NTZoNC4xOXExLjEyNCAwIDEuODkuNzh0Ljc2NiAxLjkwNy0xLjA5MyAxLjg0NHoiLz48L3N2Zz4=);
}

.Slate_button__1_6jI.Slate_formatItalic__3bDOA {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNi42NTYgMi42NTZIMTJ2MmgtMS44NzVMNy44NzUgMTBoMS40N3YySDR2LTJoMS44NzVsMi4yNS01LjM0NGgtMS40N3YtMnoiLz48L3N2Zz4=);
}

.Slate_button__1_6jI.Slate_formatUnderlined__2qD5p {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNMy4zNDQgMTIuNjU2aDkuMzEzVjE0SDMuMzQ0di0xLjM0NHpNOCAxMS4zNDRxLTEuNjU2IDAtMi44MjgtMS4xNzJUNCA3LjM0NFYyaDEuNjU2djUuMzQ0cTAgLjk3LjY4OCAxLjY0VDggOS42NTh0MS42NTYtLjY3Mi42ODgtMS42NFYySDEydjUuMzQ0UTEyIDkgMTAuODI4IDEwLjE3MlQ4IDExLjM0NHoiLz48L3N2Zz4=);
}

.Slate_button__1_6jI.Slate_numberedList__YCZEY {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNC42NTYgOC42NTZWNy4zNDNIMTR2MS4zMTNINC42NTZ6bTAgNHYtMS4zMTNIMTR2MS4zMTNINC42NTZ6bTAtOS4zMTJIMTR2MS4zMTNINC42NTZWMy4zNDR6bS0zLjMxMiA0di0uNjg4aDJ2LjYyNWwtMS4yMiAxLjM3NmgxLjIydi42ODhoLTJWOC43MmwxLjE4OC0xLjM3NkgxLjM0NHptLjY1Ni0ydi0yaC0uNjU2di0uNjg4aDEuMzEzdjIuNjg4SDJ6bS0uNjU2IDZ2LS42ODhoMnYyLjY4OGgtMnYtLjY4OGgxLjMxM3YtLjMxM0gydi0uNjg4aC42NTd2LS4zMTNIMS4zNDR6Ii8+PC9zdmc+);
}

.Slate_button__1_6jI.Slate_bulletedList__3n9si {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNC42NTYgMy4zNDRIMTR2MS4zMTNINC42NTZWMy4zNDR6bTAgNS4zMTJWNy4zNDNIMTR2MS4zMTNINC42NTZ6bTAgNHYtMS4zMTNIMTR2MS4zMTNINC42NTZ6bS0yLTEuNTNxLjM3NSAwIC42NC4yNXQuMjY3LjYyNC0uMjY2LjYyNS0uNjQuMjUtLjYyNi0uMjVUMS43OCAxMnQuMjUtLjYyNS42MjYtLjI1em0wLTguMTI2cS40MDYgMCAuNzAzLjI4dC4yOTYuNzItLjI5Ny43Mi0uNzA0LjI4LS43MDMtLjI4VDEuNjU2IDR0LjI5Ny0uNzIuNzAzLS4yOHptMCA0cS40MDYgMCAuNzAzLjI4dC4yOTYuNzItLjI5Ny43Mi0uNzA0LjI4LS43MDMtLjI4VDEuNjU2IDh0LjI5Ny0uNzIuNzAzLS4yOHoiLz48L3N2Zz4=);
}

.Slate_button__1_6jI.Slate_link__o1KeG {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDIyIDI0Ij48cGF0aCBkPSJNMTkuNSAxNi4yODZxMC0uNTM2LS4zNzUtLjkxbC0yLjc4Ni0yLjc4N3EtLjM3Ni0uMzc2LS45MTItLjM3Ni0uNTYzIDAtLjk2NC40M2wuMjU0LjI0N3EuMjE0LjIwOC4yODguMjl0LjIuMjUzLjE3NS4zNDIuMDQ4LjM2OHEwIC41MzYtLjM3NS45MXQtLjkxLjM3NnEtLjIwMiAwLS4zNy0uMDQ4dC0uMzQtLjE3NC0uMjU1LS4yLS4yODgtLjI5LS4yNDgtLjI1M3EtLjQ0Mi40MTUtLjQ0Mi45NzggMCAuNTM2LjM3NS45MWwyLjc2IDIuNzczcS4zNi4zNjIuOTEuMzYyLjUzNiAwIC45MS0uMzQ4bDEuOTctMS45NTVxLjM3NS0uMzc1LjM3NS0uODk3em0tOS40MTUtOS40NDJxMC0uNTM2LS4zNzUtLjkxTDYuOTUgMy4xNnEtLjM3NC0uMzc0LS45MS0uMzc0LS41MjIgMC0uOTEuMzYyTDMuMTYgNS4xMDNxLS4zNzUuMzc1LS4zNzUuODk3IDAgLjUzNi4zNzUuOTFsMi43ODYgMi43ODdxLjM2Mi4zNjIuOTEuMzYyLjU2NCAwIC45NjUtLjQxNmwtLjI1My0uMjQ4cS0uMjEzLS4yMDgtLjI4OC0uMjg4dC0uMjAyLS4yNTQtLjE3NC0uMzQyLS4wNDctLjM2OHEwLS41MzYuMzc1LS45MXQuOTEtLjM3NnEuMjAyIDAgLjM3LjA0N3QuMzQuMTc0LjI1NS4yLjI4OC4yODguMjQ4LjI1NHEuNDQyLS40MTUuNDQyLS45Nzh6bTExLjk4NiA5LjQ0MnEwIDEuNjA3LTEuMTM3IDIuNzJsLTEuOTcgMS45NTRxLTEuMTEgMS4xMTItMi43MTggMS4xMTItMS42MiAwLTIuNzMyLTEuMTM4bC0yLjc2LTIuNzcycS0xLjExLTEuMTEyLTEuMTEtMi43MiAwLTEuNjQ2IDEuMTc4LTIuNzk4bC0xLjE3OC0xLjE4cS0xLjE1MiAxLjE4LTIuNzg2IDEuMTgtMS42MDcgMC0yLjczMi0xLjEyNUwxLjMzOCA4LjczMlEuMjEzIDcuNjA4LjIxMyA2VDEuMzUgMy4yODNsMS45Ny0xLjk1NVE0LjQzMi4yMTUgNi4wNC4yMTVxMS42MiAwIDIuNzMgMS4xMzhsMi43NiAyLjc3MnExLjExMiAxLjExMiAxLjExMiAyLjcyIDAgMS42NDYtMS4xOCAyLjc5OGwxLjE4IDEuMThxMS4xNTItMS4xOCAyLjc4Ni0xLjE4IDEuNjA3IDAgMi43MzIgMS4xMjVsMi43ODYgMi43ODZxMS4xMjUgMS4xMjUgMS4xMjUgMi43MzJ6Ii8+PC9zdmc+);
  background-size: 14px;
}

.Slate_staticMenu__2ptdV {
  background-color: #FAFAFA;
  border-top: 1px solid #BFBFBF;
  border-left: 1px solid #BFBFBF;
  border-right: 1px solid #BFBFBF;
  border-radius: 4px 4px 0 0;
  padding: 4px 4px 3px 4px;
  box-sizing: border-box;
  margin: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.Slate_staticMenu__2ptdV > * {
  display: inline-block;
}

.Slate_staticMenu__2ptdV > * + * {
  margin-left: 5px;
}

.Slate_tooltipMenu__25PQ7 {
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  color: #fff;
  border-radius: 4px;
  transition: opacity 0.75s;
  z-index: 1024;
  background-color: #FAFAFA;
  border: 1px solid #BFBFBF;
  padding: 3px 4px 0 4px;
}

.Slate_toolbar__2R1xJ {
  position: relative;
  padding: 1px 18px 17px;
  margin: 0 -20px;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
}
.Avatar_main__17QwE {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #B4B2AF;
  border-radius: 4px;
  color: #FBFAF9;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  background-size: contain;
}

.Avatar_main__17QwE .Avatar_activeDot__1yn9- {
  background-color: #9F9C0F;
  border: 1px solid #FBFAF9;
  box-sizing: border-box;
  border-radius: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.Avatar_main__17QwE.Avatar_active__1oC95 .Avatar_activeDot__1yn9- {
  opacity: 1;
}

.Avatar_main__17QwE .Avatar_loader__36U_g {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.Avatar_main__17QwE.Avatar_loading__2Vx7B .Avatar_loader__36U_g {
  opacity: 1;
}
.Slider_sliderWrapper__alJUk {
  flex-grow: 1;
  display: flex;
}
.Slider_sliderWrapper__alJUk > *:not(:last-child) {
  margin-right: 5px;
}

.Slider_sliderValues__SSb4P {
  flex-shrink: 0;
  color: #807e7c;
}

.Slider_disabled__3s8rS {
  opacity: 0.5;
  cursor: not-allowed;
}

.DropdownMenu_cover__1eoYN {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 64;
}

.DropdownMenu_main__1tjxZ {
  background-color: #1b1b1a;
  border-radius: 4px;
  box-shadow: 0 8px 17px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  box-sizing: border-box;
  z-index: 128;
  -webkit-user-select: none;
          user-select: none;
}

.DropdownMenu_main__1tjxZ:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #1b1b1a;
  border-width: 6px;
  margin-left: -6px;
}

.DropdownMenu_main__1tjxZ ul {
  list-style: none;
  padding: 7px 0;
  margin: 0;
  box-sizing: border-box;
}

.DropdownMenu_label__p7mkE,
.DropdownMenu_back__3irfT {
  color: #eae8e4;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 7px 7px 7px 20px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.DropdownMenu_back__3irfT {
  cursor: pointer;
}

.DropdownMenu_back__3irfT svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin: 0 10px 0 0;
}

.DropdownMenu_link__GWwnY,
.DropdownMenu_action__3GdLD,
.DropdownMenu_delete__2HVT6 {
  color: #eae8e4;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  padding: 7px 20px;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;

  font-weight: 500;
}

.DropdownMenu_delete__2HVT6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.DropdownMenu_action__3GdLD.DropdownMenu_selected__DDPni {
  color: #ffffff;
  font-weight: 500;
}

.DropdownMenu_action__3GdLD.DropdownMenu_disabled__292zH {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}

.DropdownMenu_hr__2MCbV {
  border: none;
  width: 100%;
  height: 1px;
  background: #454442;
  margin: 7px 0;
  box-sizing: border-box;
}

.DropdownMenu_list__OAdgL {
  color: #eae8e4;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  padding: 7px 7px 7px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.DropdownMenu_list__OAdgL svg {
  -webkit-transform: rotate(265deg);
          transform: rotate(265deg);
}

.DropdownMenu_link__GWwnY:hover,
.DropdownMenu_action__3GdLD:hover,
.DropdownMenu_delete__2HVT6:hover,
.DropdownMenu_list__OAdgL:hover,
.DropdownMenu_back__3irfT:hover {
  background: #151413;
}

.DropdownMenu_link__GWwnY:active,
.DropdownMenu_action__3GdLD:active,
.DropdownMenu_delete__2HVT6:active,
.DropdownMenu_list__OAdgL:active,
.DropdownMenu_back__3irfT:active {
  background: #000000;
}

.DropdownMenu_scrollArea__z3R3d {
  max-height: 250px;
  box-sizing: border-box;
  overflow-y: auto;
}

.DropdownMenu_deleteLoader__2MAmP {
  width: 18px;
  height: 18px;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.DropdownMenu_delete__2HVT6:active .DropdownMenu_deleteLoader__2MAmP {
  opacity: 1;
}

.DropdownMenu_deleteHint__3sIUT {
  color: #eae8e4;
  font-size: 12px;
  line-height: 14px;
  background-color: #1b1b1a;
  border-radius: 4px;
  position: absolute;
  text-align: center;
  width: 100px;
  right: -125px;
  padding: 6px 10px;
}

.DropdownMenu_deleteHint__3sIUT:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #1b1b1a;
  border-width: 4px;
  margin-top: -4px;
}

.Header_main__3UZlt {
  color: #454442;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.FormRow_formRow__kouPv {
  margin: 0 0 15px 0;
  width: 100%;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}

.FormRow_formRow__kouPv.FormRow_hide__SmaAF {
  display: none;
}

.FormRow_formRow__kouPv label {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  margin: 0 0 7px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.FormRow_formRow__kouPv label .FormRow_toggle__3xN1o {
  margin: 0 8px 0 0;
}

.FormRow_formRow__kouPv label .FormRow_required__21PYW {
  color: #FA6D78;
  padding: 0 0 0 1px;
}

.FormRow_formRow__kouPv .FormRow_errorMessage__F4wyw {
  color: #F05252;
  font-size: 13px;
  line-height: 16px;
  margin: 5px 0;
}

.FormRow_formRow__kouPv .FormRow_description__aJIKa {
  color: #807E7C;
  font-size: 12px;
  line-height: 16px;
}

.NavMenu_nav__2m2BS {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Label_label__AdOcD {
  padding: 0 15px;
  -webkit-user-select: none;
          user-select: none;
  box-sizing: border-box;
}

.Label_label__AdOcD.Label_borderTop__37d_b {
  border-top: 1px solid #eae8e4;
}

.Label_label__AdOcD.Label_borderBottom__2dueF {
  border-bottom: 1px solid #eae8e4;
}

.Label_label__AdOcD .Label_labelContent__21474 {
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.Label_label__AdOcD .Label_labelTextTop__mufnS {
  width: 100%;
}

.Label_label__AdOcD .Label_labelTextTopContent__3pEOC {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  min-height: 36px;
  width: 100%;
}

.Label_label__AdOcD .Label_labelTextTopContent__3pEOC.Label_withBack__tAkjd {
  cursor: pointer;
}

.Label_label__AdOcD .Label_labelDescription__NWhgH {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
  padding: 0;
  margin: 0 0 15px 0;
}

.Label_label__AdOcD .Label_labelBack__3_8EU {
  cursor: pointer;
  width: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.Label_label__AdOcD .Label_labelText__1bKDL {
  width: calc(100% - 20px);
}

.Label_label__AdOcD .Label_labelClose__1AaqJ {
  cursor: pointer;
  width: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
}

.Label_label__AdOcD .Label_labelActions__2fWfQ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 4px 0;
}

.Label_label__AdOcD .Label_labelAddButton__20f-U {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;

  cursor: pointer;
}

.Label_label__AdOcD .Label_labelAddButtonLabel__3qsNz {
  color: #5d5d5c;
  font-size: 13px;
  font-weight: 500;
  margin-right: 8px;
}

.Collapse_collapse__3GkIB {
  -webkit-user-select: none;
          user-select: none;
  box-sizing: border-box;
}

.Collapse_collapse__3GkIB.Collapse_borderTop__1SR2V {
  border-top: 1px solid #eae8e4;
}

.Collapse_collapse__3GkIB.Collapse_borderBottom__3og0Y {
  border-bottom: 1px solid #eae8e4;
}

/* Removes border in adjacent closed collapses */
.Collapse_collapse__3GkIB:not(.Collapse_open__3L2vw) + .Collapse_collapse__3GkIB {
  border-top: none;
}

.Collapse_collapseLabel__AEanB {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  background-color: #f6f5f4;
  border-bottom: 1px solid #eae8e4;
  width: 100%;
  height: 36px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
}

.Collapse_labelDescription__3mvgO {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
  padding: 15px 15px 0 15px;
}

.Collapse_labelActions__1Gzh9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 15px 4px 15px;
  box-sizing: border-box;
}

.Collapse_labelActions__1Gzh9.Collapse_borderTop__1SR2V {
  border-top: 1px solid #eae8e4;
}

.Collapse_labelActions__1Gzh9.Collapse_borderBottom__3og0Y {
  border-bottom: 1px solid #eae8e4;
}

.Collapse_collapseLabelTitle__1ygjh {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
}

.Collapse_collapseLabelTitle__1ygjh svg {
  position: relative;
  margin: 0 7px 0 0;
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.Collapse_collapseLabelTitle__1ygjh .Collapse_rotate__3O3gM svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.Collapse_collapseLabelAddButton__2I7N6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  line-height: 20px;
  cursor: pointer;
}

.Collapse_collapseLabelAddButtonLabel__4z9Xb {
  color: #5d5d5c;
  font-size: 13px;
  font-weight: 500;
  margin-right: 8px;
}

.Collapse_collapseChildren__1AuPM {
  will-change: height, opacity;
  transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  overflow: hidden;
}

.Collapse_collapseChildren__1AuPM.Collapse_hide__2PpE_ {
  opacity: 0;
}

.Collapse_collapseChildren__1AuPM.Collapse_show__3qZsn {
  opacity: 1;
}

.Collapse_collapseChildrenContent__2pOB5 {
  box-sizing: border-box;
  overflow: visible;
}

.Collapse_collapseCount__lsgYF {
  color: #807e7c;
  font-size: 11px;
  background-color: #dddbd7;
  border-radius: 2px;
  padding: 0 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Tabs_tabs__nvZ-Y {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 37px; /* 36px + 1px for padding to make it consistent with label component */

  background-color: #f6f5f4;
  border-bottom: 1px solid #eae8e4;

  font-weight: 600;

  padding: 0 15px;
  -webkit-user-select: none;
          user-select: none;
}

.Tabs_tabs__nvZ-Y .Tabs_tabsItem__31sJg {
  box-sizing: border-box;
  height: 100%;

  color: #b4b2af;
  font-size: 13px;
  line-height: 36px;
  text-align: left;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  margin: 0 15px 0 0;
}

.Tabs_tabs__nvZ-Y .Tabs_tabsItem__31sJg.Tabs_active__3lmTW {
  color: #5d5d5c;
  border-color: #807e7c;
  cursor: default;
}

.Optional_optional__188_D {
  -webkit-user-select: none;
          user-select: none;
  min-height: 50px;
  box-sizing: border-box;
  padding: 10px 0;
}

.Optional_optional__188_D.Optional_borderTop__12IsV {
  border-top: 1px solid #eae8e4;
}

.Optional_optional__188_D.Optional_borderBottom__vNlJO {
  border-bottom: 1px solid #eae8e4;
}

.Optional_optionalLabel__zVxS6 {
  color: #807e7c;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
}

.Optional_optionalLabelTitle__IRafw {
  width: 100%;
  min-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.Optional_optionalLabelTitle__IRafw > .Optional_icon__PoUN6 {
  margin-left: auto;
  padding-left: 10px;
}

.Optional_optionalLabelTitle__IRafw svg {
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  margin: 0 0 0 10px;
}

.Optional_optionalLabelTitle__IRafw .Optional_rotate__30QTr svg {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.CheckLabel_checkLabel__1di0s {
  -webkit-user-select: none;
          user-select: none;
  min-height: 50px;
  color: #807e7c;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  cursor: pointer;
}

.CheckLabel_checkLabel__1di0s > *:not(:last-child) {
  margin-left: 10px;
}
.CheckLabel_checkLabel__1di0s > *:last-child {
  margin-left: auto;
}

.CheckLabel_checkLabel__1di0s.CheckLabel_borderTop__1VDoU {
  border-top: 1px solid #eae8e4;
}

.CheckLabel_checkLabel__1di0s.CheckLabel_borderBottom__2Ssf9 {
  border-bottom: 1px solid #eae8e4;
}

.CheckLabel_checkLabel__1di0s:disabled,
.CheckLabel_checkLabel__1di0s.CheckLabel_disabled__2ayKq {
  cursor: default;
  opacity: 0.7;
  pointer-events: none;
}

.Hint_hint__2IUQZ {
  /* ... */
}

.Hint_content__25io4 {
  max-width: 220px;
  font-size: 13px;
  line-height: 16px;

  text-align: center;
}

.RadioLabel_checkLabel__3vH2- {
  -webkit-user-select: none;
          user-select: none;
  min-height: 50px;
  color: #807e7c;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
  cursor: pointer;
}

.RadioLabel_checkLabel__3vH2-.RadioLabel_borderTop__rPFkG {
  border-top: 1px solid #eae8e4;
}

.RadioLabel_checkLabel__3vH2-.RadioLabel_borderBottom__1CANu {
  border-bottom: 1px solid #eae8e4;
}

.Select_select__vdNLz {
  -webkit-user-select: none;
          user-select: none;
  min-height: 50px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 15px 20px 15px;
}

.Select_select__vdNLz .Select_selectLabel__1O4rg {
  color: #807e7c;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  min-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.Select_select__vdNLz .Select_selectHint__D-jJM {
  margin: 8px 0 0 0;
  color: #807e7c;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.Select_select__vdNLz .Select_selectHint__D-jJM svg {
  margin: 0 8px 0 0;
}

.Select_select__vdNLz .Select_selectHint__D-jJM svg * {
  fill: #dbdad8;
}

.Select_select__vdNLz .Select_selectError__15Mz2 {
  margin: 8px 0 0 0;
  color: #f05252;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.Select_select__vdNLz .Select_selectError__15Mz2 svg {
  margin: 0 8px 0 0;
}

.Select_select__vdNLz.Select_borderTop__M1APX {
  border-top: 1px solid #eae8e4;
}

.Select_select__vdNLz.Select_borderBottom__jZdEX {
  border-bottom: 1px solid #eae8e4;
}

.EmptyHint_borderTop__3vyjX {
  border-top: 1px solid #EAE8E4;
}

.EmptyHint_borderBottom__yk-bR {
  border-bottom: 1px solid #EAE8E4;
}

.EmptyHint_emptyHint__1cm8A {
  border-radius: 2px;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  padding: 20px 15px;
  width: 100%;
  box-sizing: border-box;
}

.EmptyHint_emptyHint__1cm8A.EmptyHint_borderTop__1jBoK {
  border-top: 1px solid #EAE8E4;
}

.EmptyHint_emptyHint__1cm8A.EmptyHint_borderBottom__1eMQX {
  border-bottom: 1px solid #EAE8E4;
}

.EmptyHint_icon__1J88s {
  margin: 0 0 10px 0;
}
.Slider_slider__VGGsZ {
  -webkit-user-select: none;
          user-select: none;
  min-height: 50px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 15px 20px 15px;
}

.Slider_slider__VGGsZ .Slider_sliderLabel__2JkiD {
  color: #807E7C;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  min-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.Slider_slider__VGGsZ .Slider_sliderHint__2XG3a {
  margin: 8px 0 0 0;
  color: #807E7C;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.Slider_slider__VGGsZ .Slider_sliderHint__2XG3a svg {
  margin: 0 8px 0 0;
}

.Slider_slider__VGGsZ .Slider_sliderHint__2XG3a svg * {
  fill: #DBDAD8;
}

.Slider_slider__VGGsZ.Slider_borderTop__kLJKT {
  border-top: 1px solid #EAE8E4;
}

.Slider_slider__VGGsZ.Slider_borderBottom__142MN {
  border-bottom: 1px solid #EAE8E4;
}

.Input_input__31uI0 {
  -webkit-user-select: none;
          user-select: none;
  min-height: 50px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 15px 20px 15px;
}

.Input_input__31uI0 .Input_selectLabel__j5ey2 {
  color: #807E7C;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  min-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.Input_input__31uI0 .Input_selectHint__241Mk {
  margin: 8px 0 0 0;
  color: #807E7C;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.Input_input__31uI0 .Input_selectHint__241Mk svg {
  margin: 0 8px 0 0;
}

.Input_input__31uI0 .Input_selectHint__241Mk svg * {
  fill: #DBDAD8;
}

.Input_input__31uI0.Input_borderTop__3QjlZ {
  border-top: 1px solid #EAE8E4;
}

.Input_input__31uI0.Input_borderBottom__wfwC7 {
  border-bottom: 1px solid #EAE8E4;
}

/* tabs */
.PageTabs_tabs__1M6lO {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.PageTabs_tabs__1M6lO .PageTabs_tabsItem__3p9NM {
  flex-grow: 1;
  background-color: #f6f5f4;
  height: 46px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eae8e4;

  color: #b4b2af;
}
.PageTabs_tabs__1M6lO .PageTabs_tabsItem__3p9NM > *:not(:last-child) {
  margin-right: 6px;
}

.PageTabs_tabs__1M6lO .PageTabs_tabsItem__3p9NM.PageTabs_withClick__2diz7 {
  cursor: pointer;
}

.PageTabs_tabs__1M6lO .PageTabs_tabsItem__3p9NM svg {
  position: relative;
  top: 2px;
}

.PageTabs_tabs__1M6lO .PageTabs_tabsItem__3p9NM:not(:last-child) {
  border-right: 1px solid #eae8e4;
}

.PageTabs_tabs__1M6lO .PageTabs_tabsItem__3p9NM.PageTabs_active__tCWUu {
  background: #fbfaf9;
  border-bottom: 1px solid #fbfaf9;
}

.PageTabs_tabs__1M6lO .PageTabs_tabsItem__3p9NM.PageTabs_active__tCWUu {
  color: #454442;
}

.LongPress_longPress__1DcgB {
  position: relative;
  display: flex;
  align-items: center;
}

.LongPress_icon__uSdxg {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 13px;
  height: 13px;
  margin-right: 8px;

  background: transparent;
}

.LongPress_deleteHint__AOmAi {
  z-index: 100;
  color: #eae8e4;
  font-size: 12px;
  line-height: 14px;
  background-color: #1b1b1a;
  border-radius: 4px;
  position: absolute;
  text-align: center;
  box-sizing: border-box;
  width: 100px;
  left: -110px;
  padding: 5px 8px;
}

.LongPress_deleteHint__AOmAi:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #1b1b1a;
  margin-top: -4px;
}

.Mentions_mentions__I45VK {
  display: flex;
}
.Mentions_content__2-25u {
  width: calc(100% - 25px);
}
.Mentions_referToIcon__1m7w8 {
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;

  /* Button reset */
  height: 16px;
  width: 16px;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  background: none;

  margin-top: 3px;
  margin-left: auto;
  margin-right: -4px;

  transition: opacity ease-in-out 0.2s, -webkit-transform ease-in-out 0.1s;

  transition: opacity ease-in-out 0.2s, transform ease-in-out 0.1s;

  transition: opacity ease-in-out 0.2s, transform ease-in-out 0.1s, -webkit-transform ease-in-out 0.1s;
}
.Mentions_referToIcon__1m7w8:active {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}

.Mentions_disabled__27QJ4 .Mentions_referToIcon__1m7w8 {
  opacity: 0;
}

.Mentions_editable__2L8rO {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dddbd7;
  box-sizing: border-box;
  padding: 2px 10px;
  border-radius: 4px;
  color: #807e7c;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  transition: border-color 0.1s ease-in-out, box-shadow 0.3s ease-in-out;
  resize: none;
}

.Mentions_editable__2L8rO:hover {
  border-color: #b4b2af;
}

.Mentions_editable__2L8rO.Mentions_focused__3qOmV {
  border-color: #b4b2af;
  color: #454442;
  box-shadow: 0px 0px 0px 2px #eae8e4;
}

.Mentions_editable__2L8rO.Mentions_disabled__27QJ4 {
  cursor: not-allowed;
  pointer-events: none;
  background-image: linear-gradient(rgba(47, 46, 45, 0.04), rgba(47, 46, 45, 0.04));
}

.Mentions_editable__2L8rO.Mentions_withBar__xWB34 {
  border-radius: 0 0 4px 4px;
}

.Mentions_editable__2L8rO p {
  margin: 5px 0;
}

.Mentions_editable__2L8rO p:first-child {
  margin-top: 0;
}

.Mentions_editable__2L8rO p:last-child {
  margin-bottom: 0;
}

.Mentions_editable__2L8rO ul,
.Mentions_editable__2L8rO ol {
  margin: 0;
  padding: 10px 0 10px 20px;
}

.Mentions_inline__2d8x7 {
  box-sizing: content-box;

  width: 100%;
  padding: 5px 0;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(209, 214, 220, 0);

  transition: padding 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.Mentions_inline__2d8x7.Mentions_focused__3qOmV {
  padding: 5px 10px;
  border: 1px solid #dddbd7;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(209, 214, 220, 0.2);
}

.Mentions_dropdown__3oMuE {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: 8;

  box-shadow: 0 4px 4px 0 rgba(48, 47, 46, 0.07), 0 8px 8px 0 rgba(48, 47, 46, 0.07), 0 16px 16px 0 rgba(48, 47, 46, 0.07);
}

.Mentions_mentionElement__3ylqi {
  -webkit-user-select: none;
          user-select: none;
  box-sizing: border-box;

  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  margin: 0 1px;

  vertical-align: baseline;
  font-size: 13px;
  line-height: 18px;

  color: #5d5d5c;
  background-color: #fbfaf9;

  border-radius: 3px;

  border: 1px solid #e7e7e7;
}
.Mentions_mentionElement__3ylqi.Mentions_error__3ejTh {
  color: #f05252;
  font-weight: 500;
}

.Mentions_mentionElement__3ylqi .Mentions_image__2cgfi {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 5px;
  background-color: #f6f5f4;

  border-right: 1px solid #e7e7e7;
}

.Mentions_mentionElement__3ylqi .Mentions_label__2GXMY {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding: 0 5px;
  max-width: 240px;
}
.Mentions_mentionElement__3ylqi .Mentions_label__2GXMY strong {
  margin-right: 0.5ch;
}

.Mentions_button__1IHrN {
  cursor: pointer;
  opacity: 0.5;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.Mentions_button__1IHrN.Mentions_active__1h27G {
  opacity: 1;
}

.Mentions_button__1IHrN.Mentions_formatBold__CnMrm {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNOSAxMC4zNDRxLjQzOCAwIC43Mi0uMjk3dC4yOC0uNzAzLS4yOC0uNzAzVDkgOC4zNDVINi42NTZ2Mkg5em0tMi4zNDQtNnYyaDJxLjQwNiAwIC43MDMtLjI5N3QuMjk2LS43MDMtLjI5Ny0uNzAzLS43MDQtLjI5NmgtMnptMy43NSAyLjg0NHExLjQzOC42NTYgMS40MzggMi4yOCAwIDEuMDY0LS43MDMgMS43OThUOS4zNzYgMTJoLTQuNzJWMi42NTZoNC4xOXExLjEyNCAwIDEuODkuNzh0Ljc2NiAxLjkwNy0xLjA5MyAxLjg0NHoiLz48L3N2Zz4=);
}

.Mentions_button__1IHrN.Mentions_formatItalic__29zbM {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNi42NTYgMi42NTZIMTJ2MmgtMS44NzVMNy44NzUgMTBoMS40N3YySDR2LTJoMS44NzVsMi4yNS01LjM0NGgtMS40N3YtMnoiLz48L3N2Zz4=);
}

.Mentions_button__1IHrN.Mentions_formatUnderlined__2FTvT {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNMy4zNDQgMTIuNjU2aDkuMzEzVjE0SDMuMzQ0di0xLjM0NHpNOCAxMS4zNDRxLTEuNjU2IDAtMi44MjgtMS4xNzJUNCA3LjM0NFYyaDEuNjU2djUuMzQ0cTAgLjk3LjY4OCAxLjY0VDggOS42NTh0MS42NTYtLjY3Mi42ODgtMS42NFYySDEydjUuMzQ0UTEyIDkgMTAuODI4IDEwLjE3MlQ4IDExLjM0NHoiLz48L3N2Zz4=);
}

.Mentions_button__1IHrN.Mentions_numberedList__2MquF {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNC42NTYgOC42NTZWNy4zNDNIMTR2MS4zMTNINC42NTZ6bTAgNHYtMS4zMTNIMTR2MS4zMTNINC42NTZ6bTAtOS4zMTJIMTR2MS4zMTNINC42NTZWMy4zNDR6bS0zLjMxMiA0di0uNjg4aDJ2LjYyNWwtMS4yMiAxLjM3NmgxLjIydi42ODhoLTJWOC43MmwxLjE4OC0xLjM3NkgxLjM0NHptLjY1Ni0ydi0yaC0uNjU2di0uNjg4aDEuMzEzdjIuNjg4SDJ6bS0uNjU2IDZ2LS42ODhoMnYyLjY4OGgtMnYtLjY4OGgxLjMxM3YtLjMxM0gydi0uNjg4aC42NTd2LS4zMTNIMS4zNDR6Ii8+PC9zdmc+);
}

.Mentions_button__1IHrN.Mentions_bulletedList__16roV {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNC42NTYgMy4zNDRIMTR2MS4zMTNINC42NTZWMy4zNDR6bTAgNS4zMTJWNy4zNDNIMTR2MS4zMTNINC42NTZ6bTAgNHYtMS4zMTNIMTR2MS4zMTNINC42NTZ6bS0yLTEuNTNxLjM3NSAwIC42NC4yNXQuMjY3LjYyNC0uMjY2LjYyNS0uNjQuMjUtLjYyNi0uMjVUMS43OCAxMnQuMjUtLjYyNS42MjYtLjI1em0wLTguMTI2cS40MDYgMCAuNzAzLjI4dC4yOTYuNzItLjI5Ny43Mi0uNzA0LjI4LS43MDMtLjI4VDEuNjU2IDR0LjI5Ny0uNzIuNzAzLS4yOHptMCA0cS40MDYgMCAuNzAzLjI4dC4yOTYuNzItLjI5Ny43Mi0uNzA0LjI4LS43MDMtLjI4VDEuNjU2IDh0LjI5Ny0uNzIuNzAzLS4yOHoiLz48L3N2Zz4=);
}

.Mentions_button__1IHrN.Mentions_link__2ITmA {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDIyIDI0Ij48cGF0aCBkPSJNMTkuNSAxNi4yODZxMC0uNTM2LS4zNzUtLjkxbC0yLjc4Ni0yLjc4N3EtLjM3Ni0uMzc2LS45MTItLjM3Ni0uNTYzIDAtLjk2NC40M2wuMjU0LjI0N3EuMjE0LjIwOC4yODguMjl0LjIuMjUzLjE3NS4zNDIuMDQ4LjM2OHEwIC41MzYtLjM3NS45MXQtLjkxLjM3NnEtLjIwMiAwLS4zNy0uMDQ4dC0uMzQtLjE3NC0uMjU1LS4yLS4yODgtLjI5LS4yNDgtLjI1M3EtLjQ0Mi40MTUtLjQ0Mi45NzggMCAuNTM2LjM3NS45MWwyLjc2IDIuNzczcS4zNi4zNjIuOTEuMzYyLjUzNiAwIC45MS0uMzQ4bDEuOTctMS45NTVxLjM3NS0uMzc1LjM3NS0uODk3em0tOS40MTUtOS40NDJxMC0uNTM2LS4zNzUtLjkxTDYuOTUgMy4xNnEtLjM3NC0uMzc0LS45MS0uMzc0LS41MjIgMC0uOTEuMzYyTDMuMTYgNS4xMDNxLS4zNzUuMzc1LS4zNzUuODk3IDAgLjUzNi4zNzUuOTFsMi43ODYgMi43ODdxLjM2Mi4zNjIuOTEuMzYyLjU2NCAwIC45NjUtLjQxNmwtLjI1My0uMjQ4cS0uMjEzLS4yMDgtLjI4OC0uMjg4dC0uMjAyLS4yNTQtLjE3NC0uMzQyLS4wNDctLjM2OHEwLS41MzYuMzc1LS45MXQuOTEtLjM3NnEuMjAyIDAgLjM3LjA0N3QuMzQuMTc0LjI1NS4yLjI4OC4yODguMjQ4LjI1NHEuNDQyLS40MTUuNDQyLS45Nzh6bTExLjk4NiA5LjQ0MnEwIDEuNjA3LTEuMTM3IDIuNzJsLTEuOTcgMS45NTRxLTEuMTEgMS4xMTItMi43MTggMS4xMTItMS42MiAwLTIuNzMyLTEuMTM4bC0yLjc2LTIuNzcycS0xLjExLTEuMTEyLTEuMTEtMi43MiAwLTEuNjQ2IDEuMTc4LTIuNzk4bC0xLjE3OC0xLjE4cS0xLjE1MiAxLjE4LTIuNzg2IDEuMTgtMS42MDcgMC0yLjczMi0xLjEyNUwxLjMzOCA4LjczMlEuMjEzIDcuNjA4LjIxMyA2VDEuMzUgMy4yODNsMS45Ny0xLjk1NVE0LjQzMi4yMTUgNi4wNC4yMTVxMS42MiAwIDIuNzMgMS4xMzhsMi43NiAyLjc3MnExLjExMiAxLjExMiAxLjExMiAyLjcyIDAgMS42NDYtMS4xOCAyLjc5OGwxLjE4IDEuMThxMS4xNTItMS4xOCAyLjc4Ni0xLjE4IDEuNjA3IDAgMi43MzIgMS4xMjVsMi43ODYgMi43ODZxMS4xMjUgMS4xMjUgMS4xMjUgMi43MzJ6Ii8+PC9zdmc+);
  background-size: 14px;
}

.Mentions_staticMenu__v2LLp {
  background-color: #fafafa;
  border-top: 1px solid #bfbfbf;
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  border-radius: 4px 4px 0 0;
  padding: 4px 4px 3px 4px;
  box-sizing: border-box;
  margin: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.Mentions_staticMenu__v2LLp > * {
  display: inline-block;
}

.Mentions_staticMenu__v2LLp > * + * {
  margin-left: 5px;
}

.Mentions_tooltipMenu__pRy7z {
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  color: #fff;
  border-radius: 4px;
  transition: opacity 0.75s;
  z-index: 1024;
  background-color: #fafafa;
  border: 1px solid #bfbfbf;
  padding: 3px 4px 0 4px;
}

.Mentions_toolbar__2Ut2e {
  position: relative;
  padding: 1px 18px 17px;
  margin: 0 -20px;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
}

.Mentions_silentCrashHandler__3bzxX {
  box-sizing: border-box;
  -webkit-user-select: none;
          user-select: none;

  padding: 10px;

  font-size: 11px;
  font-weight: 500;

  min-width: 0px;

  color: #f05252;
  background-color: #f6f5f4;

  border-radius: 4px;
  border: 1px solid #dddbd7;
}

/* -------------------------------- CONTAINER ------------------------------- */

.SearchBox_searchBox__3cWpX {
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  color: #454442;
  background-color: #ffffff;
}

.SearchBox_searchBox__3cWpX.SearchBox_theme-inline__1K0Sf {
  width: 280px;

  border: 1px solid #b4b2af;
  border-radius: 4px;
}
.SearchBox_searchBox__3cWpX.SearchBox_theme-inline__1K0Sf > *:not(:last-child) {
  border-bottom: 1px solid #b4b2af;
}

.SearchBox_searchBox__3cWpX.SearchBox_theme-block__3MjUr {
  width: 100%;
}
.SearchBox_searchBox__3cWpX.SearchBox_theme-block__3MjUr > *:not(:last-child) {
  border-bottom: 1px solid #b4b2af;
}

/* ---------------------------------- INPUT --------------------------------- */

.SearchBox_searchBar__26CM_ {
  box-sizing: border-box;

  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  align-items: center;

  padding: 12px 16px;

  height: 45px;
}

.SearchBox_searchBar__26CM_ > .SearchBox_icon__1J7KH {
  flex-shrink: 0;
  flex-grow: 0;

  margin-right: 8px;
}
.SearchBox_searchBar__26CM_ > input {
  flex-shrink: 1;
  flex-grow: 1;

  padding: 0;
  margin: 0;
  border: none;
  outline: none;

  font-weight: 400;
  color: #454442;
}
.SearchBox_searchBar__26CM_ > input::-webkit-input-placeholder {
  color: #807e7c;
}
.SearchBox_searchBar__26CM_ > input::placeholder {
  color: #807e7c;
}

/* --------------------------------- CONTENT -------------------------------- */

.SearchBox_searchResults__2lSvf {
  flex-shrink: 1;
  flex-grow: 1;

  overflow-y: auto;

  display: flex;
  flex-direction: column;

  min-height: 0;
  max-height: 180px;
}

.SearchBox_searchResults__2lSvf > .SearchBox_placeholder__2qEgX {
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px 12px;

  word-break: break-all;
  text-align: center;

  color: #807e7c;
  font-weight: 500;

  background-color: #fbfaf9;

  min-height: 60px;
}

/* -------------------------------------------------------------------------- */
/*                             NAVIGATOR CONTAINER                            */
/* -------------------------------------------------------------------------- */

.NavigatorEntry_navigatorEntry__1derJ.NavigatorEntry_borderTop___7y8A {
  border-top: 1px solid #eae8e4;
}

.NavigatorEntry_navigatorEntry__1derJ.NavigatorEntry_borderBottom__zDpYL {
  border-bottom: 1px solid #eae8e4;
}

/* -------------------------------------------------------------------------- */
/*                                   CONTENT                                  */
/* -------------------------------------------------------------------------- */

/* -------------------------------- CONTAINER ------------------------------- */

.NavigatorEntry_content__3CfSy {
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;

  -webkit-user-select: none;

          user-select: none;
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-size: 13px;
  line-height: 16px;

  height: 36px;

  color: #302f2e;
  background-color: #fff;
}
.NavigatorEntry_content__3CfSy > * {
  flex-shrink: 0;
  flex-grow: 0;
}
.NavigatorEntry_content__3CfSy > *:not(:last-child) {
  margin-right: 10px;
}

/* ------------------------------ MAIN CONTENT ------------------------------ */

.NavigatorEntry_icon__hMbeb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 14px;
}

.NavigatorEntry_label__3rOnw {
  overflow: hidden;
  white-space: nowrap;

  flex-shrink: 1;
  flex-grow: 1;
}

.NavigatorEntry_labelIndex__2RFHA {
  font-weight: 500;
  margin-right: 0.75ch;
}

/* -------------------------------- SUFFIXES -------------------------------- */

.NavigatorEntry_inlineSuffix__agoUP {
  padding-right: 10px;
}

.NavigatorEntry_floatingSuffix__2rA7o {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  color: hsla(0, 0%, 0%, 0.45);
  background-color: #fff;
}
.NavigatorEntry_floatingSuffix__2rA7o > *:not(:last-child) {
  margin-right: 10px;
}

.NavigatorEntry_floatingSuffix__2rA7o::before {
  content: '';
  display: block;
  position: absolute;

  top: 0;
  height: 100%;

  left: -50px;
  width: 50px;

  /* NOTE: safari seems to not support 'transparent' keyword */
  background: linear-gradient(to right, hsla(0, 0%, 100%, 0), hsl(0, 0%, 100%));
}

/* -------------------------------------------------------------------------- */
/*                            CONTENT INTERACTIONS                            */
/* -------------------------------------------------------------------------- */

/*
  Additional class thats adds hover states and other custom states support to content.
*/
.NavigatorEntry_reactiveContent__13gzl {
  background-color: #fff;
  border: 1px dashed #fff;

  transition: opacity 0.1s ease-in-out;
}

.NavigatorEntry_dimmedBackground__3sqSd > .NavigatorEntry_reactiveContent__13gzl {
  background-color: #fbfaf9;
  border-color: #fbfaf9;
}

.NavigatorEntry_navigatorEntry__1derJ[data-sortful-role='stacked'] > .NavigatorEntry_reactiveContent__13gzl {
  border-color: #5d5d5c;
  opacity: 0.5;
}

.NavigatorEntry_dimmedBackground__3sqSd > .NavigatorEntry_reactiveContent__13gzl .NavigatorEntry_floatingSuffix__2rA7o {
  background-color: #fbfaf9;
}
.NavigatorEntry_dimmedBackground__3sqSd > .NavigatorEntry_reactiveContent__13gzl .NavigatorEntry_floatingSuffix__2rA7o::before {
  /* NOTE: safari seems to not support 'transparent' keyword */
  background: linear-gradient(to right, hsla(30, 20%, 98%, 0), hsl(30, 20%, 98%));
}

.NavigatorEntry_reactiveContent__13gzl:hover {
  background-color: #f6f5f4;
  border-color: #f6f5f4;
}
.NavigatorEntry_reactiveContent__13gzl:hover .NavigatorEntry_floatingSuffix__2rA7o {
  background-color: #f6f5f4;
}
.NavigatorEntry_reactiveContent__13gzl:hover .NavigatorEntry_floatingSuffix__2rA7o::before {
  background: linear-gradient(to right, hsla(30, 10%, 96%, 0), hsl(30, 10%, 96%));
}

.NavigatorEntry_reactiveContent__13gzl.NavigatorEntry_invalid__3ZWqD {
  background-color: #ffdbdb;
  border-color: #ffdbdb;
}
.NavigatorEntry_reactiveContent__13gzl.NavigatorEntry_invalid__3ZWqD .NavigatorEntry_floatingSuffix__2rA7o {
  background-color: #ffdbdb;
  border-color: #ffdbdb;
}
.NavigatorEntry_reactiveContent__13gzl.NavigatorEntry_invalid__3ZWqD .NavigatorEntry_floatingSuffix__2rA7o::before {
  /* NOTE: safari seems to not support 'transparent' keyword */
  background: linear-gradient(to right, hsla(0, 100%, 93%, 0), hsl(0, 100%, 93%));
}

.NavigatorEntry_reactiveContent__13gzl.NavigatorEntry_active__3Ysfv {
  background-color: #eae8e4;
  border-color: #eae8e4;
}
.NavigatorEntry_reactiveContent__13gzl.NavigatorEntry_active__3Ysfv .NavigatorEntry_floatingSuffix__2rA7o {
  background-color: #eae8e4;
}
.NavigatorEntry_reactiveContent__13gzl.NavigatorEntry_active__3Ysfv .NavigatorEntry_floatingSuffix__2rA7o::before {
  /* NOTE: safari seems to not support 'transparent' keyword */
  background: linear-gradient(to right, hsla(40, 13%, 91%, 0), hsl(40, 12%, 91%));
}

.DropdownIcon_dropdownIcon__18lj3 {
  cursor: pointer;

  width: 12px;
  height: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.DropdownIcon_dropdownIcon__18lj3 > * {
  transition: -webkit-transform 0s ease-in-out;
  transition: transform 0s ease-in-out;
  transition: transform 0s ease-in-out, -webkit-transform 0s ease-in-out;
}
.DropdownIcon_dropdownIcon__18lj3.DropdownIcon_open__1TxI7 > * {
  -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg);
}

/* ----------------------------- DEFAULT STYLES ----------------------------- */

/* Tabs container */
.Tabs_container__DmChm {
  -webkit-user-select: none;
          user-select: none;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;

  position: relative;

  box-sizing: border-box;

  min-height: 28px;
}

/* Tabs item */
.Tabs_item__2C7fL {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  color: #807e7c;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  cursor: pointer;

  padding: 0;
  margin: 0;
  border: none;
  background: none;
}
.Tabs_theme-underline__1fp5m .Tabs_item__2C7fL:disabled {
  cursor: default;
}
.Tabs_item__2C7fL:last-child {
  margin-right: 0;
}
.Tabs_item__2C7fL.Tabs_active__3YfUp {
  cursor: default;
}

.Tabs_itemInner__NFcB0 {
  display: flex;
  justify-content: center;
}
.Tabs_itemInner__NFcB0 > *[data-component='icon'] {
  margin-right: 5px;
}

.Tabs_separator__22Ug8,
.Tabs_indicator__1seYT {
  display: none;
}

/* ----------------------------- CONTAINED THEME ---------------------------- */

.Tabs_theme-contained__R2n28 .Tabs_item__2C7fL {
  justify-content: center;

  z-index: 2;
  padding: 6px 12px;
  border-radius: 7px;
  background-color: transparent;

  transition: background-color 0.05s ease-in-out;
}
.Tabs_theme-contained__R2n28 > *:not(:last-child) {
  margin-right: 6px;
}
.Tabs_theme-contained__R2n28 .Tabs_item__2C7fL:hover,
.Tabs_theme-contained__R2n28 .Tabs_item__2C7fL:focus {
  background-color: #f7f5f0;
}
.Tabs_theme-contained__R2n28 .Tabs_item__2C7fL.Tabs_active__3YfUp {
  background-color: #eae8e4;
  color: #454442;
}

/*
.theme-contained-moving .item {
  z-index: 2;
  padding: 6px 12px;
  border-radius: 7px;
  background-color: transparent;
}
.theme-contained-moving .indicator {
  z-index: 1;

  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 7px;

  width: 0px;
  background-color: #eae8e4;
  transform-origin: top left;
  transform: translateX(0px) scaleX(1);
}
*/

/* ----------------------------- UNDERLINE THEME ---------------------------- */

.Tabs_theme-underline__1fp5m.Tabs_container__DmChm {
  align-items: stretch;
}

.Tabs_theme-underline__1fp5m > *:not(:last-child) {
  margin-right: 30px;
}
.Tabs_theme-underline__1fp5m .Tabs_item__2C7fL {
  padding-bottom: 10px;
}
.Tabs_theme-underline__1fp5m .Tabs_item__2C7fL:hover,
.Tabs_theme-underline__1fp5m .Tabs_item__2C7fL:focus {
  color: #b4b2af;
}
.Tabs_theme-underline__1fp5m .Tabs_item__2C7fL.Tabs_active__3YfUp {
  color: #454442;
}
.Tabs_theme-underline__1fp5m .Tabs_item__2C7fL:disabled {
  color: #807e7c;
  opacity: 0.5;
}

/* Lines under tabs */
.Tabs_theme-underline__1fp5m .Tabs_separator__22Ug8,
.Tabs_theme-underline__1fp5m .Tabs_indicator__1seYT {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  height: 2px;
}

/* Line under tabs */
.Tabs_theme-underline__1fp5m .Tabs_separator__22Ug8 {
  background-color: #eae8e4;
  width: 100%;
}

/* Selection indicator under tabs */
.Tabs_theme-underline__1fp5m .Tabs_indicator__1seYT {
  width: 0px;
  background-color: #454442;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: translateX(0px) scaleX(1);
          transform: translateX(0px) scaleX(1); /* Dynamic value set from JS */
}

/* Disabled mode */
.Tabs_disabled__1t_FZ .Tabs_indicator__1seYT {
  display: none;
}

.TabsHeader_messaging__jAPt2 {
  display: flex;
  align-items: center;

  color: #454442;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin: 0;
}
.TabsHeader_messaging__jAPt2 > *:not(:last-child) {
  margin-right: 10px;
}

.SearchInput_container__3gSe- {
  flex-shrink: 0;

  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  box-sizing: border-box;
  height: 28px;
  width: 65px;
  padding: 0;

  background-color: hsla(0, 0%, 100%, 0%);
  border: 1px solid hsla(0, 0%, 100%, 0%);
  box-shadow: 0 1px 1px 0 rgba(48, 47, 46, 0);
  border-radius: 4px;

  will-change: width, padding, border-color, background-color;
  transition: padding 0.2s ease-in-out, 0.2s ease-in-out, background-color 0.2s ease-in-out, width 0.3s ease-in-out;
}
.SearchInput_container__3gSe-:focus-within,
.SearchInput_container__3gSe-.SearchInput_filled__3_ndh {
  width: 150px;
  padding: 0 6px;
  background-color: hsla(0, 0%, 100%, 100%);
  border-color: #dddbd7;
  box-shadow: 0 1px 1px 0 rgba(48, 47, 46, 0.07);
}

.SearchInput_field__bgMRl {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  width: 100%;
}
.SearchInput_container__3gSe- .SearchInput_icon__15b0x {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  margin-right: 2px;
}

/* Text color managment */
.SearchInput_field__bgMRl::-webkit-input-placeholder {
  -webkit-transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.SearchInput_field__bgMRl::placeholder {
  transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.SearchInput_field__bgMRl::-webkit-input-placeholder {
  color: #5d5d5c;
}
.SearchInput_field__bgMRl,
.SearchInput_field__bgMRl::placeholder {
  color: #5d5d5c;
}
.SearchInput_container__3gSe-:focus-within .SearchInput_field__bgMRl,
.SearchInput_container__3gSe-.SearchInput_filled__3_ndh .SearchInput_field__bgMRl {
  color: #807e7c;
}
.SearchInput_container__3gSe-:focus-within .SearchInput_field__bgMRl::-webkit-input-placeholder, .SearchInput_container__3gSe-.SearchInput_filled__3_ndh .SearchInput_field__bgMRl::-webkit-input-placeholder {
  color: #807e7c;
  opacity: 0.6;
}
.SearchInput_container__3gSe-:focus-within .SearchInput_field__bgMRl::placeholder,
.SearchInput_container__3gSe-.SearchInput_filled__3_ndh .SearchInput_field__bgMRl::placeholder {
  color: #807e7c;
  opacity: 0.6;
}

/* Positioning in fixed parent */
.ScrollableContent_scrollableAbsoluteWrapper__28xUw {
  box-sizing: border-box;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  overflow-x: auto;
}

.ScrollableContent_scrollableWrapper__QIoZI {
  display: flex;

  /* content is centered */
  justify-content: flex-start;
}

.ScrollableContent_scrollable__2qaXl {
  flex-shrink: 1;

  /* Content centering, size and padding */
  margin: auto;
  padding: 50px;
  width: 900px;

  /* Scroll on small size (always usable) */
  min-width: 550px;
  /* Possible alternative - allow full shrink (better but requires gui fixes to look nice) */
  /* min-width: 0; */
}
/* Main content and place where children are added */
.ScrollableContent_scrollable__2qaXl > *:not(:last-child),
.ScrollableContent_children__3H-LX > *:not(:last-child) {
  margin-bottom: 20px;
}

.ScrollableContent_children__3H-LX.ScrollableContent_disabled__p3Z6r {
  opacity: 0.4;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.ScrollableContent_header__21CNn {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 20px;
}
.ScrollableContent_header__21CNn > *:first-child {
  flex-grow: 1;
}
.ScrollableContent_header__21CNn > *:not(:last-child) {
  margin-right: 10px;
}

.Card_card__cYrk1 {
  position: relative;
  box-sizing: border-box;

  background-color: #ffffff;

  border: 1px solid #dddbd7;
  border-radius: 4px;

  width: 100%;
}

/* Overlay for graying out and loader */
.Card_cardOverlay__IU3Jk {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 16;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.7);
}
.Card_card__cYrk1,
.Card_cardOverlay__IU3Jk {
  border-radius: 4px;
}

.SwitchableFeature_feature__2LpPm {
  box-sizing: border-box;
  height: 100%;
  display: flex;

  padding: 20px;
}
.SwitchableFeature_content__3KdcY {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.SwitchableFeature_feature__2LpPm > *:not(:last-child) {
  margin-right: 10px;
}
.SwitchableFeature_content__3KdcY > *:not(:last-child) {
  margin-bottom: 10px;
}

/* Switch and buttons */
.SwitchableFeature_header__2yuxG {
  display: flex;
  align-items: center;
  height: 20px;
}
.SwitchableFeature_header__2yuxG > *:not(:last-child) {
  margin-right: 10px;
}

/* Logo */
.SwitchableFeature_logo__3yHpG {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.SwitchableFeature_logo__3yHpG img {
  width: 32px;
}

/* Footer */
.SwitchableFeature_tagIcon__CUYtB {
  display: inline-block;
  margin-right: 7px;
}

/* Text */
.SwitchableFeature_title__mzEyC {
  color: #454442;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}
.SwitchableFeature_description__3oquf {
  color: #807e7c;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  flex-grow: 1;
}
.SwitchableFeature_tags__3GAcP {
  display: flex;
}
.SwitchableFeature_tags__3GAcP > *:not(:last-child) {
  margin-right: 10px;
}
.SwitchableFeature_tag__1LBBf {
  color: #b4b2af;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
}
.SwitchableFeature_tag__1LBBf:hover,
.SwitchableFeature_tag__1LBBf:focus {
  opacity: 0.8;
}
.SwitchableFeature_tag__1LBBf:active {
  opacity: 0.6;
}

.SwitchableFeature_links__39vBT {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Vr_vr__1S4Q2 {
  background-color: #dddbd7;
  width: 1px;
  height: 17px;
}

.SettingRow_setting__1fXnJ {
  min-width: 0;
}

.SettingRow_setting__1fXnJ strong {
  font-weight: 600;
}

/* Grid layout logic - left input */
.SettingRow_inputPlacement-left__MAa2g.SettingRow_setting__1fXnJ {
  display: grid;
  grid-template-columns: -webkit-min-content auto;
  grid-template-columns: min-content auto;
}
.SettingRow_inputPlacement-left__MAa2g > .SettingRow_toggle__1bfam {
  grid-column-start: 1;
  align-self: center;
}
.SettingRow_inputPlacement-left__MAa2g > .SettingRow_title__1GShz {
  grid-column-start: 2;
  align-self: center;
}
.SettingRow_inputPlacement-left__MAa2g > .SettingRow_description__19oVr {
  grid-column-start: 2;
}
.SettingRow_inputPlacement-left__MAa2g > .SettingRow_children__1u4Cz {
  grid-column-start: 2;
}

/* Input will be placed in the middle between title and description */
.SettingRow_inputMiddled__4eGqj > .SettingRow_toggle__1bfam {
  grid-row-start: 1;
  grid-row-end: 3;
}
.SettingRow_inputMiddled__4eGqj > .SettingRow_title__1GShz + .SettingRow_description__19oVr {
  margin-top: 5px;
}

/* Grid layout logic - right input */
.SettingRow_inputPlacement-right__3eERZ.SettingRow_setting__1fXnJ {
  display: grid;
  grid-template-columns: auto -webkit-min-content;
  grid-template-columns: auto min-content;
}
.SettingRow_inputPlacement-right__3eERZ > .SettingRow_toggle__1bfam {
  grid-column-start: 2;
  align-self: center;
}
.SettingRow_inputPlacement-right__3eERZ > .SettingRow_title__1GShz {
  grid-column-start: 1;
  align-self: center;
}
.SettingRow_inputPlacement-right__3eERZ > .SettingRow_description__19oVr {
  grid-column-start: 1;
}
.SettingRow_inputPlacement-right__3eERZ > .SettingRow_children__1u4Cz {
  grid-column-start: 1;
}

/* Toggle styles */
.SettingRow_inputPlacement-left__MAa2g > .SettingRow_toggle__1bfam {
  margin-right: 10px;
}
.SettingRow_inputPlacement-right__3eERZ > .SettingRow_toggle__1bfam {
  margin-left: 10px;
}

/* Title styles */
.SettingRow_title__1GShz {
  display: flex;
  align-items: center;
  min-height: 20px;
}
.SettingRow_title__1GShz > *:not(:last-child) {
  margin-right: 10px;
}
.SettingRow_title__1GShz > *[data-component='icon'] {
  height: 14px;
}

/* Setting content (shows when toggle is in true state) */
.SettingRow_children__1u4Cz {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.SettingRow_children__1u4Cz > * {
  width: 100%;
}

/* Content hiding */
.SettingRow_children__1u4Cz[data-hidden='true'] {
  height: 0;
  opacity: 0;
  transition: none;
  pointer-events: none;
}
.SettingRow_children__1u4Cz.SettingRow_animated__3fkkj {
  will-change: transform, opacity;
  transition: opacity 0.3s ease-in-out, -webkit-transform 0.5s ease-in-out;
  transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
  transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.SettingRow_children__1u4Cz.SettingRow_animated__3fkkj[data-hidden='true'] {
  transition: none;
}

/* ----------------------------- SPACING ---------------------------- */

/* Between header and children */
.SettingRow_gaps-big__2ytUh > .SettingRow_children__1u4Cz:not([data-hidden='true']),
.SettingRow_gaps-big__2ytUh > .SettingRow_children__1u4Cz + .SettingRow_description__19oVr {
  margin-top: 15px;
}
.SettingRow_gaps-small__1btNO > .SettingRow_children__1u4Cz:not([data-hidden='true']),
.SettingRow_gaps-small__1btNO > .SettingRow_children__1u4Cz + .SettingRow_description__19oVr {
  margin-top: 8px;
}

/* Between children items */
.SettingRow_gaps-big__2ytUh > .SettingRow_children__1u4Cz > *:not(:last-child) {
  margin-bottom: 15px;
}
.SettingRow_gaps-small__1btNO > .SettingRow_children__1u4Cz > *:not(:last-child) {
  margin-bottom: 8px;
}

/* Translation during animation */
.SettingRow_gaps-big__2ytUh > .SettingRow_children__1u4Cz[data-hidden='true'] {
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}
.SettingRow_gaps-small__1btNO > .SettingRow_children__1u4Cz[data-hidden='true'] {
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}

.Text_text__1uTh5 * {
  vertical-align: middle;
}

/* Special cases of other kinds of components in text */
.Text_text__1uTh5 > *[data-component='button'] {
  display: inline-flex;
  font-weight: 600;
}
.Text_text__1uTh5 > *[data-component='icon'] {
  display: inline-block;
}
.Text_text__1uTh5 > *[data-component='icon'] svg {
  height: -webkit-min-content !important;
  height: min-content !important;
}

/* Themes */
.Text_theme-text__1wRdc {
  color: #302f2e;
}
.Text_theme-header__1EXF- {
  color: #454442;
}
.Text_theme-description__19Txf {
  color: #807e7c;
}
.Text_theme-detail__5oLKK {
  color: #b4b2af;
}

.Text_theme-error__1fnNJ {
  color: #f05252;
}
.Text_theme-error__1fnNJ path,
.Text_theme-error__1fnNJ svg {
  fill: #f05252;
  stroke: #f05252;
}

/* Sizes */
.Text_size-regular__GhbIM {
  font-size: 13px;
  line-height: 16px;
}
.Text_size-regular__GhbIM > *[data-component='icon'] svg {
  width: 10px;
  height: 16px;
}
.Text_size-regular__GhbIM > *[data-component='icon'] path {
  -webkit-transform: scale(0.7) translateX(1px);
          transform: scale(0.7) translateX(1px);
}
.Text_size-medium__1DU6O {
  font-size: 15px;
  line-height: 18px;
}
.Text_size-large__1JMdA {
  font-size: 18px;
  line-height: 22px;
}

/* Weights */
.Text_weight-regular__34vsw {
  font-weight: 400;
}
.Text_weight-medium__1iosB {
  font-weight: 500;
}
.Text_weight-semi-bold__1UcQU {
  font-weight: 600;
}
.Text_weight-bold__3NJt_ {
  font-weight: 700;
}

.SettingsHeader_header__2IsM7 {
  display: flex;
  flex-direction: column;
}
.SettingsHeader_header__2IsM7 > *:not(:last-child) {
  margin-bottom: 5px;
}

.SettingsHeader_title__2uusZ {
  display: flex;
  align-items: center;

  color: #454442;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  height: 20px;
}
.SettingsHeader_title__2uusZ > *:not(:last-child) {
  margin-right: 10px;
}
.SettingsHeader_title__2uusZ > *[data-component='icon'] {
  height: 14px;
}

/* Text above select */
.SelectModal_label__1jAic {
  display: flex;
  align-items: center;

  margin-top: 18px;
  margin-bottom: 8px;
}
.SelectModal_label__1jAic > *:not(:last-child) {
  margin-right: 10px;
}

/* Text under select */
.SelectModal_description__1dhzh {
  display: flex;
  flex-direction: row;

  margin-top: 10px;
  margin-bottom: 10px;
}
.SelectModal_description__1dhzh > *:not(:last-child) {
  margin-right: 7px;
}

/* Cancel, confirm buttons */
.SelectModal_buttons__3Oig- {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.SelectModal_buttons__3Oig- > *:last-child {
  margin-left: 20px;
}

.Flex_flex__3gVw3 {
  display: flex;
  box-sizing: border-box;
  min-width: 0;
}
.Flex_growItems__3NAWT > * {
  flex-grow: 1;
}

.Flex_horizontal__2tlkg {
  flex-direction: row;
}
.Flex_gap-1__1GDqX.Flex_horizontal__2tlkg > *:not(:last-child) {
  margin-right: 10px;
}
.Flex_gap-2__2bN18.Flex_horizontal__2tlkg > *:not(:last-child) {
  margin-right: 20px;
}

.Flex_vertical__2ysIp {
  flex-direction: column;
}
.Flex_gap-1__1GDqX.Flex_vertical__2ysIp > *:not(:last-child) {
  margin-bottom: 10px;
}
.Flex_gap-2__2bN18.Flex_vertical__2ysIp > *:not(:last-child) {
  margin-bottom: 15px;
}

.Flex_flexScrollableWrapper__1N8p5 {
  flex-shrink: 1;
  min-height: 0;

  position: relative;

  display: flex;
  flex-direction: column;
}
.Flex_flexScrollable__3EygZ {
  flex-shrink: 1;

  overflow: auto;
  min-height: 40px;
  min-width: 40px;

  /* To prevent input focus states from being cut */
  margin: 0 -5px;
  padding: 0 5px;
}
.Flex_overflownY__2L0WA {
  padding-right: 15px;
}
.Flex_overflownX__1ALK8 {
  padding-bottom: 15px;
}

.Flex_scrollIndicatorTop__2U7N5,
.Flex_scrollIndicatorBottom__21EXQ {
  pointer-events: none;
  position: absolute;

  left: 0;
  right: 0;
  height: 6px;
  z-index: 1;

  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.Flex_scrollIndicatorTop__2U7N5 {
  top: -1px;
  background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.15));
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.Flex_scrollIndicatorBottom__21EXQ {
  bottom: -1px;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.15));
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.Flex_scrollIndicatorVisible__396Cx {
  opacity: 1;
}

.MacBar_macBar__3fLaf {
  height: 40px;
  font-size: 13px;
}

.MacBar_left__24g8c,
.MacBar_right__2s45i,
.MacBar_middle__3SwtC {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.MacBar_left__24g8c {
  padding-left: 20px;
}
.MacBar_right__2s45i {
  padding-right: 20px;
}

.MacBar_middle__3SwtC {
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
}

.MacBar_middle__3SwtC > *:not(:last-child),
.MacBar_left__24g8c > *:not(:last-child) {
  margin-right: 13px;
}
.MacBar_right__2s45i > *:not(:last-child) {
  margin-right: 20px;
}

/* Three color dots */
.MacBar_dots__14ke3 {
  display: flex;
}
.MacBar_dots__14ke3 > * {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-right: 13px;
}
.MacBar_dots__14ke3 > *:not(:last-child) {
  margin-right: 8px;
}

/* --------------------------- LEFT,RIGHT, CENTER --------------------------- */

/*  Main + Left */
.Aligner_align-left__2fmj1,
.Aligner_align-right__hMc1s,
.Aligner_align-center__gIR_w {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.Aligner_align-left__2fmj1 > div,
.Aligner_align-right__hMc1s > div,
.Aligner_align-center__gIR_w > div {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}
.Aligner_align-left__2fmj1 > div:nth-child(2),
.Aligner_align-right__hMc1s > div:nth-child(2),
.Aligner_align-center__gIR_w > div:nth-child(2) {
  flex-grow: 1;
  overflow: hidden;
}
.Aligner_align-left__2fmj1 > div:nth-child(2) > div,
.Aligner_align-right__hMc1s > div:nth-child(2) > div,
.Aligner_align-center__gIR_w > div:nth-child(2) > div {
  display: flex;
  align-items: stretch;
  overflow: hidden;
}

/* Right and center */
.Aligner_align-right__hMc1s > div:nth-child(2) {
  justify-content: flex-end;
}
.Aligner_align-center__gIR_w > div:nth-child(2) > div {
  margin: auto;
}

/* --------------------------- ABSOLUTE CENTERING --------------------------- */

.Aligner_align-center-absolute__2OwuD {
  display: flex;
  align-items: stretch;
}

/* Sides will always have roughly equal size - middle will be absolutely centered */
.Aligner_align-center-absolute__2OwuD > div {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;

  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}
.Aligner_align-center-absolute__2OwuD > div:nth-child(2) {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  overflow: hidden;
}
.Aligner_align-center-absolute__2OwuD > div:nth-child(3) {
  justify-content: flex-end;
}

/* Centering content of centered container */
.Aligner_align-center-absolute__2OwuD > div:nth-child(2) > div {
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
}

.UrlBar_urlBar__BsCpC {
  height: 40px;
  width: 100%;
  min-width: 0;
}

.UrlBar_urlFieldWrapper__1FbAx {
  box-sizing: border-box;

  flex-shrink: 1;
  flex-grow: 1;

  display: flex;
  align-items: center;

  width: 100%;
  overflow: hidden;

  height: 24px;
  background-color: rgba(230, 230, 230, 0.64);
  border-radius: 6px;

  padding: 4px 10px;
}
.UrlBar_urlField__3VMsD {
  overflow: hidden;
  letter-spacing: -0.3px;
  white-space: nowrap;

  border: none;
  outline: none;

  color: #807e7c;
}
.UrlBar_urlField__3VMsD strong {
  font-weight: 600;
  color: #302f2e;
}

.Badge_badgeWrapper__2VduX {
  position: relative;
}

.Badge_badge__2Z8U_ {
  box-sizing: border-box;
  position: absolute;

  top: 0;
  right: 0;
  -webkit-transform: translateX(calc(100% - 6px)) translateY(calc(-100% + 8px));
          transform: translateX(calc(100% - 6px)) translateY(calc(-100% + 8px));

  z-index: 10;
}
.Badge_textBadge__1caY9 {
  font-size: 10px;
  display: flex;
  align-items: center;
  padding: 2px 4px;

  border-radius: 2px;
}
.Badge_textBadge__1caY9 > *:not(:last-child) {
  margin-right: 4px;
}

.Badge_theme-black__1JIls {
  color: #fbfaf9;
  background: #5d5d5c;
}

.FileInput_filePath__WG4yW {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.FileInput_fileInput__2j7Bk {
  display: flex;
  align-items: stretch;
}
.FileInput_fileInput__2j7Bk > *:first-child {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 6px;
}
.FileInput_fileInput__2j7Bk > *:last-child {
  flex-grow: 0;
  flex-shrink: 0;
}

.FileInput_imageInput__1omHn > *:not(:last-child) {
  margin-bottom: 6px;
}

.FileInput_imagePreview__2wfID {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5;

  cursor: pointer;

  width: 100%;

  border: 1px solid #dddbd7;
  border-radius: 4px;
  overflow: hidden;

  font-size: 13px;
  color: #807e7c97;
  background: #fbfaf9;

  transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
}
.FileInput_imagePreview__2wfID img {
  max-height: 100%;
  max-width: 100%;
}
.FileInput_imagePreview__2wfID.FileInput_disabled__3u4o3 {
  cursor: not-allowed;
}

.FileInput_dropzone__1DCPN {
  height: 50px;
  background: black;
}

.FileInput_suffixWrapper__3ptwQ {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FileInput_suffixWidthProvider__2iaW6 {
  display: flex;
  flex-direction: column;
  height: 0;
  opacity: 0;
}

@-webkit-keyframes FileInput_blinker__3O4eB {
  50% {
    opacity: 0.5;
  }
}

@keyframes FileInput_blinker__3O4eB {
  50% {
    opacity: 0.5;
  }
}
.FileInput_uploadingText__3QXYm {
  -webkit-animation: FileInput_blinker__3O4eB 1s linear infinite;
          animation: FileInput_blinker__3O4eB 1s linear infinite;
}

.CopyField_multilineCopyFiled__1o1Eq {
  display: flex;
  flex-direction: column;
}
.CopyField_multilineCopyFiled__1o1Eq > *:first-child {
  margin-bottom: 10px;
}

.CopyField_suffixWrapper__3h4iT {
  display: flex;
  flex-direction: column;
  text-align: center;

  justify-content: center;
  align-items: center;
}
.CopyField_suffixWidthProvider__3iQhe {
  display: flex;
  flex-direction: column;
  height: 0;
  opacity: 0;
}

.SettingFlow_childrenWithMargin__xGkih > *:not(.SettingFlow_hr__1XCWN):not(.SettingFlow_headerHr__1qvur) {
  margin-left: 20px;
  margin-right: 20px;
}

.SettingFlow_childrenWithMargin__xGkih > *:first-child {
  margin-top: 15px;
}
.SettingFlow_childrenWithMargin__xGkih > *:last-child {
  margin-bottom: 15px;
}

.SettingFlow_header__1Rr-s {
  margin-bottom: 0 !important;
}
.SettingFlow_header__1Rr-s + .SettingFlow_hr__1XCWN {
  margin-top: 15px;
}

.SettingFlow_goBack__16XLO {
  max-height: 18px;
}

.SettingFlow_footer__fddhs {
  background: #fbfaf9;
}

.SettingFlow_hr__1XCWN {
  flex-shrink: 0;
  background: #dddbd7;
  width: 100%;
  height: 1px;
  box-sizing: border-box;
  border: none;
  margin: 0;
}

.ColorPicker_colorPicker__f9NUw {
  display: inline-flex;
}

/* react-color */
.ColorPicker_chromePicker__oc5Xw > * {
  border: none !important;
  box-shadow: none !important;
}
.ColorPicker_chromePicker__oc5Xw * {
  font-family: inherit !important;
}

/* palette with presets */
.ColorPicker_palette__3o6d9 {
  display: flex;
  width: 60px;
  height: 225px;
}

.ColorInput_colorInput__2bVke {
  /* ... */
}

.ColorInput_input__2FqWN {
  pointer-events: none;
}

.ColorInput_colorPreview__Kb2aD {
  width: 10px;
  height: 10px;

  border-radius: 1px;

  box-sizing: border-box;
  border: 1px solid transparent;
}

.Color_color__1fV-5 {
  flex-shrink: 0;

  box-sizing: border-box;
  border: 1px solid transparent;
}

.Color_size-small__3K11X {
  width: 10px;
  height: 10px;
}
.Color_size-medium__1Im90 {
  width: 14px;
  height: 14px;
}
.Color_size-big__9Auj- {
  width: 20px;
  height: 20px;
}

.Color_shape-circle__KRfcm {
  border-radius: 100%;
}
.Color_shape-square__32C8W {
  border-radius: 1px;
}

.OpenCloseIcon_openCloseIcon__2N9bV {
  position: relative;
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
.OpenCloseIcon_open__KPjcI {
  -webkit-transform: rotateY(180deg) translateY(-50%);
          transform: rotateY(180deg) translateY(-50%);
}

.OpenCloseIcon_openCloseIcon__2N9bV > *:last-child {
  position: absolute;
  opacity: 0;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  transition: opacity 0.4s ease-in-out;
}
.OpenCloseIcon_open__KPjcI > *:last-child {
  opacity: 1;
}

.Skeleton_skeleton__2v67u {
  display: flex;
  flex-direction: column;

  width: 100%;
}
.Skeleton_skeleton__2v67u > *:not(:last-child) {
  margin-bottom: 10px;
}

@-webkit-keyframes Skeleton_slider__-bSV1 {
  0% {
    opacity: 1;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(200px);
            transform: translateX(200px);
  }
}

@keyframes Skeleton_slider__-bSV1 {
  0% {
    opacity: 1;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(200px);
            transform: translateX(200px);
  }
}
.Skeleton_verticalLine__11Vy9 {
  height: 15px;

  position: relative;
  overflow: hidden;

  background-color: #dddbd7;
}
.Skeleton_verticalLineThin__g7Rni {
  height: 10px;

  position: relative;
  overflow: hidden;

  background-color: #dddbd7;
}

.Skeleton_verticalLine__11Vy9[data-animated='true']::after,
.Skeleton_verticalLineThin__g7Rni[data-animated='true']::after {
  position: absolute;

  content: '';
  width: 100px;
  height: 100%;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.15) 50%, rgba(255, 255, 255, 0) 100%);

  -webkit-animation: Skeleton_slider__-bSV1 1.5s linear infinite;

          animation: Skeleton_slider__-bSV1 1.5s linear infinite;
}

.Skeleton_row__Mh0Ay {
  display: flex;
  flex-wrap: wrap;
}
.Skeleton_row__Mh0Ay > *:not(:last-child) {
  margin-right: 1ch;
}

.GridLayout_gridLayout__3BcCb {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;

  grid-template-areas:
    'top top top'
    'left middle right'
    'bottom bottom bottom';
}

.GridLayout_top__3M99w {
  grid-area: top;
}
.GridLayout_left__2iQTs {
  grid-area: left;
}
.GridLayout_middle__2qAC3 {
  grid-area: middle;
  overflow: hidden;
}
.GridLayout_right__2Ucu6 {
  grid-area: right;
}
.GridLayout_bottom__3XUTF {
  grid-area: bottom;
}

/* ------------------------- DEFAULT WRAPPER STYLING ------------------------ */

.SortableList_listWrapper__3orhC {
  min-width: 0;
  flex-shrink: 1;

  position: relative;
  box-sizing: border-box;
}

.SortableList_list__2HfvE {
  min-width: 0;
  flex-shrink: 0;

  box-sizing: border-box;
  display: flex;
}
.SortableList_vertical__27VsE .SortableList_list__2HfvE {
  flex-direction: column;
}
.SortableList_horizontal__5LG7t .SortableList_list__2HfvE {
  display: flex;
}

/* -------------------------------------------------------------------------- */
/*                         STYLES FOR SCROLLABLE MODE                         */
/* -------------------------------------------------------------------------- */

/* ---------------------------- SCROLLABLE STYLES --------------------------- */

.SortableList_scrollContainer__1Z_UQ {
  box-sizing: border-box;
}
.SortableList_vertical__27VsE > .SortableList_scrollContainer__1Z_UQ {
  overflow-y: scroll;
}
.SortableList_horizontal__5LG7t > .SortableList_scrollContainer__1Z_UQ {
  overflow-x: scroll;
}

/* Adds right padding that should be there but isn't due to overflow */
.SortableList_scrollContainer__1Z_UQ > .SortableList_list__2HfvE::after {
  flex-shrink: 0;
  content: ' ';
  display: block;

  white-space: pre;
  overflow: hidden;

  width: 10px;
  height: 100%;
}

.SortableList_hideScrollBar__38Wcz.SortableList_scrollContainer__1Z_UQ {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.SortableList_hideScrollBar__38Wcz.SortableList_scrollContainer__1Z_UQ::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

/* ------------------------------- SCROLL EDGE ------------------------------ */

.SortableList_scrollEdgeTop__1-rgR,
.SortableList_scrollEdgeBottom__wxz9X {
  content: '';
  display: block;
  z-index: 100;

  position: absolute;
  width: 40px;
  height: 40px;

  /*
    background-color: rgba(0, 0, 0, 0.2);
  */
}

.SortableList_horizontal__5LG7t > .SortableList_scrollEdgeTop__1-rgR,
.SortableList_horizontal__5LG7t > .SortableList_scrollEdgeBottom__wxz9X {
  top: 0;
  height: 100%;
}
.SortableList_vertical__27VsE > .SortableList_scrollEdgeTop__1-rgR,
.SortableList_vertical__27VsE > .SortableList_scrollEdgeBottom__wxz9X {
  left: 0;
  width: 100%;
}
.SortableList_horizontal__5LG7t > .SortableList_scrollEdgeTop__1-rgR {
  left: 0;
}
.SortableList_horizontal__5LG7t > .SortableList_scrollEdgeBottom__wxz9X {
  right: 0;
}
.SortableList_vertical__27VsE > .SortableList_scrollEdgeTop__1-rgR {
  top: 0;
}
.SortableList_vertical__27VsE > .SortableList_scrollEdgeBottom__wxz9X {
  bottom: 0;
}

/* -------------------------------------------------------------------------- */
/*                               SHRINKING ITEMS                              */
/* -------------------------------------------------------------------------- */

.SortableList_shrinkItems__2mDy5 {
  overflow: hidden;
}
/* list is direct children of .listWrapper if scrollable === false */
.SortableList_shrinkItems__2mDy5 .SortableList_list__2HfvE > * {
  flex-shrink: 1;
  min-width: 43px;
}
/* Removes width styling from dropline and ghost element wrappers */
.SortableList_shrinkItems__2mDy5 .SortableList_list__2HfvE > .SortableList_dropLine__3pyBz,
.SortableList_shrinkItems__2mDy5 .SortableList_list__2HfvE > .SortableList_dropLine__3pyBz + * {
  min-width: initial;
  width: initial;
}

/* -------------------------------------------------------------------------- */
/*                            DEFAULT ITEM STYLING                            */
/* -------------------------------------------------------------------------- */

.SortableList_dropLine__3pyBz {
  background-color: #807e7c;
}
:not(.SortableList_dragging__3hosp) > .SortableList_dropLine__3pyBz {
  display: none !important;
}

.SortableList_vertical__27VsE .SortableList_dropLine__3pyBz {
  height: 1px !important;
}
.SortableList_horizontal__5LG7t .SortableList_dropLine__3pyBz {
  width: 1px !important;
}

.SortableList_item__3I93W {
  /* border-box ir required for react-sortful to work right */
  box-sizing: border-box !important;
}

.SortableList_dragHandle__c_wK2 {
  width: 16px;
  height: 16px;
  cursor: grab;
}
.SortableList_dragHandle__c_wK2 svg {
  height: 100%;
  fill: black;
}

/* ----------------------------- SORTING STATES ----------------------------- */

.SortableList_item__3I93W[data-sorting-role='placeholder'] {
  pointer-events: none;
}

@-webkit-keyframes SortableList_ghostize__2ZE0H {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0.8;
  }
}

@keyframes SortableList_ghostize__2ZE0H {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0.8;
  }
}
.SortableList_item__3I93W[data-sorting-role='ghost'] {
  pointer-events: none;

  -webkit-animation-name: SortableList_ghostize__2ZE0H;

          animation-name: SortableList_ghostize__2ZE0H;
  -webkit-animation-duration: 200ms;
          animation-duration: 200ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;

  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.05);
}

/* ----------------------------- SORTING STYLING ---------------------------- */

.SortableTabs_tabs__1DeWy {
  /* So that active tabs blend into a background beneath */
  margin-bottom: -1px;
}

/* --------------------------- DEFAULT TAB STYLING -------------------------- */

/* Default state */
.SortableTabs_tab__Sube1 {
  flex-shrink: 1;
  flex-basis: 0;
  width: 100%;

  position: relative;

  cursor: pointer;

  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  color: #5d5d5c;

  background-color: #f6f5f4;
  padding: 8px 15px;

  border: 1px solid transparent;
  border-bottom: 1px solid #dddbd7;
  border-radius: 4px 4px 0 0;

  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
}
.SortableTabs_tab__Sube1:hover,
.SortableTabs_tab__Sube1:focus {
  background-color: #eae8e4;
}

.SortableTabs_tab__Sube1 > * + * {
  margin-left: 8px;
}
.SortableTabs_tab__Sube1 > * + .SortableTabs_label__2BaBk {
  margin-left: 0;
  padding-left: 8px;
}

/* Active state */
.SortableTabs_tab__Sube1.SortableTabs_active__2scAx {
  cursor: default;

  background-color: #fbfaf9;
  color: #454442;

  border-color: #dddbd7;
  border-bottom: 1px solid #fbfaf9;
}

/* Sorting states */
.SortableTabs_tab__Sube1[data-sorting-role='placeholder'] {
  background-color: transparent;
  color: #b4b2af;

  border-color: #dddbd7;
  border-style: dashed;
  border-bottom: none;
}
.SortableTabs_tab__Sube1[data-sorting-role='placeholder'] svg {
  opacity: 0.4;
}

.SortableTabs_label__2BaBk {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ------------------------------- SEPARATORS ------------------------------- */

.SortableTabs_tab__Sube1::after,
.SortableTabs_tab__Sube1::before {
  content: ' ';
  display: block;

  position: absolute;

  top: 25%;
  height: 50%;
  width: 1px;
}

/* Left separator */
.SortableTabs_tab__Sube1::before {
  left: 0;
  background-color: #dddbd7;
}
/*
Hides left separator if:
- tab is hovered/active,
- tab before is hovered/active
- is first tab
*/
.SortableTabs_tabWrapper__4H3Fs:first-child > .SortableTabs_tab__Sube1::before,
.SortableTabs_tabWrapper__4H3Fs:hover + .SortableTabs_tabWrapper__4H3Fs > .SortableTabs_tab__Sube1::before,
.SortableTabs_tabWrapper__4H3Fs:hover > .SortableTabs_tab__Sube1::before,
.SortableTabs_tabWrapper__4H3Fs.SortableTabs_active__2scAx + .SortableTabs_tabWrapper__4H3Fs > .SortableTabs_tab__Sube1::before,
.SortableTabs_tabWrapper__4H3Fs.SortableTabs_active__2scAx > .SortableTabs_tab__Sube1::before {
  display: none;
}

/* Right separator visible only for last tab (after tab there is a dropline and a ghost component) */
.SortableTabs_tabsSortableList__R3LPi > *:nth-last-child(3) > .SortableTabs_tab__Sube1::after {
  right: 0;
  background-color: #dddbd7;
}
/*
Hides right separator if:
- tab is hovered/active,
- tab before is hovered/active
*/
.SortableTabs_tab__Sube1:hover:after,
.SortableTabs_tab__Sube1.SortableTabs_active__2scAx::after {
  display: none;
}

.RangeArrows_rangeArrows__2_5QN {
  display: flex;
  align-items: center;
}
.RangeArrows_rangeArrows__2_5QN > span {
  text-align: center;
  min-width: 35px;
}
.RangeArrows_rangeArrows__2_5QN > * + * {
  margin-left: 5px;
}
.RangeArrows_rangeArrows__2_5QN > *:last-child {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}

/* Main table styles */
.Table_table__2l9_K {
  display: flex;
  flex-direction: column;

  border-collapse: collapse;
  text-align: left;

  background-color: #ffffff;
}
.Table_row__30TBh {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  width: 100%;
}
.Table_cell__1K5lw {
  display: flex;
  align-items: center;

  box-sizing: border-box;

  padding: 16px 18px;

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  background: #ffffff;
  color: #302f2e;
}

/* Borders */
.Table_table__2l9_K {
  background: #eae8e4;
  border: 1px solid #eae8e4;
  border-radius: 3px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
}
.Table_row__30TBh {
  border-bottom: 1px solid #eae8e4;
}
.Table_row__30TBh:last-child {
  border-bottom: none;
}
.Table_cell__1K5lw {
  border-right: 1px solid #eae8e4;
}
.Table_cell__1K5lw:last-child {
  border-right: none;
}

/* First row */
.Table_rowHeader__3evD9 .Table_cell__1K5lw {
  padding: 10px 18px;

  font-weight: 600;
  background-color: #f6f5f4;
  color: #807e7c;
}

/*
  Styles for react-table useGridLayout plugin
*/

/* ------------------------------ MAIN WRAPPERS ----------------------------- */

.GridLayout_table__3_7Vt {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  border: 1px solid #cbc9c5;
  background-color: #fff;
}

.GridLayout_row__3OPJY,
.GridLayout_body__15f9M,
.GridLayout_header__280mc,
.GridLayout_footer__1cxum {
  display: contents;
}

/* ---------------------------------- CELLS --------------------------------- */

.GridLayout_cell__36uvs {
  position: relative;
  box-sizing: border-box;

  min-width: 100px;

  font-size: 13px;
  font-weight: 400;

  padding: 12px 16px;
  background-color: #fff;

  overflow: hidden;
}
.GridLayout_cell__36uvs {
  border: 1px solid #f6f5f4;
}
.GridLayout_header__280mc .GridLayout_cell__36uvs,
.GridLayout_footer__1cxum .GridLayout_cell__36uvs {
  border: 1px solid #eae8e4;
  white-space: nowrap;
}

/* Hovers */
.GridLayout_body__15f9M .GridLayout_row__3OPJY:hover > .GridLayout_cell__36uvs {
  background-color: #fbfaf9;
}
.GridLayout_body__15f9M .GridLayout_row__3OPJY:hover > .GridLayout_cell__36uvs:hover {
  background-color: #f6f5f4;
}

/* Collapsing borders  */
.GridLayout_header__280mc + .GridLayout_body__15f9M > .GridLayout_row__3OPJY:first-child > .GridLayout_cell__36uvs,
.GridLayout_row__3OPJY:not(:first-child) > .GridLayout_cell__36uvs {
  margin-top: -1px;
}
.GridLayout_cell__36uvs:not(:first-child) {
  margin-left: -1px;
}

/* Positioning items */
.GridLayout_header__280mc .GridLayout_cell__36uvs {
  display: flex;
  align-items: center;
}
.GridLayout_header__280mc .GridLayout_cell__36uvs > *:not(:last-child) {
  margin-right: 8px;
}

/* ---------------------------- INTERACTIVE CELLS --------------------------- */

.GridLayout_body__15f9M .GridLayout_cell__36uvs[data-interactive-td]:focus {
  border: 1px solid #b4b2af;
}
.GridLayout_body__15f9M .GridLayout_cell__36uvs[data-interactive-td]:focus-within:not(:focus) {
  border: 1px solid #ff623d;
  outline: 2px solid #ff623d;
  outline-offset: -2px;
}

/* ---------------------------- HEADER AND FOOTER --------------------------- */

/* Darker cells with less spacing */
.GridLayout_header__280mc .GridLayout_cell__36uvs,
.GridLayout_header__280mc .GridLayout_cell__36uvs {
  padding: 8px 16px;
  background-color: #f6f5f4;
}

/* ----------------------------- STICKY STYLING ----------------------------- */

/* Sticky columns (logic is provided by react-table-sticky) */
.GridLayout_cell__36uvs[data-sticky-last-left-td] {
  border-right-color: #b4b2af;
  box-shadow: 1px 0 rgba(0, 0, 0, 0.05), 2px 0 rgba(0, 0, 0, 0.025), 3px 0 rgba(0, 0, 0, 0.01);
}
.GridLayout_cell__36uvs[data-sticky-first-right-td] {
  border-left-color: #b4b2af;
  box-shadow: -1px 0 rgba(0, 0, 0, 0.05), -2px 0 rgba(0, 0, 0, 0.025), -3px 0 rgba(0, 0, 0, 0.01);
}

/* Sticky header and footer */
.GridLayout_header__280mc.GridLayout_sticky__2Xqi3 .GridLayout_cell__36uvs {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-bottom-color: #b4b2af;
  box-shadow: 0px 1px rgba(0, 0, 0, 0.05), 0px 2px rgba(0, 0, 0, 0.015);
}
.GridLayout_footer__1cxum.GridLayout_sticky__2Xqi3 .GridLayout_cell__36uvs {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  border-top-color: #b4b2af;
  box-shadow: 0px -1px rgba(0, 0, 0, 0.05), 0px -2px rgba(0, 0, 0, 0.015);
}

/* -------------------------------- Z INDEXES ------------------------------- */

/* Focused cell */
.GridLayout_cell__36uvs:focus,
.GridLayout_cell__36uvs:focus-within {
  z-index: 1;
}

/* Sticky column */
.GridLayout_cell__36uvs[data-sticky-td] {
  z-index: 10;
}

/* Focused cell in sticky column */
.GridLayout_cell__36uvs:focus[data-sticky-td],
.GridLayout_cell__36uvs:focus-within[data-sticky-td] {
  z-index: 11;
}

/* Sticky header/footer */
.GridLayout_header__280mc.GridLayout_sticky__2Xqi3 .GridLayout_cell__36uvs,
.GridLayout_footer__1cxum.GridLayout_sticky__2Xqi3 .GridLayout_cell__36uvs {
  z-index: 20;
}

/* Sticky column in sticky header/footer */
.GridLayout_header__280mc.GridLayout_sticky__2Xqi3 .GridLayout_cell__36uvs[data-sticky-td],
.GridLayout_footer__1cxum.GridLayout_sticky__2Xqi3 .GridLayout_cell__36uvs[data-sticky-td] {
  z-index: 21;
}

/* ------------------------- ROWS INDEXES/SELECTION ------------------------- */

.GridLayout_rowSelector__1GQE6 {
  position: relative;
  min-width: 18px;
}

.GridLayout_rowSelectorCheckbox__1Oe-U {
  position: absolute;
  top: 50%;
  left: 0%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

  visibility: hidden;
}

/* Checkbox is showed on row hover, if it's already checked or if it's in header */
.GridLayout_header__280mc .GridLayout_rowSelectorLabel__2oDYx,
.GridLayout_rowSelector__1GQE6.GridLayout_checked__3J_jI .GridLayout_rowSelectorLabel__2oDYx,
.GridLayout_row__3OPJY:hover .GridLayout_rowSelectorLabel__2oDYx {
  visibility: hidden;
}
.GridLayout_header__280mc .GridLayout_rowSelectorCheckbox__1Oe-U,
.GridLayout_rowSelector__1GQE6.GridLayout_checked__3J_jI .GridLayout_rowSelectorCheckbox__1Oe-U,
.GridLayout_row__3OPJY:hover .GridLayout_rowSelectorCheckbox__1Oe-U {
  visibility: visible;
}

/* ------------------------------- PLACEHOLDER ------------------------------ */

@-webkit-keyframes GridLayout_blinker__251k6 {
  50% {
    opacity: 0.7;
  }
}

@keyframes GridLayout_blinker__251k6 {
  50% {
    opacity: 0.7;
  }
}

@-webkit-keyframes GridLayout_slider__1jN65 {
  0% {
    opacity: 1;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(300px);
            transform: translateX(300px);
  }
}

@keyframes GridLayout_slider__1jN65 {
  0% {
    opacity: 1;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(300px);
            transform: translateX(300px);
  }
}

.GridLayout_cellPlaceholder__2eYA7 {
  position: absolute;
  display: none;
  visibility: hidden;

  overflow: hidden;

  background-color: #f6f5f4;

  top: 50%;
  left: 16px;
  max-width: calc(100% - 32px);
  height: 1ch;

  -webkit-transform: translateY(-50%);

          transform: translateY(-50%);

  -webkit-animation: GridLayout_blinker__251k6 2s linear infinite;

          animation: GridLayout_blinker__251k6 2s linear infinite;
}
.GridLayout_cellPlaceholder__2eYA7::after {
  position: absolute;

  content: '';
  width: 50px;
  height: 100%;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.05) 51%, rgba(255, 255, 255, 0) 100%);

  -webkit-animation: GridLayout_slider__1jN65 2s linear infinite;

          animation: GridLayout_slider__1jN65 2s linear infinite;
}

.GridLayout_loading__3nEPf {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.GridLayout_loading__3nEPf .GridLayout_body__15f9M .GridLayout_cell__36uvs {
  color: transparent;
}
.GridLayout_loading__3nEPf .GridLayout_body__15f9M .GridLayout_cell__36uvs > * {
  visibility: hidden;
}
.GridLayout_loading__3nEPf .GridLayout_cellPlaceholder__2eYA7 {
  display: block;
  visibility: visible !important;
}

.GridLayout_indexCell__3L19G {
  display: flex;
  flex-direction: column;
}
.GridLayout_indexCellContent__Gfib7 {
  margin-top: 15px;
}
.GridLayout_indexCellContent__Gfib7,
.GridLayout_indexCellMainContent__2xr6p,
.GridLayout_indexCellHoverContent__2YHLD {
  display: flex;
}
.GridLayout_indexCellContent__Gfib7 > *:not(:last-child),
.GridLayout_indexCellMainContent__2xr6p > *:not(:last-child),
.GridLayout_indexCellHoverContent__2YHLD > *:not(:last-child) {
  margin-right: 8px;
}

.GridLayout_indexCellHoverContent__2YHLD {
  visibility: hidden;
}
.GridLayout_row__3OPJY:hover .GridLayout_indexCellHoverContent__2YHLD {
  visibility: visible;
}

.Pagination_pagination__2uvUW {
  display: flex;
  flex-shrink: 0;
}
.Pagination_pagination__2uvUW > *:not(:last-child) {
  margin-right: 7px;
}

.Pagination_paginationButton__2OFvb {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;
  height: 16px;
  line-height: 16px;

  -webkit-font-feature-settings: "tnum";

          font-feature-settings: "tnum";

  font-variant-numeric: tabular-nums;
  font-size: 13px;
  font-weight: 500;
  color: #b4b2af;

  border: none;
  box-shadow: none;
  background-color: transparent;
}
.Pagination_paginationButton__2OFvb:disabled {
  cursor: not-allowed;
}
.Pagination_paginationButton__2OFvb,
.Pagination_paginationButton__2OFvb * {
  fill: #b4b2af;
}

.Pagination_paginationButton__2OFvb:hover,
.Pagination_paginationButton__2OFvb.Pagination_active__atCA5 {
  color: #1b1b1a;
}
.Pagination_paginationButton__2OFvb:hover * {
  fill: #1b1b1a;
}

/* ---------------------------------- ICONS --------------------------------- */

.Pagination_dots__2IuzJ {
  width: 20px;
}
.Pagination_dots__2IuzJ > * {
  -webkit-transform: translateY(1px) scale(0.8);
          transform: translateY(1px) scale(0.8);
}

.Pagination_previous__35Ibl *,
.Pagination_next__1Yufp * {
  stroke: #1b1b1a;
}
.Pagination_previous__35Ibl {
  padding-right: 3px;
}
.Pagination_next__1Yufp {
  padding-left: 3px;
}
.Pagination_previous__35Ibl > * {
  -webkit-transform: scale(0.6) translateY(2px);
          transform: scale(0.6) translateY(2px);
}
.Pagination_next__1Yufp > * {
  -webkit-transform: rotateY(180deg) scale(0.6) translateY(2px);
          transform: rotateY(180deg) scale(0.6) translateY(2px);
}

/* ------------------------------ GOTO POPOVER ------------------------------ */

.Pagination_goToPopover__1etHP {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
}
.Pagination_goToPopover__1etHP > *:not(:last-child) {
  margin-right: 8px;
}

/* -------------------------------- PAGE SIZE ------------------------------- */

.Pagination_pageSize__3K4bQ {
  display: flex;
  justify-content: center;
  align-items: center;

  white-space: nowrap;

  color: #b4b2af;
  font-weight: 500;
  font-size: 13px;
}
.Pagination_pageSize__3K4bQ > *:not(:last-child) {
  margin-right: 5px;
}

/* ------------------------------ VISIBLE PART ------------------------------ */

.InlineSelect_placeholder__2VOMw {
  color: #b4b2af;
  font-weight: 400;
}

.InlineSelect_inlineSelect__3MmYX {
  display: inline-flex;
}
.InlineSelect_inlineSelect__3MmYX.InlineSelect_error__2NRjE {
  border-bottom: 1px solid #f05252;
}

.InlineSelect_inlineSelectPreview__2sDBA {
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  -webkit-user-select: none;

          user-select: none;
}
.InlineSelect_disabled__vFU32 .InlineSelect_inlineSelectPreview__2sDBA {
  cursor: not-allowed;
}

.InlineSelect_inlineSelectPreview__2sDBA > *:not(:last-child) {
  margin-right: 8px;
}

.InlineSelect_currentOptionLabel__2oCJH {
  text-align: left;

  flex-grow: 1;
  flex-shrink: 1;

  line-height: 16px;

  font-size: 13px;
  font-weight: 600;
  color: #302f2e;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-font-feature-settings: "tnum";

          font-feature-settings: "tnum";

  font-variant-numeric: tabular-nums;
}
.InlineSelect_disabled__vFU32 .InlineSelect_currentOptionLabel__2oCJH {
  color: #cbc9c5;
}

.InlineSelect_dropdownIcon__2ZRzt {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

/* -------------------------------- DROPDOWN -------------------------------- */

.InlineSelect_options__2sU8K {
  padding: 5px 0;
  -webkit-user-select: none;
          user-select: none;
}

.InlineSelect_option__3Tp-D {
  cursor: pointer;
  padding: 10px 15px;
}
.InlineSelect_option__3Tp-D:hover,
.InlineSelect_option__3Tp-D.InlineSelect_active__3YD_8 {
  background-color: #f6f5f4;
}
.InlineSelect_option__3Tp-D.InlineSelect_active__3YD_8 {
  cursor: default;
}

/* ------------------------------ SHARED STYLES ----------------------------- */

.CheckboxGroup_checkboxGroup__1lVmx {
  -webkit-user-select: none;
          user-select: none;
  display: flex;
  flex-direction: column;
}

.CheckboxGroup_header__1XCQP {
  color: #5d5d5c;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  margin-bottom: 5px;
}

.CheckboxGroup_placeholder__G3M6V {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  padding: 8px 12px;

  color: #454442;
  background-color: #f6f5f4;

  border: 1px solid #dddbd7;
  border-radius: 4px;
}

/* ----------------------------- DEFAULT LAYOUT ----------------------------- */

.CheckboxGroup_checkboxGroupDefault__2Sly0 {
  display: grid;

  grid-template-columns: auto 1fr;
}

.CheckboxGroup_checkboxGroupDefaultInput__1GHQz {
  cursor: pointer;
  padding-right: 10px;
}

.CheckboxGroup_checkboxGroupDefaultLabel__ceV1i {
  cursor: pointer;
  color: #807e7c;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 6px 0;
}

/* ------------------------------ BLOCKS LAYOUT ----------------------------- */

.CheckboxGroup_checkboxGroupBlocks__8iKWd {
  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  gap: 12px;
}

/*
  Block
*/
.CheckboxGroup_checkboxGroupBlocksBlock__-TRY4 {
  cursor: pointer;

  display: flex;
  align-items: center;

  padding: 5px 12px;

  background-color: #f6f5f4;

  border: 1px solid #f6f5f4;
  border-radius: 4px;
}
.CheckboxGroup_checkboxGroupBlocksBlock__-TRY4.CheckboxGroup_active__1HkZl {
  border: 1px solid #dddbd7;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.05);
}

/*
  Elements within block
*/
.CheckboxGroup_checkboxGroupBlocksBlock__-TRY4 > * {
  flex-shrink: 0;
  flex-grow: 0;
}
.CheckboxGroup_checkboxGroupBlocksBlock__-TRY4 > *:not(:last-child) {
  margin-right: 15px;
}

.CheckboxGroup_checkboxGroupBlocksLabel__1Mhgd {
  flex-shrink: 1;
  flex-grow: 1;

  color: #454442;
  font-size: 13px;
  line-height: 16px;

  font-weight: 500;
}

/* ------------------------- BLOCKS COMPACT LAYOUT -------------------------- */

.CheckboxGroup_checkboxGroupBlocksCompact__2DtjA {
  display: flex;
  flex-direction: row;
}
.CheckboxGroup_checkboxGroupBlocksCompact__2DtjA > *:not(:last-child) {
  margin-right: 8px;
}

/*
  Block
*/
.CheckboxGroup_checkboxGroupBlocksCompactBlock__3_76I {
  cursor: pointer;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 9px;
  height: 36px;

  color: #454442;
  background-color: #ffffff;

  border: 1px solid #dddbd7;
  border-radius: 4px;
}
.CheckboxGroup_checkboxGroupBlocksCompactBlock__3_76I.CheckboxGroup_active__1HkZl {
  background-color: #f6f5f4;
  border: 1px solid #dddbd7;
}

.Expandible_expandible__2LUKu {
  display: flex;
  flex-direction: column;
}
.Expandible_expandible__2LUKu > * {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Expandible_button__26l_S {
  margin-top: 9px;
  opacity: 0.5;
  cursor: pointer;
}
.Expandible_button__26l_S:hover {
  opacity: 0.7;
}
.Expandible_button__26l_S::before {
  content: '(';
}
.Expandible_button__26l_S::after {
  content: ')';
}

.TabularNumber_tabularNumber__3hHMz {
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

/* Root */
.react-datepicker {
  font-family: inherit;
  font-size: 12px;

  border-radius: 0px;
  border: none;
}
.react-datepicker * {
  -webkit-user-select: none;
          user-select: none;
}

.react-datepicker * {
  font-size: 12px;
}

/* Header content */
.react-datepicker__header {
  border-radius: 0;
  background-color: #fbfaf9;
  border-color: #eae8e4;
}
.react-datepicker__header * {
  color: #454442;
}
.react-datepicker__current-month {
  font-size: 13px;
  font-weight: 600;
}
.react-datepicker__day-name {
  text-transform: lowercase;
}

.react-datepicker__day {
  color: #454442;
  line-height: 25px;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #fbfaf9;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range {
  cursor: default;
  background-color: #eae8e4;
  color: #454442;
}

.react-datepicker__time-list-item {
  font-weight: 500 !important;
  color: #454442 !important;
}
.react-datepicker__time-list-item:hover:not(.react-datepicker__time-list-item--selected) {
  background-color: #fbfaf9 !important;
}
.react-datepicker__time-list-item--selected {
  font-weight: 600 !important;
  background-color: #eae8e4 !important;
  color: #454442 !important;
  cursor: default !important;
}

.DateInput_afterIcon__1Eu-A {
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}
.DateInput_dateInput__1SKUD:hover .DateInput_afterIcon__1Eu-A {
  opacity: 0.7;
}
.DateInput_dateInput__1SKUD.DateInput_focused__-OLsw .DateInput_afterIcon__1Eu-A {
  opacity: 1;
}

.Panel_cover__gKyL8 {
  position: absolute;
  background-color: rgba(48, 47, 46, 0.07);
  top: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.Panel_cover__gKyL8.Panel_visible__250Nm {
  opacity: 1;
}

.Panel_main__m3hqN {
  background-color: #FFFFFF;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 8px 8px 0 rgba(48, 47, 46, 0.07), 0 16px 16px 0 rgba(48, 47, 46, 0.07);
  width: 400px;
  right: -400px;
  position: absolute;
  top: 0;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  transition: opacity 0.3s ease-in-out, right 0.3s ease-in-out;
}

.Panel_box__1K0au {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Panel_main__m3hqN.Panel_visible__250Nm {
  right: 0;
  opacity: 1;
}

.Panel_header__3RJfv {
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EAE8E4;
  color: #454442;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}

.Panel_content__7GQks {
  padding: 20px 30px;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.Panel_buttons__3nMOq {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 20px 30px;
  border-top: 1px solid #EAE8E4;
}

.Panel_buttons__3nMOq button:not(:last-child) {
  margin: 0 10px 0 0;
}

.Panel_loader__2hQJm {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Panel_loader__2hQJm .Panel_spinner__3KlBI {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.Panel_loader__2hQJm .Panel_spinner__3KlBI.Panel_visible__250Nm {
  opacity: 1;
}

.Panel_loader__2hQJm .Panel_checkmark__9cpUL {
  position: absolute;
  -webkit-transform: scale(2.2);
          transform: scale(2.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.Panel_loader__2hQJm .Panel_checkmark__9cpUL.Panel_visible__250Nm {
  opacity: 1;
}

/* ----------------------------- MAIN CONTAINER ----------------------------- */

.FilteringMatrix_filteringMatrix__jAc_Z {
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;

  min-height: 50px;
}

.FilteringMatrix_placeholder__394IR {
  position: absolute;

  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

  color: #807e7c;
  padding: 10px 20px;
}

/* ------------------------------ ROWS ELEMENTS ----------------------------- */

.FilteringMatrix_filteringRow__3AigT {
  box-sizing: border-box;

  display: flex;
  align-items: center;
  width: 100%;

  padding: 10px 20px;
}
.FilteringMatrix_filteringRow__3AigT.FilteringMatrix_disabled__TpJZG > * {
  opacity: 0.4;
}
.FilteringMatrix_filteringRow__3AigT.FilteringMatrix_readOnly__1-rRJ {
  cursor: not-allowed;
}

.FilteringMatrix_filteringRow__3AigT:nth-child(even) {
  background-color: hsla(0, 0%, 0%, 0.02);
}

.FilteringMatrix_modeInput__TD-y6 {
  flex-grow: 0;
  flex-shrink: 0;
  width: 40px;

  font-weight: 500;
  color: #b4b2af;

  margin-right: 15px;
  -webkit-user-select: none;
          user-select: none;
}
.FilteringMatrix_columnInput__DTev4 {
  flex-grow: 0;
  flex-shrink: 0;
  width: 140px;

  margin-right: 15px;
}
.FilteringMatrix_conditionInput__1idxc {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 15px;
}
.FilteringMatrix_conditionInput__1idxc * {
  font-weight: 500;
}
.FilteringMatrix_valueInput__2Us7j {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 20px;
  min-width: 0;
}
.FilteringMatrix_deleteButton__36HPD {
  flex-grow: 0;
  flex-shrink: 0;
}

/* --------------------------------- INPUTS --------------------------------- */

.FilteringMatrix_rangeInput__1eF85 {
  display: flex;
  align-items: center;
}
.FilteringMatrix_rangeInput__1eF85 > *:not(:last-child) {
  margin-right: 7px;
}
.FilteringMatrix_rangeInput__1eF85 > span {
  color: #b4b2af;
}

/* ----------------------------- MAIN CONTAINER ----------------------------- */

.SortingMatrix_sortingMatrix__3q0dT {
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;

  min-height: 50px;
}

.SortingMatrix_placeholder__32wi- {
  position: absolute;

  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

  color: #807e7c;
  padding: 10px 20px;
}

/* ------------------------------ ROWS ELEMENTS ----------------------------- */

.SortingMatrix_sortingRow__17TvV {
  box-sizing: border-box;

  display: flex;
  align-items: center;
  width: 100%;

  padding: 10px 20px;
}
.SortingMatrix_sortingRow__17TvV:nth-child(even) {
  background-color: #fbfaf9;
}

.SortingMatrix_label__1qnmk {
  flex-grow: 0;
  flex-shrink: 0;

  font-weight: 500;
  color: #b4b2af;

  margin-right: 15px;
  -webkit-user-select: none;
          user-select: none;

  white-space: nowrap;
}
.SortingMatrix_columnInput__39kE8 {
  display: flex;
  align-items: center;

  flex-grow: 0;
  flex-shrink: 0;
  width: 220px;

  margin-right: 15px;
}
.SortingMatrix_directionInput__36IpY {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 20px;
  min-width: 0;
}
.SortingMatrix_deleteButton__3OIX3 {
  flex-grow: 0;
  flex-shrink: 0;
}

.CreditCard_creditCard__1ahe_ {
  position: relative;
  padding: 1px;
}

.CreditCard_creditCard__1ahe_ .CreditCard_creditCardInput__3xkBE {
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  height: 32px;
  padding: 8px 15px;
  border-radius: 4px;
  position: relative;
  transition: box-shadow .1s ease-in-out;
}

.CreditCard_creditCard__1ahe_ .CreditCard_creditCardInput__3xkBE:hover {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
}

.CreditCard_creditCard__1ahe_ .CreditCard_creditCardInput__3xkBE.CreditCard_active__25FB4 {
  box-shadow: 0 1px 3px 0 #732A56;
}

.CreditCard_creditCard__1ahe_ .CreditCard_creditCardInputBackground__3T_hB {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 34px;
  border-radius: 4px;
  background: #E7E7E7;
  transition: background .1s ease-in-out;
  position: absolute;
}

.CreditCard_creditCardInputBackground__3T_hB.CreditCard_active__25FB4 {
  background: #C9CEFB;
}

.CreditCard_creditCard__1ahe_.CreditCard_error__gYE9k .CreditCard_creditCardInputBackground__3T_hB {
  background: #D43D51 !important;
}

.CreditCard_creditCard__1ahe_.CreditCard_error__gYE9k .CreditCard_creditCardInput__3xkBE {
  box-shadow: 0 1px 3px 0 #D43D51;
}
.ChatBox_chatBox__1d0Kr {
  height: 100%;

  position: relative;
  display: flex;
  flex-direction: column;

  background: #f6f5f4;
}

/* -------------------------------- MESSAGES -------------------------------- */

.ChatBox_messages__2AQOc {
  flex-grow: 1;
  flex-shrink: 1;

  font-size: 12px;

  padding: 14px;

  overflow-y: auto;
}
.ChatBox_placeholder__2dCK0 {
  text-align: center;
  font-weight: 500;
  color: #b4b2af;

  padding: 10px;
}

.ChatBox_message__1Y5Mv {
  display: flex;
  align-items: stretch;
}

.ChatBox_messageLeftColumn__OqoP6 {
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 10px;
}
.ChatBox_messageLeftColumn__OqoP6 > * {
  flex-shrink: 0;
  flex-grow: 0;
}

/* Left line */
.ChatBox_message__1Y5Mv:not(:last-child) .ChatBox_messageLeftColumn__OqoP6::after {
  content: '';
  display: block;

  width: 1px;
  height: 100%;

  background: #dddbd7;

  margin: 6px 0;
}

.ChatBox_messageRightColumn__33Bs2 {
  flex-shrink: 1;
  flex-grow: 1;

  min-width: 0;
}

.ChatBox_messageHeader__3Hru4 {
  display: flex;
  align-items: center;

  line-height: 14px;
  margin-bottom: 4px;
}
.ChatBox_messageAuthor__23SRC {
  flex-shrink: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  margin-right: 10px;
  font-weight: 500;
  color: #454442;
}
.ChatBox_messageDate__2xE69 {
  flex-shrink: 1;
  white-space: nowrap;
  color: #807e7c;
  margin-right: 10px;
}
.ChatBox_messageShowMore__33sZW {
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
  padding-right: 10px;
}

.ChatBox_messageText__FcsTN {
  color: #454442;
  line-height: 18px;

  overflow-wrap: break-word;
}
.ChatBox_message__1Y5Mv:not(:last-child) .ChatBox_messageText__FcsTN {
  padding-bottom: 15px;
}

/* -------------------------- EDITOR AT THE BOTTOM -------------------------- */

.ChatBox_editor__nRkpt,
.ChatBox_editorPlaceholder__2kM5z {
  flex-grow: 0;
  flex-shrink: 0;

  padding: 14px;

  background: #fbfaf9;
  border-top: 1px solid #dddbd7;
}
.ChatBox_editor__nRkpt,
.ChatBox_editorPlaceholder__2kM5z {
  display: flex;
}
.ChatBox_editor__nRkpt > *:first-child {
  margin-top: 2px;
  margin-right: 10px;
  flex-shrink: 0;
}

.ChatBox_editorPlaceholder__2kM5z {
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #807e7c;

  text-align: center;
  line-height: 1.5em;

  font-weight: 600;
}

/* ------------------------------ PLACEHOLDERS ------------------------------ */

.ChatBox_skeleton__2NhHt {
  color: transparent;
  background-color: #eae8e4;
  display: inline-block;
  -webkit-user-select: none;
          user-select: none;
}

.PaymentStatus_paymentStatus__brwHE {
  display: flex;
  flex-direction: column;
}
.PaymentStatus_paymentStatus__brwHE > *:not(:last-child) {
  margin-bottom: 6px;
}

.PaymentStatus_modalTitle__3BMCg {
  color: #454442;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.PaymentStatus_showDetails__1jg9w {
  margin: 0;
  box-sizing: 0;
  padding: 0;
  border: 0;

  cursor: pointer;
  font-size: 13px;
  font-weight: 400;

  width: -webkit-fit-content;

  width: -moz-fit-content;

  width: fit-content;
  opacity: 0.5;
  background: none;
}
.PaymentStatus_showDetails__1jg9w:hover {
  opacity: 0.3;
}

/* --------------------------------- DETAILS -------------------------------- */

.PaymentStatus_details__3rLK3 {
  display: flex;
  flex-direction: column;
  list-style-type: none;

  padding: 0;
  margin: 0;

  overflow: hidden;
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;

  font-size: 13px;

  padding: 18px;

  border-radius: 8px;
  background-color: #fbfaf9;
  border: 1px solid #dddbd7;
}
.PaymentStatus_details__3rLK3 > * {
  flex-shrink: 0;
  flex-grow: 0;
}
.PaymentStatus_details__3rLK3 > *:not(:last-child) {
  margin-bottom: 12px;
}

.PaymentStatus_detailsHr__3Ei-C {
  height: 1px;
  width: 100%;
  background-color: #dddbd7;
}

.PaymentStatus_detailsRow__2haVz {
  line-height: 16px;
  width: 100%;
}
.PaymentStatus_detailsRow__2haVz > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.PaymentStatus_detailsRowLabel__3O4Td {
  color: #807e7c;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 4px;

  white-space: nowrap;
}
.PaymentStatus_detailsRowValue__2rrVe {
  line-height: 1.5em;
  padding: 4px 10px;

  border-radius: 4px;

  border: 1px solid #dddbd7;
  background-color: white;
}

.PaymentStatus_detailsPaymentProviderRow__1qiil {
  display: flex;
  align-items: center;
}

.PaymentStatus_detailsRowProviderIcon__kA_rb {
  width: 3.5ch;
  height: 3.5ch;
  object-fit: contain;
}

.PaymentStatus_detailsPaymentProviderText__2WmP6 {
  display: flex;
  flex-direction: column;

  margin-left: 8px;
}

.PaymentStatus_detailsRowProviderLabel__12J-d {
  color: #807e7c;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 4px;

  white-space: nowrap;
}
.PaymentStatus_detailsRowProviderName__3An6t {
  font-weight: 600;
}

/* --------------------------------- SUMMARY -------------------------------- */

.PaymentStatus_summary__3RXGU {
  font-size: 13px;
  font-weight: 400;
}
.PaymentStatus_summaryAmount__3ISY_ {
  font-size: 16px;
  font-weight: 600;

  color: #0f9f6e;

  white-space: nowrap;
}
.PaymentStatus_summaryTax__1PXW7 {
  white-space: nowrap;
  opacity: 0.5;
}
.PaymentStatus_summaryTax__1PXW7::before {
  content: ' ';
}

/* ---------------------------------- LIST ---------------------------------- */

.Sortful_list__3MO8I {
  position: relative;
}

/* ------------------------------- DRAG HANDLE ------------------------------ */

.Sortful_list__3MO8I.Sortful_enabled__3GTbv .Sortful_dragHandle__2c767 {
  cursor: grab;
}

/* -------------------------- DEFAULT ITEM STYLING -------------------------- */

.Sortful_list__3MO8I:not(.Sortful_dragging__Igrz_) .Sortful_dropLine__3u822 {
  display: none !important;
}

.Sortful_sortedItem__2ti2- {
  /* border-box ir required for react-sortful to work right */
  box-sizing: border-box !important;
}

/* ----------------------------- SORTING STATES ----------------------------- */

.Sortful_sortedItem__2ti2-[data-sortful-role='placeholder'] {
  pointer-events: none;
}

@-webkit-keyframes Sortful_ghostize__2IScb {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0.8;
  }
}

@keyframes Sortful_ghostize__2IScb {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0.8;
  }
}
.Sortful_sortedItem__2ti2-[data-sortful-role='ghost'] {
  pointer-events: none;

  -webkit-animation-name: Sortful_ghostize__2IScb;

          animation-name: Sortful_ghostize__2IScb;
  -webkit-animation-duration: 200ms;
          animation-duration: 200ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;

  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.05);
}

/* --------------------------- DEFAULT DROP LINES --------------------------- */

.Sortful_dropLine__3u822 {
  height: 2px;
  background-color: #807e7c;
}

.Sortful_dropLine__3u822.Sortful_horizontal__2ZKNG {
  width: 2px;
  height: auto;
}

.Sortful_dropLine__3u822::before,
.Sortful_dropLine__3u822::after {
  --size: 6px;
  position: absolute;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: inherit;
  content: '';
}

.Sortful_dropLine__3u822::before {
  top: calc(var(--size) / 2 * -1 + 1px);
  left: calc(var(--size) / 2 * -1);
}

.Sortful_dropLine__3u822::after {
  top: calc(var(--size) / 2 * -1 + 1px);
  right: calc(var(--size) / 2 * -1);
}

.Sortful_dropLine__3u822.Sortful_horizontal__2ZKNG::before {
  top: calc(var(--size) / 2 * -1);
  left: calc(var(--size) / 2 * -1 + 1px);
}

.Sortful_dropLine__3u822.Sortful_horizontal__2ZKNG::after {
  top: auto;
  bottom: calc(var(--size) / 2 * -1);
  left: calc(var(--size) / 2 * -1 + 1px);
}

.FloatingLogger_floatingLogger__1PxEJ {
  z-index: 10000;

  pointer-events: none;

  position: fixed;
  bottom: 10px;
  left: 10px;

  border-radius: 5px;

  background: black;
  color: white;

  opacity: 0.5;

  padding: 10px;
}

.FloatingLogger_floatingLogger__1PxEJ * {
  font-family: inherit;
  font-size: 11px;
}
.FloatingLogger_floatingLogger__1PxEJ div {
  font-size: 1.1em;
  font-weight: 600;

  text-decoration: underline;
}

.MediaCard_mediaCard__2Bmyz {
  display: inline-flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  padding: 18px 15px;

  font-size: 13px;
  line-height: 16px;

  background-color: white;
  border: 1px solid #eae8e4;

  border-radius: 6px;
}
.MediaCard_mediaCard__2Bmyz > *:not(:last-child) {
  margin-bottom: 15px;
}

/* ---------------------------------- IMAGE --------------------------------- */

.MediaCard_image__3-05U {
  height: 140px;

  border-radius: 6px;
  overflow: hidden;
}
.MediaCard_image__3-05U img {
  width: 100%;
  object-fit: fill;
}

/* ---------------------------------- LABEL --------------------------------- */

.MediaCard_label__2n36J > *:not(:last-child) {
  margin-bottom: 5px;
}
.MediaCard_title__zo5lG {
  font-weight: 600;
  color: #302f2e;
}
.MediaCard_description__2xeHE {
  color: #807e7c;
}

/* --------------------------------- BUTTONS -------------------------------- */

.MediaCard_buttons__--I59 {
  display: flex;
  justify-content: space-between;
}

.TransparentInput_transparentInput__1uEkB {
  /* ... */
}

.ModalContent_modalContent__3TxxL {
  position: relative;

  min-width: 350px;
}

/* --------------------------------- HEADER --------------------------------- */

.ModalContent_header__3SVYU {
  padding-bottom: 14px;
  border-bottom: 1px solid #eae8e4;
}

/* --------------------------------- CONTENT -------------------------------- */

.ModalContent_content__2Twm5 {
  padding: 20px 0;
}
.ModalContent_content__2Twm5 > *:not(:last-child) {
  margin-bottom: 8px;
}

/* --------------------------------- BUTTONS -------------------------------- */

.ModalContent_buttons__15hZ5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.ModalContent_buttons__15hZ5 > *:not(:last-child) {
  margin-right: 20px;
}

/* --------------------------------- THEMES --------------------------------- */

.ModalContent_theme-contained__UpPk_ .ModalContent_content__2Twm5 {
  padding: 20px 20px;
}

.ModalContent_theme-contained__UpPk_ .ModalContent_header__3SVYU {
  padding: 20px 20px;
  border-bottom: 1px solid #dddbd7;
}

.ModalContent_theme-contained__UpPk_ .ModalContent_buttons__15hZ5 {
  padding: 16px 20px;
  background: #fbfaf9;
  border-top: 1px solid #dddbd7;
}

.SortingModeSelect_sortingModeSelect__3U374 {
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #807e7c;
}

/* ------------------------------ VALUE PREVIEW ----------------------------- */

.SortingModeSelect_sortingModeSelectPreview__3GMKk {
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  -webkit-user-select: none;

          user-select: none;
}

.SortingModeSelect_label__3j3md {
  margin-right: 5px;
}

.SortingModeSelect_currentOptionLabel__1EwmZ {
  text-align: left;

  flex-grow: 1;
  flex-shrink: 1;

  font-size: 13px;
  font-weight: 600;
  color: #302f2e;

  margin-right: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-font-feature-settings: "tnum";

          font-feature-settings: "tnum";

  font-variant-numeric: tabular-nums;
}

.SortingModeSelect_dropdownIcon__10PgN {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

/* ----------------------------- OPTIONS WRAPPER ---------------------------- */

.SortingModeSelect_sortingOptions__3KAzF {
  -webkit-user-select: none;
          user-select: none;

  padding: 15px 0;
}
.SortingModeSelect_sortingOptions__3KAzF > *:not(:last-child) {
  margin-bottom: 10px;
}

.SortingModeSelect_optionsGroup__1PySL {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.SortingModeSelect_optionsGroupHeader__333P6 {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;

  color: #807e7c;

  padding: 3px 15px;
}

/* --------------------------------- OPTION --------------------------------- */

.SortingModeSelect_option__1I3LI {
  display: flex;
  align-items: center;

  width: 100%;

  font-size: 13px;
  line-height: 16px;
  font-weight: 500;

  color: #eae8e4;
  background-color: transparent;

  padding: 3px 15px;
  margin: 0;
  outline: 0;

  border: none;
}
.SortingModeSelect_optionLabel__341FX {
  white-space: nowrap;
  margin-right: 20px;
}
.SortingModeSelect_optionCheck__2j1B8 {
  margin-left: auto;
  width: 10px;
}

.SortingModeSelect_option__1I3LI:not(.SortingModeSelect_enabled__FnZNZ) {
  cursor: pointer;
}
.SortingModeSelect_option__1I3LI:not(.SortingModeSelect_enabled__FnZNZ):hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.SortingModeSelect_option__1I3LI:not(.SortingModeSelect_enabled__FnZNZ):active {
  background-color: rgba(255, 255, 255, 0.2);
}

.Beta_beta__2ALYK {
  display: inline-block;

  font-size: 9px;
  line-height: 15px;

  padding: 0px 4px;

  background-color: #b4b2af;
  color: white;

  border-radius: 4px;
}

.MediaGrid_mediaGridWrapper__1SGtb {
  box-sizing: border-box;
  width: 100%;
}

.MediaGrid_mediaGrid__2eETH {
  box-sizing: border-box;

  margin: auto;

  display: grid;
}

.MediaGrid_mediaGridItem__GasW7 {
  box-sizing: border-box;
  min-width: 0;
}

/* -------------------------------- NAVIGATOR ------------------------------- */

.NestedPropertySelect_navigator__3w92H {
  overflow: hidden;

  border: 1px solid #b4b2af;
  border-radius: 4px;
}

/* Group */
.NestedPropertySelect_navigatorGroup__2HP7K {
  display: flex;
  flex-direction: column;
}

/* Group header */
.NestedPropertySelect_navigatorGroupHeader__WI_qz {
  cursor: pointer;

  overflow: hidden;

  box-sizing: border-box;

  display: flex;
  align-items: center;

  padding: 0 15px;
  height: 45px;

  border-bottom: 1px solid #b4b2af;
}

.NestedPropertySelect_navigatorGroupHeader__WI_qz > .NestedPropertySelect_goBack__3gqOM {
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  align-items: center;

  padding-right: 15px;
  padding-left: 4px;
  margin-right: 10px;

  height: 20px;

  border-right: 1px solid #eae8e4;
}
.NestedPropertySelect_navigatorGroupHeader__WI_qz > .NestedPropertySelect_goBack__3gqOM:hover {
  opacity: 0.6;
}

/* Group Content */
.NestedPropertySelect_navigatorGroupContent__2Zz73 {
  display: flex;
  flex-direction: column;
}

/* ------------------------------- BREADCRUMBS ------------------------------ */

/* Group Content */
.NestedPropertySelect_breadcrumbs__24t7C {
  -webkit-user-select: none;
          user-select: none;

  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;

  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: row;

  height: 28px;
}

/* ---------------------------------- INPUT --------------------------------- */

.NestedPropertySelect_input__Aqqd9 {
  display: flex;
}

.NestedPropertySelect_input__Aqqd9 > * {
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;

  height: 28px;

  cursor: pointer;
  padding-left: 15px;
  padding-right: 10px;
}

.NestedPropertySelect_placeholderText__sFTOe {
  color: #807e7c;
}
.NestedPropertySelect_errorText__2IlNT {
  color: #807e7c;
}

/* -------------------------------- CONTAINER ------------------------------- */

.Breadcrumbs_breadcrumbsContainer__2LNXq {
  min-height: 16px;
  background-color: #fff;
}

/* Additional inner container is added so that
absolute positioned padding and content padding can be set with same style */
.Breadcrumbs_breadcrumbsContainerInner__Ipcox {
  box-sizing: border-box;

  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;
  overflow: hidden;

  position: relative;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;

  height: 100%;
}
.Breadcrumbs_breadcrumbsContainerInner__Ipcox > * {
  flex-shrink: 0;
  flex-grow: 0;
}

/* ---------------------------------- ITEM ---------------------------------- */

.Breadcrumbs_breadcrumbsItem__148f5 {
  flex-shrink: 1;
  flex-grow: 0;
  min-width: 0;

  height: 100%;

  display: flex;
  align-items: center;
}
.Breadcrumbs_breadcrumbsItem__148f5 > *:not(:last-child) {
  margin-right: 10px;
}
.Breadcrumbs_breadcrumbsItem__148f5.Breadcrumbs_clickable__3mYcg {
  cursor: pointer;
}
.Breadcrumbs_breadcrumbsItem__148f5.Breadcrumbs_clickable__3mYcg:hover {
  opacity: 0.6;
}

.Breadcrumbs_breadcrumbsItem__148f5 > .Breadcrumbs_icon__2pqW7 {
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  align-items: center;
}

/* ------------------------------ TEXT IN ITEM ------------------------------ */

.Breadcrumbs_breadcrumbsText__26EEK {
  flex-shrink: 0;
  flex-grow: 0;
  white-space: nowrap;

  font-weight: 500;

  font-size: 13px;
  line-height: 16px;
  color: #454442;

  /* Needs to be block or inline-block or ellipsis wont work */
  display: block;
}
.Breadcrumbs_breadcrumbsText__26EEK > *:not(:last-child) {
  margin-right: 0.75ch;
}
.Breadcrumbs_breadcrumbsText__26EEK.Breadcrumbs_shrinkable__15Yld {
  flex-shrink: 1;
  min-width: 0;

  overflow: hidden;
  text-overflow: ellipsis;
}
.Breadcrumbs_breadcrumbsText__26EEK strong {
  font-weight: 600;
}

/* ---------------------------------- SLASH --------------------------------- */

.Breadcrumbs_breadcrumbsSlash__3Z0Dp {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
}
.Breadcrumbs_breadcrumbsSlash__3Z0Dp::after {
  content: '';
  display: block;

  width: 1px;
  background-color: #b4b2af;
  height: 16px;

  -webkit-transform: rotate(20deg);

          transform: rotate(20deg);
}

/* ----------------------- FLOATING SUFFIX AND FADEOUT ---------------------- */

.Breadcrumbs_breadcrumbsFloatingSuffix__1ImMs {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  right: 0;
  height: 100%;

  font-size: 13px;
  line-height: 16px;
  color: #302f2e;
  background-color: #fff;
}
.Breadcrumbs_breadcrumbsFloatingSuffix__1ImMs:not(:empty) {
  padding-left: 10px;
}
.Breadcrumbs_breadcrumbsFloatingSuffix__1ImMs > *:not(:last-child) {
  margin-right: 10px;
}
.Breadcrumbs_breadcrumbsFloatingSuffix__1ImMs::before {
  content: '';
  display: block;
  position: absolute;

  top: 0;
  height: 100%;

  left: -50px;
  width: 50px;

  /* NOTE: safari seems to not support 'transparent' keyword */
  background: linear-gradient(to right, hsla(0, 0%, 100%, 0), hsl(0, 0%, 100%));
}

.ErrorFallback_errorFallback__1VMlP.ErrorFallback_positionAbsolute__2StUR {
  position: absolute;

  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  margin: 12px;
}

/* --------------------------------- THEMES --------------------------------- */

.ErrorFallback_errorFallback__1VMlP.ErrorFallback_theme-block__2KGFm {
  box-sizing: border-box;
  white-space: pre-line;

  padding: 8px 12px;

  line-height: 15px;
  font-size: 12px;

  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: #f05252;
  color: #fff;

  border-radius: 4px;
}

/* --------------------------------- THEMES --------------------------------- */

.ErrorFallback_errorFallback__1VMlP.ErrorFallback_theme-text__RVEfg {
  box-sizing: border-box;
  white-space: pre-line;

  line-height: 15px;
  font-size: 12px;

  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #f05252;

  font-weight: 600;
}

.InlineBadge_inlineBadge__3Kl-Z {
  -webkit-user-select: none;
          user-select: none;

  display: inline-block;

  font-size: 10px;
  line-height: 15px;
  font-weight: 600;
  text-align: center;

  padding: 1px 6px;

  background-color: black;
  color: white;

  border-radius: 2px;
}
.InlineBadge_inlineBadge__3Kl-Z.InlineBadge_hasTooltip__31Yt5 {
  cursor: help;
}

.InlineBadge_tooltipContent__3KT_9 {
  max-width: 220px;
  font-size: 13px;
  line-height: 16px;

  text-align: center;
}

/* ------------------------------ OUTLINED MODE ----------------------------- */

.InlineBadge_inlineBadge__3Kl-Z.InlineBadge_outlined__2B3T3 {
  border: 1px solid transparent;
}

/* --------------------------------- THEMES --------------------------------- */

.InlineBadge_inlineBadge__3Kl-Z.InlineBadge_theme-default__yw-7h {
  background-color: #5d5d5c;
}
.InlineBadge_inlineBadge__3Kl-Z.InlineBadge_theme-light__1eI05 {
  background-color: #b4b2af;
}
.InlineBadge_inlineBadge__3Kl-Z.InlineBadge_theme-green__1m5m- {
  font-weight: 500;
  color: #0f9f6e;
  background-color: hsla(160, 83%, 34%, 0.15);
  border-color: hsla(160, 83%, 34%, 0.4);
}
.InlineBadge_inlineBadge__3Kl-Z.InlineBadge_theme-red__1lCfO {
  font-weight: 500;
  color: #f05252;
  background-color: hsla(0, 84%, 63%, 0.15);
  border-color: hsla(0, 84%, 63%, 0.4);
}
.InlineBadge_inlineBadge__3Kl-Z.InlineBadge_theme-purple__3-lzV {
  font-weight: 500;
  color: #9061f9;
  background-color: hsla(259, 93%, 68%, 0.15);
  border-color: hsla(259, 93%, 68%, 0.15);
}
.InlineBadge_inlineBadge__3Kl-Z.InlineBadge_theme-blue__3dy8j {
  font-weight: 500;
  color: #2675d1;
  background-color: hsla(212, 69%, 48%, 0.15);
  border-color: hsla(212, 69%, 48%, 0.15);
}
.InlineBadge_inlineBadge__3Kl-Z.InlineBadge_theme-yellow__2-fbU {
  font-weight: 500;
  color: #bfa13e;
  background-color: hsla(40, 95%, 67%, 0.15);
  border-color: hsla(40, 69%, 60%, 0.15);
}
.InlineBadge_inlineBadge__3Kl-Z.InlineBadge_theme-orange__2qVPM {
  font-weight: 500;
  color: #ff873d;
  background-color: hsla(23, 100%, 62%, 0.15);
  border-color: hsla(23, 100%, 62%, 0.15);
}

/* -------------------------------------------------------------------------- */
/*                               LOADER TOOLTIP                               */
/* -------------------------------------------------------------------------- */

.IntroTour_loaderTooltip__11S-1 {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
  padding: 20px 40px;

  border-radius: 4px;
}

/* -------------------------------------------------------------------------- */
/*                                MAIN TOOLTIP                                */
/* -------------------------------------------------------------------------- */

/* -------------------------------- CONTAINER ------------------------------- */

.IntroTour_tooltip__1ZAy3 {
  display: flex;
  flex-direction: column;

  font: inherit;

  background-color: white;
  min-width: 300px;
  max-width: 400px;

  border-radius: 4px;
  overflow: hidden;
}

/* --------------------------------- CONTENT -------------------------------- */

.IntroTour_tooltipContent__1iLrt {
  display: flex;
  flex-direction: column;

  color: #454442;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  line-height: 1.5;

  padding: 24px;
}
.IntroTour_tooltipContent__1iLrt > *:not(:last-child) {
  margin-bottom: 14px;
}

.IntroTour_tooltipContentTitle__3MW9z {
  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: 600;
  line-height: 1.3em;

  color: #454442;
}
.IntroTour_tooltipContentTitle__3MW9z > *:not(:last-child) {
  margin-right: 0.75ch;
}

.IntroTour_tooltipContentTitle__3MW9z.IntroTour_size-large__2pEYq {
  font-size: 22px;
}

.IntroTour_tooltipContentInner__2uYth {
  text-align: left;
}
.IntroTour_tooltipContentInner__2uYth *[data-component='icon'] {
  display: inline-block;
}

.IntroTour_tooltipContentInner__2uYth > *:not(:last-child) {
  margin-bottom: 14px;
}

.IntroTour_tooltipContentInner__2uYth img {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;

  border: 1px solid #dddbd7;

  border-radius: 4px;
}

.IntroTour_tooltipContentInner__2uYth ul {
  list-style-type: circle;
}

.IntroTour_tooltipContentInner__2uYth small {
  font: inherit;
  font-size: 13px;
  font-weight: 400;

  opacity: 0.5;
}

.IntroTour_tooltipContentInner__2uYth dfn {
  font: inherit;
  font-weight: 600;

  padding: 0px 2px;
  margin: 0px 2px;
  background-color: #f6f5f4;
  border-radius: 2px;

  outline: 1px solid #eae8e4;
}

/* --------------------------------- BUTTONS -------------------------------- */

.IntroTour_tooltipButtons__Gmvvy {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin: 12px;
  margin-top: 0;
  padding-top: 12px;

  border-top: 1px solid #eae8e4;
}
.IntroTour_tooltipButtons__Gmvvy > *:not(:last-child) {
  margin-right: 10px;
}

/*
    Styles for popups that are related to how popups looks around 
    another popups and on the screen and not to popups thyself.
*/

/* -------------------------------- SNACKBARS ------------------------------- */

/* Margins and offset */
.ContainerStyles_snackbarContainer__2E_oT {
  -webkit-transform: translate(-16px, -16px);
          transform: translate(-16px, -16px);
}
.ContainerStyles_snackbarContainer__2E_oT > *:not(:first-child) {
  margin-top: 16px;
}

/* -------------------------------------------------------------------------- */
/*                                 ANIMATIONS                                 */
/* -------------------------------------------------------------------------- */

@-webkit-keyframes SnackbarTemplate_bounceInRight__1XJdE {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translateX(3000px) scaleX(3);
            transform: translateX(3000px) scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-12px) scaleX(1);
            transform: translateX(-12px) scaleX(1);
  }

  75% {
    -webkit-transform: translateX(5px) scaleX(0.98);
            transform: translateX(5px) scaleX(0.98);
  }

  90% {
    -webkit-transform: translateX(-2px) scaleX(0.995);
            transform: translateX(-2px) scaleX(0.995);
  }

  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes SnackbarTemplate_bounceInRight__1XJdE {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translateX(3000px) scaleX(3);
            transform: translateX(3000px) scaleX(3);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-12px) scaleX(1);
            transform: translateX(-12px) scaleX(1);
  }

  75% {
    -webkit-transform: translateX(5px) scaleX(0.98);
            transform: translateX(5px) scaleX(0.98);
  }

  90% {
    -webkit-transform: translateX(-2px) scaleX(0.995);
            transform: translateX(-2px) scaleX(0.995);
  }

  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/* -------------------------------------------------------------------------- */
/*                           COUNTDOWN PROGRESS BAR                           */
/* -------------------------------------------------------------------------- */

.SnackbarTemplate_countdownProgressBar__2mPql {
  box-sizing: border-box;
  overflow: hidden;

  height: 4px;
  width: 100%;

  color: #807e7c;
  background-color: white;

  /*
  border-top: 1px solid #eae8e4;
  */
}
.SnackbarTemplate_countdownProgressBarLane__1-_0Y {
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  color: inherit;
  background-color: currentColor;
}

/* -------------------------------------------------------------------------- */
/*                                 CONTAINERS                                 */
/* -------------------------------------------------------------------------- */

/* Container managed by react-alert with injected style */
.SnackbarTemplate_snackbarOuterContainer__3bmsj {
  -webkit-user-select: none;
          user-select: none;
}

/* Container with additional enter animation */
.SnackbarTemplate_snackbarContainer__3vFh2 {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  width: 400px;

  -webkit-animation-name: SnackbarTemplate_bounceInRight__1XJdE;

          animation-name: SnackbarTemplate_bounceInRight__1XJdE;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;

  border-radius: 4px;

  box-shadow: 0 0px 3px rgba(48, 47, 46, 0.3), 0 4px 4px rgba(48, 47, 46, 0.15), 0 8px 8px rgba(48, 47, 46, 0.15);
}

/* -------------------------------------------------------------------------- */
/*                                   CONTENT                                  */
/* -------------------------------------------------------------------------- */

/* Content container */
.SnackbarTemplate_contentContainer__9_7tT {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  min-height: 60px;

  width: 100%;
  min-width: 0;
  padding: 18px 12px;
  padding-right: 24px;
  padding-bottom: 14px;

  color: #282f2e;
  background-color: #ffffff;
}

/* Close button */
.SnackbarTemplate_closeButton__EK2mV {
  position: absolute;
  top: 0px;
  right: 5px;

  padding: 3px;
  margin: 0;

  cursor: pointer;

  font-size: 20px;
  line-height: 1em;
  font-weight: 500;
  -webkit-transform: scaleY(0.7);
          transform: scaleY(0.7);

  color: #b4b2af;
  background: none;

  outline: none;
  border: none;
}
.SnackbarTemplate_closeButton__EK2mV:hover {
  color: #454442;
}

/* Content */
.SnackbarTemplate_content__1YE_T {
  display: flex;
  align-items: center;

  min-width: 0;
  width: 100%;
}

.SnackbarTemplate_content__1YE_T > .SnackbarTemplate_icon__2Fu8r {
  flex-grow: 0;
  flex-shrink: 0;

  margin-right: 18px;

  -webkit-transform: scale(1.3) translateX(2px);

          transform: scale(1.3) translateX(2px);
}
.SnackbarTemplate_message__38qrM {
  display: flex;
  flex-direction: column;

  flex-shrink: 1;
  flex-grow: 1;

  min-width: 0;
}
.SnackbarTemplate_message__38qrM > *:not(:last-child) {
  margin-bottom: 5px;
}

.SnackbarTemplate_message__38qrM p {
  font-size: 13px;
  font-weight: 600;

  line-height: 1.3;
  white-space: pre-wrap;
}

.SnackbarTemplate_message__38qrM small {
  color: #b4b2af;
  font-weight: 600;
}

/* -------------------------------------------------------------------------- */
/*                                   THEMES                                   */
/* -------------------------------------------------------------------------- */

/* Success */
.SnackbarTemplate_snackbarContainer__3vFh2.SnackbarTemplate_type-success__1aGVl .SnackbarTemplate_countdownProgressBar__2mPql,
.SnackbarTemplate_snackbarContainer__3vFh2.SnackbarTemplate_type-success__1aGVl .SnackbarTemplate_icon__2Fu8r {
  color: #0f9f6e;
}

/* Info */
.SnackbarTemplate_snackbarContainer__3vFh2.SnackbarTemplate_type-info__r26Xe .SnackbarTemplate_countdownProgressBar__2mPql,
.SnackbarTemplate_snackbarContainer__3vFh2.SnackbarTemplate_type-info__r26Xe .SnackbarTemplate_icon__2Fu8r {
  color: #2675d1;
}

/* Error */
.SnackbarTemplate_snackbarContainer__3vFh2.SnackbarTemplate_type-error__1bG2_ .SnackbarTemplate_countdownProgressBar__2mPql,
.SnackbarTemplate_snackbarContainer__3vFh2.SnackbarTemplate_type-error__1bG2_ .SnackbarTemplate_icon__2Fu8r {
  color: #f05252;
}

/* Warning */
.SnackbarTemplate_snackbarContainer__3vFh2.SnackbarTemplate_type-warning__2YH_b .SnackbarTemplate_countdownProgressBar__2mPql,
.SnackbarTemplate_snackbarContainer__3vFh2.SnackbarTemplate_type-warning__2YH_b .SnackbarTemplate_icon__2Fu8r {
  color: #fbc75d;
}

/* -------------------------------- CONTAINER ------------------------------- */

.OtpInput_otpInput__2yYbX {
  overflow: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  background-color: #ffffff;
  border: 1px solid #dddbd7;
  border-radius: 4px;

  box-shadow: 0px 0px 0px 1px transparent, 0 1px 1px 0 rgba(48, 47, 46, 0.07);

  transition: box-shadow 0.2s ease-in-out, border 0.1s ease-in-out, background 0.2s ease-in-out;
}

/* Conditional states */
.OtpInput_otpInput__2yYbX:hover {
  border-color: #b4b2af;
}
.OtpInput_otpInput__2yYbX:focus-within {
  box-shadow: 0px 0px 0px 3px #eae8e4, 0 1px 1px 0 rgba(48, 47, 46, 0.07);
  border-color: #b4b2af;
}

.OtpInput_otpInput__2yYbX.OtpInput_error__1t1Wz {
  border-color: #d43d51;
}
.OtpInput_otpInput__2yYbX:focus-within.OtpInput_error__1t1Wz {
  border-color: #d43d51;
  box-shadow: 0px 0px 0px 3px rgba(212, 61, 81, 0.3), 0 1px 1px 0 rgba(48, 47, 46, 0.07);
}

.OtpInput_otpInput__2yYbX.OtpInput_disabled__1pW45 {
  cursor: not-allowed;
  pointer-events: none;
  background-image: linear-gradient(rgba(47, 46, 45, 0.04), rgba(47, 46, 45, 0.04));
}

/* --------------------------- RENDERER CONTAINER --------------------------- */

.OtpInput_reactCodeInput__13aRW,
.OtpInput_placeholder__3X6Ve {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 180px !important;
  height: 40px !important;
}

.OtpInput_placeholder__3X6Ve {
  background-color: #fbfaf9;
}

/* --------------------------------- NUMBERS -------------------------------- */

.OtpInput_reactCodeInput__13aRW input {
  box-sizing: border-box;

  font-family: 'Inter', sans-serif !important;
  color: #656565 !important;

  caret-color: #656565 !important;

  border: none !important;
}
.OtpInput_reactCodeInput__13aRW input:first-child {
  width: 40px !important;
  padding-left: 15px !important;
}
.OtpInput_reactCodeInput__13aRW input:last-child {
  width: 40px !important;
  padding-right: 15px !important;
}

.OtpInput_reactCodeInput__13aRW input:focus {
  color: #656565;
  caret-color: #656565 !important;
}

.OtpInput_reactCodeInput__13aRW input::-webkit-input-placeholder {
  color: #e0e0e1;
}

.OtpInput_reactCodeInput__13aRW input::placeholder {
  color: #e0e0e1;
}

/* ------------------------------- ANIMATIONS ------------------------------- */

.OtpInput_reactCodeInput__13aRW,
.OtpInput_reactCodeInput__13aRW * {
  -webkit-animation: none !important;
          animation: none !important;
  transition: none !important;
}

/* --------------------------------- CONTENT -------------------------------- */

.Reminder_reminder__3gA1w {
  box-sizing: border-box;

  display: flex;
  align-items: stretch;

  width: 100%;
  padding: 15px 15px;

  background-color: #ffffff;

  border: 1px solid #dddbd7;
  border-radius: 4px;
}

.Reminder_icon__l3mId {
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 16px;
  width: 16px;

  margin-right: 12px;
}
.Reminder_reminder__3gA1w.Reminder_horizontal__2e0ds > .Reminder_icon__l3mId {
  height: unset;
}

.Reminder_content__2kx1U {
  flex-grow: 1;
  flex-shrink: 1;

  display: flex;
  flex-direction: column;
}
.Reminder_reminder__3gA1w.Reminder_horizontal__2e0ds > .Reminder_content__2kx1U {
  flex-direction: row;
  align-items: center;
}
.Reminder_content__2kx1U > *:not(:last-child) {
  margin-bottom: 16px;
}
.Reminder_reminder__3gA1w.Reminder_horizontal__2e0ds > .Reminder_content__2kx1U > *:not(:last-child) {
  margin-bottom: 0px;
  margin-right: 16px;
}

.Reminder_textContent__3He1L {
  flex-grow: 1;
  flex-shrink: 1;

  font-size: 13px;
  line-height: 16px;
  text-align: left;

  color: #807e7c;
}
.Reminder_textContent__3He1L > *:not(:last-child) {
  margin-bottom: 6px;
}

.Reminder_header__25DuZ {
  color: #807e7c;
  font-weight: 700;
}

.Reminder_suffix__ua0mH {
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
}
.Reminder_suffix__ua0mH > *:not(:last-child) {
  margin-right: 10px;
}

/* --------------------------------- THEMES --------------------------------- */

.Reminder_reminder__3gA1w.Reminder_theme-error__3dl28 {
  border-color: #f05252;
}

.JSONTreeModal_jsonTreeModal__3zIc6 {
  /* ... */
}

.GoogleFontSelect_googleFontPicker__1ukXl {
  /* ... */
}

.GoogleFontSelect_placeholder__184JE,
.GoogleFontSelect_error__2z5hh {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.GoogleFontSelect_placeholder__184JE > *:not(:last-child),
.GoogleFontSelect_error__2z5hh > *:not(:last-child) {
  margin-right: 10px;
}

.GoogleFontSelect_error__2z5hh {
  color: #f05252;
}

.InlineHint_inlineHint__1jMWe {
  box-sizing: border-box;
  -webkit-user-select: none;
          user-select: none;

  text-align: left;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.InlineHint_inlineHint__1jMWe > *:not(:last-child) {
  margin-bottom: 8px;
}

.InlineHint_inlineHint__1jMWe .InlineHint_hint__2cqCR {
  color: #807e7c;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.InlineHint_inlineHint__1jMWe .InlineHint_hint__2cqCR svg {
  margin: 0 8px 0 0;
}

.InlineHint_inlineHint__1jMWe .InlineHint_hint__2cqCR svg * {
  fill: #dbdad8;
}

.InlineHint_inlineHint__1jMWe .InlineHint_error__1UtGP {
  color: #f05252;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.InlineHint_inlineHint__1jMWe .InlineHint_error__1UtGP svg {
  margin: 0 8px 0 0;
}

html,#root{height:100%}#root{min-height:100vh}body{min-height:100%;background:#ffffff;width:100%;margin:0;padding:0;font-family:'Inter', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-size:14px;overscroll-behavior:none;overflow:hidden}input{border:none;background:none}input:focus{outline:0}select{border:none;background:none}select:focus{outline:0}textarea{border:none;background:none}textarea:focus{outline:0}button:focus{outline:0}a{text-decoration:none}p{-webkit-margin-before:0;margin-block-start:0;-webkit-margin-after:0;margin-block-end:0}.grecaptcha-badge{visibility:hidden}::-webkit-scrollbar{width:14px;height:14px;border-left:1px solid rgba(0,0,0,0.1);border-top:1px solid rgba(0,0,0,0.1);box-sizing:border-box;background:transparent}::-webkit-scrollbar-thumb{border-left:3px solid rgba(0,0,0,0);border-right:3px solid rgba(0,0,0,0);border-top:3px solid rgba(0,0,0,0);border-bottom:3px solid rgba(0,0,0,0);background-clip:padding-box;border-radius:6px;background-color:rgba(0,0,0,0.2)}::-webkit-scrollbar-thumb:hover{background-color:rgba(0,0,0,0.3)}::-webkit-scrollbar-corner{background:transparent}.developmentt-mode .full-story-no-record{outline:1px solid pink;outline-style:dashed;outline-offset:-1px;background-color:#ffc0cb1e}.developmentt-mode .full-story-no-record-hard{outline:1px solid #c0c2ff;outline-style:dashed;outline-offset:-1px;background-color:#c0d7ff1e}

.LocalLink_localLink__1A8n- {
  font-weight: 600;
}

.SubmissionFieldPreview_submissionFieldPreview__2CMfG.SubmissionFieldPreview_blurred__1iigl {
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}
.SubmissionFieldPreview_submissionFieldPreview__2CMfG.SubmissionFieldPreview_blurred__1iigl.SubmissionFieldPreview_theme-for-table__1zgtb {
  background-color: #f6f5f4;
}

.SubmissionFieldPreview_submissionFieldPreview__2CMfG.SubmissionFieldPreview_blurred__1iigl > * {
  pointer-events: none;
  opacity: 0.5;
  -webkit-filter: blur(3px) grayscale(100%);
          filter: blur(3px) grayscale(100%);
}

/* --------------------------------- THEMES --------------------------------- */

.ContentPreview_contentPreview__3O712.ContentPreview_theme-for-table__1nQ3t {
  /* Content wrapper will cover entire cell (assuming it's padding is 12px 16px) */
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  margin: -12px -16px;
  padding: 12px 16px;
}

.FilesUploadPreview_uploadsList__32J9e {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.FilesUploadPreview_uploadPreviewSuccessful__29bmR,
.FilesUploadPreview_uploadPreviewFailed__3Bzqe {
  white-space: pre;
  line-height: 1.5;

  display: flex;
  align-items: center;
}

.FilesUploadPreview_uploadPreviewSuccessful__29bmR {
  cursor: pointer;
  color: #2675d1;
  text-decoration: underline;
}
.FilesUploadPreview_downloadIcon__3Z_yj {
  width: 14px;
  margin-right: 5px;
}

.FilesUploadPreview_uploadPreviewFailed__3Bzqe {
  cursor: not-allowed;
  color: #b4b2af;
  -webkit-user-select: none;
          user-select: none;
}

/* --------------------------------- THEMES --------------------------------- */

.FilesUploadPreview_filesUploadPreview___PEc7.FilesUploadPreview_theme-for-table__1Rosg {
  /* with this list cells are more visually aligned with regular text cells */
  margin: -4px;
}

.EmptyPreview_emptyPreview__2KoHw {
  opacity: 0.2;
  white-space: nowrap;
  -webkit-user-select: none;
          user-select: none;
}
.EmptyPreview_emphasized__t005M {
  letter-spacing: 0.05em;
}

.PaymentPreview_paymentPreview__13Alm.PaymentPreview_theme-for-rows__3ffaO {
  padding: 10px 0;
}

.LabeledListPreview_table__1aTHf {
  border-collapse: collapse;
  border: none;
}

.LabeledListPreview_table__1aTHf td {
  vertical-align: middle;
}

.LabeledListPreview_table__1aTHf tr:not(:last-child) td {
  padding-bottom: 0.5ch;
}

.LabeledListPreview_table__1aTHf .LabeledListPreview_bullet__1NG-N {
  padding-right: 0.5ch;
}

.LabeledListPreview_table__1aTHf .LabeledListPreview_label__jUGv1 {
  white-space: nowrap;
  padding-right: 1ch;
}
.LabeledListPreview_label__jUGv1::after {
  content: ' :';
}

.ListPreview_list__1z_4y {
  line-height: 1.5;
}
.ListPreview_list__1z_4y {
  list-style-position: inside;

  margin: 0;
  padding: 0;
}

/* --------------------------------- THEMES --------------------------------- */

.ListPreview_listPreview__9cNkd.ListPreview_theme-for-table__2j85Q {
  /* with this list cells are more visually aligned with regular text cells */
  margin: -4px;
}

.TitlePreview_titlePreview__3GyKP {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.TitlePreview_titlePreview__3GyKP > *:not(:last-child) {
  margin-bottom: 5px;
}

.TitlePreview_label__1b3wa,
.TitlePreview_description__3izw1 {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

/* --------------------------------- THEMES --------------------------------- */

.TitlePreview_titlePreview__3GyKP.TitlePreview_titlePreview__3GyKP.TitlePreview_theme-for-table__27NSU > * {
  white-space: nowrap;
}

.TitlePreview_titlePreview__3GyKP.TitlePreview_theme-for-table__27NSU > .TitlePreview_description__3izw1 {
  color: #807e7c;
}

.TitlePreview_titlePreview__3GyKP.TitlePreview_theme-for-rows__1RrBq {
  max-width: 100%;
}
.TitlePreview_titlePreview__3GyKP.TitlePreview_titlePreview__3GyKP.TitlePreview_theme-for-rows__1RrBq > * {
  word-break: break-all;
}

.BooleanPreview_booleanPreview__36yLm {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.BooleanPreview_booleanPreview__36yLm > *:not(:last-child) {
  margin-right: 6px;
}

.DurationPreview_timePreview__25h6X > * {
  display: flex;
  flex-direction: column;
  white-space: pre;
}
.DurationPreview_seconds__1S36l {
  opacity: 0.5;
  margin-top: 6px;
}

.UserAgentPreview_userAgentPreview__2EVsu {
  white-space: pre;
}

/* --------------------------------- THEMES --------------------------------- */

.UserAgentPreview_userAgentPreview__2EVsu.UserAgentPreview_theme-for-table__3Eawo {
  font-size: 11px;
}
.UserAgentPreview_userAgentPreview__2EVsu.UserAgentPreview_theme-for-rows__1FmTa {
  max-width: 100%;
  word-break: break-all;
}

.TimestampPreview_timestampPreview__1V5NB {
  display: flex;

  white-space: pre;
}

/* --------------------------------- THEMES --------------------------------- */

.TimestampPreview_timestampPreview__1V5NB.TimestampPreview_theme-for-table__YHBF1 {
  flex-direction: column;
}
.TimestampPreview_timestampPreview__1V5NB.TimestampPreview_theme-for-table__YHBF1 > .TimestampPreview_distance__1W7sv {
  margin-top: 6px;
  opacity: 0.5;
}

.TimestampPreview_timestampPreview__1V5NB.TimestampPreview_theme-for-rows__33rh5 > * {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
}
.TimestampPreview_timestampPreview__1V5NB.TimestampPreview_theme-for-rows__33rh5 > .TimestampPreview_distance__1W7sv {
  margin-left: 6px;
}
.TimestampPreview_timestampPreview__1V5NB.TimestampPreview_theme-for-rows__33rh5 > .TimestampPreview_distance__1W7sv::before {
  content: '(';
}
.TimestampPreview_timestampPreview__1V5NB.TimestampPreview_theme-for-rows__33rh5 > .TimestampPreview_distance__1W7sv::after {
  content: ')';
}

/* -------------------------------------------------------------------------- */
/*                                    BASE                                    */
/* -------------------------------------------------------------------------- */

.TextPreview_textPreview__2qtk3 {
  display: flex;
  justify-content: space-between;

  height: 100%;
}

/* -------------------------------------------------------------------------- */
/*                             ADDITIONAL FEATURES                            */
/* -------------------------------------------------------------------------- */

.TextPreview_intentPredictionGroup__23DaQ {
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  align-items: stretch;

  -webkit-user-select: none;

          user-select: none;

  border-left: 1px solid #f6f5f4;

  -webkit-filter: hue-rotate(-25deg);

          filter: hue-rotate(-25deg);
}

.TextPreview_intentPredictionGroup__23DaQ > *:not(:last-child) {
  margin-right: 10px;
}

/* -------------------------------- SENTIMENT ------------------------------- */

.TextPreview_sentiment__3so2x {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: 25px;
}

.TextPreview_sentiment__3so2x > * {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

/* -------------------------------- EMOTIONS -------------------------------- */

.TextPreview_emotions__XxRn_ {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 7px;
  gap: 7px;
}

/* Prevents unnecessary gaps for cases with less than 3 emotions */
.TextPreview_emotions__XxRn_.TextPreview_length-1__2x_3v {
  grid-template-columns: auto;
}
.TextPreview_emotions__XxRn_.TextPreview_length-2__3r5n_ {
  grid-template-columns: auto auto;
}

.TextPreview_emotionProgressCircle__1F-J4 {
  position: relative;

  width: 25px;
  height: 25px;
}

.TextPreview_emotionProgressCircleEmoji__3_nWQ {
  position: absolute;

  top: 50%;
  left: 50%;

  -webkit-transform: translate(-50%, calc(-50% - 0.5px));

          transform: translate(-50%, calc(-50% - 0.5px));
}

/* -------------------------------------------------------------------------- */
/*                                   THEMES                                   */
/* -------------------------------------------------------------------------- */

.TextPreview_textPreview__2qtk3.TextPreview_theme-for-rows__3f_UR .TextPreview_content__2-r0o {
  min-width: 0;
  max-width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.TextPreview_textPreview__2qtk3.TextPreview_theme-for-rows__3f_UR .TextPreview_intentPredictionGroup__23DaQ {
  margin-left: 12px;
  padding-left: 16px;
}
.TextPreview_textPreview__2qtk3.TextPreview_theme-for-rows__3f_UR .TextPreview_intentPredictionGroup__23DaQ > *:not(:last-child) {
  margin-right: 7px;
}

/* Content will cover height of entire cell (assuming it's padding is 12px 16px) */
.TextPreview_textPreview__2qtk3.TextPreview_theme-for-table__ZeAkS .TextPreview_content__2-r0o {
  box-sizing: content-box;
  margin: -12px -16px;
  padding: 12px 16px;
  margin-right: 0;
}

/* Left side bar will cover height of entire cell (assuming it's padding is 12px 16px) */
.TextPreview_textPreview__2qtk3.TextPreview_theme-for-table__ZeAkS .TextPreview_intentPredictionGroup__23DaQ {
  box-sizing: content-box;
  margin: -12px -16px;
  padding: 12px 16px;
  margin-left: 0;
}

.ImagesPreview_imagesPreview__1-IfA {
  display: flex;

  flex-wrap: wrap;
  width: -webkit-max-content;
  width: max-content;
}

.ImagesPreview_image__1tELM {
  position: relative;

  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;

  object-fit: contain;

  border-radius: 4px;

  background-color: white;
  border: 1px solid #cbc9c5;
}

.ImagesPreview_modalImage__1eQEE {
  object-fit: contain;
  max-width: 70vw;
  max-height: 70vh;
}

/* --------------------------------- THEMES --------------------------------- */

.ImagesPreview_imagesPreview__1-IfA.ImagesPreview_theme-for-table__2Q07l {
  /* negative margin used to imitate behavior of flex gap and to better align images in table context */
  margin: -8px -12px;

  max-width: 320px;
}
.ImagesPreview_imagesPreview__1-IfA.ImagesPreview_theme-for-table__2Q07l > .ImagesPreview_image__1tELM {
  margin: 2px;

  max-height: 4ch;
  max-width: 150px;
}

.ImagesPreview_imagesPreview__1-IfA.ImagesPreview_theme-for-rows__1cn40 {
  /* negative margin used to imitate behavior of flex gap */
  margin: -4px;

  max-width: 100%;
}
.ImagesPreview_imagesPreview__1-IfA.ImagesPreview_theme-for-rows__1cn40 > .ImagesPreview_image__1tELM {
  margin: 4px;

  max-height: 8ch;
  max-width: 100%;
}

.MatrixListPreview_list__HgG0u {
  line-height: 1.5;
}
.MatrixListPreview_list__HgG0u {
  list-style-position: inside;

  margin: 0;
  padding: 0;
}

.MatrixListPreview_question__2mUoa {
  opacity: 0.5;
}

/* --------------------------------- THEMES --------------------------------- */

.MatrixListPreview_listPreview__3EVjF.MatrixListPreview_theme-for-table__m6lzy {
  /* with this list cells are more visually aligned with regular text cells */
  margin: -4px;
}


.UrlPreview_urlPreview__2W2xv {
  display: block;

  text-overflow: ellipsis;
  overflow: hidden;
}
.UrlPreview_urlPreview__2W2xv > a {
  color: #2675d1;
  text-decoration: underline;
}
.UrlPreview_urlPreview__2W2xv > a:visited {
  color: #9061f9;
  text-decoration: underline;
}

/* --------------------------------- THEMES --------------------------------- */

.UrlPreview_urlPreview__2W2xv.UrlPreview_theme-for-table__1wnQl {
  white-space: nowrap;
  max-width: 400px;
}
.UrlPreview_urlPreview__2W2xv.UrlPreview_theme-for-rows__fSEUr {
  max-width: 100%;
  word-break: break-all;
}

/* ------------------------------ COMMON STYLES ----------------------------- */

.SubmissionFieldHeader_submissionFieldHeader__1N0qS {
  overflow: hidden;

  background: none;
  border: none;
  margin: 0;
  padding: 0;

  -webkit-user-select: none;

          user-select: none;

  display: flex;
  align-items: center;
}

.SubmissionFieldHeader_submissionFieldHeader__1N0qS > * {
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
}
.SubmissionFieldHeader_submissionFieldHeader__1N0qS > .SubmissionFieldHeader_icon__qb7Od {
  display: flex;
  align-items: flex-start;
  width: 24px;
  overflow: hidden;
}

.SubmissionFieldHeader_dropdownIcon__3yJwg {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-left: 12px;
}

.SubmissionFieldHeader_label__2I0OB {
  overflow: hidden;
  flex-shrink: 1;
  min-width: 0;
  text-overflow: ellipsis;
}

/* --------------------------- WHEN USED IN TABLE --------------------------- */

/* Minimum width and size to fit text */
.SubmissionFieldHeader_theme-for-table__NPoAK {
  width: 100%;
  min-width: 120px;
}
/* Dropdown indicator in right corner */
.SubmissionFieldHeader_theme-for-table__NPoAK .SubmissionFieldHeader_dropdownIcon__3yJwg {
  flex-grow: 1;
}

.SubmissionFieldHeader_theme-for-table__NPoAK .SubmissionFieldHeader_label__2I0OB {
  color: #454442;

  font-weight: 500;
  line-height: 16px;
}

/* --------------------------------- THEMES --------------------------------- */

.SubmissionFieldHeader_submissionFieldHeader__1N0qS.SubmissionFieldHeader_theme-for-table__NPoAK {
  /* Content wrapper will cover entire cell (assuming it's padding is 8px 16px) */
  box-sizing: content-box;
  width: 100%;
  margin: -8px -16px;
  padding: 8px 16px;
}

/* Ellipsis for too long headers */
.SubmissionFieldHeader_submissionFieldHeader__1N0qS.SubmissionFieldHeader_theme-for-rows__3dYkq {
  min-width: 0;
  max-width: 100%;
}

.SubmissionFieldHeader_submissionFieldHeader__1N0qS.SubmissionFieldHeader_theme-for-rows__3dYkq .SubmissionFieldHeader_label__2I0OB {
  color: #807e7c;

  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.QSUpsell_upsellModal__1qETn {
  max-width: 400px;
  padding: 20px 0;

  display: flex;
  flex-direction: column;

  text-align: center;

  font-size: 13px;
  font-weight: 400;
  line-height: 1.5em;
  color: #5d5d5c;
}
.QSUpsell_upsellModal__1qETn > *:not(:last-child) {
  margin-bottom: 20px;
}

.QSUpsell_featureName__H5z0B {
  font-size: 15px;
  font-weight: 500;

  padding: 15px 25px;

  color: white;
}

.QSUpsell_message__FqMqk {
  padding: 0px 25px;
}
.QSUpsell_message__FqMqk > *:not(:last-child) {
  margin-bottom: 7px;
}

.QSUpsell_planName__1Cz7S {
  font-weight: 600;
  color: #282f2e;
}

/* Cancel, confirm buttons */
.QSUpsell_buttons__1bDT2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 0px 20px;
}
.QSUpsell_buttons__1bDT2 > *:not(:last-child) {
  margin-right: 20px;
}

.QSFormLabel_formLabel__2CsXK {
  display: flex;
  align-items: stretch;

  cursor: pointer;
}

.QSFormLabel_formLabel__2CsXK > * {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------------- BREADCRUMBS ------------------------------ */

.QSFormLabel_breadcrumbs__16kPY {
  -webkit-user-select: none;
          user-select: none;

  display: flex;
  align-items: center;

  white-space: nowrap;

  font-size: 13px;
  line-height: 24px;
  font-weight: 500;

  color: #b4b2af;

  margin-right: 6px;
}
.QSFormLabel_breadcrumbs__16kPY > *:not(:last-child) {
  margin-right: 0.7ch;
}

.QSFormLabel_breadcrumbs__16kPY > .QSFormLabel_workspace__1nHDq {
  cursor: pointer;
}
.QSFormLabel_breadcrumbs__16kPY > .QSFormLabel_workspace__1nHDq:hover {
  color: #454442;
}
.QSFormLabel_breadcrumbs__16kPY > .QSFormLabel_slash__3JV_C {
  -webkit-transform: scaleY(1.3) rotateZ(10deg);
          transform: scaleY(1.3) rotateZ(10deg);
}
.QSFormLabel_breadcrumbs__16kPY > .QSFormLabel_form__3ZPzE {
  color: #454442;
}

/* ---------------------------------- OTHER --------------------------------- */

.QSFormLabel_status__1V4br {
  margin-right: 16px;
}

.QSFormLabel_icon__1chR9 > * {
  -webkit-transform: translateX(-4px);
          transform: translateX(-4px);
}

.QSFormStatus_formStatus__3fYcM {
  flex-shrink: 0;
  flex-grow: 0;

  width: -webkit-fit-content;

  width: -moz-fit-content;

  width: fit-content;

  -webkit-user-select: none;

          user-select: none;

  box-sizing: border-box;
  text-transform: uppercase;

  font-size: 10px;

  font-weight: 600;
  line-height: 12px;

  padding: 2px 6px;

  background-color: transparent;
  border: 1px solid currentColor;

  border-radius: 1000px;
}

.QSFormStatus_formStatus__3fYcM.QSFormStatus_status-draft__12TBt {
  color: #807e7c;
}
.QSFormStatus_formStatus__3fYcM.QSFormStatus_status-paused__2h3S2,
.QSFormStatus_formStatus__3fYcM.QSFormStatus_status-disabled__rE7bJ {
  color: #454442;
  background-color: #fbc75d;
  border-color: #fbc75d;
}
.QSFormStatus_formStatus__3fYcM.QSFormStatus_status-live__1yTuO {
  color: #f05252;
}

.QSFormStatus_formStatus__3fYcM.QSFormStatus_theme-white__3UZV6 {
  color: #807e7c;
  background-color: #ffffff;
  border-color: #ffffff;
}

/* ------------------------------ MAIN MESSAGE ------------------------------ */

.QSNotFound_notFound__Ka76b {
  position: absolute;
  box-sizing: border-box;

  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #5d5d5c;

  font-size: 13px;
  text-align: center;
  line-height: 1.5;
}
.QSNotFound_notFound__Ka76b.QSNotFound_inline__eOddK {
  overflow: hidden;

  position: static;
  -webkit-transform: none;
          transform: none;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  padding: 20px;
}

@-webkit-keyframes QSNotFound_fadeIn__1AH6t {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes QSNotFound_fadeIn__1AH6t {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.QSNotFound_logo__1PTJO {
  width: 50px;
  height: 50px;
}

.QSNotFound_icon__3ZmT- {
  position: relative;

  margin-bottom: 15px;

  opacity: 0;
  -webkit-animation: QSNotFound_fadeIn__1AH6t 1.5s forwards;
          animation: QSNotFound_fadeIn__1AH6t 1.5s forwards;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.QSNotFound_header__3s6ZR {
  color: #454442;
  font-weight: 600;

  line-height: 16px;
  margin-bottom: 3px;

  opacity: 0;
  -webkit-animation: QSNotFound_fadeIn__1AH6t 1.5s forwards;
          animation: QSNotFound_fadeIn__1AH6t 1.5s forwards;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.QSNotFound_message__1YBt4 {
  color: #807e7c;
  font-weight: 500;
  line-height: 16px;

  opacity: 0;
  -webkit-animation: QSNotFound_fadeIn__1AH6t 1.5s forwards;
          animation: QSNotFound_fadeIn__1AH6t 1.5s forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.QSNotFound_header__3s6ZR,
.QSNotFound_message__1YBt4 {
  width: 35ch;
  max-width: 100%;
}

/* ---------------------------------- HINT ---------------------------------- */

@-webkit-keyframes QSNotFound_leftToRight__ocYT2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}

@keyframes QSNotFound_leftToRight__ocYT2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
@-webkit-keyframes QSNotFound_rightToRight__gyu8F {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
}
@keyframes QSNotFound_rightToRight__gyu8F {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
}
@-webkit-keyframes QSNotFound_topToBottom__3EH1T {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}
@keyframes QSNotFound_topToBottom__3EH1T {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}
@-webkit-keyframes QSNotFound_bottomToTop__lqPXH {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
}
@keyframes QSNotFound_bottomToTop__lqPXH {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
}

.QSNotFound_hint__7ekYH {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  -webkit-user-select: none;
          user-select: none;

  -webkit-animation-duration: 1s;

          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

.QSNotFound_hintArrow__22Xut * {
  fill: currentColor;
  stroke: currentColor;
}

.QSNotFound_hintArrow__22Xut {
  opacity: 0;
  -webkit-animation: QSNotFound_fadeIn__1AH6t 3s forwards;
          animation: QSNotFound_fadeIn__1AH6t 3s forwards;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.QSNotFound_hintMessage__2vj1z {
  opacity: 0;
  -webkit-animation: QSNotFound_fadeIn__1AH6t 3s forwards;
          animation: QSNotFound_fadeIn__1AH6t 3s forwards;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

/* Hint placement */
.QSNotFound_hint__7ekYH[data-placement^='top'] {
  top: 20px;
}
.QSNotFound_hint__7ekYH[data-placement^='bottom'] {
  bottom: 20px;
}
.QSNotFound_hint__7ekYH[data-placement$='start'] {
  left: 30px;
}
.QSNotFound_hint__7ekYH[data-placement$='end'] {
  right: 30px;
}
.QSNotFound_hint__7ekYH[data-placement$='center'] {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/* Hint direction container props */
.QSNotFound_hint__7ekYH[data-direction='top'] {
  flex-direction: column;
  -webkit-animation-name: QSNotFound_topToBottom__3EH1T;
          animation-name: QSNotFound_topToBottom__3EH1T;
}
.QSNotFound_hint__7ekYH[data-direction='bottom'] {
  flex-direction: column;
  -webkit-animation-name: QSNotFound_bottomToTop__lqPXH;
          animation-name: QSNotFound_bottomToTop__lqPXH;
}
.QSNotFound_hint__7ekYH[data-direction='left'] {
  flex-direction: row;
  -webkit-animation-name: QSNotFound_leftToRight__ocYT2;
          animation-name: QSNotFound_leftToRight__ocYT2;
}
.QSNotFound_hint__7ekYH[data-direction='right'] {
  flex-direction: row;
  -webkit-animation-name: QSNotFound_rightToLeft__1rXV4;
          animation-name: QSNotFound_rightToLeft__1rXV4;
}

/* Hint direction arrow props */
.QSNotFound_hint__7ekYH[data-direction^='top'] .QSNotFound_hintArrow__22Xut {
  margin-bottom: 10px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.QSNotFound_hint__7ekYH[data-direction^='bottom'] .QSNotFound_hintArrow__22Xut {
  margin-top: 10px;
  order: 2;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.QSNotFound_hint__7ekYH[data-direction$='left'] .QSNotFound_hintArrow__22Xut {
  margin-right: 10px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.QSNotFound_hint__7ekYH[data-direction$='right'] .QSNotFound_hintArrow__22Xut {
  margin-left: 10px;
  order: 2;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

/* --------------------------- MESSAGE IN TOOLTIP --------------------------- */

.WhoIsHere_activityStatus__2Gi6O {
  display: flex;
  align-items: center;

  font-size: 12px;
  line-height: 16px;

  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.WhoIsHere_activityStatus__2Gi6O:hover > .WhoIsHere_message__3PxGA strong {
  text-decoration: underline;
}

.WhoIsHere_activityStatus__2Gi6O > .WhoIsHere_icon__3SDQt {
  margin-left: 6px;
}

.WhoIsHere_activityStatus__2Gi6O > .WhoIsHere_message__3PxGA {
  color: #cbc9c5;

  max-width: 150px;
}

.WhoIsHere_activityStatus__2Gi6O > .WhoIsHere_message__3PxGA strong {
  color: white;
  font-weight: 600;
}

/* ----------------------------- LIST OF AVATARS ---------------------------- */

.WhoIsHere_whoIsHere__2HOzA {
  -webkit-user-select: none;
          user-select: none;

  display: flex;
  align-items: center;
}
.WhoIsHere_whoIsHere__2HOzA > *:not(:last-child) {
  margin-right: 10px;
}
.WhoIsHere_whoIsHere__2HOzA.WhoIsHere_size-small__3VPAI > *:not(:last-child) {
  margin-right: 6px;
}

.WhoIsHere_hiddenUsersCount__3GIDZ {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;

  font-weight: 500;

  white-space: nowrap;
}

.WhoIsHere_whoIsHere__2HOzA.WhoIsHere_theme-overlay__3QyRV .WhoIsHere_hiddenUsersCount__3GIDZ {
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;

  color: #454442;
  background-color: #ffffff;

  border-radius: 2px;

  padding: 2px 3px;

  margin-left: 5px;

  box-shadow: 0px 0px 0px 1px rgba(48, 47, 46, 0.07), 0px 8px 8px 0px rgba(48, 47, 46, 0.07), 0px 2px 2px 0px rgba(48, 47, 46, 0.07),
    0px 1px 1px 0px rgba(48, 47, 46, 0.07);
}

.WhoIsHere_whoIsHere__2HOzA.WhoIsHere_theme-overlay__3QyRV .WhoIsHere_avatar__2a9fh {
  -webkit-filter: drop-shadow(1px 1px 1px #0008);
          filter: drop-shadow(1px 1px 1px #0008);
}

.WhoIsHere_whoIsHere__2HOzA .WhoIsHere_avatar__2a9fh.WhoIsHere_notActive__2zmOD {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.2;
}

.WhoIsHere_placeholder__3RMyA {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;

  color: #807e7c;
  font-weight: 400;
}

.YouLackPermission_youLackPermission__27gav {
  width: 400px;

  padding: 20px;
}

.YouLackPermission_header__3k0iD {
  border-bottom: 1px solid #eae8e4;
  padding-bottom: 15px;
}

.YouLackPermission_content__pbmSd {
  padding: 15px 0;
}
.YouLackPermission_content__pbmSd > *:not(:last-child) {
  margin-bottom: 15px;
}

.YouLackPermission_message__1vOA4 {
  line-height: 1.5em;
  font-size: 14px;
}

/* ------------------------------- HOLDER CARD ------------------------------ */

.YouLackPermission_holderCard__2F5pA {
  padding: 10px;

  display: flex;
  flex-direction: column;

  color: #282f2e;
  background-color: #f6f5f4;

  border: 1px solid #dddbd7;

  border-radius: 5px;

  font-size: 13px;
}

.YouLackPermission_holderCardHeader__24swh {
  font-weight: 500;

  margin-bottom: 10px;
  font-size: 12px;
}

.YouLackPermission_holderCardContent__MNkgE {
  display: flex;
}

.YouLackPermission_holderCardAvatar__3hUdk {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
}

.YouLackPermission_holderCardAbout__2EC5K {
  flex-grow: 1;
  flex-shrink: 1;
}
.YouLackPermission_holderCardAbout__2EC5K *:not(:last-child) {
  margin-bottom: 5px;
}
.YouLackPermission_holderCardAbout__2EC5K * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.YouLackPermission_holderCardAbout__2EC5K a {
  font: inherit;
  color: #2675d1;
  text-decoration: underline;
}

/* --------------------------------- BUTTONS -------------------------------- */

.YouLackPermission_buttons__2c9H- {
  display: flex;
  justify-content: flex-end;
}
.YouLackPermission_buttons__2c9H- > *:not(:last-child) {
  margin-right: 10px;
}

.FormPreview_form__2eOfa {
  position: relative;

  width: 100%;
  background-color: white;
}

.FormPreview_placeholder___lG11 {
  -webkit-user-select: none;
          user-select: none;
  box-sizing: border-box;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 15px;

  line-height: 1.5;
  font-weight: 500;
  text-align: center;

  color: #807e7c;
  background-color: #eae8e4;
}

/* -------------------------------- CONTAINER ------------------------------- */

.BuilderFormPreview_formPreview__2UdFI {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  overflow: hidden;

  background-color: #eae8e4;
}

.BuilderFormPreview_formPreview__2UdFI > * {
  flex-shrink: 0;
  flex-grow: 0;

  box-sizing: border-box;
  width: 100%;
}
.BuilderFormPreview_formPreview__2UdFI > .BuilderFormPreview_placeholder__20H6x,
.BuilderFormPreview_formPreview__2UdFI > .BuilderFormPreview_form__25voc {
  flex-shrink: 1;
  flex-grow: 1;
}

/* --------------------------------- TOP BAR -------------------------------- */

.BuilderFormPreview_topBar__35gK6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 36px;
  padding: 0 20px;

  background-color: #fbfaf9;
  border-bottom: 1px solid #dddbd7;

  z-index: 2;
}

.BuilderFormPreview_topBar__35gK6 label {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #807e7c;

  font-size: 13px;
  line-height: 16px;
  font-weight: 600;

  overflow: hidden;
}
.BuilderFormPreview_topBar__35gK6 label > *:not(:last-child) {
  margin-right: 10px;
}
.BuilderFormPreview_topBar__35gK6 label > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.LogsErrorsIcon_notification__3wD1O {
  display: flex;

  font-size: 12px;
  font-weight: 600;

  color: #f05252;

  cursor: pointer;
}
.LogsErrorsIcon_notification__3wD1O > span {
  margin-left: 5px;
}


.Profile_profile__3dzvr {
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  width: 80vw;
  height: 90vh;
  max-width: 950px;
  min-width: 850px;

  border-radius: 8px;
  background-color: #ffffff;

  box-shadow: 0 1px 1px 0 rgba(48, 47, 46, 0.07), 0 2px 2px 0 rgba(48, 47, 46, 0.07), 0 4px 4px 0 rgba(48, 47, 46, 0.07),
    0 8px 8px 0 rgba(48, 47, 46, 0.07), 0 16px 16px 0 rgba(48, 47, 46, 0.07);
}

.Menu_main___P8BZ {
  background-color: #f6f5f4;
  border-radius: 8px 0 0 8px;
  box-shadow: 1px 0 0 0 #dddbd7;
  width: 200px;
  min-width: 200px;
  box-sizing: border-box;
  height: 100%;
}

.Menu_list__3Q5-O {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Menu_list__3Q5-O .Menu_head__33dIg {
  width: 100%;
  height: 36px;
  color: #807e7c;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding: 0 10px 0 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #eae8e4;
}

.Menu_list__3Q5-O .Menu_head__33dIg:not(:first-child) {
  margin: 20px 0 0 0;
}

.Menu_list__3Q5-O ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Menu_list__3Q5-O ul li {
  padding: 0 10px 0 15px;
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
}

.Menu_list__3Q5-O ul li:hover {
  background: #eae8e4;
}

.Menu_list__3Q5-O ul li:active,
.Menu_list__3Q5-O ul li.Menu_active__3sSB4 {
  background: #dddbd7;
}

.Menu_list__3Q5-O ul li.Menu_active__3sSB4 {
  color: #454442;
}

.Menu_list__3Q5-O ul li .Menu_label__3-S7I {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Menu_list__3Q5-O ul li .Menu_label__3-S7I .Menu_balance__Zimr- {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Menu_list__3Q5-O ul li .Menu_label__3-S7I .Menu_balance__Zimr- .Menu_amount__3JK70 {
  margin: 0 0 0 3px;
}

.Menu_list__3Q5-O ul li .Menu_label__3-S7I .Menu_badge__3cDe8 {
  color: #454442;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
}

.Menu_list__3Q5-O ul li .Menu_icon__2N6AS {
  margin: 0 10px 0 0;
  width: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Menu_list__3Q5-O ul li .Menu_avatar__2GoFu {
  margin: 0 7px 0 0;
}

.Content_main__1t4PA {
  width: 100%;
  position: relative;
}

.Profile_content__3Lcn3 {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 60px);
  max-height: calc(90vh - 60px);
  overflow-y: auto;
}

.Profile_content__3Lcn3 .Profile_profilePicture__2ohwV {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Profile_content__3Lcn3 .Profile_profilePicture__2ohwV .Profile_avatar__2C9bL {
  margin: 0 20px 0 0;
}

.Profile_content__3Lcn3 .Profile_firstLastName__3Wllm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Profile_aboutAuthType__3UiNR {
  font-size: 13px;
  color: #454442;
  line-height: 16px;
}
.Profile_aboutAuthType__3UiNR > * {
  margin: 0;
}
.Profile_aboutAuthType__3UiNR > *:not(:last-child) {
  margin-bottom: 10px;
}
.Profile_aboutAuthType__3UiNR ul {
  list-style-type: circle;
  padding-left: 30px;
}

.Header_main__3yjwt {
  min-height: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px 0 30px; 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #EAE8E4;
}

.Header_title__23hmo {
  margin: 20px 0 5px 0;
}

.Header_description__376kf {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  margin: 5px 0 15px 0;
}

.Header_tabs__CCR4o {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.Header_tabs__CCR4o li {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  margin: 10px 0 0 0;
  padding: 0 0 10px 0;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  box-shadow: 0 2px 0px -1px transparent;
  transition: box-shadow 0.1s ease-in, color 0.1s ease-in;
}

.Header_tabs__CCR4o li:not(:first-child) {
  margin: 10px 0 0 30px;
}

.Header_tabs__CCR4o li:hover {
  color: #454442;
}

.Header_tabs__CCR4o li:active,
.Header_tabs__CCR4o li.Header_active__3Euhi {
  box-shadow: 0 2px 0px -1px #454442;
  color: #454442;
}

.Header_tabs__CCR4o li .Header_number__2VBAC {
  position: absolute;
  top: 1px;
  background-color: #EAE8E4;
  border-radius: 2px;
  padding: 0 3px;
  height: 15px;
  color: #1B1B1A;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
}

.Header_tabs__CCR4o li .Header_number__2VBAC.Header_one__1VZMg {
  right: -18px;
}

.Header_tabs__CCR4o li .Header_number__2VBAC.Header_two__2G6Z_ {
  right: -25px;
}

.AvatarChangePanel_main__1sYSV .AvatarChangePanel_content__3pjXF {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.AvatarChangePanel_main__1sYSV .AvatarChangePanel_scaleSlider__1blGT {
  margin: 10px 0 0 0;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.AvatarChangePanel_main__1sYSV .AvatarChangePanel_slider__tBGN0 {
  width: 80%;
}

.AvatarChangePanel_main__1sYSV .AvatarChangePanel_sizeImage__2nBW9.AvatarChangePanel_small__1cG69 {
  margin: 0 7px 0 0;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
}

.AvatarChangePanel_main__1sYSV .AvatarChangePanel_sizeImage__2nBW9.AvatarChangePanel_big__1wiJb {
  margin: 0 0 0 7px;
}

.EmailAddressChangePanel_successModal__2uu9s {
  max-width: 400px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 20px 10px;
}
.EmailAddressChangePanel_successModal__2uu9s > *:not(:last-child) {
  margin-bottom: 20px;
}

.EmailAddressChangePanel_successModal__2uu9s .EmailAddressChangePanel_header__2I_3t {
  font-size: 18px;
  font-weight: 600;
  color: #282f2e;
}
.EmailAddressChangePanel_successModal__2uu9s .EmailAddressChangePanel_text__r0w4A {
  line-height: 1.3em;
  color: #5d5d5c;
}

.OtpCreatePanel_main__3Ch_X .OtpCreatePanel_content__2ZxET {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 15px 0;
  color: #656565;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  box-sizing: border-box;
}

.OtpCreatePanel_content__2ZxET .OtpCreatePanel_qrCode__3_Ooh {
  margin: 0px auto 20px auto;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.OtpCreatePanel_content__2ZxET .OtpCreatePanel_qrCode__3_Ooh.OtpCreatePanel_visible__1w3Lq {
  opacity: 1;
}

.OtpCreatePanel_content__2ZxET p {
  margin: 10px 0 0 0;
}

.OtpCreatePanel_digitsTitle__2hbx3 {
  color: #363636;
  font-size: 15px;
  width: 100%;
  text-align: center;
  margin: 0 0 5px 0;
}

.OtpCreatePanel_error__3G3wY {
  text-align: center;
  width: 100%;
  color: #f05252;
  font-size: 13px;
  line-height: 16px;
}

.OtpRemovePanel_main__XrDcU .OtpRemovePanel_content__1R5R4 {
  position: relative;
  box-sizing: border-box;

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  font-size: 13px;
  font-weight: 400;
  line-height: 16px;

  color: #656565;
}

.OtpRemovePanel_digitsTitle__1kwLU {
  width: 100%;
  margin: 0 0 5px 0;

  color: #807e7c;
  font-size: 13px;

  text-align: center;
}

.OtpRemovePanel_error__7dT0k {
  text-align: center;
  width: 100%;
  color: #f05252;
  font-size: 13px;
  line-height: 16px;
}

.DeleteAccountPanel_main__3GoE4 .DeleteAccountPanel_content__1KORu {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}

.DeleteAccountPanel_content__1KORu p {
  margin: 0 0 20px 0;
  padding: 0;
  color: #656565;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.Members_content__3O4Ek {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 60px);
  max-height: calc(90vh - 60px);
  overflow-y: auto;
}

.Members_invite__CTM6Q {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Members_inviteInput__1FzWf {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 110px);
}

.Members_header__3gJkR {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Members_header__3gJkR .Members_left__3dyaZ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Members_header__3gJkR .Members_right__3_VIf {
  color: #807E7C;
  font-size: 11px;
  line-height: 13px;
}

.Members_users__3ynoI {
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 0 0;
  padding: 0;
}

.Members_users__3ynoI li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Members_users__3ynoI li.Members_usersHead__20lzx {
  border-bottom: 1px solid #EAE8E4;
  padding: 10px 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.Members_users__3ynoI li.Members_usersBody__3422o {
  padding: 10px 0;
}

.Members_users__3ynoI li:last-child {
  border-bottom: none;
}

.Members_users__3ynoI li .Members_info__1EGcJ {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Members_users__3ynoI li .Members_info__1EGcJ .Members_infoAvatar__3ITfI {
  margin: 0 10px 0 0;
}

.Members_users__3ynoI li .Members_info__1EGcJ .Members_infoText__1O1_2 {

}

.Members_users__3ynoI li .Members_info__1EGcJ.Members_notActive__2QCYk .Members_infoText__1O1_2,
.Members_users__3ynoI li .Members_info__1EGcJ.Members_notActive__2QCYk .Members_infoAvatar__3ITfI  {
  opacity: 0.5;
}

.Members_users__3ynoI li .Members_info__1EGcJ .Members_infoText__1O1_2 p {
  color: #454442;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  padding: 0;
}

.Members_users__3ynoI li .Members_info__1EGcJ .Members_infoText__1O1_2 span {
  color: #807E7C;
  font-size: 11px;
  line-height: 13px;
}

.Members_loader__1-GSv {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.UpdatePlan_description__2z-Wb {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
  margin: 0 0 10px 0;
}

.UpdatePlan_trial__d_OcW {
  font-weight: 500;
  text-transform: capitalize;
}

.UpdatePlan_personal__1cUXX {
  color: #2675D1;
  font-weight: 500;
  text-transform: capitalize;
}

.UpdatePlan_starter__3GsaD {
  color: #9061F9;
  font-weight: 500;
  text-transform: capitalize;
}

.UpdatePlan_pro__12aSU {
  color: #FF623D;
  font-weight: 500;
  text-transform: capitalize;
}

.UpdatePlan_price__1lNSi {
  color: #454442;
  font-weight: 600;
}

.UpdatePlan_smallPrice__anvCf {
  color: #454442;
}

.UpdatePlan_usersList__3bQpW {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #EAE8E4;
  border-radius: 4px;
  padding: 15px;
  box-sizing: border-box;
  max-height: 163px;
  overflow-y: auto;
  margin: 5px 0 30px 0;
}

.UpdatePlan_submit__21J3z {
  margin: 30px 0 20px 0;
}

.UpdatePlan_priceInclude__1KlRN {
  list-style-type: circle;
  padding: 5px 0 15px 10px;
  margin: 0 0 0 15px;
}

.UpdatePlan_emailsToInvite__3S3QS {
  list-style-type: circle;
  padding: 5px 0 15px 10px;
  margin: 0 0 0 15px;
}

.UpdatePlan_emailsToInvite__3S3QS li {
  font-weight: 500;
  color: #454442;
}

.UpdatePlan_paymentMethods__11bA1 {
  list-style: none;
  padding: 0;
  margin: 0;
}
.Domains_content__1N89K {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 60px);
  max-height: calc(90vh - 60px);
  overflow-y: auto;
}

.Domains_info__3-i1e {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
}

.Domains_buttons__iF8Ec {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 0 0;
}

.Domains_buttons__iF8Ec button:first-child {
  margin: 0 15px 0 0;
}

.Domains_domains__2k02I {
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 0 0;
  padding: 0;
}

.Domains_domains__2k02I li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Domains_domains__2k02I li.Domains_domainsHead__2pXnG {
  border-bottom: 1px solid #EAE8E4;
  padding: 10px 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.Domains_domains__2k02I li.Domains_domainsBody__8BDHD {
  padding: 10px 0;
}

.Domains_domains__2k02I li:last-child {
  border-bottom: none;
}

.Domains_loader__1Wmur {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.AddEditDomainPanel_content__pyg3e label {
  color: #454442;
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}

.AddEditDomainPanel_content__pyg3e label .AddEditDomainPanel_badge__ixrOv {
  background-color: #0D9D9D;
  border-radius: 4px;
  height: 18px;
  line-height: 18px;
  padding: 0 5px;
  margin: 0 0 0 10px;
  color: #FFFFFF;
  font-size: 10px;
  display: block;
}

.AddEditDomainPanel_content__pyg3e p {
  margin: 0;
  padding: 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
  margin: 0 0 15px 0;
}

.AddEditDomainPanel_content__pyg3e span.AddEditDomainPanel_link__2Uq-6,
.AddEditDomainPanel_content__pyg3e a {
  text-decoration: underline;
  color: #515EF3;
  cursor: pointer;
}
.DeleteDomainPanel_content__3hbqG p {
  margin: 0;
  padding: 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
  margin: 0 0 15px 0;
}
.Apps_content__ZaZK- {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 84px);
  max-height: calc(90vh - 84px);
  overflow-y: auto;
}

.Apps_services__1IAdv {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Apps_services__1IAdv li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.Apps_services__1IAdv li:not(:first-child) {
  margin: 20px 0 0 0;
}

.Apps_services__1IAdv li .Apps_left__2_e2w {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.Apps_services__1IAdv li .Apps_left__2_e2w .Apps_name__3bNS5 {
  margin: 0 0 0 10px;
}

.Apps_services__1IAdv li .Apps_left__2_e2w .Apps_name__3bNS5 span {
  color: #454442;
  font-size: 13px;
  line-height: 16px;
}

.Apps_services__1IAdv li .Apps_left__2_e2w .Apps_name__3bNS5 p {
  color: #807E7C;
  font-size: 12px;
  line-height: 14px;
  padding: 0;
  margin: 3px 0 0 0;
}

.Apps_loader__3v_BR {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Apps_connections__309mn {
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
}

.Apps_connections__309mn li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Apps_connections__309mn li.Apps_head__1ZiIu {
  border-bottom: 1px solid #EAE8E4;
  padding: 10px 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.Apps_connections__309mn li.Apps_body__3gRIT {
  padding: 10px 0;
}

.Apps_connections__309mn li:last-child {
  border-bottom: none;
}

.Apps_connections__309mn li .Apps_top__2XHlx {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Apps_connections__309mn li .Apps_name__3bNS5 {
  color: #454442;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 50px);
}

.Apps_connections__309mn li .Apps_currency__1EoyB {
  background-color: #5D5D5C;
  border-radius: 2px;
  padding: 1px 4px;
  color: #FBFAF9;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  margin: 0 0 0 7px;
}

.Apps_connections__309mn li .Apps_owner__3VZZ4 {
  color: #807E7C;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  margin: 3px 0 0 0;
}

.Apps_connections__309mn li .Apps_service__2aV7m {
  color: #454442;
  font-size: 13px;
  line-height: 16px;
}

.Apps_connections__309mn li .Apps_mode__xGhGj {

  border-radius: 2px;
  padding: 1px 4px;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  margin: 0 0 0 7px;
}

.Apps_connections__309mn li .Apps_mode__xGhGj.Apps_sandbox__2U_Xk {
  background-color: #FFE8E2;
  color: #FF623D;
}

.Apps_connections__309mn li .Apps_mode__xGhGj.Apps_production__10hMe {
  background-color: #eafdf6;
  color: #0F9F6E;
}

.Apps_connections__309mn li .Apps_actions__1hUYp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Apps_connections__309mn li .Apps_actions__1hUYp button:first-child {
  margin: 0 20px 0 0;
}

.AddEditConnectionPanel_doubleRow__Ch_Jp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.DeleteConnectionPanel_content__nZvI6 p {
  margin: 0;
  padding: 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
  margin: 0 0 15px 0;
}
.Smtps_content__ZuhIO {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 60px);
  max-height: calc(90vh - 60px);
  overflow-y: auto;
}

.Smtps_info__1lTr3 {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
}

.Smtps_buttons__1v5F_ {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 0 0;
}

.Smtps_buttons__1v5F_ button:first-child {
  margin: 0 15px 0 0;
}

.Smtps_smtps__2s2Or {
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 0 0;
  padding: 0;
}

.Smtps_smtps__2s2Or li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Smtps_smtps__2s2Or li.Smtps_smtpsHead__3xS3s {
  border-bottom: 1px solid #EAE8E4;
  padding: 10px 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.Smtps_smtps__2s2Or li.Smtps_smtpsBody__g6SVY {
  padding: 10px 0;
}

.Smtps_smtps__2s2Or li.Smtps_smtpsBody__g6SVY .Smtps_value__3Dwis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Smtps_smtps__2s2Or li:last-child {
  border-bottom: none;
}

.Smtps_loader__2gzKl {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.AddEditSmtpPanel_content__3el90 label {
  color: #454442;
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}

.AddEditSmtpPanel_content__3el90 label .AddEditSmtpPanel_badge__L6fXb {
  background-color: #0D9D9D;
  border-radius: 4px;
  height: 18px;
  line-height: 18px;
  padding: 0 5px;
  margin: 0 0 0 10px;
  color: #FFFFFF;
  font-size: 10px;
  display: block;
}

.AddEditSmtpPanel_content__3el90 p {
  margin: 0;
  padding: 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
  margin: 0 0 15px 0;
}

.AddEditSmtpPanel_content__3el90 span.AddEditSmtpPanel_link__1gkJp,
.AddEditSmtpPanel_content__3el90 a {
  text-decoration: underline;
  color: #515EF3;
  cursor: pointer;
}

.AddEditSmtpPanel_content__3el90 .AddEditSmtpPanel_test__HvVyM {
  background: #FBFAF9;
  border: 1px solid #EAE8E4;
  padding: 15px;
  box-sizing: border-box;
  margin: 30px 0 0 0;
  position: relative;
}

.AddEditSmtpPanel_content__3el90 .AddEditSmtpPanel_test__HvVyM.AddEditSmtpPanel_success__cPYma {
  background: rgba(15, 159, 110, 0.1);
  border-color: rgba(15, 159, 110, 1);
}

.AddEditSmtpPanel_content__3el90 .AddEditSmtpPanel_test__HvVyM.AddEditSmtpPanel_error__2v4p1 {
  background: rgba(240, 82, 82, 0.1);
  border-color: rgba(240, 82, 82, 1);
}

.AddEditSmtpPanel_content__3el90 .AddEditSmtpPanel_test__HvVyM.AddEditSmtpPanel_success__cPYma p,
.AddEditSmtpPanel_content__3el90 .AddEditSmtpPanel_test__HvVyM.AddEditSmtpPanel_error__2v4p1 p {
  color: #000;
}

.AddEditSmtpPanel_content__3el90 .AddEditSmtpPanel_test__HvVyM .AddEditSmtpPanel_testLabel__oTyD9 {
  color: #454442;
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.AddEditSmtpPanel_content__3el90 .AddEditSmtpPanel_test__HvVyM .AddEditSmtpPanel_spinner__2-6ax {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.DeleteSmtpPanel_content__1t_m8 p {
  margin: 0;
  padding: 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
  margin: 0 0 15px 0;
}
.Table_content__szoWN {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 84px);
  max-height: calc(90vh - 84px);
  overflow-y: auto;
}

/* colors */
.Table_blue__2NjA9 {
  color: #2675d1 !important;
  font-weight: 500 !important;
}

.Table_purple__2tPDE {
  color: #9061f9 !important;
  font-weight: 500 !important;
}

.Table_orange__3zPO1 {
  color: #ff623d !important;
  font-weight: 500 !important;
}

/* head */
.Table_head__3de-r {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  border-bottom: 1px solid #eae8e4;
}

.Table_head__3de-r .Table_item__1c7Nj {
  width: 25%;
  border-right: 1px solid #eae8e4;
  padding: 0 20px;
  min-height: 145px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
}

.Table_head__3de-r .Table_item__1c7Nj:last-child {
  border: 0;
}

.Table_head__3de-r .Table_item__1c7Nj > div {
  width: 100%;
}

.Table_head__3de-r .Table_item__1c7Nj .Table_name__1_xCL {
  font-size: 13px;
  line-height: 16px;
}

.Table_head__3de-r .Table_item__1c7Nj .Table_price__2Uer5 {
  color: #454442;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}

.Table_head__3de-r .Table_item__1c7Nj .Table_priceDesc__1bC-y {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
}

.Table_head__3de-r .Table_item__1c7Nj .Table_button__1Pi6F {
  margin: 15px auto 20px auto;
}

/* body */
.Table_body__3Xfe7 {
}

/* -------------------------------- ROW CELLS ------------------------------- */

.Table_body__3Xfe7 .Table_row__1eb5g {
  box-sizing: border-box;

  font-size: 13px;
  line-height: 16px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.Table_body__3Xfe7 .Table_row__1eb5g > div {
  box-sizing: border-box;

  width: 25%;
  padding: 10px 15px;

  font-weight: 400;
  color: #807e7c;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  border: 1px solid #eae8e4;
  border-left: none;
  border-top: none;
}
.Table_body__3Xfe7 .Table_row__1eb5g > div:last-child {
  padding-right: 5px;
  border-right: 0;
}
.Table_body__3Xfe7 .Table_row__1eb5g > div:first-child {
  padding-left: 5px;
}

.Table_body__3Xfe7 .Table_row__1eb5g > div.Table_label__2s0S4 {
  font-weight: 400;
  color: #807e7c;
}

/* --------------------------------- HEADER --------------------------------- */

.Table_body__3Xfe7 .Table_header__2Wzw_ {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.Table_body__3Xfe7 .Table_header__2Wzw_ div {
  color: #454442;
  font-size: 15px;
  line-height: 18px;
  min-height: 43px;
  font-weight: 600;
  padding: 25px 0 0 0;
  width: 25%;
  border-right: 1px solid #eae8e4;
  box-sizing: border-box;
}

.Table_body__3Xfe7 .Table_header__2Wzw_ div:last-child {
  border: 0;
}

/* spacer */
.Table_body__3Xfe7 .Table_spacer__rOsCw {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.Table_body__3Xfe7 .Table_spacer__rOsCw div {
  min-height: 20px;
  width: 25%;
  border-right: 1px solid #eae8e4;
  box-sizing: border-box;
}

.Table_body__3Xfe7 .Table_spacer__rOsCw div:last-child {
  border-right: 0;
}

/* plan colors */
.Table_trial__1leB8 {
  font-weight: 500;
  text-transform: capitalize;
}

.Table_personal__268OH {
  color: #2675d1;
  font-weight: 500;
  text-transform: capitalize;
}

.Table_starter__3WQlO {
  color: #9061f9;
  font-weight: 500;
  text-transform: capitalize;
}

.Table_pro__LYzJ2 {
  color: #ff623d;
  font-weight: 500;
  text-transform: capitalize;
}

/* ------------------------------ HIGHLIGHTING ------------------------------ */

.Table_head__3de-r > *,
.Table_header__2Wzw_ > *,
.Table_row__1eb5g > *,
.Table_spacer__rOsCw > * {
  transition: background-color 0.5s ease-in-out;
}
.Table_highlighted-plan-personal__3Gbyk .Table_head__3de-r > *:nth-child(2),
.Table_highlighted-plan-personal__3Gbyk .Table_header__2Wzw_ > *:nth-child(2),
.Table_highlighted-plan-personal__3Gbyk .Table_row__1eb5g > *:nth-child(2),
.Table_highlighted-plan-personal__3Gbyk .Table_spacer__rOsCw > *:nth-child(2) {
  background-color: rgba(255, 250, 200, 0.5);
}
.Table_highlighted-plan-starter__1eLlk .Table_head__3de-r > *:nth-child(3),
.Table_highlighted-plan-starter__1eLlk .Table_header__2Wzw_ > *:nth-child(3),
.Table_highlighted-plan-starter__1eLlk .Table_row__1eb5g > *:nth-child(3),
.Table_highlighted-plan-starter__1eLlk .Table_spacer__rOsCw > *:nth-child(3) {
  background-color: rgba(255, 250, 200, 0.5);
}
.Table_highlighted-plan-pro__ihqCk .Table_head__3de-r > *:nth-child(4),
.Table_highlighted-plan-pro__ihqCk .Table_header__2Wzw_ > *:nth-child(4),
.Table_highlighted-plan-pro__ihqCk .Table_row__1eb5g > *:nth-child(4),
.Table_highlighted-plan-pro__ihqCk .Table_spacer__rOsCw > *:nth-child(4) {
  background-color: rgba(255, 250, 200, 0.5);
}

.Table_row__1eb5g {
  transition: background-color 0.5s ease-in-out;
}
.Table_row__1eb5g.Table_highlighted__agpjj {
  background-color: rgba(255, 250, 200, 0.5);
}

/* moreResponses */
.Table_moreResponses__LnYCe {
  position: relative;
}

.Table_moreResponsesLabel__Q9h9n {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #ff623d;
  font-weight: 500;
  width: 100%;
}

.Table_moreResponsesCover__16PVE {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.Table_moreResponsesOptions__1HNas {
  position: absolute;
  top: 37px;
  right: 0;
  background-color: #FFFFFF;
  border: 1px solid #DDDBD7;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(48, 47, 46, 0.07), 0 16px 16px 0 rgba(48, 47, 46, 0.07);
  width: 250px;
  padding: 5px 0;
  -webkit-user-select: none;
          user-select: none;
}

.Table_moreResponsesOptions__1HNas .Table_label__2s0S4 {
  padding: 10px 15px;
  border-bottom: 1px solid #DDDBD7;
}

.Table_moreResponsesOptions__1HNas .Table_label__2s0S4 strong {
  color: #454442;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  margin: 0 0 5px 0;
  display: block;
}

.Table_moreResponsesOptions__1HNas .Table_label__2s0S4 p {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.Table_moreResponsesOptions__1HNas .Table_option__10KzI {
  padding: 0 15px;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  cursor: pointer;
}

.Table_moreResponsesOptions__1HNas .Table_option__10KzI:hover {
  background-color: #F6F5F4;
}

.Table_moreResponsesOptions__1HNas .Table_option__10KzI:active {
  background-color: #EAE8E4;
}

.UpdateInvoiceInformation_description__3bRkw {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
}

.UpdateInvoiceInformation_header__1HfxS {
  margin: 20px 0 15px 0;
}

.InitializePlan_description__esZlA {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
  margin: 0 0 10px 0;
}

.InitializePlan_trial__cIfUT {
  font-weight: 500;
  text-transform: capitalize;
}

.InitializePlan_personal__l9alv {
  color: #2675D1;
  font-weight: 500;
  text-transform: capitalize;
}

.InitializePlan_starter__3Vyq8 {
  color: #9061F9;
  font-weight: 500;
  text-transform: capitalize;
}

.InitializePlan_pro__s284k {
  color: #FF623D;
  font-weight: 500;
  text-transform: capitalize;
}

.InitializePlan_price__2OpGT {
  color: #454442;
  font-weight: 600;
}

.InitializePlan_usersList__jb7rP {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #EAE8E4;
  border-radius: 4px;
  padding: 15px;
  box-sizing: border-box;
  max-height: 163px;
  overflow-y: auto;
  margin: 5px 0 30px 0;
}

.InitializePlan_submit__3HCLp {
  margin: 30px 0 20px 0;
}

.InitializePlan_smallPrice__2jSQy {
  color: #454442;
}

.InitializePlan_priceInclude__3UgOT {
  list-style-type: circle;
  padding: 5px 0 15px 10px;
  margin: 0 0 0 15px;
}

.InitializePlan_discountCode__11Bqb {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.InitializePlan_discountCodeOn__2STrg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.InitializePlan_discountCodeOn__2STrg .InitializePlan_info__3QlGi {
  color: #454442;
  font-size: 12px;
  line-height: 16px;
}

.Billing_main__saGto {
  position: relative;
}

.Billing_content__18Yqv {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 84px);
  max-height: calc(90vh - 84px);
  overflow-y: auto;
}

/* plan colors */
.Billing_trial__DzeCW {
  font-weight: 500;
  text-transform: capitalize;
}

.Billing_personal__62R7A {
  color: #2675D1;
  font-weight: 500;
  text-transform: capitalize;
}

.Billing_starter__3cYjp {
  color: #9061F9;
  font-weight: 500;
  text-transform: capitalize;
}

.Billing_pro__300Sc {
  color: #FF623D;
  font-weight: 500;
  text-transform: capitalize;
}

.Billing_bold__13hoW {
  color: #454442;
  font-weight: 600;
}

.Billing_empty__bRUhR {
  color: #CBC9C5;
}

/* invoices */
.Billing_invoices__3XbO_ {
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 0 0;
  padding: 0;
}

.Billing_invoices__3XbO_ li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #454442;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.Billing_invoices__3XbO_ li.Billing_invoicesHead__FlOG7 {
  border-bottom: 1px solid #EAE8E4;
  padding: 10px 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.Billing_invoices__3XbO_ li.Billing_invoicesBody__2K-0- {
  padding: 10px 0;
}

.Billing_invoices__3XbO_ li:last-child {
  border-bottom: none;
}

.Billing_cancelSubscription__moGsI {
  margin: 30px 0 0 0;
}

/* status badges */
.Billing_open__nY3RK {
  background-color: #5D5D5C;
  border-radius: 2px;
  color: #FBFAF9;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  margin: 0 0 0 5px;
  padding: 2px 5px;
  text-transform: capitalize;
}

.Billing_paid__2on3H {
  background-color: #0F9F6E;
  border-radius: 2px;
  color: #FBFAF9;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  margin: 0 0 0 5px;
  padding: 2px 5px;
  text-transform: capitalize;
}

/* billingInformation */
.Billing_billingInformation__7zN1y {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Billing_billingInformation__7zN1y li {
  color: #1B1B1A;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 15px 0;
}

.Billing_billingInformation__7zN1y li label {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  margin: 0 0 2px 0;
}

/*  */
.Billing_top__387SQ {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.Billing_top__387SQ .Billing_left__TwH_7 {
  min-width: calc(100% - 300px);
  max-width: calc(100% - 300px);
  padding: 0 45px 0 0;
  box-sizing: border-box;
}

.Billing_top__387SQ .Billing_left__TwH_7 .Billing_description__2FjQu {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
}

.Billing_top__387SQ .Billing_right__1Y8bh {
  width: 300px;
}

/* cards */
.Billing_ccLabel__1O1XA {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  margin: 0 0 5px 0;
}

.Billing_ccList__2D2x8 {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
}

.Billing_ccList__2D2x8 li {
  min-height: 22px;
  font-weight: 400;
  color: #1B1B1A;
  font-size: 13px;
  line-height: 22px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Billing_ccList__2D2x8 li .Billing_ccBrand__1LRgc {
  text-transform: capitalize;
}

.Billing_ccList__2D2x8 li .Billing_ccLast4__1zX59 {
  text-transform: capitalize;
  font-weight: 600;
}

.Billing_ccList__2D2x8 li .Billing_more__3qt_h {
  position: absolute;
  right: 15px;
  top: 25px;
}

/* ccForm */
.Billing_ccForm__hzW5X {
  position: relative;
}

.Billing_ccFormButtons__P33SM {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.Billing_spinner__3VTwc {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.Usage_content__1X9fZ {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 84px);
  max-height: calc(90vh - 84px);
  overflow-y: auto;
}

.Usage_strong__1_2lO {
  color: #454442;
  font-weight: 600;
}

.Usage_usage__3j3LE {
  margin: 0 0 30px 0;
}

.Usage_label__2Ulky {
  color: #302F2E;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
}

.Usage_label__2Ulky span {
  color: #807E7C;
  font-weight: 400;
  padding: 0 0 0 5px;
}

.Usage_track__2iLX5 {
  background-color: #EAE8E4;
  border-radius: 0 9px 9px 0;
  width: 100%;
  height: 9px;
  margin: 10px 0 0 0;
}

.Usage_track__2iLX5 .Usage_bar__TPYwe {
  background-color: #FF623D;
  border-radius: 0 9px 9px 0;
  height: 9px;
}

.LifeTime_content__oDwCo {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 60px);
  max-height: calc(90vh - 60px);
  overflow-y: auto;
}

ul.LifeTime_summary__2Dsf_ {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.LifeTime_summary__2Dsf_ .LifeTime_leftTitle__3iew0:not(:first-child) {
  margin: 40px 0 10px 0;
}

ul.LifeTime_summary__2Dsf_ li {
  background-color: #FFFFFF;
  border: 1px solid #dddbd7;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 15px 15px 38px;
  position: relative;
}

ul.LifeTime_summary__2Dsf_ li:not(:last-child) {
  margin: 0 0 15px 0;
}

ul.LifeTime_summary__2Dsf_ li svg {
  position: absolute;
  left: 15px;
  top: 16px;
}

ul.LifeTime_summary__2Dsf_ li svg * {
  fill: #000000;
}

ul.LifeTime_summary__2Dsf_ li span {
  color: #302F2E;
  display: block;
  font-size: 14px;
  line-height: 18px;
}

ul.LifeTime_summary__2Dsf_ li p {
  color: #807E7C;
  display: block;
  padding: 0;
  margin: 3px 0 0 0;
  font-size: 13px;
  line-height: 18px;
  position: relative;
}

ul.LifeTime_payments__2s7zN {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.LifeTime_payments__2s7zN li {
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}

ul.LifeTime_payments__2s7zN .LifeTime_left__DCcP8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  color: #5D6188;
}

ul.LifeTime_payments__2s7zN .LifeTime_right__19iNB {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: #5D6188;
}

ul.LifeTime_payments__2s7zN .LifeTime_amount__3_hKg {
  color: #5D6188;
}

ul.LifeTime_payments__2s7zN .LifeTime_status__3TAwa {
  color: #656565;
  font-size: 12px;
}

ul.LifeTime_payments__2s7zN .LifeTime_createdAt__bYbWp {
  font-weight: 400;
  color: #656565;
  font-size: 12px;
  margin: 2px 0 0 0;
}

.LifeTime_head__yMMlE {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.LifeTime_refund__24YQ3 {
  margin: 30px 0 0 0;
}

.UpdateLifeTimePlan_description__1ZLqG {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
}

.UpdateLifeTimePlan_header__1KYXW {
  margin: 20px 0 15px 0;
}

.UpdateLifeTimePlan_actions__TKiqZ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0 0 0;
}

.UpdateLifeTimePlan_error__3LXQo {
  color: #FFFFFF;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  background-color: #F05252;
  border-radius: 4px;
  padding: 15px 45px;
  margin: 15px 0;
}

.ZapierKey_content__2dN2K {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 84px);
  max-height: calc(90vh - 84px);
  overflow-y: auto;
}

.Affiliate_content__3DzL0 {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 84px);
  max-height: calc(90vh - 84px);
  overflow-y: auto;
}

/* colors */
.Affiliate_bold__ysekx {
  color: #454442 !important;
  font-weight: 500 !important;
}

.Affiliate_blue__2ihk7 {
  color: #2675d1 !important;
  font-weight: 500 !important;
}

.Affiliate_purple__3eBFA {
  color: #9061f9 !important;
  font-weight: 500 !important;
}

.Affiliate_orange___5vi- {
  color: #ff623d !important;
  font-weight: 500 !important;
}

/* -------------------------------- ROW CELLS ------------------------------- */

.Affiliate_body__v8FU4 .Affiliate_row__KXZ9i {
  box-sizing: border-box;

  font-size: 13px;
  line-height: 16px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.Affiliate_body__v8FU4 .Affiliate_row__KXZ9i > div {
  box-sizing: border-box;

  width: 25%;
  padding: 10px 15px;

  font-weight: 400;
  color: #807e7c;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  border: 1px solid #eae8e4;
  border-left: none;
  border-top: none;
}

.Affiliate_body__v8FU4 .Affiliate_row__KXZ9i > div:last-child {
  padding-right: 5px;
  border-right: 0;
}

.Affiliate_body__v8FU4 .Affiliate_row__KXZ9i > div:first-child {
  padding-left: 5px;
}

.Affiliate_body__v8FU4 .Affiliate_row__KXZ9i:last-child div {
  border-bottom: 0;
}


.Affiliate_body__v8FU4 .Affiliate_row__KXZ9i > div.Affiliate_label__X2y9B {
  font-weight: 400;
  color: #807e7c;
}

.Affiliate_description__18oqI {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
  margin: 5px 0 0 0;
}

/* logs */
.Affiliate_logs__3H9yf .Affiliate_log__10YHR {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0;
  border-bottom: 1px solid #EAE8E4;
}

.Affiliate_logs__3H9yf .Affiliate_log__10YHR:last-child {
  border-bottom: 0;
}

.Affiliate_logs__3H9yf .Affiliate_log__10YHR .Affiliate_date__1fniX {
  color: #454442;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin: 0 20px 0 0;
}

.Affiliate_logs__3H9yf .Affiliate_log__10YHR .Affiliate_text__3nAv9 {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.Affiliate_addCollaborator__botI9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.Logs_container__3vZra {
  overflow: hidden;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  min-height: calc(90vh - 60px);
  max-height: calc(90vh - 60px);

  padding: 20px 30px 30px 30px;
}
.Logs_container__3vZra > *:not(:last-child) {
  margin-bottom: 15px;
}

.Logs_filters__3ksiN {
  display: flex;
  justify-content: space-between;
}

/* -------------------------------- CONTROLS -------------------------------- */

.PaginatedLogs_logsControls__1Wyn_ {
  display: flex;
  justify-content: space-between;

  font-size: 11px;
  color: #807e7c;
}
.PaginatedLogs_logsControls__1Wyn_ > * {
  display: flex;
  align-items: center;
}
.PaginatedLogs_logsControls__1Wyn_ > *:not(:last-child),
.PaginatedLogs_logsControls__1Wyn_ > * > *:not(:last-child) {
  margin-right: 15px;
}

/* ----------------------------- LOGS CONTAINER ----------------------------- */

.PaginatedLogs_logsOuterContainer__3bZL0 {
  overflow: hidden;

  flex-grow: 1;
  flex-shrink: 1;
  position: relative;

  display: flex;
  flex-direction: column;

  background-color: #fbfaf9;

  border: 1px solid #dddbd7;
  border-radius: 4px;
}
.PaginatedLogs_logsOuterContainer__3bZL0.PaginatedLogs_loading__ivavz {
  cursor: wait;
}

.PaginatedLogs_logsContainer__36G8r {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;

  display: flex;
  flex-direction: column;

  transition: opacity 0.2s ease-in-out;
}
.PaginatedLogs_logsContainer__36G8r > *:not(:last-child) {
  border-bottom: 1px solid #dddbd7;
}

.PaginatedLogs_loading__ivavz .PaginatedLogs_logsContainer__36G8r {
  pointer-events: none;
  opacity: 0.3;
}

.PaginatedLogs_loader___cLQj,
.PaginatedLogs_placeholder__2Hg23 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.PaginatedLogs_placeholder__2Hg23 {
  font-weight: 500;
  opacity: 0.5;
}

/* ----------------------------------- LOG ---------------------------------- */

.PaginatedLogs_log__U2BTE {
  display: flex;

  padding: 15px;
}

.PaginatedLogs_log__U2BTE > * {
  flex-shrink: 0;
  flex-grow: 0;
}
.PaginatedLogs_log__U2BTE > *:not(:last-child) {
  margin-right: 15px;
}

/* ------------------------------- LOG CONTENT ------------------------------ */

.PaginatedLogs_logContent__qebuQ {
  min-width: 0;
  flex-shrink: 1;
  flex-grow: 1;
}
.PaginatedLogs_logContent__qebuQ > *:not(:last-child) {
  margin-bottom: 5px;
}

.PaginatedLogs_logTags__3jX4M {
  width: 72px;
  white-space: nowrap;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.PaginatedLogs_logTags__3jX4M > *:not(:last-child) {
  margin-bottom: 5px;
}

.PaginatedLogs_logText__2T1aM {
  word-break: break-all;

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #454442;
}

.PaginatedLogs_logDate__1Dega {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #807e7c;
}

.PaginatedLogs_logActions__-RrqH {
  display: flex;
  flex-direction: column;

  align-items: stretch;
}
.PaginatedLogs_logActions__-RrqH > *:not(:last-child) {
  margin-bottom: 5px;
}
.PaginatedLogs_logActions__-RrqH > * {
  width: unset;
}

.SomethingWentWrongBoundary_content__171MK {
  min-width: 0;
  width: 500px;
  max-width: 80vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #282f2e;

  padding: 20px 0;
}
.SomethingWentWrongBoundary_content__171MK > *:not(:last-child) {
  margin-bottom: 20px;
}

.SomethingWentWrongBoundary_header__pLq-B {
  margin-top: 10px;
  padding: 0 10px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  -webkit-user-select: none;

          user-select: none;
}
.SomethingWentWrongBoundary_header__pLq-B > *:not(:last-child) {
  margin-bottom: 5px;
}
.SomethingWentWrongBoundary_header__pLq-B > .SomethingWentWrongBoundary_title__kZ1Pi {
  font-size: 24px;
  font-weight: 500;
}
.SomethingWentWrongBoundary_header__pLq-B > .SomethingWentWrongBoundary_description__1qugD {
  max-width: 400px;
  color: #807e7c;
}

.SomethingWentWrongBoundary_details__3Q50B {
  width: 100%;

  border-top: 1px solid #eae8e4;
}
.SomethingWentWrongBoundary_details__3Q50B .SomethingWentWrongBoundary_message__jRJQN {
  box-sizing: border-box;

  width: 100%;
  height: 150px;

  white-space: pre;

  overflow: auto;

  font-family: 'Courier New', monospace;

  color: #f6f5f4;
  background-color: #282f2e;

  padding: 15px 20px;
}

.SomethingWentWrongBoundary_buttons__1I7II {
  box-sizing: border-box;

  width: 100%;
  display: flex;
  justify-content: flex-end;

  padding: 0px 20px;
}
.SomethingWentWrongBoundary_buttons__1I7II > *:not(:last-child) {
  margin-right: 10px;
}

.HelpCenter_helpCenter__EQBUw {
  width: 550px;
}
.HelpCenter_helpCenter__EQBUw > *:not(:last-child) {
  margin-bottom: 12px;
}
.HelpCenter_helpCenter__EQBUw > * {
  padding: 0px 20px;
}

/* --------------------------------- HEADER --------------------------------- */

.HelpCenter_headerSection__2s3qK {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 16px;
  padding-bottom: 16px;

  border-bottom: 1px solid #dddbd7;
}
.HelpCenter_headerSection__2s3qK > * {
  line-height: 1.5em;
}

.HelpCenter_closeButton__2Cv1Z {
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-right: -4px;
}

/* --------------------------------- MIDDLE --------------------------------- */

.HelpCenter_autoPlaySection__3Qrkn {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-weight: 600;
  font-size: 13px;
  color: #807e7c;
}
.HelpCenter_autoPlaySection__3Qrkn > *:not(:last-child) {
  margin-right: 10px;
}

.HelpCenter_tutorialPickerSection___QF3F {
  padding: 6px 24px;
}

/* --------------------------------- FOOTER --------------------------------- */

.HelpCenter_knowledgeBaseSection__VhwJX {
  display: flex;
  align-items: center;

  padding-top: 8px;
  padding-bottom: 16px;
  background-color: #fbfaf9;

  border-top: 1px solid #dddbd7;
}
.HelpCenter_knowledgeBaseSection__VhwJX > * {
  line-height: 12px;
}

.HelpCenter_knowledgeBaseSection__VhwJX > *:not(:last-child) {
  margin-right: 10px;
}

.TutorialPicker_tutorialPicker__O128S {
  box-sizing: border-box;
  width: 100%;
}
.TutorialPicker_tutorialPicker__O128S > *:not(:last-child) {
  margin-bottom: 20px;
}

.TutorialPicker_tutorials__2Wzd2 {
  display: flex;
  flex-direction: column;
}
.TutorialPicker_tutorials__2Wzd2 > *:not(:last-child) {
  border-bottom: 1px solid #dddbd7;
}

/* ------------------------------- PLACEHOLDER ------------------------------ */

.TutorialPicker_placeholder__2GtMq {
  font-size: 13px;
  font-weight: 500;
  color: #807e7c;

  background-color: #fbfaf9;
  padding: 24px 12px;
  border-radius: 4px;
}

/* -------------------------------- TUTORIAL -------------------------------- */

.TutorialPicker_tutorial__hT4gk {
  -webkit-user-select: none;
          user-select: none;

  display: flex;
  align-items: center;

  padding: 12px 0px;

  border: none;
  background: none;
  outline: none;
}
.TutorialPicker_tutorial__hT4gk:not(:disabled) {
  cursor: pointer;
}
.TutorialPicker_tutorial__hT4gk:disabled {
  cursor: not-allowed;
}
.TutorialPicker_tutorial__hT4gk > * {
  flex-shrink: 0;
  flex-grow: 0;
}
.TutorialPicker_tutorial__hT4gk:not(:disabled):hover > * {
  opacity: 0.8;
}
.TutorialPicker_tutorial__hT4gk:not(:disabled):active > * {
  opacity: 0.6;
}

.TutorialPicker_tutorialProgress__Q0tee {
  width: 40px;
  margin-right: 12px;
}
.TutorialPicker_tutorialProgressText__weSpi {
  font: inherit;
  font-size: 9px;
  font-weight: 700;
  color: #807e7c;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.TutorialPicker_tutorialLabel__3a4Uu {
  flex-shrink: 1;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  min-width: 0;

  text-align: left;
  font-size: 13px;
  line-height: 16px;
}
.TutorialPicker_tutorialLabel__3a4Uu > *:not(:last-child) {
  margin-bottom: 4px;
}
.TutorialPicker_tutorialTitle__X-UOL {
  display: flex;
  align-items: center;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  color: #454442;
  font-weight: 600;
}
.TutorialPicker_tutorialDescription__pV7ga {
  color: #807e7c;
}
.TutorialPicker_tutorialBlocker__2CCfV {
  color: #f05252;
  font-weight: 600;
}

.TutorialPicker_notStartedBadge__pYBkO {
  display: inline-block;

  text-transform: uppercase;

  font-size: 8px;
  line-height: 1em;
  font-weight: 600;

  padding: 3px 3px;

  color: white;
  background-color: #b4b2af;
  border-radius: 2px;

  margin-left: 8px;
}

.TutorialPicker_tutorialArrow__3rO6T {
  margin-left: 12px;
  margin-right: 6px;
}

.TutorialSkipper_tutorialSkipper__33_Co {
  width: 450px;
}
.TutorialSkipper_content__1PCRG {
  line-height: 1.5;
}
.TutorialSkipper_content__1PCRG > *:not(:last-child) {
  margin-bottom: 20px;
}
.TutorialSkipper_content__1PCRG img {
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  border: 1px solid #dddbd7;

  border-radius: 4px;
}

