.paymentStatus {
  display: flex;
  flex-direction: column;
}
.paymentStatus > *:not(:last-child) {
  margin-bottom: 6px;
}

.modalTitle {
  color: #454442;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.showDetails {
  margin: 0;
  box-sizing: 0;
  padding: 0;
  border: 0;

  cursor: pointer;
  font-size: 13px;
  font-weight: 400;

  width: fit-content;
  opacity: 0.5;
  background: none;
}
.showDetails:hover {
  opacity: 0.3;
}

/* --------------------------------- DETAILS -------------------------------- */

.details {
  display: flex;
  flex-direction: column;
  list-style-type: none;

  padding: 0;
  margin: 0;

  overflow: hidden;
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;

  font-size: 13px;

  padding: 18px;

  border-radius: 8px;
  background-color: #fbfaf9;
  border: 1px solid #dddbd7;
}
.details > * {
  flex-shrink: 0;
  flex-grow: 0;
}
.details > *:not(:last-child) {
  margin-bottom: 12px;
}

.detailsHr {
  height: 1px;
  width: 100%;
  background-color: #dddbd7;
}

.detailsRow {
  line-height: 16px;
  width: 100%;
}
.detailsRow > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.detailsRowLabel {
  color: #807e7c;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 4px;

  white-space: nowrap;
}
.detailsRowValue {
  line-height: 1.5em;
  padding: 4px 10px;

  border-radius: 4px;

  border: 1px solid #dddbd7;
  background-color: white;
}

.detailsPaymentProviderRow {
  display: flex;
  align-items: center;
}

.detailsRowProviderIcon {
  width: 3.5ch;
  height: 3.5ch;
  object-fit: contain;
}

.detailsPaymentProviderText {
  display: flex;
  flex-direction: column;

  margin-left: 8px;
}

.detailsRowProviderLabel {
  color: #807e7c;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 4px;

  white-space: nowrap;
}
.detailsRowProviderName {
  font-weight: 600;
}

/* --------------------------------- SUMMARY -------------------------------- */

.summary {
  font-size: 13px;
  font-weight: 400;
}
.summaryAmount {
  font-size: 16px;
  font-weight: 600;

  color: #0f9f6e;

  white-space: nowrap;
}
.summaryTax {
  white-space: nowrap;
  opacity: 0.5;
}
.summaryTax::before {
  content: ' ';
}
