.skeleton {
  display: flex;
  flex-direction: column;

  width: 100%;
}
.skeleton > *:not(:last-child) {
  margin-bottom: 10px;
}

@keyframes slider {
  0% {
    opacity: 1;
    transform: translateX(-100px);
  }
  100% {
    opacity: 0;
    transform: translateX(200px);
  }
}
.verticalLine {
  height: 15px;

  position: relative;
  overflow: hidden;

  background-color: #dddbd7;
}
.verticalLineThin {
  height: 10px;

  position: relative;
  overflow: hidden;

  background-color: #dddbd7;
}

.verticalLine[data-animated='true']::after,
.verticalLineThin[data-animated='true']::after {
  position: absolute;

  content: '';
  width: 100px;
  height: 100%;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.15) 50%, rgba(255, 255, 255, 0) 100%);

  animation: slider 1.5s linear infinite;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.row > *:not(:last-child) {
  margin-right: 1ch;
}
