.content label {
  color: #454442;
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}

.content label .badge {
  background-color: #0D9D9D;
  border-radius: 4px;
  height: 18px;
  line-height: 18px;
  padding: 0 5px;
  margin: 0 0 0 10px;
  color: #FFFFFF;
  font-size: 10px;
  display: block;
}

.content p {
  margin: 0;
  padding: 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
  margin: 0 0 15px 0;
}

.content span.link,
.content a {
  text-decoration: underline;
  color: #515EF3;
  cursor: pointer;
}