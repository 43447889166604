.description {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
  margin: 0 0 10px 0;
}

.trial {
  font-weight: 500;
  text-transform: capitalize;
}

.personal {
  color: #2675D1;
  font-weight: 500;
  text-transform: capitalize;
}

.starter {
  color: #9061F9;
  font-weight: 500;
  text-transform: capitalize;
}

.pro {
  color: #FF623D;
  font-weight: 500;
  text-transform: capitalize;
}

.price {
  color: #454442;
  font-weight: 600;
}

.smallPrice {
  color: #454442;
}

.usersList {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #EAE8E4;
  border-radius: 4px;
  padding: 15px;
  box-sizing: border-box;
  max-height: 163px;
  overflow-y: auto;
  margin: 5px 0 30px 0;
}

.submit {
  margin: 30px 0 20px 0;
}

.priceInclude {
  list-style-type: circle;
  padding: 5px 0 15px 10px;
  margin: 0 0 0 15px;
}

.emailsToInvite {
  list-style-type: circle;
  padding: 5px 0 15px 10px;
  margin: 0 0 0 15px;
}

.emailsToInvite li {
  font-weight: 500;
  color: #454442;
}

.paymentMethods {
  list-style: none;
  padding: 0;
  margin: 0;
}