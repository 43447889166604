.mentions {
  display: flex;
}
.content {
  width: calc(100% - 25px);
}
.referToIcon {
  user-select: none;
  cursor: pointer;

  /* Button reset */
  height: 16px;
  width: 16px;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  background: none;

  margin-top: 3px;
  margin-left: auto;
  margin-right: -4px;

  transition: opacity ease-in-out 0.2s, transform ease-in-out 0.1s;
}
.referToIcon:active {
  transform: scale(1.25);
}

.disabled .referToIcon {
  opacity: 0;
}

.editable {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dddbd7;
  box-sizing: border-box;
  padding: 2px 10px;
  border-radius: 4px;
  color: #807e7c;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  transition: border-color 0.1s ease-in-out, box-shadow 0.3s ease-in-out;
  resize: none;
}

.editable:hover {
  border-color: #b4b2af;
}

.editable.focused {
  border-color: #b4b2af;
  color: #454442;
  box-shadow: 0px 0px 0px 2px #eae8e4;
}

.editable.disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-image: linear-gradient(rgba(47, 46, 45, 0.04), rgba(47, 46, 45, 0.04));
}

.editable.withBar {
  border-radius: 0 0 4px 4px;
}

.editable p {
  margin: 5px 0;
}

.editable p:first-child {
  margin-top: 0;
}

.editable p:last-child {
  margin-bottom: 0;
}

.editable ul,
.editable ol {
  margin: 0;
  padding: 10px 0 10px 20px;
}

.inline {
  box-sizing: content-box;

  width: 100%;
  padding: 5px 0;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(209, 214, 220, 0);

  transition: padding 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.inline.focused {
  padding: 5px 10px;
  border: 1px solid #dddbd7;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(209, 214, 220, 0.2);
}

.dropdown {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: 8;

  box-shadow: 0 4px 4px 0 rgba(48, 47, 46, 0.07), 0 8px 8px 0 rgba(48, 47, 46, 0.07), 0 16px 16px 0 rgba(48, 47, 46, 0.07);
}

.mentionElement {
  user-select: none;
  box-sizing: border-box;

  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  margin: 0 1px;

  vertical-align: baseline;
  font-size: 13px;
  line-height: 18px;

  color: #5d5d5c;
  background-color: #fbfaf9;

  border-radius: 3px;

  border: 1px solid #e7e7e7;
}
.mentionElement.error {
  color: #f05252;
  font-weight: 500;
}

.mentionElement .image {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 5px;
  background-color: #f6f5f4;

  border-right: 1px solid #e7e7e7;
}

.mentionElement .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding: 0 5px;
  max-width: 240px;
}
.mentionElement .label strong {
  margin-right: 0.5ch;
}

.button {
  cursor: pointer;
  opacity: 0.5;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.button.active {
  opacity: 1;
}

.button.formatBold {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNOSAxMC4zNDRxLjQzOCAwIC43Mi0uMjk3dC4yOC0uNzAzLS4yOC0uNzAzVDkgOC4zNDVINi42NTZ2Mkg5em0tMi4zNDQtNnYyaDJxLjQwNiAwIC43MDMtLjI5N3QuMjk2LS43MDMtLjI5Ny0uNzAzLS43MDQtLjI5NmgtMnptMy43NSAyLjg0NHExLjQzOC42NTYgMS40MzggMi4yOCAwIDEuMDY0LS43MDMgMS43OThUOS4zNzYgMTJoLTQuNzJWMi42NTZoNC4xOXExLjEyNCAwIDEuODkuNzh0Ljc2NiAxLjkwNy0xLjA5MyAxLjg0NHoiLz48L3N2Zz4=);
}

.button.formatItalic {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNi42NTYgMi42NTZIMTJ2MmgtMS44NzVMNy44NzUgMTBoMS40N3YySDR2LTJoMS44NzVsMi4yNS01LjM0NGgtMS40N3YtMnoiLz48L3N2Zz4=);
}

.button.formatUnderlined {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNMy4zNDQgMTIuNjU2aDkuMzEzVjE0SDMuMzQ0di0xLjM0NHpNOCAxMS4zNDRxLTEuNjU2IDAtMi44MjgtMS4xNzJUNCA3LjM0NFYyaDEuNjU2djUuMzQ0cTAgLjk3LjY4OCAxLjY0VDggOS42NTh0MS42NTYtLjY3Mi42ODgtMS42NFYySDEydjUuMzQ0UTEyIDkgMTAuODI4IDEwLjE3MlQ4IDExLjM0NHoiLz48L3N2Zz4=);
}

.button.numberedList {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNC42NTYgOC42NTZWNy4zNDNIMTR2MS4zMTNINC42NTZ6bTAgNHYtMS4zMTNIMTR2MS4zMTNINC42NTZ6bTAtOS4zMTJIMTR2MS4zMTNINC42NTZWMy4zNDR6bS0zLjMxMiA0di0uNjg4aDJ2LjYyNWwtMS4yMiAxLjM3NmgxLjIydi42ODhoLTJWOC43MmwxLjE4OC0xLjM3NkgxLjM0NHptLjY1Ni0ydi0yaC0uNjU2di0uNjg4aDEuMzEzdjIuNjg4SDJ6bS0uNjU2IDZ2LS42ODhoMnYyLjY4OGgtMnYtLjY4OGgxLjMxM3YtLjMxM0gydi0uNjg4aC42NTd2LS4zMTNIMS4zNDR6Ii8+PC9zdmc+);
}

.button.bulletedList {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNC42NTYgMy4zNDRIMTR2MS4zMTNINC42NTZWMy4zNDR6bTAgNS4zMTJWNy4zNDNIMTR2MS4zMTNINC42NTZ6bTAgNHYtMS4zMTNIMTR2MS4zMTNINC42NTZ6bS0yLTEuNTNxLjM3NSAwIC42NC4yNXQuMjY3LjYyNC0uMjY2LjYyNS0uNjQuMjUtLjYyNi0uMjVUMS43OCAxMnQuMjUtLjYyNS42MjYtLjI1em0wLTguMTI2cS40MDYgMCAuNzAzLjI4dC4yOTYuNzItLjI5Ny43Mi0uNzA0LjI4LS43MDMtLjI4VDEuNjU2IDR0LjI5Ny0uNzIuNzAzLS4yOHptMCA0cS40MDYgMCAuNzAzLjI4dC4yOTYuNzItLjI5Ny43Mi0uNzA0LjI4LS43MDMtLjI4VDEuNjU2IDh0LjI5Ny0uNzIuNzAzLS4yOHoiLz48L3N2Zz4=);
}

.button.link {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDIyIDI0Ij48cGF0aCBkPSJNMTkuNSAxNi4yODZxMC0uNTM2LS4zNzUtLjkxbC0yLjc4Ni0yLjc4N3EtLjM3Ni0uMzc2LS45MTItLjM3Ni0uNTYzIDAtLjk2NC40M2wuMjU0LjI0N3EuMjE0LjIwOC4yODguMjl0LjIuMjUzLjE3NS4zNDIuMDQ4LjM2OHEwIC41MzYtLjM3NS45MXQtLjkxLjM3NnEtLjIwMiAwLS4zNy0uMDQ4dC0uMzQtLjE3NC0uMjU1LS4yLS4yODgtLjI5LS4yNDgtLjI1M3EtLjQ0Mi40MTUtLjQ0Mi45NzggMCAuNTM2LjM3NS45MWwyLjc2IDIuNzczcS4zNi4zNjIuOTEuMzYyLjUzNiAwIC45MS0uMzQ4bDEuOTctMS45NTVxLjM3NS0uMzc1LjM3NS0uODk3em0tOS40MTUtOS40NDJxMC0uNTM2LS4zNzUtLjkxTDYuOTUgMy4xNnEtLjM3NC0uMzc0LS45MS0uMzc0LS41MjIgMC0uOTEuMzYyTDMuMTYgNS4xMDNxLS4zNzUuMzc1LS4zNzUuODk3IDAgLjUzNi4zNzUuOTFsMi43ODYgMi43ODdxLjM2Mi4zNjIuOTEuMzYyLjU2NCAwIC45NjUtLjQxNmwtLjI1My0uMjQ4cS0uMjEzLS4yMDgtLjI4OC0uMjg4dC0uMjAyLS4yNTQtLjE3NC0uMzQyLS4wNDctLjM2OHEwLS41MzYuMzc1LS45MXQuOTEtLjM3NnEuMjAyIDAgLjM3LjA0N3QuMzQuMTc0LjI1NS4yLjI4OC4yODguMjQ4LjI1NHEuNDQyLS40MTUuNDQyLS45Nzh6bTExLjk4NiA5LjQ0MnEwIDEuNjA3LTEuMTM3IDIuNzJsLTEuOTcgMS45NTRxLTEuMTEgMS4xMTItMi43MTggMS4xMTItMS42MiAwLTIuNzMyLTEuMTM4bC0yLjc2LTIuNzcycS0xLjExLTEuMTEyLTEuMTEtMi43MiAwLTEuNjQ2IDEuMTc4LTIuNzk4bC0xLjE3OC0xLjE4cS0xLjE1MiAxLjE4LTIuNzg2IDEuMTgtMS42MDcgMC0yLjczMi0xLjEyNUwxLjMzOCA4LjczMlEuMjEzIDcuNjA4LjIxMyA2VDEuMzUgMy4yODNsMS45Ny0xLjk1NVE0LjQzMi4yMTUgNi4wNC4yMTVxMS42MiAwIDIuNzMgMS4xMzhsMi43NiAyLjc3MnExLjExMiAxLjExMiAxLjExMiAyLjcyIDAgMS42NDYtMS4xOCAyLjc5OGwxLjE4IDEuMThxMS4xNTItMS4xOCAyLjc4Ni0xLjE4IDEuNjA3IDAgMi43MzIgMS4xMjVsMi43ODYgMi43ODZxMS4xMjUgMS4xMjUgMS4xMjUgMi43MzJ6Ii8+PC9zdmc+);
  background-size: 14px;
}

.staticMenu {
  background-color: #fafafa;
  border-top: 1px solid #bfbfbf;
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  border-radius: 4px 4px 0 0;
  padding: 4px 4px 3px 4px;
  box-sizing: border-box;
  margin: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.staticMenu > * {
  display: inline-block;
}

.staticMenu > * + * {
  margin-left: 5px;
}

.tooltipMenu {
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  color: #fff;
  border-radius: 4px;
  transition: opacity 0.75s;
  z-index: 1024;
  background-color: #fafafa;
  border: 1px solid #bfbfbf;
  padding: 3px 4px 0 4px;
}

.toolbar {
  position: relative;
  padding: 1px 18px 17px;
  margin: 0 -20px;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
}

.silentCrashHandler {
  box-sizing: border-box;
  user-select: none;

  padding: 10px;

  font-size: 11px;
  font-weight: 500;

  min-width: 0px;

  color: #f05252;
  background-color: #f6f5f4;

  border-radius: 4px;
  border: 1px solid #dddbd7;
}
