/* --------------------------------- THEMES --------------------------------- */

.contentPreview.theme-for-table {
  /* Content wrapper will cover entire cell (assuming it's padding is 12px 16px) */
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  margin: -12px -16px;
  padding: 12px 16px;
}
