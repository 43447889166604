.inlineBadge {
  user-select: none;

  display: inline-block;

  font-size: 10px;
  line-height: 15px;
  font-weight: 600;
  text-align: center;

  padding: 1px 6px;

  background-color: black;
  color: white;

  border-radius: 2px;
}
.inlineBadge.hasTooltip {
  cursor: help;
}

.tooltipContent {
  max-width: 220px;
  font-size: 13px;
  line-height: 16px;

  text-align: center;
}

/* ------------------------------ OUTLINED MODE ----------------------------- */

.inlineBadge.outlined {
  border: 1px solid transparent;
}

/* --------------------------------- THEMES --------------------------------- */

.inlineBadge.theme-default {
  background-color: #5d5d5c;
}
.inlineBadge.theme-light {
  background-color: #b4b2af;
}
.inlineBadge.theme-green {
  font-weight: 500;
  color: #0f9f6e;
  background-color: hsla(160, 83%, 34%, 0.15);
  border-color: hsla(160, 83%, 34%, 0.4);
}
.inlineBadge.theme-red {
  font-weight: 500;
  color: #f05252;
  background-color: hsla(0, 84%, 63%, 0.15);
  border-color: hsla(0, 84%, 63%, 0.4);
}
.inlineBadge.theme-purple {
  font-weight: 500;
  color: #9061f9;
  background-color: hsla(259, 93%, 68%, 0.15);
  border-color: hsla(259, 93%, 68%, 0.15);
}
.inlineBadge.theme-blue {
  font-weight: 500;
  color: #2675d1;
  background-color: hsla(212, 69%, 48%, 0.15);
  border-color: hsla(212, 69%, 48%, 0.15);
}
.inlineBadge.theme-yellow {
  font-weight: 500;
  color: #bfa13e;
  background-color: hsla(40, 95%, 67%, 0.15);
  border-color: hsla(40, 69%, 60%, 0.15);
}
.inlineBadge.theme-orange {
  font-weight: 500;
  color: #ff873d;
  background-color: hsla(23, 100%, 62%, 0.15);
  border-color: hsla(23, 100%, 62%, 0.15);
}
