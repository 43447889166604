.creditCard {
  position: relative;
  padding: 1px;
}

.creditCard .creditCardInput {
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  height: 32px;
  padding: 8px 15px;
  border-radius: 4px;
  position: relative;
  transition: box-shadow .1s ease-in-out;
}

.creditCard .creditCardInput:hover {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
}

.creditCard .creditCardInput.active {
  box-shadow: 0 1px 3px 0 #732A56;
}

.creditCard .creditCardInputBackground {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 34px;
  border-radius: 4px;
  background: #E7E7E7;
  transition: background .1s ease-in-out;
  position: absolute;
}

.creditCardInputBackground.active {
  background: #C9CEFB;
}

.creditCard.error .creditCardInputBackground {
  background: #D43D51 !important;
}

.creditCard.error .creditCardInput {
  box-shadow: 0 1px 3px 0 #D43D51;
}