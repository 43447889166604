.googleFontPicker {
  /* ... */
}

.placeholder,
.error {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.placeholder > *:not(:last-child),
.error > *:not(:last-child) {
  margin-right: 10px;
}

.error {
  color: #f05252;
}
