.cover {
  position: absolute;
  background-color: rgba(48, 47, 46, 0.07);
  top: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.cover.visible {
  opacity: 1;
}

.main {
  background-color: #FFFFFF;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 8px 8px 0 rgba(48, 47, 46, 0.07), 0 16px 16px 0 rgba(48, 47, 46, 0.07);
  width: 400px;
  right: -400px;
  position: absolute;
  top: 0;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  transition: opacity 0.3s ease-in-out, right 0.3s ease-in-out;
}

.box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.main.visible {
  right: 0;
  opacity: 1;
}

.header {
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EAE8E4;
  color: #454442;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}

.content {
  padding: 20px 30px;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 20px 30px;
  border-top: 1px solid #EAE8E4;
}

.buttons button:not(:last-child) {
  margin: 0 10px 0 0;
}

.loader {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.loader .spinner {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.loader .spinner.visible {
  opacity: 1;
}

.loader .checkmark {
  position: absolute;
  transform: scale(2.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.loader .checkmark.visible {
  opacity: 1;
}
