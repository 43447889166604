.container {
  overflow: hidden;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  min-height: calc(90vh - 60px);
  max-height: calc(90vh - 60px);

  padding: 20px 30px 30px 30px;
}
.container > *:not(:last-child) {
  margin-bottom: 15px;
}

.filters {
  display: flex;
  justify-content: space-between;
}
