.mediaCard {
  display: inline-flex;
  flex-direction: column;
  height: fit-content;

  padding: 18px 15px;

  font-size: 13px;
  line-height: 16px;

  background-color: white;
  border: 1px solid #eae8e4;

  border-radius: 6px;
}
.mediaCard > *:not(:last-child) {
  margin-bottom: 15px;
}

/* ---------------------------------- IMAGE --------------------------------- */

.image {
  height: 140px;

  border-radius: 6px;
  overflow: hidden;
}
.image img {
  width: 100%;
  object-fit: fill;
}

/* ---------------------------------- LABEL --------------------------------- */

.label > *:not(:last-child) {
  margin-bottom: 5px;
}
.title {
  font-weight: 600;
  color: #302f2e;
}
.description {
  color: #807e7c;
}

/* --------------------------------- BUTTONS -------------------------------- */

.buttons {
  display: flex;
  justify-content: space-between;
}
