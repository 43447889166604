.main .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.main .scaleSlider {
  margin: 10px 0 0 0;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.main .slider {
  width: 80%;
}

.main .sizeImage.small {
  margin: 0 7px 0 0;
  transform: scale(0.6);
}

.main .sizeImage.big {
  margin: 0 0 0 7px;
}
