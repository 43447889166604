.text * {
  vertical-align: middle;
}

/* Special cases of other kinds of components in text */
.text > *[data-component='button'] {
  display: inline-flex;
  font-weight: 600;
}
.text > *[data-component='icon'] {
  display: inline-block;
}
.text > *[data-component='icon'] svg {
  height: min-content !important;
}

/* Themes */
.theme-text {
  color: #302f2e;
}
.theme-header {
  color: #454442;
}
.theme-description {
  color: #807e7c;
}
.theme-detail {
  color: #b4b2af;
}

.theme-error {
  color: #f05252;
}
.theme-error path,
.theme-error svg {
  fill: #f05252;
  stroke: #f05252;
}

/* Sizes */
.size-regular {
  font-size: 13px;
  line-height: 16px;
}
.size-regular > *[data-component='icon'] svg {
  width: 10px;
  height: 16px;
}
.size-regular > *[data-component='icon'] path {
  transform: scale(0.7) translateX(1px);
}
.size-medium {
  font-size: 15px;
  line-height: 18px;
}
.size-large {
  font-size: 18px;
  line-height: 22px;
}

/* Weights */
.weight-regular {
  font-weight: 400;
}
.weight-medium {
  font-weight: 500;
}
.weight-semi-bold {
  font-weight: 600;
}
.weight-bold {
  font-weight: 700;
}
