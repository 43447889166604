/* -------------------------------- CONTAINER ------------------------------- */

.formPreview {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  overflow: hidden;

  background-color: #eae8e4;
}

.formPreview > * {
  flex-shrink: 0;
  flex-grow: 0;

  box-sizing: border-box;
  width: 100%;
}
.formPreview > .placeholder,
.formPreview > .form {
  flex-shrink: 1;
  flex-grow: 1;
}

/* --------------------------------- TOP BAR -------------------------------- */

.topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 36px;
  padding: 0 20px;

  background-color: #fbfaf9;
  border-bottom: 1px solid #dddbd7;

  z-index: 2;
}

.topBar label {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #807e7c;

  font-size: 13px;
  line-height: 16px;
  font-weight: 600;

  overflow: hidden;
}
.topBar label > *:not(:last-child) {
  margin-right: 10px;
}
.topBar label > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
