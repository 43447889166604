.afterIcon {
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}
.dateInput:hover .afterIcon {
  opacity: 0.7;
}
.dateInput.focused .afterIcon {
  opacity: 1;
}
