/* -------------------------------- CONTAINER ------------------------------- */

.searchBox {
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  color: #454442;
  background-color: #ffffff;
}

.searchBox.theme-inline {
  width: 280px;

  border: 1px solid #b4b2af;
  border-radius: 4px;
}
.searchBox.theme-inline > *:not(:last-child) {
  border-bottom: 1px solid #b4b2af;
}

.searchBox.theme-block {
  width: 100%;
}
.searchBox.theme-block > *:not(:last-child) {
  border-bottom: 1px solid #b4b2af;
}

/* ---------------------------------- INPUT --------------------------------- */

.searchBar {
  box-sizing: border-box;

  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  align-items: center;

  padding: 12px 16px;

  height: 45px;
}

.searchBar > .icon {
  flex-shrink: 0;
  flex-grow: 0;

  margin-right: 8px;
}
.searchBar > input {
  flex-shrink: 1;
  flex-grow: 1;

  padding: 0;
  margin: 0;
  border: none;
  outline: none;

  font-weight: 400;
  color: #454442;
}
.searchBar > input::placeholder {
  color: #807e7c;
}

/* --------------------------------- CONTENT -------------------------------- */

.searchResults {
  flex-shrink: 1;
  flex-grow: 1;

  overflow-y: auto;

  display: flex;
  flex-direction: column;

  min-height: 0;
  max-height: 180px;
}

.searchResults > .placeholder {
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px 12px;

  word-break: break-all;
  text-align: center;

  color: #807e7c;
  font-weight: 500;

  background-color: #fbfaf9;

  min-height: 60px;
}
