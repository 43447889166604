.helpCenter {
  width: 550px;
}
.helpCenter > *:not(:last-child) {
  margin-bottom: 12px;
}
.helpCenter > * {
  padding: 0px 20px;
}

/* --------------------------------- HEADER --------------------------------- */

.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 16px;
  padding-bottom: 16px;

  border-bottom: 1px solid #dddbd7;
}
.headerSection > * {
  line-height: 1.5em;
}

.closeButton {
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-right: -4px;
}

/* --------------------------------- MIDDLE --------------------------------- */

.autoPlaySection {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-weight: 600;
  font-size: 13px;
  color: #807e7c;
}
.autoPlaySection > *:not(:last-child) {
  margin-right: 10px;
}

.tutorialPickerSection {
  padding: 6px 24px;
}

/* --------------------------------- FOOTER --------------------------------- */

.knowledgeBaseSection {
  display: flex;
  align-items: center;

  padding-top: 8px;
  padding-bottom: 16px;
  background-color: #fbfaf9;

  border-top: 1px solid #dddbd7;
}
.knowledgeBaseSection > * {
  line-height: 12px;
}

.knowledgeBaseSection > *:not(:last-child) {
  margin-right: 10px;
}
