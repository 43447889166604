.feature {
  box-sizing: border-box;
  height: 100%;
  display: flex;

  padding: 20px;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.feature > *:not(:last-child) {
  margin-right: 10px;
}
.content > *:not(:last-child) {
  margin-bottom: 10px;
}

/* Switch and buttons */
.header {
  display: flex;
  align-items: center;
  height: 20px;
}
.header > *:not(:last-child) {
  margin-right: 10px;
}

/* Logo */
.logo {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.logo img {
  width: 32px;
}

/* Footer */
.tagIcon {
  display: inline-block;
  margin-right: 7px;
}

/* Text */
.title {
  color: #454442;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}
.description {
  color: #807e7c;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  flex-grow: 1;
}
.tags {
  display: flex;
}
.tags > *:not(:last-child) {
  margin-right: 10px;
}
.tag {
  color: #b4b2af;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
}
.tag:hover,
.tag:focus {
  opacity: 0.8;
}
.tag:active {
  opacity: 0.6;
}

.links {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
