.urlBar {
  height: 40px;
  width: 100%;
  min-width: 0;
}

.urlFieldWrapper {
  box-sizing: border-box;

  flex-shrink: 1;
  flex-grow: 1;

  display: flex;
  align-items: center;

  width: 100%;
  overflow: hidden;

  height: 24px;
  background-color: rgba(230, 230, 230, 0.64);
  border-radius: 6px;

  padding: 4px 10px;
}
.urlField {
  overflow: hidden;
  letter-spacing: -0.3px;
  white-space: nowrap;

  border: none;
  outline: none;

  color: #807e7c;
}
.urlField strong {
  font-weight: 600;
  color: #302f2e;
}
