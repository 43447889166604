/* tabs */
.tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tabs .tabsItem {
  flex-grow: 1;
  background-color: #f6f5f4;
  height: 46px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eae8e4;

  color: #b4b2af;
}
.tabs .tabsItem > *:not(:last-child) {
  margin-right: 6px;
}

.tabs .tabsItem.withClick {
  cursor: pointer;
}

.tabs .tabsItem svg {
  position: relative;
  top: 2px;
}

.tabs .tabsItem:not(:last-child) {
  border-right: 1px solid #eae8e4;
}

.tabs .tabsItem.active {
  background: #fbfaf9;
  border-bottom: 1px solid #fbfaf9;
}

.tabs .tabsItem.active {
  color: #454442;
}
