/* Positioning in fixed parent */
.scrollableAbsoluteWrapper {
  box-sizing: border-box;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  overflow-x: auto;
}

.scrollableWrapper {
  display: flex;

  /* content is centered */
  justify-content: flex-start;
}

.scrollable {
  flex-shrink: 1;

  /* Content centering, size and padding */
  margin: auto;
  padding: 50px;
  width: 900px;

  /* Scroll on small size (always usable) */
  min-width: 550px;
  /* Possible alternative - allow full shrink (better but requires gui fixes to look nice) */
  /* min-width: 0; */
}
/* Main content and place where children are added */
.scrollable > *:not(:last-child),
.children > *:not(:last-child) {
  margin-bottom: 20px;
}

.children.disabled {
  opacity: 0.4;
  pointer-events: none;
  user-select: none;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 20px;
}
.header > *:first-child {
  flex-grow: 1;
}
.header > *:not(:last-child) {
  margin-right: 10px;
}
