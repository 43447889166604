.formRow {
  margin: 0 0 15px 0;
  width: 100%;
  position: relative;
  user-select: none;
}

.formRow.hide {
  display: none;
}

.formRow label {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  margin: 0 0 7px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.formRow label .toggle {
  margin: 0 8px 0 0;
}

.formRow label .required {
  color: #FA6D78;
  padding: 0 0 0 1px;
}

.formRow .errorMessage {
  color: #F05252;
  font-size: 13px;
  line-height: 16px;
  margin: 5px 0;
}

.formRow .description {
  color: #807E7C;
  font-size: 12px;
  line-height: 16px;
}
