/* ------------------------------ VISIBLE PART ------------------------------ */

.placeholder {
  color: #b4b2af;
  font-weight: 400;
}

.inlineSelect {
  display: inline-flex;
}
.inlineSelect.error {
  border-bottom: 1px solid #f05252;
}

.inlineSelectPreview {
  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  user-select: none;
}
.disabled .inlineSelectPreview {
  cursor: not-allowed;
}

.inlineSelectPreview > *:not(:last-child) {
  margin-right: 8px;
}

.currentOptionLabel {
  text-align: left;

  flex-grow: 1;
  flex-shrink: 1;

  line-height: 16px;

  font-size: 13px;
  font-weight: 600;
  color: #302f2e;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-variant-numeric: tabular-nums;
}
.disabled .currentOptionLabel {
  color: #cbc9c5;
}

.dropdownIcon {
  transform: translateY(1px);
}

/* -------------------------------- DROPDOWN -------------------------------- */

.options {
  padding: 5px 0;
  user-select: none;
}

.option {
  cursor: pointer;
  padding: 10px 15px;
}
.option:hover,
.option.active {
  background-color: #f6f5f4;
}
.option.active {
  cursor: default;
}
