/* ----------------------------- MAIN CONTAINER ----------------------------- */

.sortingMatrix {
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;

  min-height: 50px;
}

.placeholder {
  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  color: #807e7c;
  padding: 10px 20px;
}

/* ------------------------------ ROWS ELEMENTS ----------------------------- */

.sortingRow {
  box-sizing: border-box;

  display: flex;
  align-items: center;
  width: 100%;

  padding: 10px 20px;
}
.sortingRow:nth-child(even) {
  background-color: #fbfaf9;
}

.label {
  flex-grow: 0;
  flex-shrink: 0;

  font-weight: 500;
  color: #b4b2af;

  margin-right: 15px;
  user-select: none;

  white-space: nowrap;
}
.columnInput {
  display: flex;
  align-items: center;

  flex-grow: 0;
  flex-shrink: 0;
  width: 220px;

  margin-right: 15px;
}
.directionInput {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 20px;
  min-width: 0;
}
.deleteButton {
  flex-grow: 0;
  flex-shrink: 0;
}
