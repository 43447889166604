/* -------------------------------- CONTAINER ------------------------------- */

.otpInput {
  overflow: hidden;
  width: fit-content;

  background-color: #ffffff;
  border: 1px solid #dddbd7;
  border-radius: 4px;

  box-shadow: 0px 0px 0px 1px transparent, 0 1px 1px 0 rgba(48, 47, 46, 0.07);

  transition: box-shadow 0.2s ease-in-out, border 0.1s ease-in-out, background 0.2s ease-in-out;
}

/* Conditional states */
.otpInput:hover {
  border-color: #b4b2af;
}
.otpInput:focus-within {
  box-shadow: 0px 0px 0px 3px #eae8e4, 0 1px 1px 0 rgba(48, 47, 46, 0.07);
  border-color: #b4b2af;
}

.otpInput.error {
  border-color: #d43d51;
}
.otpInput:focus-within.error {
  border-color: #d43d51;
  box-shadow: 0px 0px 0px 3px rgba(212, 61, 81, 0.3), 0 1px 1px 0 rgba(48, 47, 46, 0.07);
}

.otpInput.disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-image: linear-gradient(rgba(47, 46, 45, 0.04), rgba(47, 46, 45, 0.04));
}

/* --------------------------- RENDERER CONTAINER --------------------------- */

.reactCodeInput,
.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 180px !important;
  height: 40px !important;
}

.placeholder {
  background-color: #fbfaf9;
}

/* --------------------------------- NUMBERS -------------------------------- */

.reactCodeInput input {
  box-sizing: border-box;

  font-family: 'Inter', sans-serif !important;
  color: #656565 !important;

  caret-color: #656565 !important;

  border: none !important;
}
.reactCodeInput input:first-child {
  width: 40px !important;
  padding-left: 15px !important;
}
.reactCodeInput input:last-child {
  width: 40px !important;
  padding-right: 15px !important;
}

.reactCodeInput input:focus {
  color: #656565;
  caret-color: #656565 !important;
}

.reactCodeInput input::placeholder {
  color: #e0e0e1;
}

/* ------------------------------- ANIMATIONS ------------------------------- */

.reactCodeInput,
.reactCodeInput * {
  animation: none !important;
  transition: none !important;
}
