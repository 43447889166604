.badgeWrapper {
  position: relative;
}

.badge {
  box-sizing: border-box;
  position: absolute;

  top: 0;
  right: 0;
  transform: translateX(calc(100% - 6px)) translateY(calc(-100% + 8px));

  z-index: 10;
}
.textBadge {
  font-size: 10px;
  display: flex;
  align-items: center;
  padding: 2px 4px;

  border-radius: 2px;
}
.textBadge > *:not(:last-child) {
  margin-right: 4px;
}

.theme-black {
  color: #fbfaf9;
  background: #5d5d5c;
}
