/* -------------------------------------------------------------------------- */
/*                               LOADER TOOLTIP                               */
/* -------------------------------------------------------------------------- */

.loaderTooltip {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
  padding: 20px 40px;

  border-radius: 4px;
}

/* -------------------------------------------------------------------------- */
/*                                MAIN TOOLTIP                                */
/* -------------------------------------------------------------------------- */

/* -------------------------------- CONTAINER ------------------------------- */

.tooltip {
  display: flex;
  flex-direction: column;

  font: inherit;

  background-color: white;
  min-width: 300px;
  max-width: 400px;

  border-radius: 4px;
  overflow: hidden;
}

/* --------------------------------- CONTENT -------------------------------- */

.tooltipContent {
  display: flex;
  flex-direction: column;

  color: #454442;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  line-height: 1.5;

  padding: 24px;
}
.tooltipContent > *:not(:last-child) {
  margin-bottom: 14px;
}

.tooltipContentTitle {
  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: 600;
  line-height: 1.3em;

  color: #454442;
}
.tooltipContentTitle > *:not(:last-child) {
  margin-right: 0.75ch;
}

.tooltipContentTitle.size-large {
  font-size: 22px;
}

.tooltipContentInner {
  text-align: left;
}
.tooltipContentInner *[data-component='icon'] {
  display: inline-block;
}

.tooltipContentInner > *:not(:last-child) {
  margin-bottom: 14px;
}

.tooltipContentInner img {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;

  border: 1px solid #dddbd7;

  border-radius: 4px;
}

.tooltipContentInner ul {
  list-style-type: circle;
}

.tooltipContentInner small {
  font: inherit;
  font-size: 13px;
  font-weight: 400;

  opacity: 0.5;
}

.tooltipContentInner dfn {
  font: inherit;
  font-weight: 600;

  padding: 0px 2px;
  margin: 0px 2px;
  background-color: #f6f5f4;
  border-radius: 2px;

  outline: 1px solid #eae8e4;
}

/* --------------------------------- BUTTONS -------------------------------- */

.tooltipButtons {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin: 12px;
  margin-top: 0;
  padding-top: 12px;

  border-top: 1px solid #eae8e4;
}
.tooltipButtons > *:not(:last-child) {
  margin-right: 10px;
}
