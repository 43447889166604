.radio {
  color: #454442;
  font-size: 14px;
  font-weight: 400;
  line-height: 142%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  min-height: 28px;
}

.radio.inline {
  display: inline-flex;
}

.radio.block {
  display: flex;
}

.radio .circle {
  background-color: #ffffff;
  border-radius: 16px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  position: relative;
  box-sizing: border-box;
  margin-right: 8px;
  user-select: none;
  border: 1px solid #b4b2af;
  /*transition: border 0.15s ease-in-out;*/
}

/* 
 * states 
*/

/* hover */
.radio:hover .circle {
  border: 1px solid #807e7c;
}

/* active & checked */
.radio:active .circle,
.radio.checked .circle {
  border: 5px solid #454442;
}

/* disabled */
.radio.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.radio.disabled .circle {
  background-color: #fafafa;
}

/* noText */
.radio.noText {
  justify-content: center;
}

.radio.noText .circle {
  margin: 0;
}
