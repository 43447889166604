.openCloseIcon {
  position: relative;
  transition: transform 0.4s ease-in-out;
}
.open {
  transform: rotateY(180deg) translateY(-50%);
}

.openCloseIcon > *:last-child {
  position: absolute;
  opacity: 0;
  transform: rotateX(180deg);
  transition: opacity 0.4s ease-in-out;
}
.open > *:last-child {
  opacity: 1;
}
