.box {
  outline: none;
  z-index: 127;
}
.box[data-reference-hidden] {
  display: none;
}

.innerBox {
  position: relative;
}

.content {
  box-sizing: border-box;
  border-radius: 3px;
}

/* Popover shadow */
.theme-popover-black,
.theme-popover-white {
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.4)) drop-shadow(0 4px 4px rgba(48, 47, 46, 0.07))
    drop-shadow(0 8px 8px rgba(48, 47, 46, 0.07)) drop-shadow(0 16px 16px rgba(48, 47, 46, 0.07));
}

/* Content container  */
.theme-popover-black > .content,
.theme-black > .content {
  background-color: #1b1b1a;

  font-size: 13px;
  font-weight: 500;
  color: #eae8e4;
}
.theme-popover-white > .content,
.theme-white > .content {
  background-color: #fff;

  font-size: 13px;
  font-weight: 500;
  color: #454442;
}
.theme-black > .content,
.theme-white > .content {
  padding: 7px 10px;
}
.theme-popover-black > .content,
.theme-popover-white > .content {
  border-radius: 6px;
  overflow: hidden;
}

/* Arrow style */
.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}
.arrow {
  visibility: hidden;
}
.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg) translateX(-3px) translateY(3px);
}
.theme-popover-black > .arrow::before,
.theme-black > .arrow::before {
  background: #1b1b1a;
}
.theme-popover-white > .arrow::before,
.theme-white > .arrow::before {
  background: #fff;
}

/* Arrow conditional positioning */
.box[data-placement^='top'] > .innerBox > .arrow {
  bottom: -2px;
}
.box[data-placement^='bottom'] > .innerBox > .arrow {
  top: -2px;
}
.box[data-placement^='left'] > .innerBox > .arrow {
  right: 0px;
}
.box[data-placement^='right'] > .innerBox > .arrow {
  left: -0px;
}

.textTooltip {
  font-size: 13px;
  line-height: 16px;
  max-width: 220px;
  text-align: center;
  user-select: none;
}

/* Animation */
@keyframes scaleUp {
  from {
    transform: scale(0.9);
    animation-timing-function: ease-out;
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.innerBox {
  animation-duration: 0.15s;
  animation-name: scaleUp;

  transition: opacity 0.15s ease-in, transform 0.15s ease-in;
}
.hiding > .box > .innerBox {
  opacity: 0;
  transform: scale(0.9);
}

.box[data-placement^='top'] > .innerBox {
  transform-origin: bottom;
}
.box[data-placement^='bottom'] > .innerBox {
  transform-origin: top;
}
.box[data-placement^='bottom-end'] > .innerBox {
  transform-origin: top right;
}
.box[data-placement^='left'] > .innerBox {
  transform-origin: right;
}
.box[data-placement^='right'] > .innerBox {
  transform-origin: left;
}

.backdrop {
  pointer-events: none;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 126;

  background: rgba(0, 0, 0, 0.3);
  opacity: 0;

  transition: opacity 0.2s ease-in-out;
}
.backdrop.active {
  opacity: 1;
}

/* Pre-defined utility components */
.popoverHr {
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  border: none;
  height: 1px;
}
.theme-black .popoverHr,
.theme-popover-black .popoverHr {
  background-color: #454442;
}
.theme-white .popoverHr,
.theme-popover-white .popoverHr {
  background-color: #f6f5f4;
}

.popoverButton {
  padding: 10px 16px;
  padding-right: 35px;
  justify-content: center;
}
.popoverGroup.compact .popoverButton {
  padding: 6px 12px;
}
.popoverButton > *:not(:last-child) {
  /* Has a bit larger space between icon than a regular button */
  margin-right: 10px !important;
}

.theme-popover-black .popoverButton:hover {
  background-color: #302f2e;
}
.theme-popover-white .popoverButton:hover {
  background-color: #f6f5f4;
}

.popoverGroup {
  margin: 6px 0;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
}
.theme-popover-black .popoverGroup.separators > *:not(:last-child) {
  border-bottom: 1px solid #302f2e;
}
.theme-popover-white .popoverGroup.separators > *:not(:last-child) {
  border-bottom: 1px solid #f6f5f4;
}

.theme-popover-black ::-webkit-scrollbar {
  border-color: transparent;
}
.theme-popover-black ::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
}
.theme-popover-black ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
