.content {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 60px);
  max-height: calc(90vh - 60px);
  overflow-y: auto;
}

ul.summary {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.summary .leftTitle:not(:first-child) {
  margin: 40px 0 10px 0;
}

ul.summary li {
  background-color: #FFFFFF;
  border: 1px solid #dddbd7;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 15px 15px 38px;
  position: relative;
}

ul.summary li:not(:last-child) {
  margin: 0 0 15px 0;
}

ul.summary li svg {
  position: absolute;
  left: 15px;
  top: 16px;
}

ul.summary li svg * {
  fill: #000000;
}

ul.summary li span {
  color: #302F2E;
  display: block;
  font-size: 14px;
  line-height: 18px;
}

ul.summary li p {
  color: #807E7C;
  display: block;
  padding: 0;
  margin: 3px 0 0 0;
  font-size: 13px;
  line-height: 18px;
  position: relative;
}

ul.payments {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.payments li {
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}

ul.payments .left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  color: #5D6188;
}

ul.payments .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: #5D6188;
}

ul.payments .amount {
  color: #5D6188;
}

ul.payments .status {
  color: #656565;
  font-size: 12px;
}

ul.payments .createdAt {
  font-weight: 400;
  color: #656565;
  font-size: 12px;
  margin: 2px 0 0 0;
}

.head {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.refund {
  margin: 30px 0 0 0;
}
