.content {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 84px);
  max-height: calc(90vh - 84px);
  overflow-y: auto;
}

/* colors */
.blue {
  color: #2675d1 !important;
  font-weight: 500 !important;
}

.purple {
  color: #9061f9 !important;
  font-weight: 500 !important;
}

.orange {
  color: #ff623d !important;
  font-weight: 500 !important;
}

/* head */
.head {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  border-bottom: 1px solid #eae8e4;
}

.head .item {
  width: 25%;
  border-right: 1px solid #eae8e4;
  padding: 0 20px;
  min-height: 145px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
}

.head .item:last-child {
  border: 0;
}

.head .item > div {
  width: 100%;
}

.head .item .name {
  font-size: 13px;
  line-height: 16px;
}

.head .item .price {
  color: #454442;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}

.head .item .priceDesc {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
}

.head .item .button {
  margin: 15px auto 20px auto;
}

/* body */
.body {
}

/* -------------------------------- ROW CELLS ------------------------------- */

.body .row {
  box-sizing: border-box;

  font-size: 13px;
  line-height: 16px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.body .row > div {
  box-sizing: border-box;

  width: 25%;
  padding: 10px 15px;

  font-weight: 400;
  color: #807e7c;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  border: 1px solid #eae8e4;
  border-left: none;
  border-top: none;
}
.body .row > div:last-child {
  padding-right: 5px;
  border-right: 0;
}
.body .row > div:first-child {
  padding-left: 5px;
}

.body .row > div.label {
  font-weight: 400;
  color: #807e7c;
}

/* --------------------------------- HEADER --------------------------------- */

.body .header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.body .header div {
  color: #454442;
  font-size: 15px;
  line-height: 18px;
  min-height: 43px;
  font-weight: 600;
  padding: 25px 0 0 0;
  width: 25%;
  border-right: 1px solid #eae8e4;
  box-sizing: border-box;
}

.body .header div:last-child {
  border: 0;
}

/* spacer */
.body .spacer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.body .spacer div {
  min-height: 20px;
  width: 25%;
  border-right: 1px solid #eae8e4;
  box-sizing: border-box;
}

.body .spacer div:last-child {
  border-right: 0;
}

/* plan colors */
.trial {
  font-weight: 500;
  text-transform: capitalize;
}

.personal {
  color: #2675d1;
  font-weight: 500;
  text-transform: capitalize;
}

.starter {
  color: #9061f9;
  font-weight: 500;
  text-transform: capitalize;
}

.pro {
  color: #ff623d;
  font-weight: 500;
  text-transform: capitalize;
}

/* ------------------------------ HIGHLIGHTING ------------------------------ */

.head > *,
.header > *,
.row > *,
.spacer > * {
  transition: background-color 0.5s ease-in-out;
}
.highlighted-plan-personal .head > *:nth-child(2),
.highlighted-plan-personal .header > *:nth-child(2),
.highlighted-plan-personal .row > *:nth-child(2),
.highlighted-plan-personal .spacer > *:nth-child(2) {
  background-color: rgba(255, 250, 200, 0.5);
}
.highlighted-plan-starter .head > *:nth-child(3),
.highlighted-plan-starter .header > *:nth-child(3),
.highlighted-plan-starter .row > *:nth-child(3),
.highlighted-plan-starter .spacer > *:nth-child(3) {
  background-color: rgba(255, 250, 200, 0.5);
}
.highlighted-plan-pro .head > *:nth-child(4),
.highlighted-plan-pro .header > *:nth-child(4),
.highlighted-plan-pro .row > *:nth-child(4),
.highlighted-plan-pro .spacer > *:nth-child(4) {
  background-color: rgba(255, 250, 200, 0.5);
}

.row {
  transition: background-color 0.5s ease-in-out;
}
.row.highlighted {
  background-color: rgba(255, 250, 200, 0.5);
}

/* moreResponses */
.moreResponses {
  position: relative;
}

.moreResponsesLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #ff623d;
  font-weight: 500;
  width: 100%;
}

.moreResponsesCover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.moreResponsesOptions {
  position: absolute;
  top: 37px;
  right: 0;
  background-color: #FFFFFF;
  border: 1px solid #DDDBD7;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(48, 47, 46, 0.07), 0 16px 16px 0 rgba(48, 47, 46, 0.07);
  width: 250px;
  padding: 5px 0;
  user-select: none;
}

.moreResponsesOptions .label {
  padding: 10px 15px;
  border-bottom: 1px solid #DDDBD7;
}

.moreResponsesOptions .label strong {
  color: #454442;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  margin: 0 0 5px 0;
  display: block;
}

.moreResponsesOptions .label p {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.moreResponsesOptions .option {
  padding: 0 15px;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  cursor: pointer;
}

.moreResponsesOptions .option:hover {
  background-color: #F6F5F4;
}

.moreResponsesOptions .option:active {
  background-color: #EAE8E4;
}
