.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0 0 0;
  padding: 0;
  box-sizing: border-box;
}

.main li {
  min-width: 30px;
  min-height: 30px;
  margin: 0 14px 14px 0;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
}

.main li:hover:after {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  border: 2px solid #FFFFFF;
}

.main li.selected:after {
  background-image: url(~assets/images/small-check.svg);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  border: 2px solid #FFFFFF;
  cursor: default;
}

.main li:after {
  background: transparent;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  border: 2px solid transparent;
  display: block;
  content: '';
  box-sizing: border-box;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
}

.main li span {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: block;
}