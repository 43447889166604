/* -------------------------------- CONTROLS -------------------------------- */

.logsControls {
  display: flex;
  justify-content: space-between;

  font-size: 11px;
  color: #807e7c;
}
.logsControls > * {
  display: flex;
  align-items: center;
}
.logsControls > *:not(:last-child),
.logsControls > * > *:not(:last-child) {
  margin-right: 15px;
}

/* ----------------------------- LOGS CONTAINER ----------------------------- */

.logsOuterContainer {
  overflow: hidden;

  flex-grow: 1;
  flex-shrink: 1;
  position: relative;

  display: flex;
  flex-direction: column;

  background-color: #fbfaf9;

  border: 1px solid #dddbd7;
  border-radius: 4px;
}
.logsOuterContainer.loading {
  cursor: wait;
}

.logsContainer {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;

  display: flex;
  flex-direction: column;

  transition: opacity 0.2s ease-in-out;
}
.logsContainer > *:not(:last-child) {
  border-bottom: 1px solid #dddbd7;
}

.loading .logsContainer {
  pointer-events: none;
  opacity: 0.3;
}

.loader,
.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder {
  font-weight: 500;
  opacity: 0.5;
}

/* ----------------------------------- LOG ---------------------------------- */

.log {
  display: flex;

  padding: 15px;
}

.log > * {
  flex-shrink: 0;
  flex-grow: 0;
}
.log > *:not(:last-child) {
  margin-right: 15px;
}

/* ------------------------------- LOG CONTENT ------------------------------ */

.logContent {
  min-width: 0;
  flex-shrink: 1;
  flex-grow: 1;
}
.logContent > *:not(:last-child) {
  margin-bottom: 5px;
}

.logTags {
  width: 72px;
  white-space: nowrap;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.logTags > *:not(:last-child) {
  margin-bottom: 5px;
}

.logText {
  word-break: break-all;

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #454442;
}

.logDate {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #807e7c;
}

.logActions {
  display: flex;
  flex-direction: column;

  align-items: stretch;
}
.logActions > *:not(:last-child) {
  margin-bottom: 5px;
}
.logActions > * {
  width: unset;
}
