.titlePreview {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.titlePreview > *:not(:last-child) {
  margin-bottom: 5px;
}

.label,
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

/* --------------------------------- THEMES --------------------------------- */

.titlePreview.titlePreview.theme-for-table > * {
  white-space: nowrap;
}

.titlePreview.theme-for-table > .description {
  color: #807e7c;
}

.titlePreview.theme-for-rows {
  max-width: 100%;
}
.titlePreview.titlePreview.theme-for-rows > * {
  word-break: break-all;
}
