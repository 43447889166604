.notification {
  display: flex;

  font-size: 12px;
  font-weight: 600;

  color: #f05252;

  cursor: pointer;
}
.notification > span {
  margin-left: 5px;
}
