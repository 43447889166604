.cover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 64;
}

.main {
  background-color: #1b1b1a;
  border-radius: 4px;
  box-shadow: 0 8px 17px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  box-sizing: border-box;
  z-index: 128;
  user-select: none;
}

.main:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #1b1b1a;
  border-width: 6px;
  margin-left: -6px;
}

.main ul {
  list-style: none;
  padding: 7px 0;
  margin: 0;
  box-sizing: border-box;
}

.label,
.back {
  color: #eae8e4;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 7px 7px 7px 20px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.back {
  cursor: pointer;
}

.back svg {
  transform: rotate(90deg);
  margin: 0 10px 0 0;
}

.link,
.action,
.delete {
  color: #eae8e4;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  padding: 7px 20px;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;

  font-weight: 500;
}

.delete {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.action.selected {
  color: #ffffff;
  font-weight: 500;
}

.action.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}

.hr {
  border: none;
  width: 100%;
  height: 1px;
  background: #454442;
  margin: 7px 0;
  box-sizing: border-box;
}

.list {
  color: #eae8e4;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  padding: 7px 7px 7px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.list svg {
  transform: rotate(265deg);
}

.link:hover,
.action:hover,
.delete:hover,
.list:hover,
.back:hover {
  background: #151413;
}

.link:active,
.action:active,
.delete:active,
.list:active,
.back:active {
  background: #000000;
}

.scrollArea {
  max-height: 250px;
  box-sizing: border-box;
  overflow-y: auto;
}

.deleteLoader {
  width: 18px;
  height: 18px;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.delete:active .deleteLoader {
  opacity: 1;
}

.deleteHint {
  color: #eae8e4;
  font-size: 12px;
  line-height: 14px;
  background-color: #1b1b1a;
  border-radius: 4px;
  position: absolute;
  text-align: center;
  width: 100px;
  right: -125px;
  padding: 6px 10px;
}

.deleteHint:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #1b1b1a;
  border-width: 4px;
  margin-top: -4px;
}
