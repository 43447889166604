.content {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 60px);
  max-height: calc(90vh - 60px);
  overflow-y: auto;
}

.invite {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.inviteInput {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 110px);
}

.header {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header .left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header .right {
  color: #807E7C;
  font-size: 11px;
  line-height: 13px;
}

.users {
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 0 0;
  padding: 0;
}

.users li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.users li.usersHead {
  border-bottom: 1px solid #EAE8E4;
  padding: 10px 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.users li.usersBody {
  padding: 10px 0;
}

.users li:last-child {
  border-bottom: none;
}

.users li .info {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.users li .info .infoAvatar {
  margin: 0 10px 0 0;
}

.users li .info .infoText {

}

.users li .info.notActive .infoText,
.users li .info.notActive .infoAvatar  {
  opacity: 0.5;
}

.users li .info .infoText p {
  color: #454442;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  padding: 0;
}

.users li .info .infoText span {
  color: #807E7C;
  font-size: 11px;
  line-height: 13px;
}

.loader {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
