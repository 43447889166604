.slider {
  user-select: none;
  min-height: 50px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 15px 20px 15px;
}

.slider .sliderLabel {
  color: #807E7C;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  min-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.slider .sliderHint {
  margin: 8px 0 0 0;
  color: #807E7C;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.slider .sliderHint svg {
  margin: 0 8px 0 0;
}

.slider .sliderHint svg * {
  fill: #DBDAD8;
}

.slider.borderTop {
  border-top: 1px solid #EAE8E4;
}

.slider.borderBottom {
  border-bottom: 1px solid #EAE8E4;
}
