.description {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
}

.header {
  margin: 20px 0 15px 0;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0 0 0;
}

.error {
  color: #FFFFFF;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  background-color: #F05252;
  border-radius: 4px;
  padding: 15px 45px;
  margin: 15px 0;
}
