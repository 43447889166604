.content {
  min-width: 0;
  width: 500px;
  max-width: 80vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #282f2e;

  padding: 20px 0;
}
.content > *:not(:last-child) {
  margin-bottom: 20px;
}

.header {
  margin-top: 10px;
  padding: 0 10px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  user-select: none;
}
.header > *:not(:last-child) {
  margin-bottom: 5px;
}
.header > .title {
  font-size: 24px;
  font-weight: 500;
}
.header > .description {
  max-width: 400px;
  color: #807e7c;
}

.details {
  width: 100%;

  border-top: 1px solid #eae8e4;
}
.details .message {
  box-sizing: border-box;

  width: 100%;
  height: 150px;

  white-space: pre;

  overflow: auto;

  font-family: 'Courier New', monospace;

  color: #f6f5f4;
  background-color: #282f2e;

  padding: 15px 20px;
}

.buttons {
  box-sizing: border-box;

  width: 100%;
  display: flex;
  justify-content: flex-end;

  padding: 0px 20px;
}
.buttons > *:not(:last-child) {
  margin-right: 10px;
}
