.content label {
  color: #454442;
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}

.content label .badge {
  background-color: #0D9D9D;
  border-radius: 4px;
  height: 18px;
  line-height: 18px;
  padding: 0 5px;
  margin: 0 0 0 10px;
  color: #FFFFFF;
  font-size: 10px;
  display: block;
}

.content p {
  margin: 0;
  padding: 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
  margin: 0 0 15px 0;
}

.content span.link,
.content a {
  text-decoration: underline;
  color: #515EF3;
  cursor: pointer;
}

.content .test {
  background: #FBFAF9;
  border: 1px solid #EAE8E4;
  padding: 15px;
  box-sizing: border-box;
  margin: 30px 0 0 0;
  position: relative;
}

.content .test.success {
  background: rgba(15, 159, 110, 0.1);
  border-color: rgba(15, 159, 110, 1);
}

.content .test.error {
  background: rgba(240, 82, 82, 0.1);
  border-color: rgba(240, 82, 82, 1);
}

.content .test.success p,
.content .test.error p {
  color: #000;
}

.content .test .testLabel {
  color: #454442;
  font-size: 13px;
  line-height: 22px;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.content .test .spinner {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}