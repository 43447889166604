/*
    Styles for popups that are related to how popups looks around 
    another popups and on the screen and not to popups thyself.
*/

/* -------------------------------- SNACKBARS ------------------------------- */

/* Margins and offset */
.snackbarContainer {
  transform: translate(-16px, -16px);
}
.snackbarContainer > *:not(:first-child) {
  margin-top: 16px;
}
