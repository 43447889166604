.userAgentPreview {
  white-space: pre;
}

/* --------------------------------- THEMES --------------------------------- */

.userAgentPreview.theme-for-table {
  font-size: 11px;
}
.userAgentPreview.theme-for-rows {
  max-width: 100%;
  word-break: break-all;
}
