.modalContent {
  position: relative;

  min-width: 350px;
}

/* --------------------------------- HEADER --------------------------------- */

.header {
  padding-bottom: 14px;
  border-bottom: 1px solid #eae8e4;
}

/* --------------------------------- CONTENT -------------------------------- */

.content {
  padding: 20px 0;
}
.content > *:not(:last-child) {
  margin-bottom: 8px;
}

/* --------------------------------- BUTTONS -------------------------------- */

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.buttons > *:not(:last-child) {
  margin-right: 20px;
}

/* --------------------------------- THEMES --------------------------------- */

.theme-contained .content {
  padding: 20px 20px;
}

.theme-contained .header {
  padding: 20px 20px;
  border-bottom: 1px solid #dddbd7;
}

.theme-contained .buttons {
  padding: 16px 20px;
  background: #fbfaf9;
  border-top: 1px solid #dddbd7;
}
