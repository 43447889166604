.list {
  line-height: 1.5;
}
.list {
  list-style-position: inside;

  margin: 0;
  padding: 0;
}

.question {
  opacity: 0.5;
}

/* --------------------------------- THEMES --------------------------------- */

.listPreview.theme-for-table {
  /* with this list cells are more visually aligned with regular text cells */
  margin: -4px;
}

