.imagesPreview {
  display: flex;

  flex-wrap: wrap;
  width: max-content;
}

.image {
  position: relative;

  cursor: pointer;
  user-select: none;

  object-fit: contain;

  border-radius: 4px;

  background-color: white;
  border: 1px solid #cbc9c5;
}

.modalImage {
  object-fit: contain;
  max-width: 70vw;
  max-height: 70vh;
}

/* --------------------------------- THEMES --------------------------------- */

.imagesPreview.theme-for-table {
  /* negative margin used to imitate behavior of flex gap and to better align images in table context */
  margin: -8px -12px;

  max-width: 320px;
}
.imagesPreview.theme-for-table > .image {
  margin: 2px;

  max-height: 4ch;
  max-width: 150px;
}

.imagesPreview.theme-for-rows {
  /* negative margin used to imitate behavior of flex gap */
  margin: -4px;

  max-width: 100%;
}
.imagesPreview.theme-for-rows > .image {
  margin: 4px;

  max-height: 8ch;
  max-width: 100%;
}
