.filePath {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fileInput {
  display: flex;
  align-items: stretch;
}
.fileInput > *:first-child {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 6px;
}
.fileInput > *:last-child {
  flex-grow: 0;
  flex-shrink: 0;
}

.imageInput > *:not(:last-child) {
  margin-bottom: 6px;
}

.imagePreview {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5;

  cursor: pointer;

  width: 100%;

  border: 1px solid #dddbd7;
  border-radius: 4px;
  overflow: hidden;

  font-size: 13px;
  color: #807e7c97;
  background: #fbfaf9;

  transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
}
.imagePreview img {
  max-height: 100%;
  max-width: 100%;
}
.imagePreview.disabled {
  cursor: not-allowed;
}

.dropzone {
  height: 50px;
  background: black;
}

.suffixWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.suffixWidthProvider {
  display: flex;
  flex-direction: column;
  height: 0;
  opacity: 0;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}
.uploadingText {
  animation: blinker 1s linear infinite;
}
