.multilineCopyFiled {
  display: flex;
  flex-direction: column;
}
.multilineCopyFiled > *:first-child {
  margin-bottom: 10px;
}

.suffixWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;

  justify-content: center;
  align-items: center;
}
.suffixWidthProvider {
  display: flex;
  flex-direction: column;
  height: 0;
  opacity: 0;
}
