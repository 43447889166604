/* -------------------------------- CONTAINER ------------------------------- */

.breadcrumbsContainer {
  min-height: 16px;
  background-color: #fff;
}

/* Additional inner container is added so that
absolute positioned padding and content padding can be set with same style */
.breadcrumbsContainerInner {
  box-sizing: border-box;

  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;
  overflow: hidden;

  position: relative;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;

  height: 100%;
}
.breadcrumbsContainerInner > * {
  flex-shrink: 0;
  flex-grow: 0;
}

/* ---------------------------------- ITEM ---------------------------------- */

.breadcrumbsItem {
  flex-shrink: 1;
  flex-grow: 0;
  min-width: 0;

  height: 100%;

  display: flex;
  align-items: center;
}
.breadcrumbsItem > *:not(:last-child) {
  margin-right: 10px;
}
.breadcrumbsItem.clickable {
  cursor: pointer;
}
.breadcrumbsItem.clickable:hover {
  opacity: 0.6;
}

.breadcrumbsItem > .icon {
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  align-items: center;
}

/* ------------------------------ TEXT IN ITEM ------------------------------ */

.breadcrumbsText {
  flex-shrink: 0;
  flex-grow: 0;
  white-space: nowrap;

  font-weight: 500;

  font-size: 13px;
  line-height: 16px;
  color: #454442;

  /* Needs to be block or inline-block or ellipsis wont work */
  display: block;
}
.breadcrumbsText > *:not(:last-child) {
  margin-right: 0.75ch;
}
.breadcrumbsText.shrinkable {
  flex-shrink: 1;
  min-width: 0;

  overflow: hidden;
  text-overflow: ellipsis;
}
.breadcrumbsText strong {
  font-weight: 600;
}

/* ---------------------------------- SLASH --------------------------------- */

.breadcrumbsSlash {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
}
.breadcrumbsSlash::after {
  content: '';
  display: block;

  width: 1px;
  background-color: #b4b2af;
  height: 16px;

  transform: rotate(20deg);
}

/* ----------------------- FLOATING SUFFIX AND FADEOUT ---------------------- */

.breadcrumbsFloatingSuffix {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  right: 0;
  height: 100%;

  font-size: 13px;
  line-height: 16px;
  color: #302f2e;
  background-color: #fff;
}
.breadcrumbsFloatingSuffix:not(:empty) {
  padding-left: 10px;
}
.breadcrumbsFloatingSuffix > *:not(:last-child) {
  margin-right: 10px;
}
.breadcrumbsFloatingSuffix::before {
  content: '';
  display: block;
  position: absolute;

  top: 0;
  height: 100%;

  left: -50px;
  width: 50px;

  /* NOTE: safari seems to not support 'transparent' keyword */
  background: linear-gradient(to right, hsla(0, 0%, 100%, 0), hsl(0, 0%, 100%));
}
