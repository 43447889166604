.checkLabel {
  user-select: none;
  min-height: 50px;
  color: #807e7c;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  cursor: pointer;
}

.checkLabel > *:not(:last-child) {
  margin-left: 10px;
}
.checkLabel > *:last-child {
  margin-left: auto;
}

.checkLabel.borderTop {
  border-top: 1px solid #eae8e4;
}

.checkLabel.borderBottom {
  border-bottom: 1px solid #eae8e4;
}

.checkLabel:disabled,
.checkLabel.disabled {
  cursor: default;
  opacity: 0.7;
  pointer-events: none;
}
