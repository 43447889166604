.color {
  flex-shrink: 0;

  box-sizing: border-box;
  border: 1px solid transparent;
}

.size-small {
  width: 10px;
  height: 10px;
}
.size-medium {
  width: 14px;
  height: 14px;
}
.size-big {
  width: 20px;
  height: 20px;
}

.shape-circle {
  border-radius: 100%;
}
.shape-square {
  border-radius: 1px;
}
