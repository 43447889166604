.formStatus {
  flex-shrink: 0;
  flex-grow: 0;

  width: fit-content;

  user-select: none;

  box-sizing: border-box;
  text-transform: uppercase;

  font-size: 10px;

  font-weight: 600;
  line-height: 12px;

  padding: 2px 6px;

  background-color: transparent;
  border: 1px solid currentColor;

  border-radius: 1000px;
}

.formStatus.status-draft {
  color: #807e7c;
}
.formStatus.status-paused,
.formStatus.status-disabled {
  color: #454442;
  background-color: #fbc75d;
  border-color: #fbc75d;
}
.formStatus.status-live {
  color: #f05252;
}

.formStatus.theme-white {
  color: #807e7c;
  background-color: #ffffff;
  border-color: #ffffff;
}
