.formRow {
  margin: 0 0 15px 0;
  width: 100%;
  position: relative;
}

.formRow.hide {
  display: none;
}

.formRow label {
  color: #656565;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  margin: 0 0 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.formRow label .toggle {
  margin: 0 8px 0 0;
}

.formRow label span {
  color: #FA6D78;
  padding: 0 0 0 1px;
}

.formRow .errorMessage {
  color: #D43D51;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin: 5px 0 0 0;
}

.formRow .description {
  color: #656565;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  padding: 5px 0 0 0;
}
