.formLabel {
  display: flex;
  align-items: stretch;

  cursor: pointer;
}

.formLabel > * {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------------- BREADCRUMBS ------------------------------ */

.breadcrumbs {
  user-select: none;

  display: flex;
  align-items: center;

  white-space: nowrap;

  font-size: 13px;
  line-height: 24px;
  font-weight: 500;

  color: #b4b2af;

  margin-right: 6px;
}
.breadcrumbs > *:not(:last-child) {
  margin-right: 0.7ch;
}

.breadcrumbs > .workspace {
  cursor: pointer;
}
.breadcrumbs > .workspace:hover {
  color: #454442;
}
.breadcrumbs > .slash {
  transform: scaleY(1.3) rotateZ(10deg);
}
.breadcrumbs > .form {
  color: #454442;
}

/* ---------------------------------- OTHER --------------------------------- */

.status {
  margin-right: 16px;
}

.icon > * {
  transform: translateX(-4px);
}
