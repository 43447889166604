.header {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #E7E7E7;
}

.header span {
  color: #363636;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}

.content {

}

.footer {
  padding: 15px 0 0 0;
  border-top: 1px solid #E7E7E7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
}
