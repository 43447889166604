.emptyHint {
  border-radius: 2px;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  padding: 20px 15px;
  width: 100%;
  box-sizing: border-box;
}

.emptyHint.borderTop {
  border-top: 1px solid #EAE8E4;
}

.emptyHint.borderBottom {
  border-bottom: 1px solid #EAE8E4;
}

.icon {
  margin: 0 0 10px 0;
}