/* --------------------------- MESSAGE IN TOOLTIP --------------------------- */

.activityStatus {
  display: flex;
  align-items: center;

  font-size: 12px;
  line-height: 16px;

  cursor: pointer;
  user-select: none;
}
.activityStatus:hover > .message strong {
  text-decoration: underline;
}

.activityStatus > .icon {
  margin-left: 6px;
}

.activityStatus > .message {
  color: #cbc9c5;

  max-width: 150px;
}

.activityStatus > .message strong {
  color: white;
  font-weight: 600;
}

/* ----------------------------- LIST OF AVATARS ---------------------------- */

.whoIsHere {
  user-select: none;

  display: flex;
  align-items: center;
}
.whoIsHere > *:not(:last-child) {
  margin-right: 10px;
}
.whoIsHere.size-small > *:not(:last-child) {
  margin-right: 6px;
}

.hiddenUsersCount {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;

  font-weight: 500;

  white-space: nowrap;
}

.whoIsHere.theme-overlay .hiddenUsersCount {
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;

  color: #454442;
  background-color: #ffffff;

  border-radius: 2px;

  padding: 2px 3px;

  margin-left: 5px;

  box-shadow: 0px 0px 0px 1px rgba(48, 47, 46, 0.07), 0px 8px 8px 0px rgba(48, 47, 46, 0.07), 0px 2px 2px 0px rgba(48, 47, 46, 0.07),
    0px 1px 1px 0px rgba(48, 47, 46, 0.07);
}

.whoIsHere.theme-overlay .avatar {
  filter: drop-shadow(1px 1px 1px #0008);
}

.whoIsHere .avatar.notActive {
  filter: grayscale(1);
  opacity: 0.2;
}

.placeholder {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;

  color: #807e7c;
  font-weight: 400;
}
