.setting {
  min-width: 0;
}

.setting strong {
  font-weight: 600;
}

/* Grid layout logic - left input */
.inputPlacement-left.setting {
  display: grid;
  grid-template-columns: min-content auto;
}
.inputPlacement-left > .toggle {
  grid-column-start: 1;
  align-self: center;
}
.inputPlacement-left > .title {
  grid-column-start: 2;
  align-self: center;
}
.inputPlacement-left > .description {
  grid-column-start: 2;
}
.inputPlacement-left > .children {
  grid-column-start: 2;
}

/* Input will be placed in the middle between title and description */
.inputMiddled > .toggle {
  grid-row-start: 1;
  grid-row-end: 3;
}
.inputMiddled > .title + .description {
  margin-top: 5px;
}

/* Grid layout logic - right input */
.inputPlacement-right.setting {
  display: grid;
  grid-template-columns: auto min-content;
}
.inputPlacement-right > .toggle {
  grid-column-start: 2;
  align-self: center;
}
.inputPlacement-right > .title {
  grid-column-start: 1;
  align-self: center;
}
.inputPlacement-right > .description {
  grid-column-start: 1;
}
.inputPlacement-right > .children {
  grid-column-start: 1;
}

/* Toggle styles */
.inputPlacement-left > .toggle {
  margin-right: 10px;
}
.inputPlacement-right > .toggle {
  margin-left: 10px;
}

/* Title styles */
.title {
  display: flex;
  align-items: center;
  min-height: 20px;
}
.title > *:not(:last-child) {
  margin-right: 10px;
}
.title > *[data-component='icon'] {
  height: 14px;
}

/* Setting content (shows when toggle is in true state) */
.children {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.children > * {
  width: 100%;
}

/* Content hiding */
.children[data-hidden='true'] {
  height: 0;
  opacity: 0;
  transition: none;
  pointer-events: none;
}
.children.animated {
  will-change: transform, opacity;
  transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
}
.children.animated[data-hidden='true'] {
  transition: none;
}

/* ----------------------------- SPACING ---------------------------- */

/* Between header and children */
.gaps-big > .children:not([data-hidden='true']),
.gaps-big > .children + .description {
  margin-top: 15px;
}
.gaps-small > .children:not([data-hidden='true']),
.gaps-small > .children + .description {
  margin-top: 8px;
}

/* Between children items */
.gaps-big > .children > *:not(:last-child) {
  margin-bottom: 15px;
}
.gaps-small > .children > *:not(:last-child) {
  margin-bottom: 8px;
}

/* Translation during animation */
.gaps-big > .children[data-hidden='true'] {
  transform: translateY(-15px);
}
.gaps-small > .children[data-hidden='true'] {
  transform: translateY(-8px);
}
