/*
 * addIconWithLoader
 */
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addIconWithLoader {
  position: relative;
}

.inheritColor * {
  fill: currentColor;
}

.addIconWithLoader.loading,
.addIconWithLoader.loading * {
  cursor: default !important;
}

.addIconWithLoader svg path {
  transition: opacity 0.3s ease-in-out;
}

.addIconWithLoader.loading svg path {
  opacity: 0;
}

.addIconWithLoader .spinner {
  position: absolute;
  right: 5px;
  top: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.addIconWithLoader.loading .spinner {
  opacity: 1;
}

.addIconWithLoader svg {
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  position: relative;
}

.addIconWithLoader:hover svg rect {
  stroke: #b4b2af;
}

.addIconWithLoader:active svg rect {
  border-color: #454442;
}

.addIconWithLoader .activeShadow {
  position: absolute;
  top: 6px;
  left: 6px;
  box-shadow: 0px 0px 0px 2px #eae8e4;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.addIconWithLoader:active .activeShadow {
  opacity: 1;
}

/*
 * upgradeIcon
 */

.upgradeIcon {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  position: relative;
  padding: 0 0 0 0;
  cursor: pointer;
  user-select: none;
}

.upgradeIcon.purple {
  color: #9061f9;
}

.upgradeIcon.green {
  color: #0f9f6e;
}

.upgradeIcon.blue {
  color: #2675d1;
}

.upgradeIcon svg {
  position: relative;
  top: 2px;
  margin: 0 7px 0 0;
}

.iconInRectangle {
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 34px;
  height: 34px;

  background-color: #f6f5f4;
  border-radius: 3px;
}

/* ----------------------------- CSS ANIMATIONS ----------------------------- */

@keyframes headShake {
  0% {
    transform: translateX(0);
  }
  6.5% {
    transform: translateX(-2px) rotateY(-7deg);
  }
  18.5% {
    transform: translateX(1px) rotateY(5deg);
  }
  31.5% {
    transform: translateX(-0.5px) rotateY(-3deg);
  }
  43.5% {
    transform: translateX(0.25px) rotateY(1deg);
  }
  50% {
    transform: translateX(0);
  }
}
.animation-headShake {
  animation-timing-function: ease-in-out;
  animation-name: headShake;
  animation-duration: 1s;
}
