.main {
  flex-shrink: 0;
  background: #dddbd7;
  width: 100%;
  height: 1px;
  box-sizing: border-box;
  border: none;
  margin: 0;
  position: relative;  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.main span {
  display: inline-block;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  padding: 0 15px;
}

.margin {
  margin: 18px 0;
}
