.main .content {
  position: relative;
  box-sizing: border-box;

  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  font-size: 13px;
  font-weight: 400;
  line-height: 16px;

  color: #656565;
}

.digitsTitle {
  width: 100%;
  margin: 0 0 5px 0;

  color: #807e7c;
  font-size: 13px;

  text-align: center;
}

.error {
  text-align: center;
  width: 100%;
  color: #f05252;
  font-size: 13px;
  line-height: 16px;
}
