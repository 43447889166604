.content {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 84px);
  max-height: calc(90vh - 84px);
  overflow-y: auto;
}

.services {
  list-style: none;
  padding: 0;
  margin: 0;
}

.services li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.services li:not(:first-child) {
  margin: 20px 0 0 0;
}

.services li .left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.services li .left .name {
  margin: 0 0 0 10px;
}

.services li .left .name span {
  color: #454442;
  font-size: 13px;
  line-height: 16px;
}

.services li .left .name p {
  color: #807E7C;
  font-size: 12px;
  line-height: 14px;
  padding: 0;
  margin: 3px 0 0 0;
}

.loader {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.connections {
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
}

.connections li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.connections li.head {
  border-bottom: 1px solid #EAE8E4;
  padding: 10px 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.connections li.body {
  padding: 10px 0;
}

.connections li:last-child {
  border-bottom: none;
}

.connections li .top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.connections li .name {
  color: #454442;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 50px);
}

.connections li .currency {
  background-color: #5D5D5C;
  border-radius: 2px;
  padding: 1px 4px;
  color: #FBFAF9;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  margin: 0 0 0 7px;
}

.connections li .owner {
  color: #807E7C;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  margin: 3px 0 0 0;
}

.connections li .service {
  color: #454442;
  font-size: 13px;
  line-height: 16px;
}

.connections li .mode {

  border-radius: 2px;
  padding: 1px 4px;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  margin: 0 0 0 7px;
}

.connections li .mode.sandbox {
  background-color: #FFE8E2;
  color: #FF623D;
}

.connections li .mode.production {
  background-color: #eafdf6;
  color: #0F9F6E;
}

.connections li .actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.connections li .actions button:first-child {
  margin: 0 20px 0 0;
}