.content {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 60px);
  max-height: calc(90vh - 60px);
  overflow-y: auto;
}

.info {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
}

.buttons {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 0 0;
}

.buttons button:first-child {
  margin: 0 15px 0 0;
}

.domains {
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0 0 0;
  padding: 0;
}

.domains li {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.domains li.domainsHead {
  border-bottom: 1px solid #EAE8E4;
  padding: 10px 0;
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.domains li.domainsBody {
  padding: 10px 0;
}

.domains li:last-child {
  border-bottom: none;
}

.loader {
  background: rgba(251, 250, 249, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
