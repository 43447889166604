.inlineHint {
  box-sizing: border-box;
  user-select: none;

  text-align: left;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.inlineHint > *:not(:last-child) {
  margin-bottom: 8px;
}

.inlineHint .hint {
  color: #807e7c;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.inlineHint .hint svg {
  margin: 0 8px 0 0;
}

.inlineHint .hint svg * {
  fill: #dbdad8;
}

.inlineHint .error {
  color: #f05252;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.inlineHint .error svg {
  margin: 0 8px 0 0;
}
