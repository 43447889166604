.colorPicker {
  display: inline-flex;
}

/* react-color */
.chromePicker > * {
  border: none !important;
  box-shadow: none !important;
}
.chromePicker * {
  font-family: inherit !important;
}

/* palette with presets */
.palette {
  display: flex;
  width: 60px;
  height: 225px;
}
