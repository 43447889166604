.main {
  background-color: #f6f5f4;
  border-radius: 8px 0 0 8px;
  box-shadow: 1px 0 0 0 #dddbd7;
  width: 200px;
  min-width: 200px;
  box-sizing: border-box;
  height: 100%;
}

.list {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list .head {
  width: 100%;
  height: 36px;
  color: #807e7c;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  padding: 0 10px 0 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #eae8e4;
}

.list .head:not(:first-child) {
  margin: 20px 0 0 0;
}

.list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list ul li {
  padding: 0 10px 0 15px;
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
}

.list ul li:hover {
  background: #eae8e4;
}

.list ul li:active,
.list ul li.active {
  background: #dddbd7;
}

.list ul li.active {
  color: #454442;
}

.list ul li .label {
  color: #807e7c;
  font-size: 13px;
  line-height: 16px;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.list ul li .label .balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.list ul li .label .balance .amount {
  margin: 0 0 0 3px;
}

.list ul li .label .badge {
  color: #454442;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
}

.list ul li .icon {
  margin: 0 10px 0 0;
  width: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.list ul li .avatar {
  margin: 0 7px 0 0;
}
