.container {
  flex-shrink: 0;

  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  box-sizing: border-box;
  height: 28px;
  width: 65px;
  padding: 0;

  background-color: hsla(0, 0%, 100%, 0%);
  border: 1px solid hsla(0, 0%, 100%, 0%);
  box-shadow: 0 1px 1px 0 rgba(48, 47, 46, 0);
  border-radius: 4px;

  will-change: width, padding, border-color, background-color;
  transition: padding 0.2s ease-in-out, 0.2s ease-in-out, background-color 0.2s ease-in-out, width 0.3s ease-in-out;
}
.container:focus-within,
.container.filled {
  width: 150px;
  padding: 0 6px;
  background-color: hsla(0, 0%, 100%, 100%);
  border-color: #dddbd7;
  box-shadow: 0 1px 1px 0 rgba(48, 47, 46, 0.07);
}

.field {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  width: 100%;
}
.container .icon {
  transform: translateY(1px);
  margin-right: 2px;
}

/* Text color managment */
.field::placeholder {
  transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.field,
.field::placeholder {
  color: #5d5d5c;
}
.container:focus-within .field,
.container.filled .field {
  color: #807e7c;
}
.container:focus-within .field::placeholder,
.container.filled .field::placeholder {
  color: #807e7c;
  opacity: 0.6;
}
