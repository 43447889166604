.rangeArrows {
  display: flex;
  align-items: center;
}
.rangeArrows > span {
  text-align: center;
  min-width: 35px;
}
.rangeArrows > * + * {
  margin-left: 5px;
}
.rangeArrows > *:last-child {
  transform: rotateZ(180deg);
}
