/*
  Styles for react-table useGridLayout plugin
*/

/* ------------------------------ MAIN WRAPPERS ----------------------------- */

.table {
  width: fit-content;

  border: 1px solid #cbc9c5;
  background-color: #fff;
}

.row,
.body,
.header,
.footer {
  display: contents;
}

/* ---------------------------------- CELLS --------------------------------- */

.cell {
  position: relative;
  box-sizing: border-box;

  min-width: 100px;

  font-size: 13px;
  font-weight: 400;

  padding: 12px 16px;
  background-color: #fff;

  overflow: hidden;
}
.cell {
  border: 1px solid #f6f5f4;
}
.header .cell,
.footer .cell {
  border: 1px solid #eae8e4;
  white-space: nowrap;
}

/* Hovers */
.body .row:hover > .cell {
  background-color: #fbfaf9;
}
.body .row:hover > .cell:hover {
  background-color: #f6f5f4;
}

/* Collapsing borders  */
.header + .body > .row:first-child > .cell,
.row:not(:first-child) > .cell {
  margin-top: -1px;
}
.cell:not(:first-child) {
  margin-left: -1px;
}

/* Positioning items */
.header .cell {
  display: flex;
  align-items: center;
}
.header .cell > *:not(:last-child) {
  margin-right: 8px;
}

/* ---------------------------- INTERACTIVE CELLS --------------------------- */

.body .cell[data-interactive-td]:focus {
  border: 1px solid #b4b2af;
}
.body .cell[data-interactive-td]:focus-within:not(:focus) {
  border: 1px solid #ff623d;
  outline: 2px solid #ff623d;
  outline-offset: -2px;
}

/* ---------------------------- HEADER AND FOOTER --------------------------- */

/* Darker cells with less spacing */
.header .cell,
.header .cell {
  padding: 8px 16px;
  background-color: #f6f5f4;
}

/* ----------------------------- STICKY STYLING ----------------------------- */

/* Sticky columns (logic is provided by react-table-sticky) */
.cell[data-sticky-last-left-td] {
  border-right-color: #b4b2af;
  box-shadow: 1px 0 rgba(0, 0, 0, 0.05), 2px 0 rgba(0, 0, 0, 0.025), 3px 0 rgba(0, 0, 0, 0.01);
}
.cell[data-sticky-first-right-td] {
  border-left-color: #b4b2af;
  box-shadow: -1px 0 rgba(0, 0, 0, 0.05), -2px 0 rgba(0, 0, 0, 0.025), -3px 0 rgba(0, 0, 0, 0.01);
}

/* Sticky header and footer */
.header.sticky .cell {
  position: sticky;
  top: 0;
  border-bottom-color: #b4b2af;
  box-shadow: 0px 1px rgba(0, 0, 0, 0.05), 0px 2px rgba(0, 0, 0, 0.015);
}
.footer.sticky .cell {
  position: sticky;
  bottom: 0;
  border-top-color: #b4b2af;
  box-shadow: 0px -1px rgba(0, 0, 0, 0.05), 0px -2px rgba(0, 0, 0, 0.015);
}

/* -------------------------------- Z INDEXES ------------------------------- */

/* Focused cell */
.cell:focus,
.cell:focus-within {
  z-index: 1;
}

/* Sticky column */
.cell[data-sticky-td] {
  z-index: 10;
}

/* Focused cell in sticky column */
.cell:focus[data-sticky-td],
.cell:focus-within[data-sticky-td] {
  z-index: 11;
}

/* Sticky header/footer */
.header.sticky .cell,
.footer.sticky .cell {
  z-index: 20;
}

/* Sticky column in sticky header/footer */
.header.sticky .cell[data-sticky-td],
.footer.sticky .cell[data-sticky-td] {
  z-index: 21;
}

/* ------------------------- ROWS INDEXES/SELECTION ------------------------- */

.rowSelector {
  position: relative;
  min-width: 18px;
}

.rowSelectorCheckbox {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);

  visibility: hidden;
}

/* Checkbox is showed on row hover, if it's already checked or if it's in header */
.header .rowSelectorLabel,
.rowSelector.checked .rowSelectorLabel,
.row:hover .rowSelectorLabel {
  visibility: hidden;
}
.header .rowSelectorCheckbox,
.rowSelector.checked .rowSelectorCheckbox,
.row:hover .rowSelectorCheckbox {
  visibility: visible;
}

/* ------------------------------- PLACEHOLDER ------------------------------ */

@keyframes blinker {
  50% {
    opacity: 0.7;
  }
}

@keyframes slider {
  0% {
    opacity: 1;
    transform: translateX(-100px);
  }
  100% {
    opacity: 0;
    transform: translateX(300px);
  }
}

.cellPlaceholder {
  position: absolute;
  display: none;
  visibility: hidden;

  overflow: hidden;

  background-color: #f6f5f4;

  top: 50%;
  left: 16px;
  max-width: calc(100% - 32px);
  height: 1ch;

  transform: translateY(-50%);

  animation: blinker 2s linear infinite;
}
.cellPlaceholder::after {
  position: absolute;

  content: '';
  width: 50px;
  height: 100%;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.05) 51%, rgba(255, 255, 255, 0) 100%);

  animation: slider 2s linear infinite;
}

.loading {
  pointer-events: none;
  user-select: none;
}

.loading .body .cell {
  color: transparent;
}
.loading .body .cell > * {
  visibility: hidden;
}
.loading .cellPlaceholder {
  display: block;
  visibility: visible !important;
}

.indexCell {
  display: flex;
  flex-direction: column;
}
.indexCellContent {
  margin-top: 15px;
}
.indexCellContent,
.indexCellMainContent,
.indexCellHoverContent {
  display: flex;
}
.indexCellContent > *:not(:last-child),
.indexCellMainContent > *:not(:last-child),
.indexCellHoverContent > *:not(:last-child) {
  margin-right: 8px;
}

.indexCellHoverContent {
  visibility: hidden;
}
.row:hover .indexCellHoverContent {
  visibility: visible;
}
