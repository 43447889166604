.content {
  padding: 20px 30px 50px 30px;
  box-sizing: border-box;
  min-height: calc(90vh - 84px);
  max-height: calc(90vh - 84px);
  overflow-y: auto;
}

/* colors */
.bold {
  color: #454442 !important;
  font-weight: 500 !important;
}

.blue {
  color: #2675d1 !important;
  font-weight: 500 !important;
}

.purple {
  color: #9061f9 !important;
  font-weight: 500 !important;
}

.orange {
  color: #ff623d !important;
  font-weight: 500 !important;
}

/* -------------------------------- ROW CELLS ------------------------------- */

.body .row {
  box-sizing: border-box;

  font-size: 13px;
  line-height: 16px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.body .row > div {
  box-sizing: border-box;

  width: 25%;
  padding: 10px 15px;

  font-weight: 400;
  color: #807e7c;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  border: 1px solid #eae8e4;
  border-left: none;
  border-top: none;
}

.body .row > div:last-child {
  padding-right: 5px;
  border-right: 0;
}

.body .row > div:first-child {
  padding-left: 5px;
}

.body .row:last-child div {
  border-bottom: 0;
}


.body .row > div.label {
  font-weight: 400;
  color: #807e7c;
}

.description {
  color: #807E7C;
  font-size: 13px;
  line-height: 22px;
  margin: 5px 0 0 0;
}

/* logs */
.logs .log {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0;
  border-bottom: 1px solid #EAE8E4;
}

.logs .log:last-child {
  border-bottom: 0;
}

.logs .log .date {
  color: #454442;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin: 0 20px 0 0;
}

.logs .log .text {
  color: #807E7C;
  font-size: 13px;
  line-height: 16px;
}

.addCollaborator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}