.optional {
  user-select: none;
  min-height: 50px;
  box-sizing: border-box;
  padding: 10px 0;
}

.optional.borderTop {
  border-top: 1px solid #eae8e4;
}

.optional.borderBottom {
  border-bottom: 1px solid #eae8e4;
}

.optionalLabel {
  color: #807e7c;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
}

.optionalLabelTitle {
  width: 100%;
  min-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.optionalLabelTitle > .icon {
  margin-left: auto;
  padding-left: 10px;
}

.optionalLabelTitle svg {
  transition: transform 0.1s ease-in-out;
  margin: 0 0 0 10px;
}

.optionalLabelTitle .rotate svg {
  transform: rotate(-45deg);
}
