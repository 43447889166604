/* Text above select */
.label {
  display: flex;
  align-items: center;

  margin-top: 18px;
  margin-bottom: 8px;
}
.label > *:not(:last-child) {
  margin-right: 10px;
}

/* Text under select */
.description {
  display: flex;
  flex-direction: row;

  margin-top: 10px;
  margin-bottom: 10px;
}
.description > *:not(:last-child) {
  margin-right: 7px;
}

/* Cancel, confirm buttons */
.buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.buttons > *:last-child {
  margin-left: 20px;
}
