.absoluteLoader {
  z-index: 100;

  position: absolute;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: wait;
}
.inlineLoader {
  padding: 20px 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.spinnerContainer > *:not(:last-child) {
  margin-bottom: 15px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.spinnerText {
  user-select: none;

  color: #807e7c;

  min-width: 0;

  font-size: 11px;
  line-height: 1.5;

  padding: 0px 10px;
  text-align: center;

  animation: fadeIn 3s;
}
